<template>
<div>
    <div class="px-5 pt-2 pb-5">
        <div class="d-flex flex-row-reverse">
            <div class="mt-1 mb-4 btn-home">
                <button type="button" class="btn btn-outline-main ms-1" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div id="div-modal-anular" class="w-100">
            <div id="div-anular" class="card w-100">
                <div class="text-center card-header text-white h4 bg-main">
                    <strong>ORDEN DE SERVICIO</strong>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="w-100 border-box-main pt-4" style="background-color: #f2f4f4">
                        <div class=" row mx-0 px-0 responsive-search px-4">
                            <div class="col-xl-10 px-0 pe-xl-1">
                                <div class="input-group  input-group mb-3">
                                    <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                    <input v-model="filter.servicio" @keyup.enter="mtdChangeFilter()" type="text" class="form-control form-control" style="background-color: #f9e79f" placeholder="Nombre de servicio" />
                                </div>
                            </div>

                            <!-- <div class="col-xl-2 px-0 px-xl-1 ">
                                    <div class=" col-xl-2 input-group input-group mb-3 ">
                                        <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                        <input v-model="filter.proveedor" @keyup.enter="mtdChangeFilter()" type="text" class="form-control form-control" style="background-color: #aed6f1" placeholder="PROVEEDOR" />
                                    </div>

                                </div>
                                <div class="col-xl-2 px-0">
                                    <div class="input-group input-group mb-3 pe-xl-1 ">
                                        <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                        <input v-model="filter.ruc" @keyup.enter="mtdChangeFilter()" type="text" class="form-control form-control" style="background-color: #abebc6" placeholder="RUC" />
                                    </div>

                                </div> -->
                            <div class="col-xl-2 px-0">
                                <div class="input-group input-group mb-3">
                                    <span class="input-group-text text-white bg-main " @click="mtdChangeFilterOS()"><i class="fas fa-search"></i></span>
                                    <input v-model="filter_os_id" type="text" @keyup.enter="mtdChangeFilterOS()" class="form-control form-control" style="background-color: #f5b7b1" placeholder="O.S." />
                                </div>
                            </div>
                        </div>
                        <div class="cuerpo">
                            <OS v-if="page == 'os'" :filter_os="filter_os_id" />
                            <Servicio v-if="page == 'servicio'" :servicio="Servicio" :ordendeservicio="ordendeservicio" @mtdAddItemCarrito="mtdAddItemCarrito" @mtdviewcarrito="mtdOpenModalCarrito" @registro-exitoso="manejarRegistroExitoso" />
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
    <cModalViewCarritoOrdenServicioVue :numcarrito="numcarrito" :title="modalCarrito.title" :boo_modal="modalCarrito.modal_form" @closeModalCarrito="closeModalCarrito" :ordendeservicio="ordendeservicio" @registro-exitoso="manejarRegistroExitoso" />
    <cModalPreview :title="modalPreview.title" :boo_modal="modalPreview.modal_form" :ordendeservicio="ordendeservicio" @mtdclosemodal="closeModalCarrito" @mtdOpenModalCarrito="mtdOpenModalCarrito" @mtdstoredata="mtdstoredata"></cModalPreview>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import OS from '@/components/Logistica/pages/Ordendeservicios/Ospendiente.vue'
import Servicio from '@/components/Logistica/pages/Ordendeservicios/Servicio.vue'
import cModalViewCarritoOrdenServicioVue from "../modals/Os/cModalViewCarritoOrdenServicio.vue";
import cModalPreview from '../modals/Os/cModalPreview.vue';
export default {
    name: "c-logistica-pages-ordendeservicio",
    data() {
        return {
            filter: {
                servicio: ''
            },
            filter_os_id: "",
            page: "os",
            Servicio: [],
            modalCarrito: {
                title: '',
                modal_form: false
            },
            modalPreview: {
                title: '',
                modal_form: false
            },
            ordendeservicio: [],
            itemOrdendeServicio: {
                cabecera: {},
                detalle: []
            },
            cargado_edit: '',
            numcarrito: null,
            swal: null,

        }
    },
    components: {
        OS,
        Servicio,
        cModalViewCarritoOrdenServicioVue,
        cModalPreview,
        SweetAlert,
    },
    methods: {
        ...mapActions(["get", "post"]),
        returnHome: function () {
            if (this.page == "servicio") {
                Swal.fire({
                    icon: "warning",
                    title: "SI SALE SE PERDERA LOS ITEMS, SI A AGREGADO EN EL CARRITO DE SERVICIOS",
                    width: "400px",
                    showDenyButton: true,
                    denyButtonText: `Rechazar`,
                    denyButtonColor: "#bb8a17",
                    confirmButtonText: "Permitir",
                    confirmButtonColor: "#3085d6",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$emit("returnHome");
                    }
                });
            } else {
                this.$emit("returnHome");
            }

        },
        mtdChangeFilter() {
            this.page = "servicio";
            this.post({
                    url: this.$store.getters.get__url + "/logistics/os/filter",
                    token: this.$store.getters.get__token,
                    params: this.filter,
                })
                .then((response) => {
                    this.Servicio = response.data;

                    this.mtdHide();

                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdHide() {
            this.filter = {
                servicio: ''

            }
        },
        mtdChangeFilterOS() {
            this.page = "os";
        },
        mtdOpenModalCarrito: function () {
            this.modalCarrito.modal_form = true;
            this.modalCarrito.title = "ORDENES DE SERVICIO ";
            this.modalPreview.modal_form = false;

        },
        mtdstoredata: function () {
            this.modalPreview.modal_form = false;
            this.post({
                    url: this.$store.getters.get__url + "/logistics/os/store",
                    token: this.$store.getters.get__token,
                    params: {
                        carrito: this.ordendeservicio,
                    },
                })
                .then((response) => {

                    let msg = response.data.length < 2 ? "una solicitud" : response.data.length + ' solicitudes';
                    let msgAmount = response.data.length < 2 ? " por el monto de: " : "por los montos de: "
                    for (let index = 0; index < response.data.length; index++) {
                        const element = response.data[index];
                        msgAmount += " S/. " + element.total;
                        window.open(
                            this.$store.getters.get__url +
                            "/logistics/os/viewpdf/" +
                            element.id,
                            "_blank"
                        );
                    }
                    //ENVIAR A WSP
                    const whatsappPhone = '+51959422813';
                    const gerente = 'Benjamin Medoza';
                    const tipo = ' ha registrado ' + msg + ' de Orden de servicio ';
                    const admin = this.$store.getters.get__user.name + ' ' + this.$store.getters.get__user.last_name;
                    window.open(
                        this.generateWhatsAppLinks(gerente, whatsappPhone, tipo, admin, msgAmount), '_blank');

                        this.$refs.SweetAlert.showSuccess("Registro exitoso");

                    this.manejarRegistroExitoso();
                })
                .catch((errors) => {});
        },
        mtdAddItemCarrito: function (payload) {

            let existeProveedor = this.ordendeservicio.some(item => item.cabecera.id === payload.proveedor.id);

            if (!existeProveedor) {
                let nuevoItemOrdenServicio = {
                    cabecera: {
                        id: payload.proveedor.id,
                        proveedor_name: payload.proveedor.name,
                        email: payload.proveedor.email,
                        phone: payload.proveedor.phone,
                        address: payload.proveedor.address,
                        ruc: payload.proveedor.ruc,
                        credit: payload.proveedor.credit,
                        expiration_days: payload.proveedor.expiration_days,
                        //expiration_days: "",
                        observation: '',
                        payment_condition: "",
                        comment: "",
                        total: '',
                        total_sin_igv: '',
                        type: "",
                        igv: ''
                        // send: '',
                    },
                    detalle: []
                };
                this.ordendeservicio.push(nuevoItemOrdenServicio);
            }
            let itemDetalle = {
                id: payload.id,
                servicio: payload.servicio_name,
                precio_purchase: payload.proveedor.price_purchase,
                quantity: 1,
                price_purchase: 0,
            };

            let ordenServicioProveedor = this.ordendeservicio.find(item => item.cabecera.id === payload.proveedor.id);

            let itemExistente = ordenServicioProveedor.detalle.find(detalle => detalle.id === payload.id);
            if (!itemExistente) {
                ordenServicioProveedor.detalle.push(itemDetalle);
                this.numcarrito = this.ordendeservicio.length;
                this.mtdOpenModalCarrito();
            } else {
        this.$refs.SweetAlert.showWarning("Servicio Ya Agregado");

            
            }
        },
        closeModalCarrito: function (option) {
            this.modalCarrito.modal_form = false;
            this.modalPreview.modal_form = false;
            this.modalCarrito.title = "";
            if (this.ordendeservicio.length == 0) {
                this.numcarrito = null
            }
            if (option == 'preview') {
                this.modalPreview = {
                    title: "ORDEN DE SERVICIO",
                    modal_form: true,
                }
            }
        },
        manejarRegistroExitoso() {
            this.ordendeservicio = [];
            this.itemOrdemCompra = {
                cabecera: {},
                detalle: [],
            };
            this.closeModalCarrito();
            this.mtdChangeFilterOS()
        },
        /** helpers */
        generateWhatsAppLinks(gerente, whatsappPhone, tipo, admin,msgAmount) {
            const message = `Hola ${gerente},el usuario ${admin} ${tipo} ${msgAmount}`;
            const encodedMessage = encodeURIComponent(message);
            // const whatsappLink=`https://wa.me/${whatsappPhone}?text=${encodedMessage}`;
            const whatsappLink = `https://api.whatsapp.com/send?phone=${whatsappPhone}&text=${encodedMessage}`;
            return whatsappLink;
        },
    }
}
</script>
