<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show.sync="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'xl'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
    :scrollable="true"
  >
    <div class="row d-flex">
      <!--Search Filtro -->  
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="input-group input-group">
          <span class="input-group-text text-white bg-main"
            ><i class="fas fa-search"></i
          ></span>
          <input
            v-model="search"
            type="text"
            class="form-control form-control"
            placeholder="Buscar Producto"
            @keyup="calculateData()"
          />
        </div>
      </div>  

      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3">
          <div class="col-md-12 table-responsive" id="div-tabla-anular">
              <table id="tb-anular" class="table table-bordered text-xs">
                  <caption class="mx-0 px-0"></caption>
                  <thead class="bg-main">
                      <tr class="text-white text-center align-middle">
                        <th scope="col">N°</th>
                        <th scope="col">LOTE</th>
                        <!-- <th class="nowrap">MARCA
                            <i class="fa fa-arrow-up arrow-order" @click="sortData('marca', 'asc')"></i>
                            <i class="fa fa-arrow-down arrow-order" @click="sortData('marca', 'desc')"></i>
                        </th> -->
                        <th scope="col">PRODUCTO</th>
                        <th scope="col">CANTIDAD</th>
                      </tr>
                  </thead>
                  <tbody class="border-main">
                    <tr v-if="cpData.length === 0" class="text-center">
                      <td colspan="4">No hay datos disponibles</td>
                    </tr>
                    <tr v-for="(det, index) in cpData" :key="index" class="text-center align-middle">
                      <template>
                        <td class="fw-semibold">{{ index + 1 }}</td>
                        <td>{{ det.lote}}</td>
                        <!-- <td>{{ det.marca ? det.marca : '-'}}</td> -->
                        <td>{{ det.product}}</td>
                        <td>{{ det.quantity_destination}}</td>
                      </template>
                    </tr>
                  </tbody>
              </table>
          </div>

      </div>
    </div>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import { mapActions } from "vuex";
export default {
  name: "c-Pharmacy-cModalMovDetail",
  components: {
    CModal,
  },
  data() {
    return {
      search: "",
      tabla: [],
      // Estado de orden
      sortKey: '',
      sortOrder: '',
    };
  },
  created() {
  },
  computed: {
    cpData() {
      /* let show = [];
      this.item.forEach((element) => {
        show.push(element);
      });
      this.tabla = show;
      return show; */
      return this.tabla;
    },
  },
  watch: {
    item: {
      handler(newVal) {
        this.tabla = newVal; 
      },
      immediate: true,
    },
  },
  props: {
    title: String,
    boo_modal: Boolean,
    id: Number,
    item: [],
  },
  methods: {
    ...mapActions(["get", "post"]),

    mtdClose() {
      this.mtdClear();
      this.$emit("mtdClose");
    },

    mtdClear() {
      this.search = "",
      this.tabla = []
    },

    calculateData() {
      if (this.search.length >= 2 && this.search.trim() !== "") {
        const query = this.search.toUpperCase();
        this.tabla = this.item.filter((element) => {
          const product = element.product ? element.product.toString().toUpperCase() : "";
          return product.includes(query);
        });
      } else {
        this.tabla = this.item;
      }
    },

    sortData(key, order) {
        if (this.sortKey && this.sortKey !== key) {
            this.sortOrder = ''; 
        }
        this.sortKey = key;
        this.sortOrder = order;

        this.item.sort((a, b) => {
            let result = 0;
            const valueA = a[key];
            const valueB = b[key];

            if (valueA < valueB) result = -1;
            if (valueA > valueB) result = 1;

            return order === 'asc' ? result : -result;
        });
    },
  },
};
</script>
<style scoped>
.align-middle{
  vertical-align: middle;
}
</style>
