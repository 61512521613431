<template>
<div class="  mx-0  px-2 py-3">
    <div id="div-modal-cxc" class="d-flex justify-content-center">
        <div id="div-cxc" class="card w-100">
            <div class="text-center card-header text-white h4 bg-main">
                <strong>DEVOLUCIONES</strong>
            </div>
            <div class="card-body w-100">
            <div class="row mx-0">

                <div class="col-md-12 ">
                    <div class=" d-flex justify-content-between">
                        <div class="mt-2 col-xl-3 ">
                            <select v-model="type" class=" form-control form-control-sm" aria-label="Default select example" @change="mtdDevoluciones">
                                <option v-for="(item, index) in destino" :key="index" :value="item">
                                    <span>
                                        {{
                                            item == 1 ? 'CONSULTA' : 
                                            item == 3 ? 'HOSPITALIZACIÓN':
                                            item == 6 ? 'SOP':
                                            item == 4 ? 'UCI ADULTO': 'EMERGENCIA' 
                                        }}
                                    </span>
                                </option>
                            </select>
                        </div>
                        <div>
                            <button v-if="devoluciones.length >0" class="btn btn-success btn-sm mt-2 px-3"  @click="addReturns" :disabled="mtddisable">
                              Devolver
                            </button>
                        </div>
                    </div>

                    <div class="row div-text mx-0 px-0 mb-2 text-center mt-3">
                        <table id="tb-anular" class="table table-bordered">
                            <caption class="mx-0 px-0"></caption>
                            <thead class="bg-main" style="font-size: 12px">
                                <tr class="text-white">
                                    <th scope="col" class="align-middle " style="width: 10%;">Seleccionar</th>
                                    <th scope="col" class="align-middle  " style="width: 70%;">Nombre de Producto</th>
                                    <th scope="col" class="align-middle " style="width: 20%;">Cantidad</th>
                                </tr>
                            </thead>
                            <tbody class="border-main" style="font-size: 11 px" v-if="devoluciones.length >0">
                                <tr class="tbody-table" v-for="(item, index) in devoluciones " :key="index">
                                    <td class="align-middle">
                                        <input class="form-check-input" v-model="item.status" type="checkbox" value="" id="flexCheckDefault">
                                    </td>
                                    <td class="align-middle">
                                        {{item.molecule }} {{ item.presentation }} {{ item.dosis }}
                                    </td>

                                    <td class="text-center">
                                        <input :disabled="!item.status || item.type" v-on:keypress="isNumber($event)" type="text" v-model="item.quantity" placeholder="Cantidad Entrante" class="form-control form-control-input form-control-sm" />
                                    </td>

                                </tr>

                            </tbody>
                            <tbody class=" text-center" v-else>
                                <tr>
                                    <td colspan="3" class=" text-center">
                                        <strong> No hay resultados</strong>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
        </div>
       
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";
export default {
    name: "c-asistencial-devoluciones",

    data() {
        return {
            swal: null,
            devoluciones: [],
            destino: [],
            type: null
        }
    },
    components: {
        SweetAlert
    },

    watch: {

        id_medical: {
            handler: function (newValue) {
                if (newValue != null && newValue != 0) {
                    this.mtdselect();
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        mtddisable: function () {
            let value = 0
            this.devoluciones.forEach(item => {
                if (!item.quantity && item.status) {
                    value++
                }
            })

            return value !== 0;
            
        }
    },
    props: {
        title: String,
        boo_modal: Boolean,
        id_medical: {
            type: Number,
            default: 0
        },
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdselect: function () {
            this.post({
                url: this.$store.getters.get__url + "/Emergency/indexMovimientos",
                token: this.$store.getters.get__token,
                params: {
                    id: this.id_medical
                }
            }).then((response) => {
                if (response.statusCode === 200) {
                    let destinoold = response.data 
                    this.destino =[]
                    destinoold.forEach(item => {
                        if(item != 1){
                            this.destino.push(item)
                        }
                    })
                    let num = this.destino.length
                    this.type = this.destino[num - 1];
                    this.mtdDevoluciones()
                }
            });
        },

        mtdDevoluciones: function () {
            this.post({
                url: this.$store.getters.get__url + "/Emergency/indexDevoluciones",
                token: this.$store.getters.get__token,
                params: {
                    id: this.id_medical,
                    type: this.type
                }
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.devoluciones = response.data;
                }
            });
        },

        addReturns: function () {
            this.post({
                url: this.$store.getters.get__url + "/Emergency/addReturns",
                token: this.$store.getters.get__token,
                params: {
                    id: this.id_medical,
                    returns: this.devoluciones
                }
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.mtdDevoluciones()
                    window.open(
                                this.$store.getters.get__url +
                                "/Emergency/viewpdf/" +
                                    this.id_medical + '/'+ this.type ,
                                "_blank"
                            );
                }
            });
        },

        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    },
}
</script>
