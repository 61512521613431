<template>
<div>
    <div class="px-5">
        <div class="d-flex justify-content-between">
            <div class="mt-3 mb-4">
                <button type="button" class="btn btn-secondary" @click="mtdChangeView('gestionPacientes')">
                    <i class="fas fa-arrow-left"></i> Volver
                </button>
            </div>
            <div class=" btn-home mt-3 mb-4">
                <button type="button" class="btn btn-outline-main" @click="mtdChangeView('home')">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <div class="card w-100 mb-lg-5">
                <div class="card-header text-white h4 bg-main">
                    <div class="row d-flex text-center">
                        <div class="col-lg-11 col-md-10 col-sm-9 col-xs-8">
                            <strong>{{ subpage == 'cola' ? 'COLA DE PACIENTES DEL DIA' : 'RESERVAS' }}</strong>
                        </div>
                        <div class="col-lg-1 col-md-2 col-sm-3 col-xs-4">
                            <div class="d-flex justify-content-end mt-sm-2" v-if="subpage == 'cola'">
                                <button type="button" class="btn btn-light" @click="mtdchangeSubPage('reserva')">Reservas</button>
                            </div>
                            <div class="d-flex justify-content-end mt-sm-2" v-if="subpage == 'reserva'">
                                <button type="button" class="btn btn-light" @click="mtdchangeSubPage('cola')">Volver</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body w-100">
                    <div v-if="subpage == 'cola'" class="d-flex flex-row flex-wrap justify-content-around mb-3">

                        <div id="div-listcola-adm" class="card m-3">
                            <div class="card-header text-white h6 header-main">
                                <strong>CONSULTAS</strong>
                            </div>

                            <div class="card-body" id="content-timeline">
                                <div class="row">

                                    <div class="px-sm-1 px-md-3">

                                        <div v-for="(item, index) in data.consultas" :key="index" class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                            <div>
                                                <span class="text-paciente">{{item.patient.name}}</span>
                                                <br />
                                                <span class="text-datos">{{item.hora}} - {{item.specialty}}</span>
                                                <br>
                                                <span class="text-datos"><b>DOCTOR:</b> {{ item.nameDoctor }}</span>
                                                <br>
                                                <span class="text-datos">{{ item.consultorio }}</span>
                                                <br>
                                                <button v-if="item.state == 1" @click="handleCheckIn(item,1)" type="button" class="btn btn-sm btn-outline-success">
                                                    <i class="fas fa-file-check"></i> CheckIn
                                                </button>
                                                <button v-if="item.state == 2 && item.acts == 0" @click="showPdfAfiliacion(item,1)" type="button" class="btn btn-sm btn-outline-primary">
                                                    <i class="fas fa-file-pdf"></i> Afiliacion
                                                </button>
                                            </div>

                                        </div>
                                        <div v-if="data.consultas && data.consultas.length == 0" class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                            <div class="color-main">
                                                <h6>NO HAY RESERVAS</h6>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div id="div-listcola-adm" class="card m-3">
                            <div class="card-header text-white h6 header-main">
                                <strong>OPERACIONES</strong>
                            </div>

                            <div class="card-body" id="content-timeline">
                                <div class="row">

                                    <div class="px-sm-1 px-md-3">

                                        <div v-for="(item, index) in data.sops" :key="index" class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                            <span class="text-paciente">{{item.patientName}}</span>
                                            <br />
                                            <span class="text-datos">{{item.hora}} - {{item.specialty}}</span>
                                            <br>
                                            <span class="text-datos">DOCTOR: {{ item.nameDoctor }}</span>
                                            <br>
                                            <!-- <span class="text-datos">{{ item.piso }} - {{ item.numero }}</span> -->
                                            <br>
                                            <button v-if="item.state == 1" @click="handleCheckIn(item,2)" type="button" class="btn btn-sm btn-outline-success">
                                                <i class="fas fa-file-check"></i> CheckIn
                                            </button>
                                            <button v-if="item.state == 2 && item.acts == 0" @click="showPdfAfiliacion(item,2)" type="button" class="btn btn-sm btn-outline-primary">
                                                <i class="fas fa-file-pdf"></i> Afiliacion
                                            </button>
                                        </div>
                                        <div v-if="data.sops && data.sops.length == 0" class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                            <div class="color-main">
                                                <h6>NO HAY RESERVAS</h6>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div id="div-listcola-adm" class="card m-3">
                            <div class="card-header text-white h6 header-main">
                                <strong>HOSPITALIZACIONES</strong>
                            </div>

                            <div class="card-body" id="content-timeline">
                                <div class="row">
                                    <div class="px-sm-1 px-md-3">

                                        <div v-for="(item, index) in data.hospitalizaciones" :key="index" class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                            <span class="text-paciente">{{item.patientName}}</span>
                                            <br />
                                            <span class="text-datos">{{item.hora}} - {{item.specialty}}</span>
                                            <br>
                                            <span class="text-datos">{{ item.piso }} - {{ item.numero }}</span>
                                            <br>
                                            <button v-if="item.state == 1" @click="handleCheckIn(item,3)" type="button" class="btn btn-sm btn-outline-success">
                                                <i class="fas fa-file-check"></i> CheckIn
                                            </button>
                                            <button v-if="item.state == 2 && item.acts == 0" @click="showPdfAfiliacion(item,3)" type="button" class="btn btn-sm btn-outline-primary">
                                                    <i class="fas fa-file-pdf"></i> Afiliacion
                                                </button>
                                        </div>
                                        <div v-if="data.hospitalizaciones && data.hospitalizaciones.length == 0" class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                            <div class="color-main">
                                                <h6>NO HAY RESERVAS</h6>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div id="div-listcola-adm" class="card m-3">
                            <div class="card-header text-white h6 header-main">
                                <strong>UCI</strong>
                            </div>

                            <div class="card-body" id="content-timeline">
                                <div class="row">
                                    <div class="px-sm-1 px-md-3">

                                        <div v-for="(item, index) in data.uciAdult" :key="index" class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                            <span class="text-paciente">{{item.patientName}}</span>
                                            <br />
                                            <span class="text-datos">{{item.hora}} - {{item.specialty}}</span>
                                            <br>
                                            <span class="text-datos">{{ item.piso }} - {{ item.numero }}</span>
                                            <br>
                                            <button v-if="item.state == 1" @click="handleCheckIn(item,4)" type="button" class="btn btn-sm btn-outline-success">
                                                <i class="fas fa-file-check"></i> CheckIn
                                            </button>
                                            <button v-if="item.state == 2 && item.acts == 0" @click="showPdfAfiliacion(item,4)" type="button" class="btn btn-sm btn-outline-primary">
                                                <i class="fas fa-file-pdf"></i> Afiliacion
                                            </button>
                                        </div>
                                        <div v-if="data.uciAdult && data.uciAdult.length == 0" class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                            <div class="color-main">
                                                <h6>NO HAY RESERVAS</h6>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div id="div-listcola-adm" class="card m-3">
                            <div class="card-header text-white h6 header-main">
                                <strong>EMERGENCIA</strong>
                            </div>

                            <div class="card-body" id="content-timeline">
                                <div class="row">
                                    <div class="px-sm-1 px-md-3">

                                        <div v-for="(item, index) in data.emergencia" :key="index" class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                            <span class="text-paciente">{{item.patientName}}</span>
                                            <br />
                                            <span class="text-datos">{{item.hora}} - {{item.specialty}}</span>
                                            <br>
                                            <span class="text-datos">{{ item.piso }} - {{ item.numero }}</span>
                                            <br>
                                            <button v-if="item.state == 1" @click="handleCheckIn(item,5)" type="button" class="btn btn-sm btn-outline-success">
                                                <i class="fas fa-file-check"></i> CheckIn
                                            </button>
                                            <button v-if="item.state == 2 && item.acts == 0" @click="showPdfAfiliacion(item,5)" type="button" class="btn btn-sm btn-outline-primary">
                                                <i class="fas fa-file-pdf"></i> Afiliacion
                                            </button>
                                        </div>
                                        <div v-if="data.emergencia && data.emergencia.length == 0" class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                            <div class="color-main">
                                                <h6>NO HAY RESERVAS</h6>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Reservas v-if="subpage == 'reserva'" />
                </div>
            </div>
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" @confirm="confirm" />
        <Filiacion :boo_modal="modal.modal_form" :title="modal.title" @mtdCloseModal="mtdCloseModal" :item="modal.item" />
    </div>

</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import Reservas from '../pages/Reservas.vue';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import Filiacion from '../modal/cModalDatosAfiliacion.vue';
export default {
    name: 'c-admision-page-cola',
    components: {
        Reservas,
        Filiacion,
        SweetAlert
    },
    data() {
        return {
            subpage: 'cola',
            swal: null,
            day: "",
            data: [],
            modal: {
                title: "",
                modal_form: false,
                item: {},
            },
            type: "",
            patient_id: null,
            reservation_id: null,
        };
    },
    created() {
        this.mtdGetData();

    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData: function () {
            //this.day = new Date().toISOString().split('T')[0];
            this.get({
                    url: this.$store.getters.get__url + "/Admision/cola/" + this.$store.getters.get__campus,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.data = response.data;
                })
                .catch((errors) => {
                    // this.$refs.SweetAlert.showError(errors);
                });
        },
        handleCheckIn(item, type) {
            //this.showModal(item);
            this.type = type;
            this.$refs.SweetAlert.confirmCheckin(item);
        },
        showModal(item) {
            //llamar al formulario
            this.modal = {
                title: "DATOS DE FILIACION",
                modal_form: true,
                item: item ? item : null,
            };
        },
        confirm(item) {
            this.post({
                    url: this.$store.getters.get__url + "/Admision/cola/checkin",
                    token: this.$store.getters.get__token,
                    params: {
                        option: this.type,
                        item: item
                    }
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        if(item.acts == 0){
                            this.showModal(item);
                        }else{
                            this.mtdGetData();
                        }
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        showPdfAfiliacion: function (item, opt) {
           // console.log(opt);
            let type = this.type  !== "" ? this.type  : opt;
            window.open(
                this.$store.getters.get__url + "/viewpdfAfiliacion/" + item.patient.id + "/"+item.reservation_id+"/"+type,
                "_blank"
            );
            
        },
        mtdCloseModal: function (pos, item) {
            this.modal = {
                title: "",
                modal_form: false,
                item: {},
            };
            if (pos == 0) {
                this.mtdGetData();
                this.showPdfAfiliacion(item);
            }
            this.type = "";

        },
        mtdChangeView: function (option) {
            this.$emit("mtdChangePage", option);
        },
        mtdchangeSubPage(subpage) {
            this.subpage = subpage;
            if (subpage == 'cola') {
                this.mtdGetData();
            }
        }
    }
}
</script>
