<template>
    <div>
        <div class="px-4 pb-4" v-if="view == 'data'">
            <div class="row">
                <div class="btn-home col-md-12 mt-3 mb-4 d-flex justify-content-end">
                    <button type="button" class="btn btn-outline-main" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div id="div-modal-anular" class="w-100">
                <div id="div-anular" class="card w-100">
                    <div class="text-center card-header text-white h4 bg-main">
                        <strong>PRODUCTOS</strong>
                    </div>
                    <div class="d-flex justify-content-center ">
                        <div class="w-100 border-box-main pt-4">
                            <div class="row d-flex mb-2 px-4">
                                <div class="col-xl-9 col-lg-8 col-md-8 col-sm-12 col-xs-12 mb-2">
                                    <div class="d-flex responsive-search">
                                        <div class="input-group input-group">
                                            <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                            <input  v-model = "search"  type="text" class="form-control form-control " style="background-color: #EDEAEC" placeholder="Buscar Productos" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-xs-12  mb-1">
                                    <div class="d-flex responsive-search">
                                        <div class="input-group input-group">
                                            <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                            <v-select @input="mtdBuscar" :options="marcas" v-model="filter.brand" class="form-control" :reduce="(name) => name.id" label="name" placeholder="Seleccione Marca" required />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mt-2" id="content-timelinetable">
                                    <table class="w-100 table table-bordered">
                                        <caption class="div-text"></caption>
                                        <thead class=" text-xs text-center bg-main text-white">
                                            <tr>
                                                <th class="align-middle" >Registro Sanitario</th>
                                                <th class="align-middle" >Nombre</th>
                                                <th class="align-middle">Molecula</th>
                                                <th class="align-middle">Comercial</th>
                                                <th class="align-middle">Marca</th>
                                                <th class="align-middle">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody class="border-main div-text align-middle" v-if="filteredMedicamento.length > 0">
                                            <tr v-for="(item, index) in filteredMedicamento" :key="index" class="px-1 text-center">
                                                <td>
                                                    <span>{{ item.subsubcategorie.reg_sanitario }}</span>
                                                </td>
                                                <td>
                                                    <span>{{ item.subsubcategorie.name}}</span>
                                                </td>
                                                <td>
                                                    <span>{{ item.molecule }}</span>
                                                </td>
                                                <td>
                                                    <span>{{ item.commercial }}</span>
                                                </td>
                                                <td>
                                                    <span>{{ item.brand.name }}</span>
                                                </td>
                                               <td>
                                                    <span>
                                                      <button type="button" @click="mtdOpenModal(item)" class="btn btn-sm btn-info" style="font-size: 11px;">
                                                        <i class="fas fa-plus"></i>
                                                      </button> 
                                                     </span>
                                               </td>   
                                            </tr>
                                        </tbody>
                                        <tbody class="border-main text-center" v-else>
                                            <tr>
                                                <td colspan="6" class=" text-center">
                                                    <span class="fw-bold text-xxs"> No hay resultados</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
        
                                </div>
    
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <cModalProduct :ProductDetails="ProductDetails" :almacenes="almacenes"  :title="cModalProduct.title" :boo_modal="cModalProduct.modal_form" @mtdclosemodalproduct="mtdclosemodalproduct"  />
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>
    
      
<script>
    import {
        mapActions
    } from "vuex";
    import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
    import cModalProduct from '@/components/Farmacia/Modals/cModalProduct.vue'

    export default {
        name: "c-Logistica-ordendecompra",
        data() {
            return {
                swal: null,
                view: "data",
                page: "",
                marcas: [],
                medicamento:[],
                almacenes:[],
                filter:{
                  search:'',
                  brand:''
                },
                search:'',
                cModalProduct: {
                    title: "",
                    modal_form: false,
                },
                ProductDetails:{}
            };
        },
        components: {
            SweetAlert,
            cModalProduct
        },
        computed: {
          filteredMedicamento() {
            if (!this.filter.brand) {
              return [];
            }
            if (this.search.trim() === '') {
              return this.medicamento;
            }
            const searchLower = this.search.toLowerCase();
            return this.medicamento.filter(item =>
              (item.molecule && item.molecule.toLowerCase().includes(searchLower)) ||
              (item.subsubcategorie && item.subsubcategorie.name.toLowerCase().includes(searchLower)) ||
              (item.commercial && item.commercial.toLowerCase().includes(searchLower))
            );
          }
        },
        created() {
            this.mtGetMarcas();
            this.mtdGetAlmacenes();
        },
        methods: {
            ...mapActions(["get", "post"]),
    
            returnHome: function () {
                this.$emit("returnHome");
            },
            mtGetMarcas() {
                this.get({
                        url: this.$store.getters.get__url + "/Brand",
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        this.marcas = response.data
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });
            },
            mtdGetAlmacenes(){
              this.get({
                        url: this.$store.getters.get__url + "/Almacen",
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                      this.almacenes = [],
                      response.data.forEach(element => {
                        if(element.id !=1){
                           this.almacenes.push(element)
                        }
                      });
                     
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });
            },
            mtdBuscar:function(){
               this.search = '';
              if(this.filter.brand){
                this.post({
                        url: this.$store.getters.get__url + "/Pharmacy/recipe/filterProduct",
                        token: this.$store.getters.get__token,
                        params: this.filter
                    })
                    .then((response) => {
                     
                        this.medicamento = response.data
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });
              }else{
                this.$refs.SweetAlert.showWarning('Seleccione Marcas');
              }
            },
            mtdclosemodalproduct:function(){
              this.cModalProduct = {
                    title: "",
                    modal_form: false,
                }
            },
            mtdOpenModal:function(paylaod){
              this.cModalProduct = {
                    title: paylaod.subsubcategorie.name,
                    modal_form: true,
                }
               this.ProductDetails = paylaod
            }
        },
    };
</script>
      
<style lang="css" scoped>
  .v-select {
      padding: 0px
  }
</style>
    