<template>
<CModal @mtdclosemodal="mtdclosemodal" :show.sync="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-2 row mx-0 mb-2">
        <div class="row">
            <div class="col-md-12">
                <div class="card shadow">
                    <div class="card-body mb-2">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-2">
                                <label for="providerSelect">Proveedores</label>
                                <v-select class="text-dark flex-grow-1 mt-1" label="name" :options="proveOptions" placeholder="Seleccione Proveedor" @search="mtdGetProve" v-model="cabezera.selectedProve" :reduce="(name) => name.id" required :clearable="false">
                                    <template slot="no-options">
                                        No hay Proveedores para la busqueda
                                    </template>
                                </v-select>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-2">
                                <label for="fechaCompra">Fecha de Compra</label>
                                <input type="date" class="form-control mt-1" id="fechaCompra" v-model="cabezera.date">
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-2">
                                <label for="medioPago">Medio de Pago</label>
                                <select class="form-control mt-1" id="medioPago" v-model="cabezera.selectedMeto">
                                    <option value="" disabled selected>Seleccione Medio de Pago</option>
                                    <option value="Efectivo">Efectivo</option>
                                    <option value="Tarjeta">Tarjeta</option>
                                    <option value="Transferencia">Transferencia</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-4 col-sm-12 mt-3">
                <div class="card shadow">
                    <div class="card-header" style="background: #e1dedeb0">
                        <span><i class="fas fa-coins"></i> <strong>PRODUCTOS</strong></span>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 mt-2">
                                <label for="productoSelect">Producto</label>
                                <input type="text" class="form-control mt-1" id="productoSelect" placeholder="Digite producto" v-model="purchase.product">

                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-2">
                                <label for="precio">Precio</label>
                                <input type="text" class="form-control mt-1" id="precio" placeholder="Digite precio" @input="validateAmount" v-model="purchase.price">
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-2">
                                <label for="cantidad">Cantidad</label>
                                <input type="number" class="form-control mt-1" id="cantidad" placeholder="Cantidad" @input="validateQuantity" v-model="purchase.quantity" min="1">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 mt-2">
                                <label for="subTotal">Sub-Total</label>
                                <input type="text" class="form-control mt-1" id="subTotal" placeholder="Subtotal" v-model="purchase.subtotal" readonly>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 d-flex align-items-end mt-2">
                                <button type="button" class="btn btn-success text-white w-100" :disabled="!purchase.quantity || !purchase.price || !purchase.subtotal" @click="mtdAddProd()">
                                    <i class="fas fa-plus"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 col-sm-12 mt-3">
                <div class="card shadow">
                    <div class="card-header" style="background: #e1dedeb0">
                        <span><i class="fa fa-box-open"></i> <strong>DETALLE DE COMPRA</strong></span>
                    </div>
                    <div class="card-body">
                        <div class="col-md-12 mt-2" id="content-timelinetable">
                            <table id="tb-cxc" class="table table-bordered border-main" style="font-size: 13px">
                                <caption class="py-0 my-0"></caption>
                                <thead>
                                    <tr class="text-center text-white border-white bg-main align-middle">
                                        <th scope="col" class="table-th-number">N°</th>
                                        <th scope="col">PRODUCTO</th>
                                        <th scope="col" class="table-th-acciones">PRECIO</th>
                                        <th scope="col" class="table-th-acciones">CANTIDAD</th>
                                        <th scope="col" class="table-th-acciones">SUBTOTAL</th>
                                        <th scope="col" class="table-th-acciones">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in  cabezera.dataPurchase" :key="item.id" class="text-center align-middle">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ item.product }}</td>
                                        <td>{{ item.price }}</td>
                                        <td>{{ item.quantity }}</td>
                                        <td>{{ item.subtotal }}</td>
                                        <td><button type="button" @click="mtdDeleteProd(item.id)" class="btn btn-danger btn-sm px-1 py-0"><i class="fas fa-trash-alt text-white"></i></button></td>
                                    </tr>
                                    <tr>
                                        <th colspan="4" scope="col" class="text-center">TOTAL</th>
                                        <th colspan="2" scope="col" class="text-center">{{ total }}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <button class="btn btn-success btn-sm" @click="mtdInsert" :disabled="isDisabled">
            <i class="fas fa-save" />&nbsp; Guardar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "../../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";
export default {
    name: "c-logistica-upload",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            file: "",
            proveOptions: [],
            purchase: {
                id: null,
                price: null,
                quantity: null,
                subtotal: null,
                product: "",
            },
            cabezera: {
                campu_id: "",
                selectedProve: "",
                selectedMeto: "",
                date: "",
                serie: "",
                dataPurchase: [],
                total: null
            },
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        id: Number,
    },
    computed: {

        isDisabled() {
            return !this.cabezera.selectedProve ||
                !this.cabezera.selectedMeto ||
                !this.cabezera.date ||
                this.cabezera.dataPurchase.length === 0;
        },

        total() {
            const total = this.cabezera.dataPurchase.reduce((sum, item) => {
                return sum + parseFloat(item.subtotal || 0);
            }, 0);
            return total.toFixed(2);
        }
    },
    watch: {
        'purchase.price': function () {
            this.calculateSubtotal();
        },
        'purchase.quantity': function () {
            this.calculateSubtotal();
        },
    },

    methods: {
        ...mapActions(["get", "post"]),
        calculateSubtotal() {
            if (this.purchase.price !== null && this.purchase.quantity !== null) {
                this.purchase.subtotal = (this.purchase.price * this.purchase.quantity).toFixed(2);
            } else {
                this.purchase.subtotal = null;
            }
        },

        mtdclosemodal(opt) {
            this.file = '';
            this.$emit('mtdcloseModal', opt);
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        validateAmount(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }
            if (sanitizedValue !== 0.00) {
                this.purchase.price = sanitizedValue;
            } else {
                this.purchase.price = '';
            }
        },
        validateQuantity(event) {
            let value = parseInt(event.target.value);
            if (value < 1 || isNaN(value)) {
                event.target.value = 1;
                this.purchase.quantity = 1;
            } else {
                this.purchase.quantity = value;
            }
        },
        mtdGetProve: function (search, loading) {
            if (search.length > 2) {
                this.proveOptions = [];
                this.cabezera.selectedProve = "",
                    loading(true);
                this.get({
                        url: this.$store.getters.get__url + "/logistics/maintenance/provider/" + search,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        this.proveOptions = response.data;
                        loading(false);
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });
            }
        },
        mtdAddProd: function () {
            //buscar que no se repita el nombre del producto
            const indice = this.cabezera.dataPurchase.findIndex(item => item.product === this.purchase.product);
            if (indice !== -1) {
                this.$refs.SweetAlert.showWarning("El producto ya se encuentra en la lista");
                return;
            }
            if(this.purchase.price === '0.00'){
                this.$refs.SweetAlert.showWarning("Precio no puede ser 0.00");
                return;
            }
            this.purchase.id = this.cabezera.dataPurchase.length + 1;
            this.cabezera.total = (parseFloat(this.cabezera.total) || 0) + (parseFloat(this.purchase.subtotal) || 0);
            this.cabezera.dataPurchase.push(this.purchase);
            this.purchase = {
                id: null,
                price: null,
                quantity: null,
                subtotal: null,
                product: "",
            }
        },
        mtdDeleteProd: function (id) {
            this.$refs.SweetAlert.showConfirmSimple2("", "¿Estás seguro eliminar este Producto?", "warning", "Eliminar")
                .then((result) => {
                    if (result.value) {
                        const indice = this.cabezera.dataPurchase.findIndex(item => item.id === id);
                        if (indice !== -1) {
                            this.cabezera.dataPurchase.splice(indice, 1);
                            this.purchase = {
                                id: null,
                                price: null,
                                quantity: null,
                                subtotal: null,
                                product: "",
                            }
                        }
                    }
                });
        },
        mtdInsert: function () {
            this.$refs.SweetAlert.showConfirmSimple2("", "¿Estás seguro de guardar esta Orden de Compra?", "warning", "Guardar")
                .then((result) => {
                    if (result.value) {
                        const data = {
                            provider_id: this.cabezera.selectedProve,
                            method: this.cabezera.selectedMeto,
                            date: this.cabezera.date,
                            serie: this.cabezera.serie,
                            total: this.cabezera.total,
                            description: this.cabezera.dataPurchase
                        };
                        this.post({
                                url: this.$store.getters.get__url + "/ordendecompra/storeOther",
                                token: this.$store.getters.get__token,
                                params: data
                            })
                            .then((response) => {
                                this.$refs.SweetAlert.showSuccess(response.message);
                                this.$emit('mtdViewPdf', response.data.id);
                                this.mtdHidden();
                                this.mtdclosemodal(0);
                            })
                            .catch((errors) => {
                                this.$refs.SweetAlert.showError(errors);
                            });
                    }
                });
        },
        mtdHidden() {
            this.purchase = {
                id: null,
                price: null,
                quantity: null,
                subtotal: null,
                product: "",
            };
            this.cabezera = {
                campu_id: "",
                selectedProve: "",
                selectedMeto: "",
                date: "",
                serie: "",
                dataPurchase: [],
                total: null
            };
            this.proveOptions = [];
        },

    },
};
</script>
