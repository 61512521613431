<template>
<CModal @mtdclosemodal="mtdclosemodal" :show.sync="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'sm'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-xs-12 mt-2 mb-2 ">
            <div class="input-group input-group-sm">
                <div class="input-group-text col-md-3">
                    <span style="border: none" class="input-group-text"><i class="fas fa-layer-group"></i></span>Servicio
                </div>
                <input type="text" class="form-control form-control-sm" name="correo" v-model="service.name" placeholder="Nombre del servicio" />
            </div>
        </div>
    </div>
    <template #footer>
        <button class="btn btn-success btn-sm" @click="mtdSave" :disabled="cpDisabled">
            <i class="fas fa-save" />&nbsp; {{action=='store'?'Registrar':'Actualizar'}}
        </button>
        &nbsp;
    </template>
</CModal>
</template>

<script>
import CModal from "../../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-logistica-modals-ficha",
    components: {
        CModal,
    },
    data() {
        return {
            service: {
                id: "",
                name: "",
                state: "",
            },
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        payload: Object,
    },
    watch: {
        payload: {
            handler(newVal) {
                if (this.action == 'edit') {
                    this.service = {
                            ...newVal
                        };
                }
            },
            immediate: true,
        },
    },
    computed: {
        cpDisabled() {
            if (this.service.name.length) return false;
            return true;
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        mtdclosemodal(opt) {
            this.service = {
                id: "",
                name: "",
                state: "",
            };
            this.$emit('closeModal',opt);
        },

        mtdSave: function () {
            let num  = this.action == 'store' ? 0 : 1;
            console.log(num);
            this.$emit('Datadetail',this.service, num);
            this.service = {
                id: "",
                name: "",
                state: "",
            };
            
        },
    },
};
</script>
