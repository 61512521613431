<template>
<div>
    <div class="container">
        <div id="div-modal-pasaje" class="w-100">
            <div id="div-pasaje" class="card-body w-100">
                <div class="row">
                    <div class="col-md-12 h2" v-if="view == 'table'">
                        <div class="d-flex justify-content-start" id="btn-registro-recibo">
                            <a href="javascript:void(0)" @click="mtdStore">
                                <i class="fas fa-plus-circle"></i>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-12" id="div-tabla-pasaje">
                        <table id="tb-pasaje" class="table table-bordered border-main" v-if="view=='table'">
                            <thead>
                                <tr align="center" class="text-white border-white" style="background: #900052">
                                    <th scope="col">N°</th>
                                    <th scope="col">RESPONSABLE</th>
                                    <th scope="col">PAGO</th>
                                    <th scope="col">COSTO</th>
                                    <th scope="col">VUELTO</th>
                                    <th scope="col" colspan="1">PDF</th>
                                    <th scope="col">ESTADO</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in dataPassage" :key="index">
                                    <td>{{index + 1}}</td>
                                    <td>{{item.document}}-{{item.name}}</td>
                                    <td>{{item.payment}}</td>
                                    <td>{{item.cost}}</td>
                                    <td>{{item.turned}}</td>
                                    <td>
                                        <div class="text-center d-flex justify-content-center text-xs" v-if="item.state === 2">
                                            <button class="btn btn-sm btn-info" title="Recibo de egreso" @click="mtdViewPdfEgreso(item)">
                                                <i class="fas fa-file-pdf"></i>
                                            </button>
                                        </div>
                                        <div v-else>
                                            <span>-</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="text-center d-flex justify-content-center text-xs">
                                            <button class="btn btn-sm a-success text-white" v-if="item.state == 2">
                                                <i class="fas fa-check-circle"></i>
                                            </button>
                                            <button class="btn btn-sm a-danger text-white" v-if="item.state == 3">
                                                <i class="fas fa-times-circle"></i>
                                            </button>
                                            <button class="btn btn-sm a-warning text-white" v-if="item.state == 1">
                                                <i class="far fa-circle"></i>
                                            </button>
                                        </div>
                                    </td>
                                    <!-- <td align="center">
                        <a href="javascript:void(0)" @click="mtdProccessTurned(index,item.id,2)" id="btn-error-pasaje" v-if="item.state == 1">
                          <i class="fas fa-times-circle"></i>
                        </a>
                        <a href="javascript:void(0)" @click="mtdProccessTurned(index,item.id,3,item)" id="btn-autorizar-pasaje" v-if="item.state == 1">
                          <i class="fas fa-check-circle"></i>
                        </a>
                        <a href="javascript:void(0)" id="btn-pediente-pasaje" v-if="item.state != 1">
                          <i class="fas fa-recycle"></i>
                        </a>
                      </td> -->
                                </tr>
                                <tr v-if="dataPassage.length == 0" class="text-center">
                                    <td class="text-center" colspan="7">No hay registros</td>
                                </tr>
                            </tbody>
                        </table>
                        <PasajeStore @mtdBackTable="mtdBackTable" @mtdAddPassage="mtdAddPassage" :bead="bead" v-else />
                    </div>
                </div>
            </div>
        </div><br><br><br>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
//import { bus } from '../../main'
import PasajeStore from './PasajeCreate.vue';

export default {
    name: 'c-admision-pasaje',
    components: {
        PasajeStore
    },
    data() {
        return {
            process: {
                box: 0,
                initial: 0,
                observation: '',
                status: 0
            },
            view: 'table',
            dataPassage: [],
            message: false,
        }
    },
    props: {
        boxes: [],
        bead: {}
    },
    created() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Expense/passage",
                    token: this.$store.getters.get__token,
                    params: {
                        bead: this.bead.id
                    }
                })
                .then((response) => {
                    this.dataPassage = response.data;

                })
                .catch((errors) => {});
        },
        retroceder() {
            // window.history.back('/#/home/');
            this.$emit('mtdChangePage', 'home')
        },
        mtdStore: function () {
            this.view = 'store';
        },
        mtdBackTable: function () {
            this.view = 'table';
        },
        mtdAddPassage: function (item) {
            this.dataPassage.push(item);
            this.mtdBackTable();
        },
        mtdProccessTurned: function (index, id, state, item) {
            this.post({
                    url: this.$store.getters.get__url + "/Expense/passage/proccess",
                    token: this.$store.getters.get__token,
                    params: {
                        bead_id: this.bead.id,
                        id: id,
                        campu_id: this.$store.getters.get__campus,
                        state: state
                    }
                })
                .then((response) => {
                    const numero = parseFloat(this.bead.expense) + parseFloat(item.payment);
                    this.bead.expense = numero;
                    this.$emit("mtdCommitBead", this.bead);
                    // this.bead.expense=item.turned+bead.expense;
                    this.dataPassage[index].state = state;
                })
                .catch((errors) => {});
        },
        /** view pdf expense */
        mtdViewPdfEgreso: function (rh) {
            window.open(
                this.$store.getters.get__url + "/Permisions/viewpdf/expense/" + rh.expense_id,
                "_blank"
            );
        },
    }
}
</script>

<style scoped>
.btn-home {
    z-index: 1;
}

#btn-autorizar-pasaje {
    color: #39b54a;
    font-size: 25px;
}

#btn-autorizar-pasaje:hover {
    color: #217b2d;
}

#btn-error-pasaje {
    color: #ff0000;
    font-size: 25px;
}

#btn-error-pasaje:hover {
    color: #a30404;
}

#btn-pediente-pasaje {
    color: #ffe600;
    font-size: 25px;
}

#btn-pediente-pasaje:hover {
    color: #c0ac01;
}

#btn-registro-pasaje a {
    color: #900052;
}

#btn-registro-pasaje a:hover {
    color: #65013a;
    align-items: center;
    transform: scale(1.5);
}

.circulo {
    vertical-align: middle;
    border-style: none;
}

#div-modal-pasaje {
    margin-top: 10px;
}

@media(max-width: 800px) {

    /* #div-modal-pasaje{
      margin-top: 10px;
    } */
    #btn-open-caja {
        justify-content: center !important;
    }

    #btn-registro-pasaje {
        text-align: center;
    }

    #div-tabla-pasaje {
        overflow-x: auto
    }
}

@media(min-width: 800px) {

    /* #div-modal-pasaje{
      margin-top: 10px;
    } */
    #btn-registro-pasaje {
        text-align: left;
    }
}
</style>
