<template>
    <div class="container">
        <div class="" v-if="data">
            <div class="row mt-3">
                <div class="col-md-12">
                    <div class="row mb-2 mx-0">
                        <div class="py-1 div-title rounded fw-bold justify-content-center text-center"
                            style="background-color: #17A2B8">
                            <span>DIAGNÓSTICO</span>
                        </div>
                        <div 
                            class="col-12 mt-1 align-left " 
                            v-for="(item, index) in transformedCies" 
                            :key="index"
                        >
                            <div id="div-cies" class="form-group">
                                <label :for="'cie' + index" class="color-main fw-bold text-xs mb-2">{{ item.label }}</label>
                                <input type="text " class=" form-control form-control-sm" v-model="item.value" disabled>
                            </div>
                        </div>
                        <div class="col-md-12 mt-1">
                            <div class="form-group w-100">
                                <label for="cie"
                                    class="text-content w-100 text-sm-start color-main fw-bold mb-2 ">Intervención quirúrgica
                                    propuesta</label>
                                    <input 
                                    type="text" 
                                    class="form-control form-control-sm" 
                                    :value="data.subsub_categorie ? data.subsub_categorie.name : ''" 
                                    disabled
                                    >
                            </div>
                        </div>
                    </div>
                </div>
              
                <div class=" col-xl-12 col-md-12 ">
                    <div class="row div-title rounded fw-bold py-1 justify-content-center text-center mx-0 "
                        style="background-color: #17A2B8">
                        <div class="col-xl-12">
                            <span class="text-uppercase">Antecedentes Patológicos</span>
                        </div>
                     
                    </div>
                    <div class="">
                        <div class="col-md-12 row mx-0 ">
                           
                            <div class=" row  col-md-4 mx-0 px-0">
                                <label class="form-check-label text-center my-auto mb-0 fw-bold color-main mt-1">Hábitos
                                    Nocivos</label>
                                
                                <div v-for="item in transformedharmful" :key="item.id"
                                    class="col-12  mt-2">
                                    <div  class="form-check  card "> 
                                        <div  class="w-100 text-start ">
                                            <label class="form-check-label text-content mb-0" :for="'harmful' + item.id">
                                                {{ item.label }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                  
                            <div class="row d-flex col-md-8 mx-0 px-0">
                                <label class="form-check-label text-center my-auto mb-0 fw-bold color-main mt-1">Otras Enfermedades</label>
                                <div v-for="item in transformedpathological" :key="item.id"
                                    class="col-md-3 col-sm-6 col-12 mb-0 mt-0  mt-1">
                                    <div class="form-check card">
                                        <div  class="w-100 text-start">
                                            <label class="form-check-label text-content mb-0" :for="'otro' + item.id">
                                                {{ item.label }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2 mx-0">
                                <div class="px-0 col-xl-6 col-lg-6 col-md-12 mt-1">
                                    <div class="w-100 pe-xl-1">
                                        <label for="treatment" class="fw-bold text-xs color-main">Tratamiento</label>
                                        <textarea class="form-control input-textarea " id="treatment" rows="2"
                                            style="resize: none;" v-model="data.treatment" disabled />
                                    </div>
                                </div>
                                <div class="px-0 col-xl-6 col-lg-6 col-md-12 mt-1">
                                    <div class="w-100 ">
                                        <label for="cirugia"
                                            class="col-md-5 col-5 fw-bold text-xs color-main my-auto">Cirugía
                                            Previa</label>
                                        <div class="col-md-12">
                                                <textarea class="form-control input-textarea  " id="cirugia" rows="2"
                                                    style="resize: none;" v-model="data.previous_surgery" disabled
                                                    ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="col-xl-12 col-md-12 row mx-0">
                    
                        <div class=" col-xl-6 col-lg-6 col-md-12  mx-0 pe-xl-1 ">
                          
                            <div class="">
                                <div class="div-title-anestecia text-center fw-bold py-1 justify-content-center mt-2">
                                    <span>CABEZA - CUELLO - DETADURA</span>
                                </div>
                                
                                <div class="form-check card mt-1 " >
                                    <div  class="w-100 text-start  ">
                                        <label class="form-check-label text-content mb-0" >
                                            {{ transformedTheet }}
                                        </label>
                                    </div>
                                </div>
                                
                            </div>
                          
                            <div class=" mb-1 ">
                                <div class="div-title-anestecia fw-bold py-1 text-center justify-content-center mt-2 mb-1">
                                    <span class="text-uppercase">Clasificación Mallampati de la orofaringe</span>
                                </div>
                                <div class="row d-flex justify-content-center px-4">
                                    <div v-for="(image, index) in mallampatiImages" :key="index"
                                        class="col-md-3 col-sm-6 col-12 mb-0 text-center d-flex flex-column px-0 rounded rounded-2"
                                        :class="['image-container', { 'selected-image': data.clasif_mallampati === image.clasificacion }]"
                                        >
                                        <img :src="image.src" :alt="image.alt" class="img-fluid rounded" />
                                        <p class="mt-0 text-capitalize">{{ image.clasificacion }}</p>
                                    </div>
                                </div>
                            </div>
                           
                            <div class="row d-flex mb-2 mx-0">
                                <div class="py-1 div-title-anestecia fw-bold justify-content-center">
                                    <span class="text-uppercase">Aparato Respiratorio</span>
                                </div>
                                <div v-for="(item, index) in transformedrespiratory" :key="index"
                                    class="col-md-4 col-sm-4 col-12 mb-0 mt-0 px-1">
                                    <div class="form-check card mt-1 ">
                                        <div class="w-100 text-start ">
                                            <label class="form-check-label text-content mb-0" :for="'habito' + item.id">
                                                {{ item.label }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                      
                            <div class="row mb-2 mx-0">
                                <div class="py-1 div-title-anestecia fw-bold justify-content-center">
                                    <span class="text-uppercase">Evaluación Neumológica</span>
                                </div>
                                <div class="col-md-12 mt-1 align-left px-1">
                                    <div class="mb-1">
                                        <label for="rxtorax" class="fw-bold text-xs color-main">Rx Tórax</label>
                                        <textarea class="form-control input-textarea" id="rxtorax" rows="2"
                                            style="resize: none;" v-model="data.rx_torax" disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12 px-3">
                   
                            <div class="row d-flex my-2">
                                <div class="py-1 div-title-anestecia fw-bold">
                                    <span class="text-uppercase">Aparato Cardiovacular</span>
                                </div>
                                <div class="col-xl-3 col-md-6 col-sm-6 col-12 mt-1 align-left px-1"
                                    v-for="(item, index) in transformedcardiovascular" :key="index">
                                    <div id="div-estado" class="form-group ">
                                        <label :for="'aparCardio' + index" class="color-main fw-bold text-xs">{{ item.label
                                            }}</label>
                                        <div class="">
                                            <input type="text" v-model="item.value"
                                                class="form-control form-control-input form-control-sm"
                                                :id="'aparCardio' + index" disabled>
                                        </div>
                                    </div>
                                </div>
                            </div>
                      
                            <div class="row d-flex mb-1">
                                <div class="div-title-anestecia fw-bold py-1 justify-content-center mt-2">
                                    <span>DOLOR PRECORDIAL</span>
                                </div>
                                <div v-for="item in transformedprecordial" :key="item.id"
                                    class="col-md-4 col-sm-4 col-12 mb-1 mt-1 px-1">
                                    <div class="form-check card">
                                        <div class="w-100 text-start">
                                            <label class="form-check-label text-content mb-0" :for="'cordial' + item.id">
                                                {{ item.label }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                            <div class="row mb-1">
                                <div class="col-md-12 py-1 div-title-anestecia fw-bold mb-2">
                                    <label for="cianosis" class="text-uppercase">Cianosis</label>
                                </div>
                                <div class="col-md-12 mb-2 px-0">
                                    <textarea id="cianosis" type="text" class="form-control input-textarea" rows="1"
                                        style="resize: none;" disabled v-model="data.cianosis" />
                                </div>
                            </div>
               
                            <div class="row mb-2">
                                <div class="py-1 div-title-anestecia fw-bold">
                                    <span class="text-uppercase">Evalucación Cardiológica</span>
                                </div>
                                <div class="col-md-12 mt-1 align-left px-0">
                                    <div class="mb-1 ">
                                        <label for="rq" class="fw-bold text-xs color-main">RQ</label>
                                        <textarea class="form-control input-textarea" id="rq" rows="2" style="resize: none;"
                                            v-model="data.rq" disabled />
                                    </div>
                                </div>
                            </div>
               
                            <div class="row d-flex mb-1">
                                <div class="div-title-anestecia fw-bold py-1">
                                    <span class="text-uppercase">Columna</span>
                                </div>
                                <div class="col-md-6 col-12 align-left px-0">
                                    <label for="dorso" class="text-uppercase fw-bold text-xs color-main">Dorso
                                        Lumbar</label>
                                    <input id="dorso" type="text"
                                        class="form-control form-control-input form-control-sm mb-1"
                                        v-model="data.lumbar_back" />
                                </div>
                                <div v-for="item in transformedcolumn" :key="item.id"
                                    class="col-md-2 col-sm-4 col-12 align-content-center mt-3 ">
                                    <div class=" text-center card ">
                                        <div class="w-100  ">
                                            <label class="form-check-label mb-0"
                                                :for="'dorso_lumbar' + item.id">
                                                {{ item.label }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                    </div>
    
                </div>
            </div>
            <div class=" row mx-0 my-1">
                <div class="col-xl-12 row mx-0">
                    
                    <div class="col-xl-6 col-lg-6 col-md-12 px-0 pe-xl-1">
                     
                        <div class="row d-flex mb-2 mx-0">
                            <div class="py-1 div-title rounded fw-bold" style="background-color: #17A2B8">
                                <span>EX. LABORATORIO</span>
                            </div>
                            <div class="col-xl-2 col-md-2 col-12 mt-1 align-left px-1"
                                v-for="(item2, index) in transformedex" :key="'examLab-' + index">
                                <div id="div-estado" class="form-group">
                                    <label :for="'input2' + index" class="color-main fw-bold text-xs">{{ item2.label
                                        }}</label>
                                    <div class="">
                                        <input type="text" v-model="item2.value"
                                            class="form-control form-control-input form-control-sm" :id="'input2' + index" disabled>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 px-0">
                     
                        <div class="row d-flex mx-0">
                            <div class="py-1 div-title rounded fw-bold" style="background-color: #17A2B8">
                                <span>A.S.A</span>
                            </div>
                            <div v-for="(item, index) in asa_num_options" :key="'asaNum-' + index"
                                class="col-md-4 col-sm-4 col-12 mb-1 mt-0">
                                <div class="form-check">
                                    <input class="form-check-input align-middle" type="radio" :id="'asa_num' + item.id"
                                        :value="item.id" v-model="data.asa_num" disabled />
                                    <div class="w-100 text-start">
                                        <label class="form-check-label text-content mb-0" :for="'asa_num' + item.id">
                                            {{ item.label }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-1 align-left mb-2" v-for="(item, index) in transformedasa"
                                :key="'asa-' + index">
                                <div class="mb-1">
                                    <label :for="'textarea2' + index" class="fw-bold text-xs color-main">
                                        {{ item.label }}
                                    </label>
                                    <textarea class="form-control input-textarea" :id="'textarea2' + index" rows="2"
                                        style="resize: none;" v-model="item.value"  disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="px-0" v-else>
            <div class="alert alert-info mt-3" role="alert">
                No se ha encontrado información
            </div>

        </div>

    </div>
</template>
 
 <script>
import { elements } from 'chart.js';
import ControlDiarioView from '@/components/Asistencial/Control.vue';
import Devoluciones from '@/components/Asistencial/Devoluciones.vue';

 export default {
    name: 'reportsop-preanestecia',
    data() {
        return {
            cabezaCuello: [{
                    id: 1,
                    label: 'Dentadura Normal'
                },
                {
                    id: 2,
                    label: 'Prótesis'
                },
                {
                    id: 3,
                    label: 'D. Leche'
                },
            ],
            mallampatiImages: [{
                    src: require('@/assets/clasif_mallampati_I.png'),
                    alt: 'Mallampati I',
                    clasificacion: 'I'
                },
                {
                    src: require('@/assets/clasif_mallampati_II.png'),
                    alt: 'Mallampati II',
                    clasificacion: 'II'
                },
                {
                    src: require('@/assets/clasif_mallampati_III.png'),
                    alt: 'Mallampati III',
                    clasificacion: 'III'
                },
                {
                    src: require('@/assets/clasif_mallampati_IV.png'),
                    alt: 'Mallampati IV',
                    clasificacion: 'IV'
                }
            ],
            asa_num_options: [{
                    id: 1,
                    label: '1'
                },
                {
                    id: 2,
                    label: '2'
                },
                {
                    id: 3,
                    label: '3'
                },
                {
                    id: 4,
                    label: '4'
                },
                {
                    id: 5,
                    label: '5'
                },
            ],
        }
    },
    props: {
        data: {
        type: Object,
        default: () => {}
        }
    },
    computed: {
        transformedCies() {
            return JSON.parse(this.data.cies);
        },
        transformedharmful() {
            let newdata1=[]
            let data1 =JSON.parse(this.data.harmful_habits)
            data1.forEach(element => {
              if(element.value){
                newdata1.push(element)
              }  
            });
            return newdata1 ;
        },
        transformedpathological() {
            let newdata = []
            let data = JSON.parse(this.data.pathological_history)
            data.forEach(element => {
              if(element.value){
                newdata.push(element)
              }  
            });
            return newdata;
        },

        transformedTheet(){
            let data3 = ''
            this.cabezaCuello.forEach(element=>{
                if(element.id == this.data.teeth){
                    data3 = element.label
                }
            })
            return data3
        },

        transformedrespiratory(){
            let newdata4 = []
            let data4 = JSON.parse(this.data.respiratory_system)
            data4.forEach(element=>{
                if(element.value){
                    newdata4.push(element)
                }
            })
            return newdata4
        },

        transformedcardiovascular(){
            let data5 = JSON.parse(this.data.cardiovascular_system)
            return data5
        },
        transformedprecordial(){
            let newdata6 = []
            let data6 = JSON.parse(this.data.precordial_pain)
            data6.forEach(element=>{
                if(element.value){
                    newdata6.push(element)
                }
            })
            return newdata6
        },

        transformedcolumn(){
            let newdata7 = []
            let data7 = JSON.parse(this.data.column)
            data7.forEach(element=>{
                if(element.value){
                    newdata7.push(element)
                }
            })
            return newdata7
        },

        transformedex(){
            let dataex = JSON.parse(this.data.laboratory_exams)
            return dataex
        },

        transformedasa(){
            let newdata8 = []
            let data8 = JSON.parse(this.data.asa)
            data8.forEach(element=>{
                if(element.value){
                    newdata8.push(element)
                }
            })
            return newdata8
        },
    }
 }
 </script>
 
 <style scoped>
 .image-container {
    background-color: transparent;
    padding: 10px;
    border-radius: 8px;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.image-container img {
    max-height: 80%;
    width: auto;
    border-radius: 8px;
}
 .image-container.selected-image {
        background-color: #17A2B8;
    }
 </style>