<template>
  <div>
    <div class="row d-flex mt-3">
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text">Fecha y hora</span>
          <input
            type="date"
            aria-label="date"
            class="form-control form-control-sm"
            v-model="passage.date"
          />
          <input
            type="time"
            aria-label="time"
            class="form-control form-control-sm"
            v-model="passage.time"
          />
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text">Destinos</span>
          <input
            type="text"
            aria-label="initial"
            placeholder="Destino inicio"
            class="form-control form-control-sm"
            v-model="passage.initial"
          />
          <input
            type="text"
            aria-label="end"
            placeholder="Destino final"
            class="form-control form-control-sm"
            v-model="passage.end"
          />
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text">Montos</span>
          <input
            type="text"
            aria-label="payment"
            placeholder="Entregado"
            class="form-control form-control-sm"
            v-on:keypress="isNumber($event)"
            v-model="passage.payment"
          />
          <input
            type="text"
            aria-label="cost"
            placeholder="Costo"
            class="form-control form-control-sm"
            v-on:keypress="isNumber($event)"
            v-on:input="verifyCost"
            v-model="passage.cost"
          />
          <input
            type="text"
            aria-label="turned"
            placeholder="Vuelto"
            class="form-control form-control-sm"
            v-model="cpTurned"
            disabled
          />
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text" id="motive">Motivo</span>
          <select class="form-control" v-model="passage.motive">
            <option disabled selected>Seleccione Motivo....</option>
            <option v-for="(item, index) in dataPassage" :key="index" :value="item.id">
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
      <!--
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text" id="document">Responsable</span>
          <input
            type="text"
            class="form-control form-control-sm"
            aria-label="Sizing example input"
            placeholder="DNI"
            maxlength="8"
            aria-describedby="document"
            v-model="passage.document"
            @keyup="mtdSearchDocument"
          />
          <input
            type="text"
            class="form-control"
            aria-label="Sizing example input"
            placeholder="Nombre y Apellido"
            disabled
            aria-describedby="name"
            v-model="passage.fullname"
          />
        </div>
      </div>
      -->
     
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text" id="document">Responsable</span>
          <input
            type="text"
            class="form-control form-control-sm"
            aria-label="Sizing example input"
            placeholder="DNI"
            maxlength="8"
            aria-describedby="document"
            v-model="passage.document"
            @keyup="mtdSearchDni"
          />
          <input
            type="text"
            class="form-control"
            aria-label="Sizing example input"
            placeholder="Nombre y Apellido"
            disabled
            aria-describedby="name"
            v-model="passage.fullname"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="form-check form-check-inline mb-3">
          <input
            class="form-check-input"
            v-model="passage.type"
            type="radio"
            name="inlineRadioOptions"
            value="1"
          />
          <label class="form-check-label">Solo ida</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            v-model="passage.type"
            type="radio"
            name="inlineRadioOptions"
            value="2"
          />
          <label class="form-check-label">Ida y vuelta</label>
        </div>
      </div>
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center mt-3">
        <div class="btn-group" role="group" aria-label="Basic mixed styles example">
          <button type="button" class="btn btn-sm btn-danger" @click="mtdCancelar">
            Cancelar
          </button>
          &nbsp;
          <button type="button" class="btn btn-sm btn-success ml-1" @click="mtdCommit">
            Guardar
          </button>
        </div>
      </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
//import { bus } from "../../main";
import moment from "moment";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";

export default {
  name: "c-admsion-storepasaje",
  props: {
    bead: {},
  },
  components: {
        SweetAlert
  },
  data() {
    return {
      view: "table",
      swal: null,
      dataPassage: [],
      passage: {
        bead_id: null,
        date: null,
        time: null,
        campu_id: "",
        document: "",
        initial: "",
        end: "",
        motive: "",
        payment: "",
        cost: "",
        turned: "",
        type: 1,
        fullname: "",
      },
      fullname: "",
    };
  },
  computed: {
    cpTurned() {
      if (this.passage.payment > 0 && this.passage.cost > 0) {
        if (parseFloat(this.passage.payment) >= parseFloat(this.passage.cost)) {
          this.passage.turned =
            parseFloat(this.passage.payment) - parseFloat(this.passage.cost);
        } else {
          this.passage.turned = 0;
        }
      } else {
        this.passage.turned = 0;
      }
      return this.passage.turned;
    },
  },
  created() {
    moment.locale("es");
    this.passage.date =
      moment().format("L").substr(6, 4) +
      "-" +
      moment().format("L").substr(3, 2) +
      "-" +
      moment().format("L").substr(0, 2);
    this.passage.time = moment().format("LT");
   
    this.passage.bead_id = this.bead.id;
    this.passage.campu_id = this.$store.getters.get__campus;
    this.mtdGetReasons();
    
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdBackTable: function () {
      this.$emit("mtdBackTable");
    },
    //metodo obtener data
    mtdGetReasons: function () {
      this.get({
        url: this.$store.getters.get__url + "/Passagereasons",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.dataPassage = response.data;
        })
        .catch((errors) => {});
    },
    verifyCost() {
      //const currentDate = new Date();
      //const formattedCurrentDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
      if (
        this.passage.cost !== null &&
        this.passage.cost > 41 
      ) {
        this.$refs.SweetAlert.showWarning("El costo debe ser menor a 41");
        this.passage.cost = "";
      }
    },
    mtdStore: function () {
      this.view = "store";
    },
    mtdCancelar: function () {
      // this.view = "table";
      this.mtdBackTable();
    },
    mtdCommit: function () {
      if (!this.passage.initial ||
      !this.passage.end ||
      !this.passage.payment ||
      !this.passage.cost ||
      !this.passage.motive ||
      !this.passage.document) {
        this.$refs.SweetAlert.showWarning("Complete todos lo campos antes de registrar");
    return;
  }
      this.post({
        url: this.$store.getters.get__url + "/Expense/passage/store",
        token: this.$store.getters.get__token,
        params: this.passage,
      })
        .then((response) => {
          if (response.state == 1) {
            const whatsappPhone ='+51959422813';
            const gerente = 'Benjamin Medoza';
            const tipo = 'ha registrado  una Solicitud de Egreso De Pasaje';
            const monto =this.passage.payment;
            const admin =this.$store.getters.get__user.name + ' ' + this.$store.getters.get__user.last_name;

            window.open(
              this.generateWhatsAppLinks(gerente, whatsappPhone, tipo,monto,admin)
                 , '_blank');
            this.passage = {
              bead_id: null,
              date: null,
              time: null,
              campu_id: "",
              document: "",
              initial: "",
              end: "",
              motive: "",
              payment: "",
              cost: "",
              turned: "",
              type: 1,
            };
            // this.dataPassage.push(response.data);
            this.$emit("mtdAddPassage", response.data);
          } else if (response.state == 41) {
                Swal.fire({
                    text: response.message,
                    icon: "warning",
                    confirmButtonColor: "#900052",
                });
          }else {
            alert(response.message);
          }
        })
        .catch((errors) => {});
    },
    /** helpers */
    generateWhatsAppLinks(gerente, whatsappPhone, tipo,monto,admin) {
      const message = `Hola ${gerente},el Admisionista ${admin} ${tipo} ,por un monto de  S/. ${monto}.`;
      const encodedMessage = encodeURIComponent(message);
      // const whatsappLink=`https://wa.me/${whatsappPhone}?text=${encodedMessage}`;
      const whatsappLink = `https://api.whatsapp.com/send?phone=${whatsappPhone}&text=${encodedMessage}`;
      return whatsappLink;
    }, 
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    mtdSearchDocument: function () {
      if (this.passage.document.length === 8) {
        this.get({
          url: this.$store.getters.get__url + "/reniec/" + this.passage.document,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
         
            if (response.data.boo == 3) {
              this.passage.document = "";
              this.$refs.SweetAlert.showWarning("DNI no encontrado");
            } else {
              this.passage.fullname = response.data.name;
            }
          })
          .catch((errors) => {});
      }
    },

    mtdSearchDni: function () {
      if (this.passage.document.length === 8) {
        this.get({
          url: this.$store.getters.get__url + "/Expense/patient/" + this.passage.document,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
         
            if (response.data.boo == 3) {
              this.passage.document = "";
              this.passage.fullname = "";
              this.$refs.SweetAlert.showWarning("DNI no Encontrado en Planilla");
            } else {
              this.passage.fullname = response.data.data.name;
            }
          })
          .catch((errors) => {});
      }
    },
  },
};
</script>
