<template>
<div class="col-md-12">
    <!-- DATOS DEL PACIENTE -->
    <div class="row d-flex">
        <div class="col-xl-1 col-lg-2 col-md-12 mt-2">
            <button type="button" class="btn btn-sm btn-info" @click="mtdback('table')">
                <i class="fas fa-arrow-left"></i> Volver
            </button>
        </div>
        <div class="mt-2 px-4 col-md-12" :class="subpage == '' ? 'col-xl-11 col-lg-10' : 'col-xl-9 col-lg-8'">
            <div class="row d-flex">
                <div class="mx-0 col-sm-12 col-md-12 col-xl-12 h-100 rounded-3 px-0 bg-blue mb-2">
                    <div class="row mx-0">
                        <div class="h-100 py-1 mx-0 col-sm-12 col-md-9 col-xl-9 px-0 border rounded-start d-inline-flex">
                            <span class=" align-middle col-sm-4 h-100 px-2 text-start text-white">Paciente:</span>
                            <span class=" align-middle col-sm-8 h-100 px-0 text-start text-white">{{dataDetail.patient.name}}</span>
                        </div>
                        <div class="h-100 mx-0 px-0 py-1 col-sm-12 col-md-3 col-xl-3 border rounded-end d-inline-flex">
                            <span class=" align-middle col-sm-4 h-100 px-2 text-start text-white">Edad:</span>
                            <span class=" align-middle col-sm-8 h-100 px-0 text-sm-start text-xl-center text-white">{{dataDetail.patient.year}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-12 mt-2 mb-2" v-if="subpage !== ''">
            <button type="button" class="btn btn-sm btn-success w-100" @click="mtdExportFile" :disabled="cpDisabledExport">
                <i class="fas fa-file-pdf"></i> Exportar
            </button>
        </div>
    </div>
    <!-- DESPLEGABLES -->
    <div class="row mt-3" v-if="subpage == ''">
        <div class="col-md-12">
            <!-- CONSULTING -->
            <div class="dropdown mt-1" :class="[dropdown && 'activeItem']" v-if="dataDetail.type_array.includes(1)">
                <div class="dropdown-header" @click="toggleDropdown()">
                    CONSULTAS
                    <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon" :class="[dropdown && 'active']">
                </div>
                <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
                    <div class="dropdown-wrapper border border-main border-2 rounded mt-2" v-if="dropdown">
                        <div class="row d-flex justify-content-center px-4">
                            <div class="col-md-6 col-lg-4 col-sm-6 col-xs-10 my-4">
                                <div class="card card-book shadow" @click="mtdShowReportHC(1,'consulting','CONSULTA')">
                                    <h5>HISTORIA CLINICA</h5>
                                    <div class="icon-book">
                                        <img class="img-fluid-bed-sop rounded-start" src="@/assets/HC.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <!-- EMERGENCY -->
            <div class="dropdown mt-1" :class="[dropdownEmergency && 'activeItem']" v-if="dataDetail.type_array.includes(7)">
                <div class="dropdown-header" @click="toggleDropdownEmergency()">
                    EMERGENCIA
                    <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon" :class="[dropdownEmergency && 'active']">
                </div>
                <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
                    <div class="dropdown-wrapper-emergency border border-main border-2 rounded mt-2" v-if="dropdownEmergency">
                        <div class="row d-flex justify-content-center px-4">
                            <!-- ITEMS DE EMERGENCIA -->
                            <div class="col-md-4 col-lg-2 col-sm-6 col-xs-10 my-4" v-for="(item, index) in itemsEmergency" :key="index">
                                <div class="card card-book shadow" @click="mtdShowReport(item,'emergency','EMERGENCIA')">
                                    <h6>{{item.label}}</h6>
                                    <div class="icon-book">
                                        <img class="img-fluid-bed-sop rounded-start" :src="require(`@/assets/emergency/${item.img}.png`)" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <!-- HOSP -->
            <div class="dropdown mt-1" :class="[dropdownHosp && 'activeItem']" v-if="dataDetail.type_array.includes(3)">
                <div class="dropdown-header" @click="toggleDropdownHosp()">
                    HOSPITALIZACION
                    <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon" :class="[dropdownHosp && 'active']">
                </div>
                <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
                    <div class="dropdown-wrapper border border-main border-2 rounded mt-2" v-if="dropdownHosp">
                        <div class="d-lg-flex gap-1 justify-content-center px-4">
                            <!-- ITEMS DE HOSPITALIZACION -->
                            <div class="col my-4" v-for="(item, index) in itemsHospi" :key="index">
                                <div class="card card-book shadow" @click="mtdShowReport(item,'hosp','HOSPITALIZACION')">
                                    <h6>{{item.label}}</h6>
                                    <div class="icon-book">
                                        <img class="img-fluid-bed-sop rounded-start" :src="require(`@/assets/emergency/${item.img}.png`)" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <!-- UCI -->
            <div class="dropdown mt-1" :class="[dropdownUci && 'activeItem']" v-if="dataDetail.type_array.includes(4)">
                <div class="dropdown-header" @click="toggleDropdownUci()">
                    UCI
                    <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon" :class="[dropdownUci && 'active']">
                </div>
                <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
                    <div class="dropdown-wrapper-emergency border border-main border-2 rounded mt-2" v-if="dropdownUci">
                        <div class="row d-flex justify-content-center px-4">
                            <!-- ITEMS DE UCI -->
                            <div class="col-md-4 col-lg-2 col-sm-6 col-xs-10 my-4" v-for="(item, index) in itemsUci" :key="index">
                                <div class="card card-book shadow" @click="mtdShowReport(item,'uci','UCI')">
                                    <h6>{{item.label}}</h6>
                                    <div class="icon-book">
                                        <img class="img-fluid-bed-sop rounded-start" :src="require(`@/assets/emergency/${item.img}.png`)" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <!-- SOP -->
            <div class="dropdown mt-1" :class="[dropdownSop && 'activeItem']" v-if="dataDetail.type_array.includes(6)">
                <div class="dropdown-header" @click="toggleDropdownSop()">
                    SOP
                    <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon" :class="[dropdownSop && 'active']">
                </div>
                <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
                    <div class="dropdown-wrapper-emergency border border-main border-2 rounded mt-2" v-if="dropdownSop">
                        <div class="d-md-flex gap-2 justify-content-center px-4">
                            <!-- ITEMS DE SOP -->
                            <div class="col-sm-12 col-xs-12 my-4 col-md-4 col-lg-4" v-for="(item, index) in itemsSop" :key="index">
                                <div class="card  shadow" :class="id_card == item.id ? 'card-book-inverse' : 'card-book'" @click="mtdShowItemProcess(item)">
                                    <h6>{{item.label}}</h6>
                                    <div class="icon-book">
                                        <img class="img-fluid-bed-sop rounded-start text-white" :src="require(`@/assets/sop/${item.img}.png`)" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-lg-flex gap-2 justify-content-center px-4 mb-4" v-if="viewArrow">
                            <div class="col mb-1" v-for="(item2, index2) in subItems" :key="index2">
                                <div class="card shadow h-100" @click="mtdShowReportSop(index2)">
                                    <div class="card-body card-subprocess">
                                        <div class="h6 color-main">{{item2.label}}</div>
                                    </div>
                                    <!-- <div class="icon-book">
                                                <img class="img-fluid-process-sop rounded-start" :src="require(`@/assets/sop/${item.img}.png`)" alt="" />
                                            </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
    <!-- CONTENIDO -->
    <div class="row px-3">
        <showHistoryClinica v-if="subpage == 1" :typeService="typeService" :clinica_history="clinica_history" :doctor="doctor"></showHistoryClinica>
        <showEvMedica v-if="subpage == 2" :typeService="typeService" :data="evMedica"></showEvMedica>
        <showRp v-if="subpage == 3" :typeService="typeService" :data="rps"></showRp>
        <showNotes v-if="subpage == 4" :typeService="typeService" :data="notes"></showNotes>
        <showKardex v-if="subpage == 5" :typeService="typeService" :data="data"></showKardex>
        <showMonitoreo v-if="subpage == 6" :typeService="typeService" :data="monitoreo"></showMonitoreo>
        <showGrafica v-if="subpage == 7" :typeService="typeService" :data="grafico" :medical_act_id="id"></showGrafica>
        <showBalance v-if="subpage == 8" :typeService="typeService" :data="balance"></showBalance>
        <!-- SI ES UCI Y MONITOREO -->
        <showMonitoreoUci v-if="subpage == 9" :typeService="typeService" :data="monitoreo" ref="monitoreoUci"></showMonitoreoUci>
        <showBalanceUci v-if="subpage == 10" :typeService="typeService" :data="balance"></showBalanceUci>
        <!--SOP-->
        <showOrdenSop v-if="subpage == 60 && id_card == 1" :data="datasop"></showOrdenSop>
        <showRxqx v-if="subpage == 61 && id_card == 1" :data="datasop"></showRxqx>
        <showConsentimientos v-if="subpage == 62 && id_card == 1" :medical_act_id="id" :origin="'report'"></showConsentimientos>
        <showInformeOperatorio v-if="subpage == 63 && id_card == 1" :data="datasop"></showInformeOperatorio>
        <showPreAnestecia v-if="subpage == 64 && id_card == 1" :data="datasop"></showPreAnestecia>
        <showReportHojaA v-if="subpage == 65 && id_card == 1"  :medical_act_id="id"></showReportHojaA>
        <showListVerificacion v-if="subpage == 61 && id_card == 2" :medical_act_id="id" :history="listVeri"></showListVerificacion>
        <showKardex v-if="subpage == 66 && id_card == 1"  :data="data"></showKardex>
        <showKardex v-if="subpage == 62 && id_card == 2"  :data="data"></showKardex>
        <showKardex v-if="subpage == 62 && id_card == 3"  :data="data"></showKardex>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import showHistoryClinica from './ReportHc.vue';
import showEvMedica from './ReportEvMedica.vue';
import showRp from './ReportRp.vue';
import showKardex from './ReportKardex.vue';
import showNotes from './ReportNotes.vue';
import showBalance from './ReportBalance.vue';
import showMonitoreo from './ReportMonitoreo.vue';
import showGrafica from './ReportGrafica.vue';
import showMonitoreoUci from './ReportMonitoreoUci.vue';
import showBalanceUci from './ReportBalanceUci.vue';
/* SOP */
import showOrdenSop from './ReportOrderSop.vue';
import showRxqx from './ReportRxqx.vue';
import showConsentimientos from '@/components/Asistencial/Sop/Historia/Consentimientos.vue';
import showInformeOperatorio from './ReportInformeOperatorio.vue';
import showPreAnestecia from './ReportPreanestecia.vue'
import showListVerificacion from '@/components/Asistencial/Sop/Historia/Listaverificacion.vue';
import showReportHojaA from './ReportHojaAnestecia.vue';
export default {
    name: 'c-consultingshow',
    components: {
        showHistoryClinica,
        showEvMedica,
        showRp,
        showKardex,
        showNotes,
        showMonitoreo,
        showGrafica,
        showBalance,
        showMonitoreoUci,
        showBalanceUci,
        showOrdenSop,
        showRxqx,
        showConsentimientos,
        showInformeOperatorio,
        showPreAnestecia,
        showListVerificacion,
        showReportHojaA
    },
    data() {
        return {
            subpage: "",
            typeService: "",
            dropdown: false,
            dropdownEmergency: false,
            dropdownHosp: false,
            dropdownUci: false,
            dropdownSop: false,
            doctor: {},
            evMedica: [],
            datasop: [],
            rps: [],
            monitoreo: {},
            balance: [],
            grafico: [],
            notes: [],
            subItems: [],
            clinica_history: {},
            itemsEmergency: [{
                    id: 1,
                    label: "HISTORIA CLINICA",
                    img: "HC",
                },
                {
                    id: 2,
                    label: "EV. MEDICA",
                    img: "ev_medica",
                },
                {
                    id: 3,
                    label: "RP",
                    img: "rp",
                },
                {
                    id: 4,
                    label: "NOTAS DE ENF",
                    img: "note",
                },
                {
                    id: 5,
                    label: "KARDEX",
                    img: "kardex",
                },
                {
                    id: 6,
                    label: "MONITOREO",
                    img: "monitoreo",
                },
            ],
            itemsHospi: [{
                    id: 1,
                    label: "HISTORIA CLINICA",
                    img: "HC",
                },
                {
                    id: 2,
                    label: "EV. MEDICA",
                    img: "ev_medica",
                },
                {
                    id: 3,
                    label: "RP",
                    img: "rp",
                },
                {
                    id: 4,
                    label: "NOTAS DE ENF",
                    img: "note",
                },
                {
                    id: 5,
                    label: "KARDEX",
                    img: "kardex",
                },
                {
                    id: 6,
                    label: "MONITOREO",
                    img: "monitoreo",
                },
                {
                    id: 7,
                    label: "HOJA GRAFICA",
                    img: "grafica",
                },
                {
                    id: 8,
                    label: "BALANCE HIDRICO",
                    img: "note",
                },
            ],
            itemsUci: [{
                    id: 1,
                    label: "HISTORIA CLINICA",
                    img: "HC",
                },
                {
                    id: 2,
                    label: "EV. MEDICA",
                    img: "ev_medica",
                },
                {
                    id: 3,
                    label: "RP",
                    img: "rp",
                },
                {
                    id: 4,
                    label: "NOTAS DE ENF",
                    img: "note",
                },
                {
                    id: 5,
                    label: "KARDEX",
                    img: "kardex",
                },
                {
                    id: 6,
                    label: "MONITOREO",
                    img: "monitoreo",
                },
                {
                    id: 7,
                    label: "HOJA GRAFICA",
                    img: "grafica",
                },
                {
                    id: 8,
                    label: "BALANCE HIDRICO",
                    img: "note",
                },
            ],
            itemsSop: [{
                    id: 1,
                    label: "PRE OPERATORIO",
                    img: "pre_sop",
                    items: [
                    
                    {
                        label: 'Orden de Sop',
                        img : "pre_sop",
                    },
                        {
                        label: 'Rxqx',
                        img : "pre_sop",
                    },
                        {
                        label: 'Consentimientos',
                        img : "pre_sop",
                    },
                        {
                        label: 'Informe Operatorio',
                        img : "pre_sop",
                    },
                        {
                        label: 'Pre Anestesia',
                        img : "pre_sop",
                    },
                    {
                        label: 'Hoja de Anestesia',
                        img : "pre_sop",
                    },
                    {
                        label: 'Kardex',
                        img : "pre_sop",
                    }
                 ]
                },
                {
                    id: 2,
                    label: "OPERATORIO",
                    img: "cirugia",
                    items: [
                      
                        {
                        label: 'RP',
                        img : "pre_sop",
                    },
                        {
                        label: 'Lista de verificación',
                        img : "pre_sop",
                    },
                        {
                        label: 'Kardex',
                        img : "pre_sop",
                    },
                        {
                        label: 'Nota de Enfermeria',
                        img : "pre_sop",
                    },
                    {
                        label: 'Balance',
                        img : "pre_sop",
                    },
                    {
                        label: 'Post Anestesia',
                        img : "pre_sop",
                    }]
                },
                {
                    id: 3,
                    label: "POST OPERATORIO",
                    img: "post",
                    items: [
                        {
                        label: 'Nota de ingreso',
                        img : "pre_sop",
                    },
                        {
                        label: 'RP',
                        img : "pre_sop",
                    },
                        {
                        label: 'Kardex',
                        img : "pre_sop",
                    },{
                        label: 'Nota de Enfermeria',
                        img : "pre_sop",
                    },
                    {
                        label: 'Balance',
                        img : "pre_sop",
                    },
                    {
                        label: 'Monitoreo',
                        img : "pre_sop",
                    },
                    {
                        label: 'Hoja Gráfica',
                        img : "pre_sop",
                    }]
                },
            ],
            viewArrow: false,
            data: {
                datatime: [],
                recipes: [],
                nursing_kardexes_1: [],
                nursing_kardexes_2: [],
                nursing_kardexes_3: [],
                nursing_kardexes_4: [],
                nursing_kardexes_5: [],
                nursing_kardexes_6: [],
                datatime2: [],
                historys: [],
                notes: [],
                dietas: [],
                procedimiento_invasivo: [],
                procedimiento_invasivo2: [],
                plan_laboratorio: [],
                plan_imagenes: [],
                plan_procedimientos: [],
                plan_interconsultas: [],
                insulina: [],
                upp: [],
                commonmedicationfrequency: [],
                datatimefrequency: []
            },
            medicamentos: [],
            datatime: [],
            datatime2: [],
            datatimefrequency: [],
            medicamentosfrequency: [],
            commonmedicationfrequency: [],
            posfrequency: 0,
            id_card: -1,
            listVeri: 1
        }
    },
    props: {
        dataDetail: Object,
        id: Number,
    },
    computed: {
        cpDisabledExport() {
            let validation = true;
            switch (this.subpage) {
                case 1: 
                    validation = false;
                    break;
                case 2:
                    validation = this.evMedica.length == 0;
                    break;
                case 3:
                    validation = this.rps.length == 0;
                    break;
                case 4:
                    validation = this.notes.length == 0;
                    break;
                case 6:
                    validation = Object.keys(this.monitoreo).length === 0 || this.monitoreo.sheets.length == 0; //para emergencia
                    break;
                case 7:
                    validation = this.grafico.length == 0;
                    break;
                case 8:
                    validation = this.balance.length == 0;
                    break;
                case 9:
                    validation = this.monitoreo.sheets.length == 0; //para uci
                    break;
                case 10:
                    validation = this.balance.sheets.length == 0; //para uci
                    break;
                default:
                    break;
            }
            return validation;
        }
    },
    watch: {
        dataDetail: {
            handler(newVal) {
                if (newVal) {
                    this.doctor = {
                        ...newVal.doctor
                    };
                }
            },
            immediate: true,
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdShowItemProcess(item) {
            this.viewArrow = true;
            this.id_card = item.id;
            this.subItems = item.items;
        },
        returnHome() {
            this.$emit('mtdChangePage', 'home');
        },

        mtdback(subpage) {
            if (this.subpage !== "") {
                this.subpage = '';
            } else {
                this.$emit('mtdChangeSubPage', subpage);
            }

        },
        mtdShowReportHC(option, model, type) {
            this.subpage = option;
            if (option == 1) {
                this.typeService = type;
                this.clinica_history = this.dataDetail[model]['hc'];

            }
        },
        mtdShowReport(item, model, type) {
            this.typeService = type;
            let optionNumber = item.id;
            switch (optionNumber) {
                case 1:
                    this.mtdShowReportHC(1, model, type);
                    break;
                case 2:
                    this.evMedica = this.dataDetail[model]['ev_medical'];
                    break;
                case 3:
                    this.rps = this.dataDetail[model]['rps'];
                    break;
                case 4:
                    this.notes = this.dataDetail[model]['notes'];
                    break;
                case 5:
                    this.mtdKardexReport(this.typeService);
                    break
                case 6:
                    this.monitoreo = this.dataDetail[model]['monitoreo'];
                    break;
                case 7:
                    this.grafico = this.dataDetail[model]['grafico'];
                    break;
                case 8:
                    type == 'UCI' ?
                        this.balance = this.dataDetail[model]['balanceUci'] :
                        this.balance = this.dataDetail[model]['balance'];
                    break;
                default:
                    break;
            }
            this.subpage = (type == 'UCI' && optionNumber == 6) ?
                9 :
                (type == 'UCI' && optionNumber == 8 ?
                    10 :
                    optionNumber);
        },
        /* SOP */
        mtdShowReportSop(option){
            //se usará con prefijo 6
            let num = '6'+option;
            let model = 'sop';
            let optionNumber = parseInt(num);
            if(this.id_card == 1){
                switch (num) {
                    case '60':
                        this.datasop = this.dataDetail[model]['soporder'];
                        break;
                    case '61':
                        this.datasop = this.dataDetail[model]['rxqx'];
                        break;
                    case '62':
                        this.datasop = this.dataDetail[model]['consent'];
                        break;
                    case '63':
                        this.datasop = this.dataDetail[model]['operativereport'];
                        break;
                    case '64':
                        this.datasop = this.dataDetail[model]['preanestecia'];
                        break
                    case '66':
                        this.mtdKardexReportSop(3)
                    break
                    default:
                        break;
                }
                this.subpage = optionNumber ;
            }
            if(this.id_card == 2){
                switch (num) {
                    case '62':
                        this.mtdKardexReportSop(1)
                        break;
                    default:
                        break;
                }
                this.subpage = optionNumber ;
            }
            if(this.id_card == 3){
                switch (num) {
                    case '62':
                        this.mtdKardexReportSop(2)
                        break;
                    case '63':
                        // this.mtdKardexReportSop(1)
                        break
                    default:
                        break;
                }
                this.subpage = optionNumber ;
            }
        },
        /* EXPORTAR EL PDF */
        mtdExportFile() {
            let id = this.id; //id del acto medico
            let ruta = "";
            let type;
            if (this.typeService === 'EMERGENCIA') {
                type = 7;
            } else if (this.typeService === 'UCI') {
                type = 4;
            } else {
                type = 3;
            }
            switch (this.subpage) {
                case 1:
                    let id_hc = this.clinica_history.id;
                    ruta = "/viewpdfReportPatient/consulta/hc/" + id_hc + "/" + this.typeService;
                    break;
                case 2:
                    ruta = "/viewpdfReportPatient/evolutions/" + type + "/" + id;
                    break;
                case 4:
                    ruta = "/viewpdfReportPatient/nurseNotes/" + type + "/" + id;
                    break;
                case 3:
                    ruta = "/viewpdfReportPatient/rp/" + type + "/" + id;
                    break;
                case 5:
                    ruta = "/viewpdfReportPatient/Kardex/" + type + "/" + id;
                    break;
                case 6:
                    ruta = "/viewpdfReportPatient/monitoreo/" + type + "/" + id;
                    break;
                case 7:
                    if (type === 3) {
                        ruta = "/Hospitalization/viewpdfGrafica/" + id;
                    } else if (type === 4) {
                        ruta = "/Uciadulto/viewpdfGrafica/" + id;
                    }
                    break;
                case 8:
                    if (type === 3) {
                        ruta = "/Hospitalization/viewpdfBalance/" + id;
                    } else if (type === 7) {
                        ruta = "/Emergency/viewpdfBalance/" + id;
                    }
                    break;
                case 9:
                    ruta = "/viewpdfReportPatient/monitoreoUci/" + id;
                    break;
                case 10:
                    ruta = "/viewpdfReportPatient/balanceUci/" + id;
                    if (ruta !== "") {
                        window.open(
                            this.$store.getters.get__url + ruta,
                            "_blank"
                        );
                    }
                    break;
                default:
                    break;
            }
            if (ruta !== "") {
                window.open(
                    this.$store.getters.get__url + ruta,
                    "_blank"
                );
            } else {
                console.error("Ruta no definida");
            }
        },
        /* TRASICCIONES */
        //Dropdown
        toggleDropdown() {
            this.dropdown = !this.dropdown;
        },
        toggleDropdownEmergency() {
            this.dropdownEmergency = !this.dropdownEmergency;
        },
        toggleDropdownHosp() {
            this.dropdownHosp = !this.dropdownHosp;
        },
        toggleDropdownUci() {
            this.dropdownUci = !this.dropdownUci;
        },
        toggleDropdownSop() {
            this.dropdownSop = !this.dropdownSop;

        },

        beforeEnter(el) {
            el.style.transformOrigin = 'top';
            el.style.transform = 'scaleY(0)';
            el.style.opacity = 0;
        },
        enter(el) {
            el.style.transition = 'transform 0.1s ease, opacity 0.1s ease';
            el.style.transform = 'scaleY(1)';
            el.style.opacity = 1;
        },
        beforeLeave(el) {
            el.style.transformOrigin = 'top';
            el.style.transform = 'scaleY(1)';
            el.style.opacity = 1;
        },
        leave(el) {
            el.style.transition = 'transform 0.1s ease, opacity 0.1s ease';
            el.style.transform = 'scaleY(0)';
            el.style.opacity = 0;
        },
        mtdKardexReport: function (payload) {
            let type = payload == 'EMERGENCIA' ? 7 : (payload == 'UCI' ? 4 : 3)
            this.post({
                    url: this.$store.getters.get__url + "/paciente/showKardex",
                    token: this.$store.getters.get__token,
                    params: {
                        id: this.id,
                        type_service: type
                    },
                })
                .then(response => {

                    // fecha de los medicamentos
                    this.datatime = response.data.allDates
                    this.data.datatime = response.data.allDates
                    // Listado de medicamentos 
                    let ids = [];
                    this.medicamentos = []
                    response.data.results.forEach(recipe => {
                        ids.push(recipe.id)
                        recipe.recipedetails.forEach(recipedetail => {
                            let medicenedetail = {
                                recipe_id: recipedetail.recipe_id,
                                recipedetail_id: recipedetail.id,
                                name: recipedetail.molecule + ' ' + recipedetail.presentation,
                                quantity: recipedetail.quantity + '' + (recipedetail.measure == 1 ? 'CC' : (recipedetail.measure == 2 ? 'ML' : 'GOTAS')) + '/' + (recipedetail.unit_time == 1 ? 'H' : 'MIN'),
                                quantity_real: recipedetail.quantity,
                                unidad: recipedetail.measure,
                                time: recipedetail.unit_time,
                                group: recipedetail.mix,
                                dosis: recipedetail.dosis,
                                via: recipedetail.via,
                                hora: recipedetail.frequency,
                                start: recipedetail.start_date ? true : false,
                                prn: recipedetail.prn == 1 ? true : false,
                                cancelation: recipedetail.cancellation_date ? true : false,
                                insuline: recipedetail.insulin,
                                type: recipedetail.type,
                                indicaciones: recipedetail.indicaciones,
                                count: recipedetail.count + 1,
                                horario: []
                            }
                            this.datatime.forEach(item => {
                                let date = {
                                    date: item,
                                    mañana: [],
                                    tarde: [],
                                    noche: [],
                                }
                                medicenedetail.horario.push(date);
                            });
                            let aplicaciones = 0
                            recipedetail.schedule.forEach(horario => {
                                aplicaciones++
                                let num = 0
                                medicenedetail.horario.forEach(hour => {
                                    if (hour.date == horario.date) {
                                        if (medicenedetail.type == 3) {
                                            horario.aplicacion = `(${this.convertirARomanos(aplicaciones)})`
                                        }
                                        if (horario.time_kardex >= "07:31:00" && horario.time_kardex < "13:31:00") {
                                            hour.mañana.push(horario);
                                        } else if (horario.time_kardex >= "13:31:00" && horario.time_kardex < "19:30:00") {
                                            hour.tarde.push(horario);
                                        } else if (horario.time_kardex >= "19:30:00" && horario.time_kardex <= "23:59:00") {
                                            hour.noche.push(horario);
                                        } else {
                                            medicenedetail.horario[num - 1].noche.push(horario)
                                        }
                                    }
                                    num++
                                })
                            })
                            this.medicamentos.push(medicenedetail)
                        })

                    });
                    let num = 0
                    this.medicamentos.forEach(me => {
                        if (me.horario[this.datatime.length - 1].mañana.length == 0 &&
                            me.horario[this.datatime.length - 1].tarde.length == 0 &&
                            me.horario[this.datatime.length - 1].noche.length == 0
                        ) {
                            this.pos = 0;
                        } else {
                            num = num + 1
                        }

                    })
                    if (num == 0) {
                        this.datatime.pop();
                        this.medicamentos.forEach(me => {
                            me.horario.pop()
                        })
                    }

                    let mezcla = this.medicamentos;
                    let resultado = [];
                    let counter = 1;

                    mezcla.forEach((item) => {

                        if (item.group === 0) {
                            let data = {
                                idsDetails: item.recipedetail_id,
                                name: item.name
                            }
                            resultado.push({
                                ...item,
                                recipedetail_ids: [data],
                                count: counter++
                            });
                            return;
                        }

                        let existente = resultado.find(
                            (res) => res.recipe_id === item.recipe_id && res.group === item.group
                        );

                        if (existente) {
                            let data = {
                                idsDetails: item.recipedetail_id,
                                name: item.name
                            }
                            existente.name += ` <strong>+</strong> ${item.name}`;
                            existente.recipedetail_ids.push(data);
                        } else {
                            let data = {
                                idsDetails: item.recipedetail_id,
                                name: item.name
                            }
                            resultado.push({
                                ...item,
                                recipedetail_ids: [data],
                                count: counter
                            });
                            counter++;
                        }
                    });

                    this.medicamentos = resultado;

                    this.recipes = [];
                    ids.forEach(item => {
                        let recipe = {
                            id: item,
                            medicamentos: []
                        }
                        this.medicamentos.forEach(item2 => {
                            if (item == item2.recipe_id) {
                                recipe.medicamentos.push(item2)
                            }
                        })
                        this.recipes.push(recipe);
                    });

                    this.data.recipes = this.recipes;

                    // Listado de medicamentos 
                    //listados de tabla 2
                    this.datatime2 = response.data.allDates2;
                    this.data.datatime2 = this.datatime2;
                    let datedataK_1 = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedataK_1.push(date);
                    });
                    response.data.nursing_kardexes_1.forEach(kardex => {
                        let numkardex = 0
                        datedataK_1.forEach(date => {
                            if (kardex.date == date.date) {
                                if (kardex.hour >= "07:31:00" && kardex.hour < "13:31:00") {
                                    date.mañana.push(kardex);
                                } else if (kardex.hour >= "13:31:00" && kardex.hour < "19:30:00") {
                                    date.tarde.push(kardex);
                                } else if (kardex.hour >= "19:30:00" && kardex.hour <= "23:59:00") {
                                    date.noche.push(kardex);
                                } else {
                                    datedataK_1[numkardex].noche.push(kardex)
                                }
                            }
                            numkardex++
                        })
                    });
                    this.data.nursing_kardexes_1 = datedataK_1;

                    let datedataK_2 = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedataK_2.push(date);
                    });
                    response.data.nursing_kardexes_2.forEach(kardex => {
                        let numkardex = 0
                        datedataK_2.forEach(date => {
                            if (kardex.date == date.date) {
                                if (kardex.hour >= "07:31:00" && kardex.hour < "13:31:00") {
                                    date.mañana.push(kardex);
                                } else if (kardex.hour >= "13:31:00" && kardex.hour < "19:30:00") {
                                    date.tarde.push(kardex);
                                } else if (kardex.hour >= "19:30:00" && kardex.hour <= "23:59:00") {
                                    date.noche.push(kardex);
                                } else {

                                    datedataK_2[numkardex].noche.push(kardex)

                                }
                            }
                            numkardex++
                        })
                    });
                    this.data.nursing_kardexes_2 = datedataK_2;

                    let datedataK_3 = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedataK_3.push(date);
                    });
                    response.data.nursing_kardexes_3.forEach(kardex => {
                        let numkardex = 0
                        datedataK_3.forEach(date => {
                            if (kardex.date == date.date) {
                                if (kardex.hour >= "07:31:00" && kardex.hour < "13:31:00") {
                                    date.mañana.push(kardex);
                                } else if (kardex.hour >= "13:31:00" && kardex.hour < "19:30:00") {
                                    date.tarde.push(kardex);
                                } else if (kardex.hour >= "19:30:00" && kardex.hour <= "23:59:00") {
                                    date.noche.push(kardex);
                                } else {
                                    datedataK_3[numkardex].noche.push(kardex)
                                }
                            }
                            numkardex++
                        })
                    });
                    this.data.nursing_kardexes_3 = datedataK_3;

                    let datedataK_4 = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedataK_4.push(date);
                    });
                    response.data.nursing_kardexes_4.forEach(kardex => {
                        let numkardex = 0
                        datedataK_4.forEach(date => {
                            if (kardex.date == date.date) {
                                if (kardex.hour >= "07:31:00" && kardex.hour < "13:31:00") {
                                    date.mañana.push(kardex);
                                } else if (kardex.hour >= "13:31:00" && kardex.hour < "19:30:00") {
                                    date.tarde.push(kardex);
                                } else if (kardex.hour >= "19:30:00" && kardex.hour <= "23:59:00") {
                                    date.noche.push(kardex);
                                } else {
                                    datedataK_4[numkardex].noche.push(kardex)
                                }
                            }
                            numkardex++
                        })
                    });
                    this.data.nursing_kardexes_4 = datedataK_4;

                    let datedataK_5 = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedataK_5.push(date);
                    });
                    response.data.nursing_kardexes_5.forEach(kardex => {
                        let numkardex = 0
                        datedataK_5.forEach(date => {
                            if (kardex.date == date.date) {
                                if (kardex.hour >= "07:31:00" && kardex.hour < "13:31:00") {
                                    date.mañana.push(kardex);
                                } else if (kardex.hour >= "13:31:00" && kardex.hour < "19:30:00") {
                                    date.tarde.push(kardex);
                                } else if (kardex.hour >= "19:30:00" && kardex.hour <= "23:59:00") {
                                    date.noche.push(kardex);
                                } else {
                                    datedataK_5[numkardex].noche.push(kardex)
                                }
                            }
                            numkardex++
                        })
                    });
                    this.data.nursing_kardexes_5 = datedataK_5;

                    let datedataK_6 = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedataK_6.push(date);
                    });
                    response.data.nursing_kardexes_6.forEach(kardex => {
                        let numkardex = 0
                        datedataK_6.forEach(date => {
                            if (kardex.date == date.date) {
                                if (kardex.hour >= "07:31:00" && kardex.hour < "13:31:00") {
                                    date.mañana.push(kardex);
                                } else if (kardex.hour >= "13:31:00" && kardex.hour < "19:30:00") {
                                    date.tarde.push(kardex);
                                } else if (kardex.hour >= "19:30:00" && kardex.hour <= "23:59:00") {
                                    date.noche.push(kardex);
                                } else {
                                    datedataK_6[numkardex].noche.push(kardex)
                                }
                            }
                            numkardex++
                        })
                    });
                    this.data.nursing_kardexes_6 = datedataK_6;

                    // curaciones

                    let datedataC = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedataC.push(date);
                    });
                    response.data.historys.forEach(item => {
                        let numhistory = 0
                        datedataC.forEach(date => {
                            if (item.aplication_date == date.date) {
                                if (item.hour >= "07:31:00" && item.hour < "13:31:00") {
                                    date.mañana.push(item);
                                } else if (item.hour >= "13:31:00" && item.hour < "19:30:00") {
                                    date.tarde.push(item);
                                } else if (item.hour >= "19:30:00" && item.hour <= "23:59:00") {
                                    date.noche.push(item);
                                } else {
                                    datedataC[numhistory - 1].noche.push(item)
                                }
                            }
                            numhistory++
                        })
                    })
                    this.data.historys = datedataC

                    // notes 
                    let datedataN = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: '',
                            tarde: '',
                            noche: '',
                        }
                        datedataN.push(date);
                    });
                    response.data.notes.forEach(item => {

                        let numnote = 0
                        datedataN.forEach(date => {
                            if (item.date == date.date) {
                                if (item.hour >= "07:31:00" && item.hour < "13:31:00") {
                                    date.mañana = item;
                                } else if (item.hour >= "13:31:00" && item.hour < "19:30:00") {
                                    date.tarde = item;
                                } else if (item.hour >= "19:30:00" && item.hour <= "23:59:00") {
                                    date.noche = item;
                                } else {
                                    datedataN[numnote - 1].noche = item
                                }
                            }
                            numnote++
                        })
                    })
                    this.data.notes = datedataN

                    // dieta 
                    let datedata = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedata.push(date);
                    });
                    response.data.dietas.forEach(dieta => {
                        let numdieta = 0
                        datedata.forEach(date => {
                            if (dieta.date == date.date) {
                                if (dieta.hour >= "07:31:00" && dieta.hour < "13:31:00") {
                                    date.mañana.push(dieta);
                                } else if (dieta.hour >= "13:31:00" && dieta.hour < "19:30:00") {
                                    date.tarde.push(dieta);
                                } else if (dieta.hour >= "19:30:00" && dieta.hour <= "23:59:00") {
                                    date.noche.push(dieta);
                                } else {
                                    datedata[numdieta - 1].noche.push(dieta)
                                }
                            }
                            numdieta++
                        })
                    });

                    this.data.dietas = datedata;
                    //listados de tabla 2

                    //procedimientos invasivos 1
                    this.data.procedimiento_invasivo = response.data.procedimiento_invasivo;
                    //procedimientos invasivos 1
                    this.data.procedimiento_invasivo2 = response.data.procedimiento_invasivo2;
                    //planes
                    let plandetails = response.data.planes;
                    this.data.plan_laboratorio = []
                    this.data.plan_imagenes = []
                    this.data.plan_procedimientos = []
                    this.data.plan_interconsultas = []
                    plandetails.forEach(item => {
                        let concepto = {
                            name: item.subsubcategorie.name,
                            aplicacion: item.aplication_date_new,
                            resultado: item.result_date_new,
                            interconsulta: item.interconsulta ? item.interconsulta : '-'
                        }

                        if (item.type == 1) {
                            this.data.plan_laboratorio.push(concepto)
                        } else if (item.type == 2) {
                            this.data.plan_imagenes.push(concepto)
                        } else if (item.type == 3) {
                            this.data.plan_procedimientos.push(concepto)
                        } else {
                            this.data.plan_interconsultas.push(concepto)
                        }

                    });

                    // Insulina 
                    this.data.insulina = response.data.insulina;
                    //LOCALIZACIÓN DE CATÉTERES Y UPP
                    this.data.upp = response.data.upp;

                    //MEDICAMENTOS FRECUENTES

                    this.datatimefrequency = response.data.allDatesfreq;
                    this.data.datatimefrequency = response.data.allDatesfreq
                    let idsfreq = [];
                    this.medicamentosfrequency = []
                    response.data.resultsfreq.forEach(recipe => {
                        idsfreq.push(recipe.id)
                        recipe.commonmedicationsdetail.forEach(recipedetail => {
                            let medicenedetail = {
                                recipe_id: recipedetail.common_medication_id,
                                recipedetail_id: recipedetail.id,
                                name: recipedetail.name,
                                quantity: recipedetail.total,
                                start: recipedetail.start_date ? true : false,
                                indicaciones: recipe.indications,
                                count: recipedetail.countfreq + 1,
                                horario: []
                            }
                            this.datatimefrequency.forEach(item => {
                                let date = {
                                    date: item,
                                    mañana: [],
                                    tarde: [],
                                    noche: [],
                                }
                                medicenedetail.horario.push(date);
                            });
                            recipedetail.schedule.forEach(horario => {
                                let num = 0
                                medicenedetail.horario.forEach(hour => {
                                    if (hour.date == horario.date) {
                                        if (horario.time_kardex >= "07:31:00" && horario.time_kardex < "13:31:00") {
                                            hour.mañana.push(horario);
                                        } else if (horario.time_kardex >= "13:31:00" && horario.time_kardex < "19:30:00") {
                                            hour.tarde.push(horario);
                                        } else if (horario.time_kardex >= "19:30:00" && horario.time_kardex <= "23:59:00") {
                                            hour.noche.push(horario);
                                        } else {
                                            medicenedetail.horario[num - 1].noche.push(horario)
                                        }
                                    }
                                    num++
                                })
                            })
                            this.medicamentosfrequency.push(medicenedetail)
                        })

                    });
                    let numfreq = 0
                    this.medicamentosfrequency.forEach(me => {
                        if (me.horario[this.datatimefrequency.length - 1].mañana.length == 0 &&
                            me.horario[this.datatimefrequency.length - 1].tarde.length == 0 &&
                            me.horario[this.datatimefrequency.length - 1].noche.length == 0
                        ) {
                            this.posfrequency = 0;
                        } else {
                            numfreq = numfreq + 1
                        }

                    })
                    if (numfreq == 0) {
                        this.datatimefrequency.pop();
                        this.medicamentosfrequency.forEach(me => {
                            me.horario.pop()
                        })
                    }

                    this.commonmedicationfrequency = [];
                    idsfreq.forEach(item => {
                        let recipe = {
                            id: item,
                            medicamentos: []
                        }
                        this.medicamentosfrequency.forEach(item2 => {
                            if (item == item2.recipe_id) {
                                recipe.medicamentos.push(item2)
                            }
                        })
                        this.commonmedicationfrequency.push(recipe);

                    })

                    this.data.commonmedicationfrequency = this.commonmedicationfrequency

                })
                .catch(error => {
                    this.$refs.SweetAlert.showError(error);
                })
        },
        mtdKardexReportSop: function (process) {
            this.post({
                    url: this.$store.getters.get__url + "/paciente/showKardexSop",
                    token: this.$store.getters.get__token,
                    params: {
                        id: this.id,
                        process: process
                    },
                })
                .then((response) => {
                    // fecha de los medicamentos
                    this.datatime = response.data.allDates
                    this.data.datatime = response.data.allDates
                    // Listado de medicamentos 
                    let ids = [];
                    this.medicamentos = []
                    response.data.results.forEach(recipe => {
                        ids.push(recipe.id)
                        recipe.recipedetails.forEach(recipedetail => {
                            let medicenedetail = {
                                recipe_id: recipedetail.recipe_id,
                                recipedetail_id: recipedetail.id,
                                name: recipedetail.molecule + ' ' + recipedetail.presentation,
                                quantity: recipedetail.quantity + '' + (recipedetail.measure == 1 ? 'CC' : (recipedetail.measure == 2 ? 'ML' : 'GOTAS')) + '/' + (recipedetail.unit_time == 1 ? 'H' : 'MIN'),
                                quantity_real: recipedetail.quantity,
                                unidad: recipedetail.measure,
                                time: recipedetail.unit_time,
                                group: recipedetail.mix,
                                dosis: recipedetail.dosis,
                                via: recipedetail.via,
                                hora: recipedetail.frequency,
                                start: recipedetail.start_date ? true : false,
                                prn: recipedetail.prn == 1 ? true : false,
                                cancelation: recipedetail.cancellation_date ? true : false,
                                insuline: recipedetail.insulin,
                                type: recipedetail.type,
                                indicaciones: recipedetail.indicaciones,
                                count: recipedetail.count + 1,
                                horario: []
                            }
                            this.datatime.forEach(item => {
                                let date = {
                                    date: item,
                                    mañana: [],
                                    tarde: [],
                                    noche: [],
                                }
                                medicenedetail.horario.push(date);
                            });
                            let aplicaciones = 0
                            recipedetail.schedule.forEach(horario => {
                                aplicaciones++
                                let num = 0
                                medicenedetail.horario.forEach(hour => {
                                    if (hour.date == horario.date) {
                                        if (medicenedetail.type == 3) {
                                            horario.aplicacion = `(${this.convertirARomanos(aplicaciones)})`
                                        }
                                        if (horario.time_kardex >= "07:31:00" && horario.time_kardex < "13:31:00") {
                                            hour.mañana.push(horario);
                                        } else if (horario.time_kardex >= "13:31:00" && horario.time_kardex < "19:30:00") {
                                            hour.tarde.push(horario);
                                        } else if (horario.time_kardex >= "19:30:00" && horario.time_kardex <= "23:59:00") {
                                            hour.noche.push(horario);
                                        } else {
                                            medicenedetail.horario[num - 1].noche.push(horario)
                                        }
                                    }
                                    num++
                                })
                            })
                            this.medicamentos.push(medicenedetail)
                        })

                    });
                    let num = 0
                    this.medicamentos.forEach(me => {
                        if (me.horario[this.datatime.length - 1].mañana.length == 0 &&
                            me.horario[this.datatime.length - 1].tarde.length == 0 &&
                            me.horario[this.datatime.length - 1].noche.length == 0
                        ) {
                            this.pos = 0;
                        } else {
                            num = num + 1
                        }

                    })
                    if (num == 0) {
                        this.datatime.pop();
                        this.medicamentos.forEach(me => {
                            me.horario.pop()
                        })
                    }

                    let mezcla = this.medicamentos;
                    let resultado = [];
                    let counter = 1;

                    mezcla.forEach((item) => {

                        if (item.group === 0) {
                            let data = {
                                idsDetails: item.recipedetail_id,
                                name: item.name
                            }
                            resultado.push({
                                ...item,
                                recipedetail_ids: [data],
                                count: counter++
                            });
                            return;
                        }

                        let existente = resultado.find(
                            (res) => res.recipe_id === item.recipe_id && res.group === item.group
                        );

                        if (existente) {
                            let data = {
                                idsDetails: item.recipedetail_id,
                                name: item.name
                            }
                            existente.name += ` <strong>+</strong> ${item.name}`;
                            existente.recipedetail_ids.push(data);
                        } else {
                            let data = {
                                idsDetails: item.recipedetail_id,
                                name: item.name
                            }
                            resultado.push({
                                ...item,
                                recipedetail_ids: [data],
                                count: counter
                            });
                            counter++;
                        }
                    });

                    this.medicamentos = resultado;

                    this.recipes = [];
                    ids.forEach(item => {
                        let recipe = {
                            id: item,
                            medicamentos: []
                        }
                        this.medicamentos.forEach(item2 => {
                            if (item == item2.recipe_id) {
                                recipe.medicamentos.push(item2)
                            }
                        })
                        this.recipes.push(recipe);
                    });

                    this.data.recipes = this.recipes;

                    // Listado de medicamentos 
                    //listados de tabla 2
                    this.datatime2 = response.data.allDates2;
                    this.data.datatime2 = this.datatime2;
                    let datedataK_1 = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedataK_1.push(date);
                    });
                    response.data.nursing_kardexes_1.forEach(kardex => {
                        let numkardex = 0
                        datedataK_1.forEach(date => {
                            if (kardex.date == date.date) {
                                if (kardex.hour >= "07:31:00" && kardex.hour < "13:31:00") {
                                    date.mañana.push(kardex);
                                } else if (kardex.hour >= "13:31:00" && kardex.hour < "19:30:00") {
                                    date.tarde.push(kardex);
                                } else if (kardex.hour >= "19:30:00" && kardex.hour <= "23:59:00") {
                                    date.noche.push(kardex);
                                } else {
                                    datedataK_1[numkardex].noche.push(kardex)
                                }
                            }
                            numkardex++
                        })
                    });
                    this.data.nursing_kardexes_1 = datedataK_1;

                    let datedataK_2 = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedataK_2.push(date);
                    });
                    response.data.nursing_kardexes_2.forEach(kardex => {
                        let numkardex = 0
                        datedataK_2.forEach(date => {
                            if (kardex.date == date.date) {
                                if (kardex.hour >= "07:31:00" && kardex.hour < "13:31:00") {
                                    date.mañana.push(kardex);
                                } else if (kardex.hour >= "13:31:00" && kardex.hour < "19:30:00") {
                                    date.tarde.push(kardex);
                                } else if (kardex.hour >= "19:30:00" && kardex.hour <= "23:59:00") {
                                    date.noche.push(kardex);
                                } else {

                                    datedataK_2[numkardex].noche.push(kardex)

                                }
                            }
                            numkardex++
                        })
                    });
                    this.data.nursing_kardexes_2 = datedataK_2;

                    let datedataK_3 = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedataK_3.push(date);
                    });
                    response.data.nursing_kardexes_3.forEach(kardex => {
                        let numkardex = 0
                        datedataK_3.forEach(date => {
                            if (kardex.date == date.date) {
                                if (kardex.hour >= "07:31:00" && kardex.hour < "13:31:00") {
                                    date.mañana.push(kardex);
                                } else if (kardex.hour >= "13:31:00" && kardex.hour < "19:30:00") {
                                    date.tarde.push(kardex);
                                } else if (kardex.hour >= "19:30:00" && kardex.hour <= "23:59:00") {
                                    date.noche.push(kardex);
                                } else {
                                    datedataK_3[numkardex].noche.push(kardex)
                                }
                            }
                            numkardex++
                        })
                    });
                    this.data.nursing_kardexes_3 = datedataK_3;

                    let datedataK_4 = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedataK_4.push(date);
                    });
                    response.data.nursing_kardexes_4.forEach(kardex => {
                        let numkardex = 0
                        datedataK_4.forEach(date => {
                            if (kardex.date == date.date) {
                                if (kardex.hour >= "07:31:00" && kardex.hour < "13:31:00") {
                                    date.mañana.push(kardex);
                                } else if (kardex.hour >= "13:31:00" && kardex.hour < "19:30:00") {
                                    date.tarde.push(kardex);
                                } else if (kardex.hour >= "19:30:00" && kardex.hour <= "23:59:00") {
                                    date.noche.push(kardex);
                                } else {
                                    datedataK_4[numkardex].noche.push(kardex)
                                }
                            }
                            numkardex++
                        })
                    });
                    this.data.nursing_kardexes_4 = datedataK_4;

                    let datedataK_5 = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedataK_5.push(date);
                    });
                    response.data.nursing_kardexes_5.forEach(kardex => {
                        let numkardex = 0
                        datedataK_5.forEach(date => {
                            if (kardex.date == date.date) {
                                if (kardex.hour >= "07:31:00" && kardex.hour < "13:31:00") {
                                    date.mañana.push(kardex);
                                } else if (kardex.hour >= "13:31:00" && kardex.hour < "19:30:00") {
                                    date.tarde.push(kardex);
                                } else if (kardex.hour >= "19:30:00" && kardex.hour <= "23:59:00") {
                                    date.noche.push(kardex);
                                } else {
                                    datedataK_5[numkardex].noche.push(kardex)
                                }
                            }
                            numkardex++
                        })
                    });
                    this.data.nursing_kardexes_5 = datedataK_5;

                    let datedataK_6 = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedataK_6.push(date);
                    });
                    response.data.nursing_kardexes_6.forEach(kardex => {
                        let numkardex = 0
                        datedataK_6.forEach(date => {
                            if (kardex.date == date.date) {
                                if (kardex.hour >= "07:31:00" && kardex.hour < "13:31:00") {
                                    date.mañana.push(kardex);
                                } else if (kardex.hour >= "13:31:00" && kardex.hour < "19:30:00") {
                                    date.tarde.push(kardex);
                                } else if (kardex.hour >= "19:30:00" && kardex.hour <= "23:59:00") {
                                    date.noche.push(kardex);
                                } else {
                                    datedataK_6[numkardex].noche.push(kardex)
                                }
                            }
                            numkardex++
                        })
                    });
                    this.data.nursing_kardexes_6 = datedataK_6;

                    // curaciones

                    let datedataC = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedataC.push(date);
                    });
                    response.data.historys.forEach(item => {
                        let numhistory = 0
                        datedataC.forEach(date => {
                            if (item.aplication_date == date.date) {
                                if (item.hour >= "07:31:00" && item.hour < "13:31:00") {
                                    date.mañana.push(item);
                                } else if (item.hour >= "13:31:00" && item.hour < "19:30:00") {
                                    date.tarde.push(item);
                                } else if (item.hour >= "19:30:00" && item.hour <= "23:59:00") {
                                    date.noche.push(item);
                                } else {
                                    datedataC[numhistory - 1].noche.push(item)
                                }
                            }
                            numhistory++
                        })
                    })
                    this.data.historys = datedataC

                    // notes 
                    let datedataN = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: '',
                            tarde: '',
                            noche: '',
                        }
                        datedataN.push(date);
                    });
                    response.data.notes.forEach(item => {

                        let numnote = 0
                        datedataN.forEach(date => {
                            if (item.date == date.date) {
                                if (item.hour >= "07:31:00" && item.hour < "13:31:00") {
                                    date.mañana = item;
                                } else if (item.hour >= "13:31:00" && item.hour < "19:30:00") {
                                    date.tarde = item;
                                } else if (item.hour >= "19:30:00" && item.hour <= "23:59:00") {
                                    date.noche = item;
                                } else {
                                    datedataN[numnote - 1].noche = item
                                }
                            }
                            numnote++
                        })
                    })
                    this.data.notes = datedataN

                    // dieta 
                    let datedata = []
                    this.datatime2.forEach(item => {
                        let date = {
                            date: item,
                            mañana: [],
                            tarde: [],
                            noche: [],
                        }
                        datedata.push(date);
                    });
                    response.data.dietas.forEach(dieta => {
                        let numdieta = 0
                        datedata.forEach(date => {
                            if (dieta.date == date.date) {
                                if (dieta.hour >= "07:31:00" && dieta.hour < "13:31:00") {
                                    date.mañana.push(dieta);
                                } else if (dieta.hour >= "13:31:00" && dieta.hour < "19:30:00") {
                                    date.tarde.push(dieta);
                                } else if (dieta.hour >= "19:30:00" && dieta.hour <= "23:59:00") {
                                    date.noche.push(dieta);
                                } else {
                                    datedata[numdieta - 1].noche.push(dieta)
                                }
                            }
                            numdieta++
                        })
                    });

                    this.data.dietas = datedata;
                    //listados de tabla 2

                    //procedimientos invasivos 1
                    this.data.procedimiento_invasivo = response.data.procedimiento_invasivo;
                    //procedimientos invasivos 1
                    this.data.procedimiento_invasivo2 = response.data.procedimiento_invasivo2;
                    //planes
                    let plandetails = response.data.planes;
                    this.data.plan_laboratorio = []
                    this.data.plan_imagenes = []
                    this.data.plan_procedimientos = []
                    this.data.plan_interconsultas = []
                    plandetails.forEach(item => {
                        let concepto = {
                            name: item.subsubcategorie.name,
                            aplicacion: item.aplication_date_new,
                            resultado: item.result_date_new,
                            interconsulta: item.interconsulta ? item.interconsulta : '-'
                        }

                        if (item.type == 1) {
                            this.data.plan_laboratorio.push(concepto)
                        } else if (item.type == 2) {
                            this.data.plan_imagenes.push(concepto)
                        } else if (item.type == 3) {
                            this.data.plan_procedimientos.push(concepto)
                        } else {
                            this.data.plan_interconsultas.push(concepto)
                        }

                    });

                    // Insulina 
                    this.data.insulina = response.data.insulina;
                    //LOCALIZACIÓN DE CATÉTERES Y UPP
                    this.data.upp = response.data.upp;

                    //MEDICAMENTOS FRECUENTES

                    this.datatimefrequency = response.data.allDatesfreq;
                    this.data.datatimefrequency = response.data.allDatesfreq
                    let idsfreq = [];
                    this.medicamentosfrequency = []
                    response.data.resultsfreq.forEach(recipe => {
                        idsfreq.push(recipe.id)
                        recipe.commonmedicationsdetail.forEach(recipedetail => {
                            let medicenedetail = {
                                recipe_id: recipedetail.common_medication_id,
                                recipedetail_id: recipedetail.id,
                                name: recipedetail.name,
                                quantity: recipedetail.total,
                                start: recipedetail.start_date ? true : false,
                                indicaciones: recipe.indications,
                                count: recipedetail.countfreq + 1,
                                horario: []
                            }
                            this.datatimefrequency.forEach(item => {
                                let date = {
                                    date: item,
                                    mañana: [],
                                    tarde: [],
                                    noche: [],
                                }
                                medicenedetail.horario.push(date);
                            });
                            recipedetail.schedule.forEach(horario => {
                                let num = 0
                                medicenedetail.horario.forEach(hour => {
                                    if (hour.date == horario.date) {
                                        if (horario.time_kardex >= "07:31:00" && horario.time_kardex < "13:31:00") {
                                            hour.mañana.push(horario);
                                        } else if (horario.time_kardex >= "13:31:00" && horario.time_kardex < "19:30:00") {
                                            hour.tarde.push(horario);
                                        } else if (horario.time_kardex >= "19:30:00" && horario.time_kardex <= "23:59:00") {
                                            hour.noche.push(horario);
                                        } else {
                                            medicenedetail.horario[num - 1].noche.push(horario)
                                        }
                                    }
                                    num++
                                })
                            })
                            this.medicamentosfrequency.push(medicenedetail)
                        })

                    });
                    let numfreq = 0
                    this.medicamentosfrequency.forEach(me => {
                        if (me.horario[this.datatimefrequency.length - 1].mañana.length == 0 &&
                            me.horario[this.datatimefrequency.length - 1].tarde.length == 0 &&
                            me.horario[this.datatimefrequency.length - 1].noche.length == 0
                        ) {
                            this.posfrequency = 0;
                        } else {
                            numfreq = numfreq + 1
                        }

                    })
                    if (numfreq == 0) {
                        this.datatimefrequency.pop();
                        this.medicamentosfrequency.forEach(me => {
                            me.horario.pop()
                        })
                    }

                    this.commonmedicationfrequency = [];
                    idsfreq.forEach(item => {
                        let recipe = {
                            id: item,
                            medicamentos: []
                        }
                        this.medicamentosfrequency.forEach(item2 => {
                            if (item == item2.recipe_id) {
                                recipe.medicamentos.push(item2)
                            }
                        })
                        this.commonmedicationfrequency.push(recipe);

                    })

                    this.data.commonmedicationfrequency = this.commonmedicationfrequency
                    console.log(this.data)

                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
    }

}
</script>
