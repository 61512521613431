<template>
<div class="c-app">
    <div class="container">
        <div class="d-flex" :class="num == 1 ?'justify-content-between' : 'justify-content-end'" v-show="subpage == 'table'">
            <div class="mt-3 mb-4" v-if="num == 1">
                <button type="button" class="btn btn-secondary" @click="mtdChangeView('gestionPacientes')">
                    <i class="fas fa-arrow-left"></i> Volver
                </button>
            </div>
            <div class=" btn-home mt-3 mb-4">
                <button type="button" class="btn btn-outline-main" @click="mtdChangeView('home')">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div class="d-flex justify-content-center" :class="subpage == 'table'?'':'mt-4'">
            <div class="card w-100 mb-lg-5">
                <div class="text-center card-header text-white h4 bg-main" v-if="subpage == 'table'">
                    <strong>HISTORIAS CLINICAS</strong>
                </div>
                <div class="card-body w-100 h-100">
                    <div class="row" v-if="subpage == 'table'">
                        <div class="col-md-12 py-2 px-2">
                            <div class="row mx-0">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                                    <div class="input-group input-group">
                                        <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                        <input @keyup.enter="mtdGetData" v-model="search" type="text" class="form-control form-control" placeholder="Buscar DNI / Nombre y Apellido" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Pag -->
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center px-4">
                            <div v-if="cpData.length > 0" class="d-flex justify-content-end">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div class="col-md-12" id="content-timelinetable">
                            <table class="table table-bordered border-main">
                                <caption class="py-0 my-0"></caption>
                                <thead class="bg-main">
                                    <tr class=" table-th text-white text-center">
                                        <th class="table-th-number">N°</th>
                                        <th>DNI</th>
                                        <th>PACIENTE</th>
                                        <th>EDAD</th>
                                        <th>FECHA</th>
                                        <th>INGRESO POR</th>
                                        <th class="table-th-acciones">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main">
                                    <tr class="table-body text-center" v-if="cpData.length === 0 && state == false">
                                        <td colspan="7" class="text-center">Escriba un nombre o DNI</td>
                                    </tr>
                                    <tr class="table-body text-center" v-if="cpData.length === 0 && state == true">
                                        <td colspan="7" class="text-center">No se encontraron resultados</td>
                                    </tr>
                                    <tr class="table-body text-center" v-for="(item, index) in cpData" :key="index">
                                        <td>
                                            {{mtdNumber(index)}}
                                        </td>
                                        <td>
                                            {{ item.patient.document }}
                                        </td>
                                        <td>
                                            {{ item.patient.name }}
                                        </td>
                                        <td>
                                            {{ item.patient.year }}
                                        </td>
                                        <td>
                                            {{ item.date | formatDate }}
                                        </td>
                                        <td>
                                            {{ item.incomen }}
                                        </td>
                                        <td>
                                            <div class="text-center d-flex justify-content-center">
                                                <button @click="mtdViewHC(item)" class="btn btn-sm btn-info text-white mx-2">
                                                    <i class="fa fa-eye"></i>
                                                </button>
                                                <button @click="mtdViewPdf(item)" class="btn btn-sm btn-primary text-white mx-2">
                                                    <i class="fa fa-file-pdf"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row" v-if="subpage == 'choose'">
                        <DetailMedicalAct @mtdChangeSubPage="mtdChangeSubPage" :dataDetail="dataDetail" :id="medical_act_id"></DetailMedicalAct>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />

</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import ConsultingShow from './MedicalAct/ReportHc.vue';
import DetailMedicalAct from './MedicalAct/DetailMedicalAct.vue';

export default {
    name: "c-admision-pages-reportehc",
    components: {
        SweetAlert,
        ConsultingShow,
        DetailMedicalAct
    },
    data() {
        return {
            filter: {
                begindate: null,
                enddate: null,
            },
            subpage: "table",
            swal: null,
            search: "",
            medical_act_id: 0,
            dataShow: [],
            excel: [],
            state: "",
            dataDetail: {},
            typeArray: [],
            //Pag
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
        };
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
        search(newVal) {
            if (newVal === "") {
                this.dataShow = [];
                this.state = false;
            }
        }
    },
    props: {
        num: Number,
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    created() {
        this.state = false;
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdViewHC: function (item) {
            this.medical_act_id = item.id;
            this.get({
                    url: this.$store.getters.get__url + "/paciente/showMedicalAct/" + item.id,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.typeArray = response.data.type_array;
                    this.dataDetail = response.data;
                    //ver que pagina mostrar
                    this.subpage = 'choose';

                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });

        },

        mtdGetData: function () {
            if (this.search.length > 0) {

                this.get({
                        url: this.$store.getters.get__url + "/paciente/filter/" + this.search,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        this.dataShow = [];
                        this.selectPage = 1;
                        this.state = true;
                        this.data = response.data;
                        this.calculateData(10);
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });
            } else {
                this.data = [];
                this.dataShow = [];
                this.calculateData(10);
            }

        },

        calculateData(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.data;
            let indexInitial = this.selectPage;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );

            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },

        mtdViewPdf: function (item) {
            window.open(
                this.$store.getters.get__url + "/viewpdfAfiliacionHisto/" + item.id,
                "_blank"
            );
        },

        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (this.selectPage - 1) * 10 + (index + 1)
        },
        mtdChangeSubPage: function (subpage) {
            this.subpage = 'table';
        },
        mtdChangeView: function (option) {
            if (this.num == 1) {
                //this.$emit("returnHome", "home");
                this.$emit("mtdChangePage", option);

            } else if (this.num == 0) {
                window.location.reload();
            } else {
                console.log("Error");
            }
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatTime(time) {
            let arTime = time.split(":");
            if (arTime[0] <= 12) {
                return time + " am";
            } else {
                return arTime[0] - 12 + ":" + arTime[1] + ":" + arTime[2] + " pm";
            }
        },
    },
}
</script>
