<template>
<div class="container">
    <div class="row">
        <div class="btn-home col-md-12 mt-3 mb-4 d-flex justify-content-end">
            <button type="button" class="btn btn-outline-main" @click="returnHome">
                <i class="fas fa-home-lg-alt"></i> Inicio
            </button>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <div class="card w-100">
            <div class="card-header h4 bg-main text-white text-center">
                <strong>REPORTE DE EGRESOS</strong>
            </div>
            <div class="card-body w-100">
                <div class="d-lg-flex flex-grap gap-1 px-1">
                    <div class="col-xl-3 col-md-12 col-lg-3 col-sm-12 mb-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-4">Desde</span>
                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.date_start" @change="mtdGetData" />
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-12 col-lg-3 col-sm-12 mb-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-4">Hasta</span>
                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filter.date_end" @change="mtdGetData" />
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6 col-sm-12 mb-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text text-white bg-main cursor-pointer" @click="mtdGetData">
                                <i class="fas fa-search"></i>&nbsp;&nbsp;Filtrar
                            </span>
                            <input type="text" class="form-control form-control-sm" placeholder="Buscar serie o número" v-model="search" @input="calculateData(10)" />
                        </div>
                    </div>
                </div>
                <!-- CONTENIDO - TABLA -->
                <div class="row">
                    <div v-if="cpData.length > 0" class="d-flex justify-content-end mb-2">
                        <nav aria-label="Page navigation example ">
                            <ul class="pagination mb-0 ">
                                <li class="page-item">
                                    <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                </li>
                                <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                    <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                </li>
                                <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                    <span class="page-link">...</span>
                                </li>

                                <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                    <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                    </a>
                                </li>
                                <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                    <span class="page-link">...</span>
                                </li>
                                <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                    <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                    </a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div class="col-md-12 mb-4 px-2" id="content-timelinetable">
                        <table class="table table-bordered border-main" aria-describedby="mydesc">
                            <caption class="py-0 my-0"></caption>
                            <thead>
                                <tr class="text-white border-white text-center bg-main text-xs align-middle">
                                    <th scope="col">N°</th>
                                    <th scope="col">ADMISIONISTA</th>
                                    <th scope="col">FECHA EGRESO</th>
                                    <th scope="col">FECHA DE COMPROBANTE</th>
                                    <th scope="col">TIPO COMPROBANTE</th>
                                    <th scope="col">TIPO DE EGRESO</th>
                                    <th scope="col">TIPO DE GASTO</th>
                                    <th scope="col">SERIE</th>
                                    <th scope="col">NÚMERO</th>
                                    <th scope="col">RAZÓN SOCIAL</th>
                                    <th scope="col">RUC / DNI</th>
                                    <th scope="col">TOTAL</th>
                                    <th scope="col">MONTO PAGADO</th>
                                    <th scope="col">PENDIENTE</th>
                                    <th scope="col">MEDIO DE PAGO</th>
                                    <th scope="col">MOTIVO, DESCRIPCIÓN O DETALLE DE COMPRA</th>
                                    <th scope="col">PDF</th>
                                </tr>
                            </thead>
                            <tbody class="text-xxs">
                                <tr v-if="cpData.length === 0">
                                    <td colspan="15" class="text-center">No se encontraron resultados</td>
                                </tr>
                                <tr v-for="(item, index) in cpData" :key="index">
                                    <td>{{ mtdNumber(index) }}</td>
                                    <td>{{ item.bead.user.name }} {{ item.bead.user.last_name }}</td>
                                    <td>{{ item.date | formatDate }}</td>
                                    <td>{{
                          item.date_doc == null
                          ? item.date
                          : item.date_doc | formatDate
                        }}</td>
                                    <td>{{ item.document_type ? item.document_type.name : '-'  }}</td>
                                    <td>{{ item.modulo == 'passage' ? 'PASAJE' 
                    : (item.modulo == 'expense' ? 'EGRESO' : item.modulo == 'receipt' ? 'RH' : 'RETIROS CAJA FUERTE' ) }}</td>
                                    <td>{{ item.type ? item.type.name : 'NO ESPECIFICADO'}}</td>
                                    <td>{{ item.serie ?? '-'}}</td>
                                    <td>{{ item.number ?? '-' }}</td>
                                    <td>{{ item.modulo == 'passage' ? item.passage.name : (item.modulo == 'retiro' ? item.bead.user.last_name + 
                      " "+item.bead.user.name:(item.modulo =='receipt'? item.receipt.name:item.company )) }}</td>
                                    <td>{{ item.modulo == 'passage' ? item.passage.document : (item.modulo == 'retiro' ? item.bead.user.dni : (
                        item.modulo == 'receipt' ? item.receipt.document : item.document)) }}</td>
                                    <td>{{ item.total}}</td>
                                    <td>{{ item.amount}}</td>
                                    <td>{{ item.pending}}</td>
                                    <td>{{ item.method}}</td>
                                    <td>{{ item.observation}}</td>
                                    <td>
                                        <button v-if="item.modulo == 'expense' || item.modulo == 'receipt'" @click="mtdViewPdfAdjunto(item)" title="Visualizar comprobante" class="btn btn-sm btn-info text-white mx-1">
                                            <i class="fa fa-file-pdf"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <SweetAlert ref="SweetAlert" :swal="swal" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
export default {
    name: "c-Administracion-ReporteEgresos",
    components: {
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            filter: {
                date_start: "",
                date_end: "",
                campu_id: 1,
                bead_id: 1,
            },
            data: [],
            search: "",
            dataShow: [],
            //paginado
            totalPagesToShow: 4,
            selectPage: 1,
            stepPagination: [],
        };
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },

    },
    created() {
        this.getFirstDayOfMonth();
    },
    computed: {
        //cpData() {}
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7);
            const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            this.filter.date_start = firstDayOfMonth.toISOString().split('T')[0];
            this.filter.date_end = today.toISOString().split('T')[0];
            this.mtdGetData();
        },
        returnHome: function () {
            this.$emit("changePage", "home");
        },
        mtdGetData: function () {
            this.get({
                    url: this.$store.getters.get__url +
                        "/ReportesCajas/reportecajaRe?type=EGRESO&campu_id=" +
                        this.filter.campu_id +
                        "&bead_id=" +
                        this.filter.bead_id +
                        "&begindate=" +
                        this.filter.date_start +
                        "&enddate=" +
                        this.filter.date_end,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    let dataTotal = response.data;
                    //filtrar solo los aprobadados
                    this.data = dataTotal.filter((element) => {
                        return element.state == 2;
                    });
                    this.calculateData(10);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        //paginado
        mtdNumber: function (index) {
            return (index + 1) + ((this.selectPage - 1) * 10);
        },
        calculateData(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.data;
            if (this.search.length >= 2 && this.search != "") {

                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    //verificar que exista el campo serie y number

                    if (element.serie || element.number) {
                        const serie = element.serie ?.toString().toUpperCase() || "";
                        const number = element.number ?.toString().toUpperCase() || "";
                        return serie.includes(query) || number.includes(query);
                    }

                });
                // Si no hay resultados y se elimina el término de búsqueda, mostrar los datos originales
                if (!filteredData.length && this.search === "") {
                    filteredData = this.data;
                }

                //indexInitial = 1;
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            if (this.selectPage > totalPages) {
                this.selectPage = totalPages || 1; // Manejar el caso donde no haya datos
            }

            let startIndex = (this.selectPage - 1) * items;
            let endIndex = startIndex + items;
            this.dataShow = filteredData.slice(startIndex, endIndex);
        },
        mtdViewPdfAdjunto: function (expense) {
            if(expense.modulo == 'receipt'){
                window.open(
                    "https://clinicabm.v2.howertpi.com/assets/" + expense.receipt.file,
                    "_blank"
                );
                return;
            }
            if (expense.file == null) {
                this.$refs.SweetAlert.showWarning("No hay archivo adjunto", "warning");
                return;
            }
            //verificar que no haya mas de un archivo (json)
            let variable = expense.file;
            if (variable.includes("}")) {
                const jsonObject = JSON.parse(variable);
                jsonObject.forEach(element => {
                    window.open(
                        "https://clinicabm.v2.howertpi.com/assets/" + element.route,
                        "_blank"
                    );
                });
            } else {
                window.open(
                    "https://clinicabm.v2.howertpi.com/assets/" + expense.file,
                    "_blank"
                );
            }
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    },
};
</script>
