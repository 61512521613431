<template>
<div>
    <div class="container">
        <div id="div-modal-recibo" class="w-100">
            <div id="div-recibo" class="card-body w-100">
                <div class="row" v-if="view == 'table'">
                    <div class="col-md-12 h2">
                        <div class="d-flex justify-content-start" id="btn-registro-recibo">
                            <a href="javascript:void(0)" @click="mtdStore">
                                <i class="fas fa-plus-circle"></i>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-12 p-2" id="content-timelinetable">
                        <table class="table table-bordered border-main">
                            <thead>
                                <tr align="center" class="text-white border-white bg-main">
                                    <th scope="col">N°</th>
                                    <th scope="col">NOMBRES Y APELLIDOS</th>
                                    <th scope="col">CONCEPTO</th>
                                    <th scope="col">MONTO</th>
                                    <th scope="col">ARCHIVOS SUBIDOS</th>
                                    <th scope="col">PDF</th>
                                    <th scope="col">ESTADO</th>
                                    <!-- <th scope="col" colspan="1">VB</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in dataReceipt" :key="index">
                                    <td>{{index + 1}}</td>
                                    <td>{{item.name}}</td>
                                    <td>{{item.concept}}</td>
                                    <td>S/ {{item.amount}}</td>
                                    <td>
                                        <div class="text-center d-flex justify-content-center">
                                            <button @click="openPdf(item.file)" class="btn btn-sm btn-warning text-white mx-1" :disabled="item.file == ''">
                                                <i class="far fa-eye"></i>
                                            </button>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="text-center d-flex justify-content-center text-xs" v-if="item.state === 2">
                                            <button class="btn btn-sm btn-info" title="Recibo de egreso" @click="mtdViewPdfEgreso(item)">
                                                <i class="fas fa-file-pdf"></i>
                                            </button>
                                        </div>
                                        <div v-else>
                                            <span>-</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="text-center d-flex justify-content-center">
                                            <button class="btn btn-sm a-success text-white" v-if="item.state == 2">
                                                <i class="fas fa-check-circle"></i>
                                            </button>
                                            <button class="btn btn-sm a-danger text-white" v-if="item.state == 3">
                                                <i class="fas fa-times-circle"></i>
                                            </button>
                                            <button class="btn btn-sm a-warning text-white" v-if="item.state == 1">
                                                <i class="far fa-circle"></i>
                                            </button>
                                        </div>
                                    </td>
                                    <!-- <td align="center">
                        <a href="javascript:void(0)" @click="mtdProccessReceipt(index,item.id,2)" id="btn-error-recibo" v-if="item.state == 1">
                          <i class="fas fa-times-circle"></i>
                        </a>
                        <a href="javascript:void(0)" @click="mtdProccessReceipt(index,item.id,3,item.amount)" id="btn-autorizar-recibo" v-if="item.state == 1">
                          <i class="fas fa-check-circle"></i>
                        </a>
                        <a href="javascript:void(0)" id="btn-pediente-recibo" v-if="item.state != 1">
                          <i class="fas fa-recycle"></i>
                        </a>
                      </td> -->
                                </tr>
                                <tr v-if="dataReceipt.length==0" class="text-center">
                                    <td class="text-center" colspan="7">No hay registros</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row mt-3" v-else>
                    <ReciboStore @mtdBackTable="mtdBackTable" @mtdAddReceipt="mtdAddReceipt" :bead="bead" />
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
//import { bus } from '../../main'
import ReciboStore from './ReciboCreate.vue';

export default {
    name: 'c-admision-recibo',
    components: {
        ReciboStore
    },
    data() {
        return {
            process: {
                box: 0,
                initial: 0,
                observation: '',
                status: 0
            },
            view: 'table',
            dataReceipt: [],
            message: false,
        }
    },
    props: {
        boxes: [],
        bead: {}
    },
    created() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Expense/receipt",
                    token: this.$store.getters.get__token,
                    params: {
                        bead: this.bead.id,
                    }
                })
                .then((response) => {
                    this.dataReceipt = response.data.data;
                    if (this.dataReceipt.length === 0) {
                        this.message = true;
                    } else {
                        this.message = false;
                    }
                })
                .catch((errors) => {});
        },
        retroceder() {
            this.$emit('mtdChangePage', 'home')

        },
        mtdStore: function () {
            this.view = 'store';
        },
        mtdBackTable: function () {
            this.view = 'table';
        },
        mtdAddReceipt: function (item) {
            this.dataReceipt.push(item);
            this.mtdBackTable();
        },
        mtdProccessReceipt: function (index, id, state, amount) {
            this.post({
                    url: this.$store.getters.get__url + "/Expense/receipt/proccess",
                    token: this.$store.getters.get__token,
                    params: {
                        bead_id: this.bead.id,
                        id: id,
                        state: state
                    }
                })
                .then((response) => {
                    this.dataReceipt[index].state = state;
                    const numero = parseFloat(this.bead.expense) + parseFloat(amount);
                    this.bead.expense = numero;
                    this.$emit("mtdCommitBead", this.bead);
                })
                .catch((errors) => {});
        },
        openPdf(file) {
            javascipt: window.open("https://clinicabm.v2.howertpi.com/assets/" + file);
        },
        /** view pdf expense */
        mtdViewPdfEgreso: function (rh) {
            window.open(
                this.$store.getters.get__url + "/Permisions/viewpdf/expense/" + rh.expense_id,
                "_blank"
            );
        },
    }
}
</script>
