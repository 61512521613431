<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-3">
        <div class="row mx-0 px-0">
            <div class="mb-2 col-xl-12 px-1">
                <label id="miSelect" for="subsector" class="form-label">Clasificación</label>

                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-vector-square"></i>
                        </span>
                    </div>
                    <select v-model="data_detail.sector_id" class="form-control form-control-sm" aria-label=".form-select-sm example" id="subsector">
                        <option value="" selected disabled>Seleccione...</option>
                        <option v-for="(item,index) in dataSector" :key="index" :value="item.id">{{ item.name }}</option>
                    </select>
                </div>
            </div>
            <div class="mb-2 col-xl-12 px-1">
                <label for="name-subsector" class="form-label">Nombre</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fas fa-cubes"></i>
                        </span>
                    </div>
                    <input type="text" id="name-subsector" class="form-control form-control-sm" v-model="data_detail.name" placeholder=" Nombre">

                </div>
            </div>
            <div class="col-md-12 mt-3 pt-1" v-if="num === 1">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="data_detail.state" :checked="data_detail.state == 1 ? true : false" aria-checked="false" />
                    <label class="form-check-label" for="flexSwitchCheckChecked">
                        {{ data_detail.state == 1 ? 'Activo' : 'Inactivo' }}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpButton">
            {{ (num == 0 ) ? 'Registrar':'Editar' }}
        </button>
        &nbsp;
    </template>
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-documento",
    components: {
        CModal,
    },
    data() {
        return {
            data_detail: {
                name: '',
                sector_id: '',
                state: 1,
            },
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if (this.num == 1) {
                    if (newVal) {
                        this.data_detail = {
                            ...newVal
                        };
                    }
                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
        dataSector: {
            type: Array,
            default: null
        }
    },
    computed: {
        cpButton() {
            return !(this.data_detail.name != '' && this.data_detail.sector_id != '');
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.data_detail.name = '';
            this.data_detail.sector_id = '';
            this.$emit("closeModal");
        },
        DataDetail() {
            this.$emit('Datadetail', this.data_detail, this.num);
            this.mtdClose();
        },
    },

};
</script>
