<template>
    <div class="mt-2">
        <div class="d-flex flex-row flex-wrap justify-content-around mb-3">
            <div id="div-listcola-sop" class="card m-3" v-for="(room, index) in data" :key="index">
                <div class="card-header text-white h6 header-main">
                    <strong>{{room.room}}</strong>
                </div>
                <div class="card-body" id="content-timeline">
                    <div class="row align-middle">
                        <div class="px-sm-1 px-md-3">
                            <div class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end hover-effect" style="cursor: pointer;" v-for="(item,indexc) in room.cola" :key="indexc">
                                <div @click="mtdGetDataMedicalAct(item.patient_id, item.medical_act_id, item.hc_id, item.recipe_ids)">
                                    <span class="text-paciente me-1">{{ item.patient }}</span>
                                    <span class="recipe-icon" v-if="item.medical_act_id && item.recipe_ids.length > 0">
                                        REC.
                                    </span>
                                    <br />
                                    <span class="text-datos">{{item.date}} {{ item.hour }} - {{item.servicio}}</span>
        
                                    <br>
                                    <span class="text-datos">Chek in: {{ item.checkin }}</span>
                                </div>
                            </div>
                            <div v-if="room.cola.length == 0" class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                <div class="h6 text-center color-main">
                                    NO HAY PACIENTES
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
export default {
    name: 'c-Sop-cola',
    data() {
        return {
            swal: null,
            data: [],
            colaRoom1: [],
            colaRoom2: []
        };
    },
    created() {
        this.mtdSelectGroup();
    },
    props: {
        rolname: String
    },
    components: {
        SweetAlert,
    },
    methods: {
        ...mapActions(['get', 'post']),

        mtdSelectGroup: function () {
            this.post({
                url: this.$store.getters.get__url + "/Pharmacy/regRecipe/cola",
                token: this.$store.getters.get__token,
                params: {
                    //floor_id: id,
                    campu_id: this.$store.getters.get__campus,
                    user_id: this.$store.getters.get__user.id
                },
            })
            .then((response) => {
                this.data = response.data;
                this.colaRoom1 = response.data.salaGrande;
                this.colaRoom2 = response.data.salaPequeña;
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },
        
        mtdGetDataMedicalAct: function (patient_id, medical_act_id, hc_id, recipe_id) {
            if(medical_act_id){
                const lastRecipeId = recipe_id[recipe_id.length - 1];
                this.$emit("openCarroRegRecipe", patient_id, medical_act_id, hc_id, lastRecipeId);
            }else{
                this.$refs.SweetAlert.showWarning("Sin Acto Medico");
            }
        },
    },
}
</script>
<style scoped>
    .hover-effect {
        transition: background-color 0.3s, color 0.3s;
    }

    .hover-effect:hover {
        background-color: #f4869a;
    }

    .hover-effect:hover .text-paciente,
    .hover-effect:hover .text-datos {
        color: white;
    }

    .recipe-icon {
        background: #e15a72; 
        color: white; 
        border-radius: 50%; 
        width: 25px; 
        height: 25px; 
        display: inline-flex; 
        align-items: center; 
        justify-content: center; 
        font-size: 0.6rem; 
        font-weight: bold;
    }
</style>
