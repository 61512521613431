<template>
<CModal @mtdclosemodal="mtdclosemodal" :show.sync="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true" :fullScreen="true">
    <div class="row">
        <div class="tab-content-oc">
            <div class="px-4">
                <div class="col-md-12 table-responsive" id="div-tabla-anular">
                    <p id="mydesc">&nbsp;</p>
                    <table id="tb-anular" class="table table-bordered text-center" aria-describedby="mydesc">
                        <thead class="bg-main" style="font-size: 12px">
                            <tr class="text-white text-center" style="vertical-align: middle">
                                <th scope="col">N°</th>
                                <th scope="col">MOLECULA</th>
                                <th scope="col">COMERCIAL</th>
                                <th scope="col">MARCA</th>
                                <th scope="col">PRESENTACIÓN</th>
                                <th scope="col">DOSIS</th>
                                <th scope="col" colspan="1">UNIDAD</th>
                                <th scope="col" colspan="1">CANTIDAD ENTRANTE</th>
                                <th scope="col" colspan="1"  style="white-space: nowrap;">PRECIO CON IGV</th>
                                <th scope="col" colspan="1">LOTE</th>
                                <th scope="col" colspan="1"  style="white-space: nowrap;">CODIGO DE BARRAS (11)</th>
                                <th scope="col" colspan="1"  style="white-space: nowrap;">F. VENCIMIENTO</th>
                                <th scope="col" colspan="1"  style="white-space: nowrap;">REG. SANITARIO</th>
                                <!-- <th scope="col" colspan="1">SECTOR</th> -->
                                <th scope="col" colspan="1">SUBTOTAL CON IGV</th>
                                <th scope="col">ACCIÓN</th>
                            </tr>
                        </thead>

                        <tbody class="border-main" style="font-size: 12px; vertical-align: middle">
                            <tr v-if="datos.length === 0">
                                <td colspan="16">Sin registros</td>
                            </tr>
                            <template v-else>
                                <tr class="tbody-table1 text-center" v-for="(item, index) in datos" :key="index" :class="getRowClass(item)">
                                    <td>
                                        {{ index + 1}}
                                    </td>
                                    <td>
                                        {{ item.molecule }}
                                    </td>
                                    <td>
                                        {{ item.commercial }}
                                    </td>
                                    <td>
                                        {{ item.brand }}
                                    </td>
                                    <td>
                                        {{ item.presentation}}
                                    </td>
                                    <td>
                                        {{ item.dosis ? item.dosis : '-'}}
                                    </td>
                                    <td>
                                        <div class="d-flex justify-content-center">
                                            <select class="form-control form-control-sm select-p" aria-label=".form-select-sm example" v-model="item.unit" @change="mtdSelectUnit(index)">
                                                <option v-for="(un, pos) in item.units" :key="pos" :value="un.id">
                                                    {{ un.unit.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </td>
                                    <!--Cantidad-->
                                    <td>
                                        <div v-if="posEditQuantity !== index" @dblclick="mtdEditField(index, 'quantity')">
                                            {{ item.quantity }}
                                        </div>
                                        <div v-else>
                                            <input type="text" v-model="item.editQuantity" 
                                                    @keyup.enter="mtdSaveEdit(index, 'quantity')" 
                                                    @blur="mtdSaveEdit(index, 'quantity')" 
                                                    @keydown="filterKeyDni"
                                                    class="form-control form-control-sm text-center" 
                                                    placeholder="Cantidad" />
                                        </div>
                                    </td>
                                    <!--Fin Cantidad-->
                                    <!--Precio-->
                                    <td>
                                        <div v-if="posEditPrice !== index" @dblclick="mtdEditField(index, 'price')">
                                            {{ item.price}}
                                        </div>
                                        <div v-else>
                                            <input type="text" v-model="item.editPrice" 
                                                @keyup.enter="mtdSaveEdit(index, 'price')" 
                                                @blur="mtdSaveEdit(index, 'price')" 
                                                @keypress="isNumber($event)" 
                                                class="form-control form-control-sm text-center" 
                                                placeholder="Precio" />
                                        </div>
                                    </td>
                                    <!--Fin Precio-->
                                    <!--Lote-->
                                    <td>
                                        <div class="d-flex justify-content-center">
                                                <input v-model="item.lote" type="text" class="form-control form-control-sm input-cantidad align-middle" placeholder="Lote" />
                                        </div>
                                    </td>
                                    <!--Codigo Barra-->
                                    <td>
                                        <div class="d-flex justify-content-center">
                                            <input v-model="item.codigoBarra" type="text" class="form-control form-control-sm input-cantidad align-middle" maxlength="11"  @keypress="isNumber($event)"  placeholder="Código de Barras" />
                                        </div>
                                    </td>
                                    <!--F Vencimiento-->
                                    <td>
                                        <div class="d-flex justify-content-center">
                                            <input v-model="item.fVencimiento" type="date" class="form-control form-control-sm input-cantidad align-middle" :min="minDate" placeholder="F. Vencimiento" />
                                        </div>
                                    </td>
                                    <!--Reg. Sanitario-->
                                    <td>
                                        <div class="d-flex justify-content-center">
                                            <input v-model="item.regSanitario" type="text" class="form-control form-control-sm input-cantidad align-middle" placeholder="Reg. Sanitario" />
                                        </div>
                                    </td>
                                <!--     <td>
                                       <div class="d-flex justify-content-center">
                                         <select v-model="item.sector_id" class="form-control form-control-sm select-p" aria-label=".form-select-sm example">
                                             <option value="" selected disabled>Seleccione...</option>
                                             <option v-for="(sectorItem, index) in sector" :key="index" :value="sectorItem.id">
                                                 {{ sectorItem.name }}
                                             </option>
                                         </select>
                                       </div>
                                    </td> -->
                                    <td>
                                        {{ (item.price ? (item.quantity * item.price).toFixed(2) : '0.00') }}
                                    </td>

                                    <td>
                                        <a class="btn btn-primary btn-sm rounded-circle" type="button" style="background-color: #900052; border: 2px solid #900052;"
                                            @click="mtdDelete(item.id)">
                                            <i class="fas fa-trash-alt"></i>
                                        </a>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4 px-4 d-flex">
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12">
            <div class="form-group">
                <label for="observation" class="form-label text-black">Observación</label>
                <textarea v-model="observation" class="form-control" rows="2" placeholder="Observaciones"></textarea>
            </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-xs-6">
            <div class="form-group">
                <label for="almacen_id" class="form-label text-black">Almacén</label>
                <select v-model="almacen_id" class="form-control" aria-label=".form-select-sm example">
                    <option value="" selected disabled>Seleccione...</option>
                    <option v-for="(almacenItem, index) in almacen" :key="index" :value="almacenItem.id">
                        {{ almacenItem.name }}
                    </option>
                </select>
            </div>
        </div>
        <!--
        <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-xs-6">
            <div class="form-group">
                <label for="sector_id" class="form-label text-black">Sector</label>
                <select v-model="sector_id" class="form-control" aria-label=".form-select-sm example">
                    <option value="" selected disabled>Seleccione...</option>
                    <option v-for="(sectorItem, index) in sector" :key="index" :value="sectorItem.id">
                        {{ sectorItem.name }}
                    </option>
                </select>
            </div>
        </div>
        -->
    </div>

    <template #footer>
        <button type="button" class="btn btn-register text-white" :disabled="isSaveDisabled" @click="registerCarrito">
            GUARDAR
        </button>
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import CButtonClose from "@/components/shared/modals/cButtonClose.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

import {mapActions} from "vuex";
import moment from "moment";

export default {
    name: "c-modal-notaIngreso",
    components: {
        SweetAlert,
        CModal,
        CButtonClose,
    },
    created() {
    },
    data() {
        return {
            swal: null,
            money: {
                name: 'SOLES',
                type: 1,
                valor: 0,
            },
            almacen_id: '',
            //sector_id: '',
            observation: '',
            posEditConcentration: null,
            posEditQuantity: null,
            posEditPrice: null,
            tempPrice: '',
            minDate: moment().format('YYYY-MM-DD'),
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        datos: [],
        almacen: [],
        sector: [],
    },
    watch: {   
    },
    computed: {
        isSaveDisabled() {
            if (this.datos.length === 0) {
                return true;
            }

            return this.datos.some(item => {
                /*
                console.log("price:", item.price);
                console.log("almacen_id:", this.almacen_id);
                console.log("sector_id:", this.sector_id);
                console.log("lote:", item.lote);
                console.log("codigoBarra:", item.codigoBarra);
                console.log("fVencimiento:", item.fVencimiento);
                console.log("isZeroPrice:", parseFloat(item.pricew) === 0);
                */
                return (
                    !this.almacen_id ||
                    //!this.sector_id ||
                    !item.lote ||    
                    !item.codigoBarra || item.codigoBarra.length !== 11 || 
                    !item.fVencimiento ||
                    !item.price || parseFloat(item.price) <= 0
                );
            });
        }
    },
    methods: {
        ...mapActions(["get", "post"]),

        getRowClass(item) {
            if (item.lote && 
                item.codigoBarra && item.codigoBarra.length === 11 &&
                item.fVencimiento &&
                item.price && parseFloat(item.price) > 0) {
                return 'bg-light-green';
            }
            return '';
        },

        mtdclosemodal() {
            this.money.name = 'SOLES';
            this.money.type = 1;
            this.$emit("closeModalCarrito");
        },

        mtdDelete: function (item) {
            this.$emit("mtdDelete", item);
        },

        mtdSelectUnit: function (index) {
            let cantidadUnidades = 1;
            this.datos[index].units.forEach((element) => {
                if (element.id == this.datos[index].unit) {
                    cantidadUnidades = element.unit.quantity;
                }
            });
            this.datos[index].precio_unitario = parseFloat(
                this.datos[index].precio_purchase / cantidadUnidades
            ).toFixed(2);
        },

        mtdEditField(index, field) {
            this.resetEditFields();
            if (field === "quantity") {
                this.posEditQuantity = index;
                this.datos[index].editQuantity = this.datos[index].quantity;
            } else if (field === "price") {
                this.posEditPrice = index;
                this.datos[index].editPrice = this.datos[index].price;
            }
            this.editingField = field;
        },

        mtdSaveEdit(index, field) {
            if (field === "quantity") {
                const newQuantity = parseInt(this.datos[index].editQuantity);
                if (!isNaN(newQuantity)) {
                    this.datos[index].quantity = newQuantity;
                }
            } else if (field === "price") {
                const newPrice = parseFloat(this.datos[index].editPrice);
                if (!isNaN(newPrice)) {
                    this.datos[index].price = newPrice.toFixed(2);
                    //this.datos[index].price = parseFloat(newPrice.toFixed(2));
                }
            }
            this.resetEditFields();
        },

        updatePrice(index) {
            const newPrice = parseFloat(this.tempPrice);
            if (!isNaN(newPrice)) {
                this.datos[index].price = newPrice.toFixed(2);
            }
            this.resetEditFields();
        },

        resetEditFields() {
            this.posEditQuantity = null;
            this.posEditPrice = null;
            this.editingField = "";
        },

        isNumber(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        registerCarrito() {
            const hasExpiredDates = this.datos.some(item => {
                const expiryDate = moment(item.fVencimiento, 'YYYY-MM-DD');
                return expiryDate.isSameOrBefore(moment(), 'day');
            });

            if (hasExpiredDates) {
                this.$refs.SweetAlert.showWarning("Hay productos con fechas de vencimiento menores o iguales a la fecha actual.");
                return;
            }

            this.$refs.SweetAlert.showConfirmSimple2("","¿Estás seguro de Registrar esta Nota de Ingreso?","warning","Registrar") 
            .then((result) => {
                if (result.value) {
                    this.registerCar();
                }
            });
        },

        registerCar() {
            const payload = {
                datos: this.datos,
                observation: this.observation,
                almacen_id: this.almacen_id,
                //sector_id: this.sector_id
            };
            this.post({
                url: this.$store.getters.get__url + "/NotaIngreso/registerNote",
                token: this.$store.getters.get__token,
                params: payload,
            })
            .then((response) => {
                if(response.data.state == 0){
                    this.$refs.SweetAlert.showSuccess("Registro guardado Exitosamente");
                    this.$emit("registerCarrito", response.data.data);
                    this.openPdf(response.data.note);
                    this.clearData();
                    this.mtdclosemodal();
                }else{
                    this.$refs.SweetAlert.showError("Error");
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        clearData(){
            this.datos.forEach(item => {
                item.quantity = '';        
                item.editQuantity = '';      
                item.price = '';
                item.editPrice = '';       
                item.lote = '';            
                item.codigoBarra = '';    
                item.fVencimiento = '';    
                item.regSanitario = ''; 
            });
            this.almacen_id = '';
            //this.sector_id = '';
            this.observation = '';
        },

        openPdf(id){
            window.open(
                this.$store.getters.get__url +
                "/viewpdfNotaIngreso/" + id,
                "_blank"
            );
        },

        filterKeyDni: function (e) {
            let b = false;
            b = /^\d$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
},
};
</script>
<style>
.observation {
    width: 300px;
}
@media (max-width: 992px) {
   
    .responsive-footer {
        flex-direction: column;
    }

    .responsive-footer-div {
        justify-content: end;
    }
}

@media (max-width: 365px) {
    .responsive-footer>.text-white {
        flex-direction: column;
        padding: 0px 20px 0px 20px;
    }

    .responsive-footer>.text-white>div {
        margin: 0px 0px 9px 0px !important;
    }
}

.btn-register {
    background-color: #900052; 
    border: 2px solid #900052; 
}

.btn-register:hover {
    background-color: #700042;
    border-color: #700042; 
}

.bg-light-green {
    background-color: #ddf9f0;
}

</style>

