<template>
    <div  class="px-4">
        <div class="row">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="changePage('home')">
                    <i class="fas fa-home"></i> Atras
                </button>
            </div>
        </div>
        <div id="div-modal-cxc" class="d-flex justify-content-center">
            <div id="div-cxc" class=" card w-100">
                <div class="text-center card-header text-white h4 bg-main">
                        <strong>PRECIOS DE COMPRAS </strong>
                    </div>
                <div class="card-body w-100">
                    <div class="row">
                        <div class="col-md-12 py-2">
                            <div class="row d-flex mx-0">
                                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 px-1 mb-2">
                                    <div class="input-group input-group">
                                        <span class="input-group-text" id="basic-addon1">Proveedor</span>
                                        <v-select 
                                        :options="providers" 
                                        class="form-control form-control-sm" 
                                        :reduce="(name) => name.id" 
                                        label="name" 
                                        placeholder="-- Seleccionar --" 
                                        required 
                                        v-model="filter.provider"  
                                        @input="mtdSearchProducts"
                                        />
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 px-1 mb-2">
                                    <div class="input-group input-group">
                                        <span class="input-group-text text-white bg-main"
                                        ><i class="fas fa-search"></i
                                        ></span>
                                        <input
                                        @keyup="calculateData(10)"
                                        v-model="search"
                                        type="text"
                                        class="form-control form-control"
                                        placeholder="Buscar"
                                        />
                                    </div>
                                </div>
                                <!-- <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 btn-home px-1 py-xl-0">
                                    <button
                                        type="button"
                                        class="btn btn-bm-noradius w-100"
                                        @click="mtdModalShow(0,null)"
                                    >
                                        <i class="fas fa-plus"></i> Nueva
                                    </button>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-md-12 mt-2" id="content-timelinetable">
                            <table id="tb-cxc" class="table table-bordered border-main">
                                <caption class="py-0 my-0"></caption>
                                <thead class="bg-main">
                                    <tr class=" table-th text-white text-center font-size">
                                        <th class="table-th-number">N°</th>
                                        <th class="">Producto</th>
                                        <th class="">Precio Compra Actual</th>
                                        <th class="">Nuevo Precio Compra (S/igv)</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main">
                                    <tr class="table-body text-center font-size" v-if="cpData.length === 0">
                                        <td colspan="4" class="text-center">No hay registros</td>
                                    </tr>
                                    <tr class="table-body text-center font-size" v-for="(item, index) in cpData" :key="index">
                                        <td>
                                            {{mtdNumber(index) }}
                                        </td>
                                        <td>{{ item.name ? item.name : '-'  }}</td>
                                        <td class="text-center">{{ item.price_old }}</td>
                                        <td @dblclick="mtdSelectInput(index)" class="text-center">
                                            <span v-if="posEdit != index">0.00</span>
                                            <input v-else type="text" class="form-control text-center form-control-sm w-100" v-model="newPrice" @keyup.enter="mtdOutFocusEditPrice" @blur="mtdOutFocusEditPrice" @input="validateAmount" placeholder="0.00" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 text-center">
                            <div v-if="cpData.length > 0" class="d-flex justify-content-center mb-2">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <cModalPriceVue :num="modal.num" :payload="modal.payload" :title="modal.title" :providers="providers"
        :boo_modal="modal.modal_form" @closeModal="closeModal" @Datadetail="Datadetail"/>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
    import { mapActions} from "vuex";
    import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
    import cModalPriceVue from './../../modals/Mantenimiento/cModalPrice.vue'
    export default {
        name:"logis-maintenance-price",
        components:{
            SweetAlert,
            cModalPriceVue
        },
        data() {
            return {
                swal: null,
                search:"",
                dataShow: [],
                categorie_control: "",
                subcategorie_control: "",
                filter: {
                    provider: null,
                    category: null,
                    subcategory: null,
                    specialty: null,
                    name: null,
                },
                product: [],
                posEdit: null,
                oldPrice: null,
                newPrice: null,
                search: "",
                products: [],
                dataShow: [],
                categories: [],
                servico: {
                    sub_sub_category_id: "",
                    id: "",
                    molecule: "",
                    commercial: "",
                    brand_id: "",
                    presentation: "",
                    concentration: "",
                    format: "",
                    stock: 0,
                    minimum_stock: 0,
                    state: "",
                    sub_sub_category_id: null,
                    provider_id: null,
                    provider_id_2: null,
                    dosis: null,
                    price_purcharse: 0,
                },
                noRecordsMessage: "",
                posEdit: null,
                noRecordsMessage: "",
                dataSubsubcategory: [],
                subcategories: [],

                modal: {
                    title: '',
                    modal_form: false,
                    num: null,
                    payload: {},
                },
                 //Pag
                page: 0,
                selectPage: 1,
                stepPagination: [],
                totalPagesToShow: 4,
            };
        },
        props: {
            providers: {
                type: Array,
                default: [],
            },
        },
        created() {
        },
        watch: {
            selectPage() {
                this.calculateData(10);
            },
        },
        computed: {
            cpData() {
                return this.dataShow;
            },
            limitedStepPagination() {
                const totalPages = this.stepPagination.length;
                const currentPage = this.selectPage;

                let startPage = 1;
                let endPage = totalPages;
                if (totalPages > this.totalPagesToShow) {
                    const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                    if (currentPage > halfPagesToShow) {
                        startPage = currentPage - halfPagesToShow;
                        endPage = currentPage + halfPagesToShow - 1;

                        if (endPage > totalPages) {
                            endPage = totalPages;
                            startPage = totalPages - this.totalPagesToShow + 1;
                        }
                    } else {
                        endPage = this.totalPagesToShow;
                    }
                }

                return Array.from({
                        length: endPage - startPage + 1,
                    },
                    (_, i) => startPage + i
                );
            },
        },
        methods: {
            ...mapActions(["get", "post"]),

            mtdSearchProducts: function () {
                this.get({
                    url: this.$store.getters.get__url + "/logistics/maintenance/getProducts/" + this.filter.provider,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.products = response.data;
                    this.calculateData(10);
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
            },

            calculateData(items) {
                if (!this.products || !this.products.length) {
                    return (this.dataShow = []);
                }
                let filteredData = this.products;
                let indexInitial = this.selectPage;
                if (this.search.length >= 2 && this.search != "") {
                    let query = this.search.toString().toUpperCase();
                    filteredData = this.products.filter((element) => {
                        let name = element.name.toString().toUpperCase();
                        return name.includes(query);
                    });
                    indexInitial = 1;
                }

                let totalPages = Math.ceil(filteredData.length / items);
                this.stepPagination = Array.from({
                        length: totalPages,
                    },
                    (_, index) => index + 1
                );

                let startIndex = (indexInitial - 1) * items;
                let endIndex = startIndex + (items - 1);
                this.dataShow = filteredData.slice(startIndex, endIndex + 1);
                this.count = this.count + 1;
            },

            mtdSelectInput: function (pos) {
                this.posEdit = pos;
                this.oldPrice = this.dataShow[pos].price;
            },

            mtdOutFocusEditPrice: function () {
                if (this.oldPrice != this.newPrice && this.newPrice > 0) {
                    this.$refs.SweetAlert.showConfirmSimple2("","¿Desea cambiar este precio?","warning","Aceptar") 
                    .then((result) => {
                        if (result.value) {
                            this.mtdCommitEditPrice();
                        } else {
                            this.posEdit = null;
                            this.oldPrice = null;
                            this.newPrice = null;
                        }
                    });
                } else {
                    this.posEdit = null;
                    this.oldPrice = null;
                    this.newPrice = null;
                }
            },

            mtdCommitEditPrice: function () {
                this.post({
                    url: this.$store.getters.get__url + "/logistics/maintenance/update/pricepurchase",
                    token: this.$store.getters.get__token,
                    params: {
                        detail: this.dataShow[this.posEdit].id,
                        provider: this.filter.provider,
                        price: this.newPrice,
                    },
                })
                .then((response) => {
                    if (response.data.state == 0) {
                        this.dataShow[this.posEdit].price_old = parseFloat(this.newPrice).toFixed(2);
                        this.posEdit = null;
                        this.oldPrice = null;
                        this.newPrice = null;
                        this.$refs.SweetAlert.showSuccess("Editado correctamente");
                    } else {
                        this.$refs.SweetAlert.showError("Error");
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
            },

            validateAmount(event) {
                let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');
    
                const decimalCount = sanitizedValue.split('.').length - 1;
                if (decimalCount > 1) {
                    sanitizedValue = sanitizedValue.slice(0, -1);
                }
                const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
                if (decimalIndex !== -1) {
                    const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                    sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
                }
    
                this.newPrice = sanitizedValue;
            },

            mtdModalShow: function (num, payload) {
                this.modal.title = 'Asignar Medicamento a Proveedor';
                this.modal.modal_form = true;
                this.modal.num = num;
                this.modal.payload = payload;
            },

            closeModal: function () {
                this.modal = {
                    title: '',
                    modal_form: false,
                    num: null,
                    payload: {},
                }
            },

            Datadetail: function (payload_detail) {
                this.post({
                    url: this.$store.getters.get__url + "/logistics/maintenance/providerasign",
                    token: this.$store.getters.get__token,
                    params: payload_detail,
                })
                .then((response) => {
                    if (response.data.state == 0) {
                        this.$refs.SweetAlert.showSuccess("Modificación Exitosa");
                        this.filter.provider= payload_detail.provider_id;
                        this.mtdSearchProducts();
                    } else {
                        this.$refs.SweetAlert.showError("Error");
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
            },

            //Paginacion
            backPage() {
                this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
            },
            nextPage() {
                this.selectPage =
                    this.selectPage < this.stepPagination.length ?
                    this.selectPage + 1 :
                    this.stepPagination.length;
            },
            selectedPage(page) {
                this.selectPage = page;
            },
            mtdNumber: function(index){
                return (this.selectPage-1)*10 + (index + 1)
            },
            changePage(page) {
            this.$emit('changePage', page)
        },
        },
    };
</script>

<style lang="scss" scoped>
.font-size {
    font-size: 12px !important; 
}
</style>
    