<template>
<div>
    <div class="px-4">
        <div class="d-flex justify-content-between">
            <h6 class="mt-2">
                <i class="fas fa-shopping-cart"></i> PENDIENTES
            </h6>
            <div class="btn-home">
                <button type="button" class="btn btn-outline-main" @click="mtdaddOrder">
                    <i class="fas fa-shopping-cart"></i> Nuevo
                </button>
            </div>

        </div>
        <div class="row mt-3">

            <div class="px-0 col-md-12" id="content-timelinetable">
                <table class="table table-responsive table-bordered">
                    <caption class="py-0 my-0"></caption>
                    <thead class="bg-main">
                        <tr class="text-white align-middle">
                            <th class="table-th-number">N°</th>
                            <th scope="col">FECHA</th>
                            <th scope="col">PROVEEDOR</th>
                            <th scope="col">METODO DE PAGO</th>
                            <th scope="col">TOTAL</th>
                            <th scope="col">REGISTRADO POR</th>
                            <th scope="col">ESTADO</th>
                            <th scope="col">DESCRIPCION</th>
                        </tr>
                    </thead>
                    <tbody class="border border-main">
                        <tr class="text-center" v-if="cpData.length === 0">
                            <td colspan="8" class="text-center">No se encontraron resultados</td>
                        </tr>
                        <tr class="text-center" v-for="(item, index) in cpData" :key="index">
                            <td>
                                {{mtdNumber(index)}}
                            </td>
                            <td>{{ item.date | formatDate }} </td>
                            <td>{{ item.provider.name }}</td>
                            <td>{{ item.method }}</td>
                            <!-- <td>{{ item.description }}</td> -->
                            <td>{{ item.total }}</td>
                            <td>{{ item.user.name }} {{ item.user.last_name }}</td>
                            <td>
                                <div class="text-center">
                                            <span v-if="item.state == 3" class="badge text-bg-danger text-xxs">Rechazado</span>
                                            <span v-if="item.state == 2" class="badge text-bg-success text-xxs">Aceptado</span>
                                            <span v-if="item.state == 1" class="badge text-bg-warning text-xxs">Registrado</span>
                                        </div>
                            </td>
                            <td>
                                <button @click="mtdViewDetail(item)" class="btn btn-sm btn-primary text-white me-1">
                                    <i class="fa fa-eye"></i>
                                </button>
                                <button @click="mtdViewPdf(item.id)" class="btn btn-sm btn-info text-white">
                                    <i class="fa fa-file-pdf"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="cpData.length > 0" class="d-flex justify-content-center mb-2">
                <nav aria-label="Page navigation example ">
                    <ul class="pagination mb-0 ">
                        <li class="page-item">
                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                        </li>
                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                        </li>
                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                            <span class="page-link">...</span>
                        </li>

                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                            </a>
                        </li>
                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                            <span class="page-link">...</span>
                        </li>
                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                            </a>
                        </li>
                        <li class="page-item">
                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
    <cModalOtros :title="modal.title" :boo_modal="modal.modal_form" @mtdcloseModal="mtdcloseModal" @mtdViewPdf="mtdViewPdf"/>
    <cModalViewOthers :title="modalDetalle.title" :data="modalDetalle.item" :boo_modal="modalDetalle.modal_form" @mtdcloseModal="mtdcloseModal" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import cModalOtros from "../../modals/Oc/cModalRegisterOthers.vue";
import cModalViewOthers from "../../modals/Oc/cModalViewOthers.vue";
export default {
    name: 'logistica-ordendecompra-Otros',
    components: {
        cModalOtros,
        cModalViewOthers
    },
    data() {
        return {
            dataOrdes: [],
            modal: {
                title: "",
                modal_form: false,
            },
            modalDetalle: {
                title: "",
                modal_form: false,
                item:[],
            },
            //paginado
            dataShow: [],
            totalPagesToShow: 4,
            selectPage: 1,
            stepPagination: [],
        };
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
    },
    created() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),
        //paginado
        mtdNumber: function (index) {
            return (index + 1) + ((this.selectPage - 1) * 10);
        },
        mtdGetData: function () {
            this.get({
                    url: this.$store.getters.get__url +
                        "/ordendecompra/indexOthers",
                    token: this.$store.getters.get__token
                })
                .then((response) => {
                    this.dataOrdes = response.data;
                    this.calculateData(10);
                })
                .catch((errors) => {});
        },
        mtdaddOrder: function () {
            this.modal= {
                title: "REGISTRAR ORDEN DE COMPRA",
                modal_form: true,
            };
        },
        mtdViewDetail: function (item) {
            this.modalDetalle= {
                title: "DETALLE DE ORDEN DE COMPRA",
                modal_form: true,
                item: item,
            };
        },
        calculateData(items) {
            if (!this.dataOrdes || !this.dataOrdes.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.dataOrdes;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        mtdcloseModal: function (opt) {
            this.modal.modal_form = false;
            this.modalDetalle.modal_form = false;
            if(opt == 0){
                this.mtdGetData();
            }
        },

        mtdViewPdf: function (id) {
            window.open(
                this.$store.getters.get__url +
                "/ordendecompra/viewpdfOthers/" + id,
                "_blank"
            );
        },

        //Pag
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    },

};
</script>
