<template>
<CModal @mtdclosemodal="mtdclosemodal" :show.sync="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="inputs px-5 pt-2">
        <div class="row d-flex w-100">
            <div class="mb-3 col-lg-3 col-md-6">
                <label for="staticEmail" class="col-form-label">N°</label>
                <input type="text" class="form-control " id="staticEmail" v-model="purcharseOrder.number" disabled>
            </div>
            <div class="mb-3 col-lg-3 col-md-6">
                <label for="total" class=" col-form-label">S/Total OC</label>
                <input type="text" class="form-control " id="total" v-model="purcharseOrder.total" disabled>
            </div>
            <div class="mb-3 col-lg-6 col-md-6">
                <label for="exampleFormControlInput11" class="col-form-label">Proveedor</label>
                <input type="text" class="form-control" id="exampleFormControlInput11" disabled v-model="purcharseOrder.provider">
            </div>
        </div>
        <div class="row d-flex">
            <div class="mb-3 col-lg-3 col-md-4">
                <label for="exampleFormControlInput1" class="form-label">Fecha OC</label>
                <input type="text" class="form-control" id="exampleFormControlInput1" v-model="purcharseOrder.date" disabled>
            </div>
            <div class="mb-3 col-lg-3 col-md-4">
                <label for="num-serie" class="form-label">N° de serie</label>
                <input type="text" class="form-control" id="num-serie" placeholder="N° de serie" v-model="bill.number">
            </div>
            <div class="mb-3 col-lg-3 col-md-6">
                <label for="date" class="form-label">Fecha</label>
                <input type="date" class="form-control" id="date" v-model="bill.date">
            </div>
            <div class="mb-3 col-lg-3 col-md-6">
                <label for="date" class="form-label">Fecha Vcto.</label>
                <input type="date" class="form-control" id="date" v-model="bill.due_date">
            </div>
        </div>
        <div class=" row d-flex">

            <!-- <div class="mb-3 col-md-3">
                <label for="select-detraction" class="form-label">Detraccion</label>
                <select id="select-detraction" class="form-control" v-model="bill.detraction">
                    <option value="0" selected>No Aplica</option>
                    <option value="1">Aplica</option>
                </select>
            </div> -->
            <div class="mb-3 col-md-3 ">
                <label for="pay" class="form-label">S/ Total</label>
                <input type="text" class="form-control" id="pay" placeholder="00.00" v-model="bill.total" @input="validateTotal">
                <span class="text-danger text-alert-modal" v-if="cpInputPaid">Monto no permitido</span>
            </div>
            <!-- <div class="mb-3 col-md-3 ">
                <label for="pay" class="form-label">S/ Pagado</label>
                <input type="text" class="form-control" id="pay" placeholder="00.00" v-model="bill.paid" @input="validateAmount" :disabled="cpInputPaid">
            </div> -->
        </div>
        <!-- central de costos -->
        <div class="d-flex justify-content-end">
            <button type="button" class=" border-0 rounded-2 bg-main px-5 py-2 text-white" id="btn-guardar" @click="mtdStore" :disabled="cpButton">GUARDAR</button>
        </div>
    </div>

</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import CButtonClose from "@/components/shared/modals/cButtonClose.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-vigilancia-modal-control",
    components: {
        CModal,
        CButtonClose,
    },
    data() {
        return {
            bill: {
                number: "",
                date: "",
                money: "soles",
                paid: "",
                detraction: "0",
                purchase_orders_id: "",
                provider_id: "",
                total: '',
                due_date: "",
            }

        };
    },
    watch: {
        purcharseOrder(newvalor, old) {
            this.bill.total = newvalor.pendiente;
        }
    },
    props: {
        title: String,
        boo_modal: Boolean,
        purcharseOrder: Object,
    },

    computed: {
        cpButton() {
            const billTotal = parseFloat(this.bill.total);
            const billpaid = parseFloat(this.bill.paid);
            return (this.bill.number === "" || this.bill.date === "" || this.bill.due_date === "" || this.bill.total === "" || billpaid > billTotal || billTotal === 0.00);
        },
        cpInputPaid() {
            const billTotal = parseFloat(this.bill.total);
            const purchaseOrderTotal = parseFloat(this.purcharseOrder.pendiente);
            return billTotal > purchaseOrderTotal;
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdStore: function () {
            this.bill.purchase_orders_id = this.purcharseOrder.id;
            this.bill.provider_id = this.purcharseOrder.provider_id;
            this.post({
                    url: this.$store.getters.get__url + "/Pharmacy/oc/storeBill",
                    token: this.$store.getters.get__token,
                    params: this.bill,

                })
                .then((response) => {
                    if (response.statusCode == 200) {

                        Swal.fire({
                            icon: "success",
                            title: "Factura registrada",
                            showConfirmButton: false,
                            timer: 1300,
                        });
                        const type = this.bill.number.substring(0, 1) === 'B' ? "BOLETA" : "FACTURA";
                        const state = this.purcharseOrder.pending === 0 ? "CONTANDO" : "CREDITO";
                        const title = "DESPACHO DE LA " + type + " " + this.bill.number + " - " + state + " / ESTADO: " + response.data.stateBill; //agregar el estado de la factura
                        this.$emit('mtdShowModalStoreDispatch', {
                            title: title,
                            bill: response.data.bill,
                            id: this.purcharseOrder.id
                        });
                        this.bill = {
                            number: "",
                            date: "",
                            due_date: "",
                            money: "soles",
                            paid: "",
                            detraction: "0",
                            total: null,
                        };

                    }
                })
                .catch((errors) => {});
        },
        validateAmount(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, "");

            const decimalCount = sanitizedValue.split(".").length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }

            const decimalIndex = sanitizedValue.indexOf(".");
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(
          0,
          decimalIndex
        )}.${decimalPart.substring(0, 2)}`;
            }
            this.bill.paid = sanitizedValue;

        },
        validateTotal(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, "");

            const decimalCount = sanitizedValue.split(".").length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }

            const decimalIndex = sanitizedValue.indexOf(".");
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(
          0,
          decimalIndex
        )}.${decimalPart.substring(0, 2)}`;
            }

            if (sanitizedValue !== 0.00) {
                this.bill.total = sanitizedValue;
            } else {
                this.bill.total = '';
            }
        },
        mtdclosemodal() {
            this.bill = {
                number: "",
                date: "",
                due_date: "",
                money: "soles",
                paid: "",
                detraction: "0",
                total: null,
            };
            this.$emit("closeModalStoreBill");
        },
    },
};
</script>
