<template>
<div class="c-app">
    <div class="container w-100 d-flex align-items-center">
        <div class="w-100">
            <div class="d-lg-flex justify-content-center  gap-2">
                <div class="col-lg-6 col-md-12 mt-3">
                    <div class="card card-home-cirujano shadow" @click="mtdChooseItem('orden')">
                        <h4 >ORDEN DE SOP</h4>
                        <div class="icon-book">
                            <img class="img-fluid-bed-sop rounded-start" src="@/assets/sop/pre_sop.png" alt="" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 mt-3">
                    <div class="card card-home-cirujano shadow" @click="mtdChooseItem('homeAtencion')">
                        <h5>ATENCION MEDICA</h5>
                        <div class="icon-book">
                            <img class="img-fluid-bed-sop rounded-start" src="@/assets/consulta.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: 'c-asistencial-homeCirugia',
    data() {
        return {

        }
    },
    methods: {
        mtdChooseItem: function(item){
            this.$emit('mtdChangePage',item);
        },
    }
}
</script>
