<template>
    <div>
        <div class="container" v-if="view == 'data'">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
            <div id="div-modal-cxc" class="container d-flex justify-content-center">
                <div id="div-cxc" class="card w-100">
                    <div class="text-center card-header text-white h4 bg-main">
                        <strong>REQUERIMIENTOS EPP</strong>
                    </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <div class="col-md-12 py-2">
                                <div class="row d-flex mx-0">
                                    <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10 px-1">
                                        <div class="input-group input-group">
                                            <span class="input-group-text text-white bg-main">
                                                <i class="fas fa-search"></i>
                                            </span>
                                            <input v-model="search" type="text" class="form-control form-control"
                                                placeholder="Buscar Tec.Enfermera(o)" @keyup="calculateData(7)" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 px-1 mt-1">
                                        <div class="form-check custom-input">
                                            <input class="form-check-input custom-checkbox" type="checkbox" value=""
                                                id="flexCheckDefault" v-model="listado">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Ver Todas
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mt-3" id="content-timelinetable">
                                <table id="tb-cxc" class="table table-bordered border-main">
                                    <caption class="py-0 my-0"></caption>
                                    <thead>
                                        <tr class="text-center text-white border-white bg-main align-middle">
                                            <th scope="col" class="align-middle table-th-number">N°</th>
                                            <th scope="col" class="align-middle">FECHA</th>
                                            <th scope="col" class="align-middle">DESTINO</th>
                                            <th scope="col" class="align-middle">TEC.ENFERMERA(O)</th>
                                            <th scope="col" class="align-middle table-th-number">ESTADO</th>
                                            <th scope="col" class="align-middle table-th-acciones">ACCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody style="font-size: 12px;">
                                        <tr class="tbody-table align-middle" v-for="(item, index) in cpData"
                                            :key="index">
                                            <td class="clickCarrito">
                                                {{ mtdNumber(index) }}
                                            </td>
                                            <td>
                                                {{ item.date_time_registration }}
                                            </td>
                                            <td class="text-nowrap">
                                                {{ item.almacen_destino.name}}
                                            </td>
                                           
                                           
                                            <td>
                                                {{ item.user ? item.user.name + ' ' + item.user.last_name : ' ' }}
                                            </td>
                                            
                                            <td class="text-center">
                                                <div class="badge text-bg-danger" v-if="item.status == 1">
                                                    <div>
                                                        <strong>SIN ATENDER</strong>
                                                    </div>
                                                </div>
                                                <div class="badge text-bg-warning" v-else-if="item.status == 2">
                                                    <div>
                                                        <strong>EN PROCESO</strong>
                                                    </div>
                                                </div>
                                                <div class="badge text-bg-success" v-else-if="item.status == 3">
                                                    <div>
                                                        <strong>COMPLETADO</strong>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="d-flex justify-content-center">
                                                    <button @click="mtdRecipeDetail(item)"
                                                        class="btn btn-primary btn-sm rounded-circle bootom me-1"
                                                        title="Despachar Recetas">
                                                        <i class="fas fa-pills icono-pills"></i>
                                                    </button>
                                                    <button v-if="item.despachos.length > 0" @click="pdfRecipe(item)"
                                                        class="btn btn-primary btn-sm rounded-circle bootom me-1"
                                                        title="Pdf Recetas">
                                                        <i class="fas fa-solid fa-file-pdf"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="!cpData.length > 0" class="text-center">
                                            <th scope="col" colspan="9">NO SE ENCONTRARON RESULTADOS</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!--Paginado-->
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 text-center">
                                <div v-if="cpData.length > 0" class="d-flex justify-content-center mb-2">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination mb-1">
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)"
                                                    @click="backPage">Anterior</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                                <a :class="selectPage === 1 ? 'page-link active' : 'page-link'"
                                                    href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>

                                            <li v-for="(item, index) in limitedStepPagination" :key="index"
                                                class="page-item">
                                                <a :class="selectPage === item ? 'page-link active' : 'page-link'"
                                                    href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                                </a>
                                            </li>
                                            <li v-if="limitedStepPagination[limitedStepPagination.length - 1] < stepPagination.length - 1"
                                                class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>
                                            <li v-if="limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length"
                                                class="page-item">
                                                <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active' : 'page-link'"
                                                    href="javascript:void(0)"
                                                    @click="selectedPage(stepPagination.length)">{{
                                                        stepPagination.length }}
                                                </a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)"
                                                    @click="nextPage">Siguiente</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="px-3" v-else-if="view == 'tablaRecipe'">
            <cRecipe ref="cRecipes" @mtdBack="mtdBack"  :destination="destination" :name="name" :Req_id="Req_id" />
        </div>
        
        <cModalDispacthDetails :despachos="despachos" :title="modalview.title" :boo_modal="modalview.modal_form" @mtdclosemodalview="mtdclosemodalview"/>
        <SweetAlert  :swal="swal" ref="SweetAlert" />
    </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalViewDetailVue from "@/components/Farmacia/Modals/cModalViewDetail.vue";
import cRecipe from "@/components/Farmacia/Recipes/cEpps.vue";
import cModalDispacthDetails from "../Modals/cModalDispacthDetails.vue";
export default {
    name: "listadoRecetas",
    components: {
        SweetAlert,
        cModalViewDetailVue,
        cRecipe,
        cModalDispacthDetails
    },
    data() {
        return {
            swal: null,
            view: "data",
            search: "",
            dataShow: [],
            search: "",
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 7,
            listado: false,
            name:null,
            Req_id:null,
            destination:'',
            despachos:[],
            modalview: {
                title: '',
                modal_form: false
            },
        };
    },
    watch: {
        selectPage() {
            this.calculateData(7);
        },
        listado() {
            this.mtdGetData();
        },
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                length: endPage - startPage + 1,
            },
                (_, i) => startPage + i
            );
        },

    },
    created() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdGetData: function () {
            const urlBase = this.$store.getters.get__url + "/Requirements/indexRequirements";
            const url = this.listado ? `${urlBase}/3` : `${urlBase}/1`;

            this.get({
                url: url,
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    this.listaRecipe = response.data;
                    this.calculateData(7);
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        
        calculateData(items) {
            if (!this.listaRecipe || !this.listaRecipe.length) {
                return (this.dataShow = []);
            }

            let filteredData = this.listaRecipe;
            let indexInitial = this.selectPage;

            if (this.search.length >= 2) {
                let query = this.search.toString().toUpperCase();
                filteredData = this.listaRecipe.filter((element) => {
                    let patientName = element.patient ? element.patient.name.toString().toUpperCase() : "";
                    let patientDocument = element.patient ? element.patient.document.toString() : "";
                    return (
                        patientName.includes(query) || patientDocument.includes(query)
                    );
                });
                if (!filteredData.length && this.search === "") {
                    filteredData = this.listaRecipe;
                }

                indexInitial = 1;
            }

            let totalPages = Math.ceil(filteredData.length / items);

            this.stepPagination = Array.from(
                { length: totalPages },
                (_, index) => index + 1
            );

            if (this.selectPage > totalPages) {
                this.selectPage = totalPages || 1;
            }

            let startIndex = (this.selectPage - 1) * items;
            let endIndex = startIndex + items;
            this.dataShow = filteredData.slice(startIndex, endIndex);
            this.count = this.count + 1;
        },

       

        //Componentes 
    
        mtdRecipeDetail: function (item) {     
            this.view = "tablaRecipe";
            this.name = item.user.name + ' '+ item.user.last_name
            this.Req_id = item.id;
            this.destination = item.almacen_destino.name
        },

        /*Pag*/
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                    this.selectPage + 1 :
                    this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (this.selectPage - 1) * 7 + (index + 1)
        },
        returnHome: function () {
            this.$emit("returnHome");
        },

        mtdBack:function(){
            this.view = "data";
            this.mtdGetData()
        },
        pdfRecipe:function(item){
            if(item.despachos.length > 1){
                this.despachos = item.despachos
                this.modalview =  {
                    title: 'LISTA DE DESPACHOS',
                    modal_form: true
               }
                
            }else{
                window.open(
                        this.$store.getters.get__url + "/viewpdfDocEpp/" + item.despachos[0].id,
                        "_blank"
                );
            }
        },

        mtdclosemodalview(){
            this.modalview =  {
                    title: '',
                    modal_form: false
               }
        }
       
    },
   
    
}
</script>

<style scoped>
    @media (max-width: 536px) {
        .responsive-default {
            flex-direction: column;
        }

        .responsive-default>.py-1 {
            margin-top: 5px;
            margin-bottom: 5px;
            border-radius: 0px;
        }

        .responsive-default-div {
            border-radius: 10px !important;
            text-align: center !important;
            font-size: 11px;
        }
    }

    .bootom {
        background-color: #900052 !important;
        border: 0.5px solid #900052 !important;
    }
</style>
