<template>
    <div>
        <div class="card-bod mt-2">
            <div class="row tab-content pb-3" id="camas">
                <div class="tab-pane fade show active" role="tabpanel">
                    <div align="center" class="row m-2 p-2 d-flex justify-content-center">
                        <div v-if="Object.keys(dataBedGroupsD).length === 0" class="col-12">
                            <div class="card mb-3">
                                <div class="card-header text-white h6 header-main">
                                    <h6>NO HAY RESERVAS</h6>
                                </div>
                            </div>
                        </div>
                        <div v-else class="row">
                            <div v-for="(group, key) in dataBedGroupsD" :key="key"
                                :class="Object.keys(dataBedGroupsD).length === 1 ? 'col-md-12' : Object.keys(dataBedGroupsD).length === 2 ? 'col-md-6' : 'col-md-4'">
                                <div class="card mb-3">
                                    <div class="card-header text-white h6 header-main">
                                        <strong>{{ group[0].consultorio }}</strong>
                                    </div>
                                    <div class="card-body" id="content-timeline">
                                        <div v-for="(item, index) in group" :key="index"
                                            class="p-3 mb-3 mt-3 border border-main border-start-0 rounded-end hover-effect"
                                            style="cursor: pointer;"
                                            @click="mtdGetDataMedicalAct(item.patient_id, item.medical_act_id, item.hc_id, item.recipe_ids)">
                                            <span class="text-paciente me-1">{{ item.patientName }}</span>
                                            <span class="recipe-icon" v-if="item.medical_act_id && item.recipe_ids.length > 0">
                                                REC.
                                            </span>
                                            <br />
                                            <span class="text-datos">{{ item.hora }} - {{ item.specialty }}</span>
                                            <br>
                                        </div>
                                        <div v-if="group.length === 0"
                                            class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                            <div class="color-main">
                                                <h6>NO HAY RESERVAS</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
export default {
    name: 'c-consulta-cola',
    data() {
        return {
            swal:null,
            tab: 0,
            dataBedGroupsD: []
        }
    },
    created() {
        this.mtdSelectGroup();
    },
    components:{
        SweetAlert
    },
    methods: {
        ...mapActions(['get', 'post']),

        mtdGetDataMedicalAct: function (patient_id, medical_act_id, hc_id, recipe_id) {
            if(medical_act_id){
                const lastRecipeId = recipe_id[recipe_id.length - 1];
                this.$emit("openCarroRegRecipe", patient_id, medical_act_id, hc_id, lastRecipeId);
            }else{
                this.$refs.SweetAlert.showWarning("Sin Acto Medico");
            }
        },

        mtdSelectGroup: function () {
            this.post({
                url: this.$store.getters.get__url + "/Pharmacy/regRecipe/bedgroup/filterCon",
                token: this.$store.getters.get__token,
                params: {
                    campu_id: this.$store.getters.get__campus,
                    user_id: this.$store.getters.get__user.id
                },
            })
            .then((response) => {
                this.dataBedGroupsD = response.data;
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },
    }
}
</script>

<style scoped>
    .hover-effect {
        transition: background-color 0.3s, color 0.3s;
    }

    .hover-effect:hover {
        background-color: #f4869a;
    }

    .hover-effect:hover .text-paciente,
    .hover-effect:hover .text-datos {
        color: white;
    }

    #content-timeline {
        border: 1px solid #900052;
        height: 315px;
        overflow: auto;
        box-sizing: border-box;
        padding: 0 1rem;
    }

    .recipe-icon {
        background: #e15a72; 
        color: white; 
        border-radius: 50%; 
        width: 25px; 
        height: 25px; 
        display: inline-flex; 
        align-items: center; 
        justify-content: center; 
        font-size: 0.6rem; 
        font-weight: bold;
    }
</style>
