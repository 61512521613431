<template>
  <div>
    <div class="px-4 pb-4" v-if="view == 'data'">
      <div class="row">
        <div class="btn-home col-md-12 mt-3 mb-4 d-flex justify-content-end">
          <button type="button" class="btn btn-outline-main" @click="returnHome">
            <i class="fas fa-home-lg-alt"></i> Inicio
          </button>
        </div>
      </div>
      <div id="div-modal-anular" class="w-100">
        <div id="div-anular" class="card w-100">
          <div class="text-center card-header text-white h4 bg-main">
            <strong>NOTA DE INGRESO</strong>
          </div>
          <div class="d-flex justify-content-center ">
            <div class="w-100 border-box-main pt-3">
              <div class="row d-flex mb-2 px-4">
                  <div class="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-xs-12 mb-2">
                    <div class="d-flex responsive-search">
                      <div class="input-group input-group">
                        <span class="input-group-text text-white bg-main"
                          ><i class="fas fa-search"></i
                        ></span>
                        <input
                          v-model="filter.molecula"
                          @keyup.enter="mtdChangeFilter()"
                          type="text"
                          class="form-control form-control"
                          style="background-color: #EDEAEC"
                          placeholder="Nombre / molécula / comercial / marca"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-xs-6 btn-home mb-1">
                      <button
                          type="button"
                          class="btn btn-bm-noradius w-100"
                          @click="openVerNotas()"
                      >
                          <i class="fas fa-warehouse"></i>&nbsp; Ver Notas
                      </button>
                  </div>
                  <div class="col-xl-1 col-lg-1 col-md-1 col-sm-6 col-xs-6 btn-home">
                    <button
                      class="btn btn-primary rounded-circle"
                      type="button"
                      style="background-color: #900052; border: 2px solid #900052"
                      @click="mtdAddItemCarro()"
                    >
                      <i class="far fa-cart-plus fa-sm"></i>
                    </button>
                  </div>
              </div>
              <div class="cuerpo mt-1">
                <Medicamento
                  v-if="page == 'medicamento'"
                  :medicaments="Medicaments"
                  @mtdAddItemCarrito="mtdAddItemCarrito"
                  ref="medicamentoComponent"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="px-4 pb-4" v-else-if="view == 'pdfNota'">
      <cPdfNota
        @mtdBack="retroceder"
      />
    </div>
    <cModalViewCarritoNotaVue
      :title="modalCarrito.title"
      :boo_modal="modalCarrito.modal_form"
      :datos="datos"
      :almacen="Almacen"
      :sector="Sector"
      @closeModalCarrito="closeModalCarrito"
      @mtdDelete="mtdDelete"
      @registerCarrito="registerCarrito"
    />
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>

<script>
import Medicamento from "@/components/Logistica/pages/NotaIngreso/Medicamento.vue";
import { mapActions } from "vuex";
import cModalViewCarritoNotaVue from "../modals/NotaIngreso/cModalViewCarritoNota.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cPdfNota from "../modals/NotaIngreso/cPdfNota.vue";

export default {
  name: "c-Logistica-ordendecompra",
  data() {
    return {
      swal: null,
      view: "data",
      page: "",
      filter: {
        molecula: "",
      },
      Medicaments: [],
      Almacen: [],
      Sector: [],
      page: "",
      datos: [],
      modalCarrito: {
        title: "",
        modal_form: false,
        valorType: null,
        valorTypeVen: null,
      },
      carroNota: [],
      pdfNota: [],
    };
  },
  components: {
    SweetAlert,
    Medicamento,
    cModalViewCarritoNotaVue,
    cPdfNota
  },

  created() {},
  methods: {
    ...mapActions(["get", "post"]),

    returnHome: function () {
      if(this.datos.length == 0 ){
        this.$emit("returnHome");
      }else{
        this.$refs.SweetAlert.showConfirmSimple2("¿Estás seguro?", "Se Perderán los Items Agregados al Carrito", "warning", "Continuar")
        .then((result) => {
            if (result.value) {
              this.$emit("returnHome");
            }
        });
      }
    },
    
    changePage(page) {
      this.$emit("changePage", page);
    },
    mtdHide() {
      (this.filter = {
        molecula: "",
      }),
        (this.filter_id = "");
    },
    mtdChangeFilter() {
      this.post({
        url: this.$store.getters.get__url + "/NotaIngreso/detalles/filter",
        token: this.$store.getters.get__token,
        params: this.filter,
      })
        .then((response) => {
          this.Medicaments = response.data.data;
          this.Almacen = response.data.almacen;
          this.Sector = response.data.sector;
          this.page = "medicamento";
          if (this.$refs.medicamentoComponent) {
            this.$refs.medicamentoComponent.clear();
          };
          this.mtdHide();
    
        })
        .catch((errors) => {
          this.$refs.SweetAlert.showError(errors);
        });
    },

    mtdAddItemCarrito: function (payload) {
      // if (this.datos.some(item => item.id === payload.id)) {
      //     payload.lote = ""
       
      // }

      //this.mtdTypeChanges();
      let datosdetail =  {
            id : payload.id,
            molecule: payload.molecule,
            commercial: payload.commercial,
            brand:payload.brand,
            presentation:payload.presentation,
            dosis:payload.dosis,
            unit:payload.units[0].id,
            quantity: 1,
            regSanitario:'',
            sector_id:'',
            price:payload.price,
            lote: '',
            units:payload.units
      }
      this.modalCarrito.title = "NOTAS DE INGRESO";
  
      // payload.unit = payload.units[0].id;
      // payload.regSanitario = "";
      // payload.sector_id = "";
      
      this.datos.push(datosdetail);
      //console.log(this.datos);
      this.modalCarrito.modal_form = true;
    },

    mtdAddItemCarro: function () {
      //this.mtdTypeChanges();
      this.modalCarrito.title = "NOTAS DE INGRESO";
      this.modalCarrito.modal_form = true;
    },

    mtdTypeChanges() {
      this.post({
        url: this.$store.getters.get__url + "/logistics/typeChanges",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.modalCarrito.valorType = response.compra;
          this.modalCarrito.valorTypeVen = response.venta;
        })
        .catch((errors) => {});
    },

    closeModalCarrito: function () {
      this.modalCarrito = {
        title: "",
        modal_form: false,
        valorType: null,
        valorTypeVen: null,
      };
    },

    mtdDelete(idProducto) {
      const indice = this.datos.findIndex(item => item.id === idProducto);

      if (indice !== -1) {
        this.datos.splice(indice, 1);
      }
    },

    registerCarrito(data){
      if(data){
        this.datos = [];
      }
      this.page = "";
    },

    openVerNotas: function () {
      this.view = "pdfNota";
    },

    retroceder: function () {
      this.view = "data";
    },
  },
};
</script>
