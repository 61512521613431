<template>
<div>
    <div id="app" class="px-2 mt-3">
        <div class="d-lg-flex mt-3">
            <div class="col-lg-1 mb-1">
                <div class="btn-success text-start ">
                    <button type="button" class="btn text-white btn-sm text-start" @click="mtdBackSop">
                        <i class="fas fa-arrow-left"></i> Atr&aacute;s
                    </button>
                </div>
            </div>
            <div class="col-xl-6 col-lg-7 col-md-12 rounded-3 px-0 bg-blue mb-2 text-white">
                <div class="row mx-0">
                    <div class="h-100 py-1 mx-0 col-sm-12 col-md-9 col-xl-9 px-0 text-x border rounded-start d-inline-flex">
                        <span class=" align-middle col-sm-4 h-100 px-2 text-start">Nombre y Apellido:</span>
                        <span class=" align-middle col-sm-8 h-100 px-0 text-start ">{{ datapatients.patient_name }}</span>
                    </div>
                    <div class="h-100 mx-0 text-x   px-0 py-1 col-sm-12 col-md-3 col-xl-3 border rounded-end d-inline-flex">
                        <span class=" align-middle col-sm-4 h-100 px-2 text-start">Edad:</span>
                        <span class=" align-middle col-sm-7 h-100 px-0 text-sm-start text-xl-center">{{datapatients.patient_year }}</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-2 mb-2 px-1 px-md-1 px-xl-3">
                <div class="card border border-main pt-1">
                    <span class="color-main fw-bold text-uppercase">PreOperatorio</span>
                </div>
            </div>
            <div class="col-xl-2 col-lg-2 mb-1 d-none d-lg-flex">
                <button type="button" class="btn text-white btn-sm bg-main text-xs w-100" @click="mtdSave()" :disabled="cpDisabledButton">
                    <i class="fas fa-save"></i> GUARDAR
                </button>
            </div>
        </div>
    </div>
    <!-- TABs -->
    <div class="px-2 mt-2 ">

<ul class="nav pb-0 mt-2 d-none d-lg-flex" id="myTabHijoHistorial" role="tablist">
    
    <li class="nav-itemUci" role="presentation">
        <button @click="changeSubPage('ordensop')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage=='ordensop' }" id="profile-tab" type="button">
            <strong class="text-xxs">ORDEN DE SOP</strong>
        </button>
    </li>
    <li class="nav-itemUci" role="presentation">
        <button @click="changeSubPage('rxqx')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'rxqx' }" id="profile-tab" type="button">
            <strong class="text-xxs">RX QX</strong>
        </button>
    </li>
    <li class="nav-itemUci" role="presentation">
        <button @click="changeSubPage('consentimientos')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'consentimientos' }" id="profile-tab" type="button">
            <strong class="text-xxs">CONSENTIMIENTOS</strong>
        </button>
    </li>
   
    <li class="nav-itemUci" role="presentation">
        <button @click="changeSubPage('preanestecia')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'preanestecia' }" id="profile-tab" type="button">
            <strong class="text-xxs">PRE ANESTECIA</strong>
        </button>
    </li>
    <li class="nav-itemUci" role="presentation">
        <button @click="changeSubPage('entrevista')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'entrevista' }" id="profile-tab" type="button">
            <strong class="text-xxs">ENTREVISTA DE ENFERMERIA</strong>
        </button>
    </li>
    <li class="nav-itemUci" role="presentation">
        <button @click="changeSubPage('rp')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'rp' }" id="profile-tab" type="button">
            <strong class="text-xxs">RP MEDICO</strong>
        </button>
    </li>
    <li class="nav-item-h" role="presentacion">
        <button @click="changeSubPage('kardex')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'kardex' }" id="profile-tab" type="button">
            <strong class="text-xxs">KARDEX SOP</strong>
        </button>
    </li>
</ul>

<div class="tab-content mb-3 d-none d-lg-block" id="myTabContentHijo">
   
    <Ordensop v-if="subpage=='ordensop'" :medical_act_id="medical_act_id" :addOrder="addOrder" @finishAddOrder="finishAddOrder" :rolname="rolname" />
    <Rxqx v-if="subpage=='rxqx'" :medical_act_id="medical_act_id" :addRxqx="addRxqx" @finishAddOrder="finishAddOrder" :rolname="rolname"/>
    <Entrevista v-if="subpage =='entrevista'" :medical_act_id="medical_act_id" :rolname="rolname" :addEntrevista="addEntrevista" @finishAddOrder="finishAddOrder" />
    <Consentimiento v-if="subpage == 'consentimientos'" :medical_act_id="medical_act_id"/>
    <Preanestecia v-if="subpage=='preanestecia'" :medical_act_id="medical_act_id" :addPreAnestecia="addPreAnestecia" @finishAddOrder="finishAddOrder"/>
    <Rp v-if="subpage=='rp'" :process="sop_process" :addRp="addRp" @mtdaddrpexit="mtdaddrpexit" :medical_act_id="datapatients.medical_act_id" :dete_out="date_out" :rolname="rolname"  :dete_out_doctor="date_out_doctor"/>
    <Kardex v-if="subpage == 'kardex'" :process="sop_process" :medical_act_id="medical_act_id" :datapatients="datapatients" :dete_out="date_out" :rolname="rolname" :dete_out_doctor="date_out_doctor"/>
</div>

<!-- Tablet -->
<div id="app" class="root d-md-block d-lg-none">
    <div class="wrapper-list">
        <dropdown title="Rp" component="Rp" :addRp="addRp" @mtdaddrpexit="mtdaddrpexit" :process="sop_process" :medical_act_id="datapatients.medical_act_id" :date_out="date_out" :rolname="rolname"  :date_out_doctor="date_out_doctor"></dropdown>
        <dropdown title="Orden de Sop" component="Ordensop" :medical_act_id="medical_act_id" :addOrder="addOrder" @finishAddOrder="finishAddOrder" :rolname="rolname"></dropdown>
        <dropdown title="Rx qx" component="Rxqx" :medical_act_id="medical_act_id" :addRxqx="addRxqx" :rolname="rolname" @finishAddOrder="finishAddOrder"></dropdown>
        <dropdown title="Consentimientos" component="consentimientos" :medical_act_id="medical_act_id"></dropdown>
      
        <dropdown title="Pre anestecia" component="preanestecia" :medical_act_id="medical_act_id"  :addPreAnestecia="addPreAnestecia" @finishAddOrder="finishAddOrder"></dropdown>
        <dropdown title="Kardex" component="Kardex" :medical_act_id="medical_act_id" :process="sop_process" :datapatients="datapatients" :dete_out="date_out" :dete_out_doctor="date_out_doctor" :rolname="rolname"></dropdown>
    </div>
</div>
<!--  -->
<SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import Rp from '@/components/Asistencial/Sop/Historia/Rp.vue';
import Ordensop from '@/components/Asistencial/Sop/Historia/Ordendesop.vue';
import Preanestecia from '@/components/Asistencial/Sop/Historia/Preanestecia.vue';
import Rxqx from '@/components/Asistencial/Sop/Historia/Rxqx.vue';
import Consentimiento from '@/components/Asistencial/Sop/Historia/Consentimientos.vue';
import Entrevista from '@/components/Asistencial/Sop/Historia/Entrevista.vue'
import Dropdown from '@/components/Asistencial/Sop/Dropdown.vue';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import Kardex from '@/components/Asistencial/Sop/Historia/Kardex.vue'
export default {
    name: 'c-asistencial-sop-preoperatorio',
    props: {
        rolname: String,
        datapatients: {
            type: Object,
            default: () => ({})
        },
        
    },
    data() {
        return {
            subpage: 'ordensop',
            medical_act_id: 0, 
            addOrder: null,
            addRxqx: null,
            addReportSop: null,
            addPreAnestecia: null,
            addhojaanestecia: null,
            sop_process: 3,
            date_out: false,
            date_out_doctor:false,
            addRp: null,
            swal: null,
            addEntrevista:null
        }
    },
    components: {
        Ordensop,
        Preanestecia,
        Rxqx,
        Consentimiento,
        // Informeoperatorio,
        Dropdown,
        // Hojaanestecia,
        Kardex,
        Rp,
        SweetAlert,
        Entrevista
    },
    watch: {
        datapatients: {
            handler: function (newValue) {
                if (newValue && Object.keys(newValue).length > 0) {
                    this.medical_act_id = newValue.medical_act_id;
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed:{
        cpDisabledButton: function(component){
            let dbutton = false;
            if(this.rolname !== 'DOCTOR(A)'){
                if(this.subpage =='ordensop' || this.subpage =='rxqx' || this.subpage=='informeoperatorio' || this.subpage == ''){
                    dbutton = true;
                }
            }
            return dbutton;
        }
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdBackSop: function(){
            this.$emit('mtdBackSop');
        },
        changeSubPage: function (subpage) {
            this.subpage = subpage;
        },
        mtdSave: function(){
            if(this.subpage=='ordensop'){
                this.addOrder = 1;
            }else if(this.subpage == 'rxqx'){
                this.addRxqx = 1;
            }else if(this.subpage == 'informeoperatorio'){
                this.addReportSop = 1;
            }else if(this.subpage == 'preanestecia'){
                this.addPreAnestecia = 1;
            }
            else if(this.subpage == 'rp'){
                const areEqualrp = JSON.stringify(this.$miVariableGlobalRpSop) === JSON.stringify(this.$miVariableGlobalRpSop_plantilla);
                if (!areEqualrp) {
                    this.mtdaddrp()
                } else {
                        this.$refs.SweetAlert.showWarning("Registre Rp");  
                }
            }else if (this.subpage == 'entrevista'){
                this.addEntrevista = 1
            }
        },
        finishAddOrder: function(){
            this.addOrder = 0;
            this.addRxqx = 0;
            this.addReportSop = 0;
            this.addPreAnestecia = 0;
            this.addEntrevista = 0;
        },
        mtdaddrpexit: function () {
            this.addRp = null;
            // this.addhojaanestecia = null;
            // this.addChecklist= null;
            // this.addPostA = null;
            // this.addPostAnestecia = null;
            // this.addRp = null;
            // this.addNota = null;
            // this.addPostAnestecia = null;
        },
        mtdaddrp: function () {
            let count = 0
            this.$miVariableGlobalRpSop.recipe.forEach(element => {
                if(element.type == 1 && element.total != '' && element.group == 0){
                    count++
                }
             });
           
           if(count == 0){
                this.post({
                        url: this.$store.getters.get__url + "/Sop/storeRp",
                        token: this.$store.getters.get__token,
                        params: {
                            ... this.$miVariableGlobalRpSop,
                            process: 3,
                        },
                    })
                    .then((response) => { //   
                        this.addRp = 1
                        this.$refs.SweetAlert.showSuccess(response.message);
                    })
                    .catch((errors) => {
                        console.log(errors);
                    });
            }else{
                this.$refs.SweetAlert.showWarning("Medicamentos para realizar mezcla");
             }
        },
    }
}
</script>
