<template>
<div class="px-4 mb-4">
    <!--tabla-->
    <div class="d-flex mb-2">
        <h6 class="mt-2 me-2"><i class="fas fa-pills"></i> RECOMENDADOS</h6>
        <button v-if="carrito.length > 0" type="button" @click="mtdviewcarrito()" class="border-0 btn rounded-2 bg-main btn-ver text-white btn-sm">
            <i class="fas fa-eye"></i> Ver Carrito
        </button>
    </div>
    <div class=" px-0">
        <div class="row mx-0 px-0">
            <div class="col px-0">
                <div v-if="cpRecommendedProducts.length>0" class=" py-1 responsive-default text-xxs overflow-auto w-100" style="max-height: 150px; font-size: 11px;">
                    <div v-for="(product, indexp) in cpRecommendedProducts" :key="indexp">
                        <div class="d-flex div-pointer w-100 " @click="mtdAddItemCarrito(product)">
                            <div class="px-3 py-1 responsive-default-div border rounded-start w-25" :class="{'bg-color1': product.color === 1, 'bg-color2': product.color === 2}">
                                {{ product.proveedor.name }}
                            </div>
                            <div class="px-3 py-1 responsive-default-div border w-25" :class="{'bg-color1': product.color === 1, 'bg-color2': product.color === 2}">
                                {{ product.brand }}
                            </div>
                            <div class="px-3 py-1 responsive-default-div border w-25" :class="{'bg-color1': product.color === 1, 'bg-color2': product.color === 2}">
                                {{ product.molecule }}
                            </div>
                            <div class="px-3 py-1 responsive-default-div border w-25" :class="{'bg-color1': product.color === 1, 'bg-color2': product.color === 2}">
                                {{ product.presentation }}
                            </div>
                            <div class="px-3 py-1 responsive-default-div border w-25" :class="{'bg-color1': product.color === 1, 'bg-color2': product.color === 2}">
                                {{ product.commercial }}
                            </div>
                            
                            <div class="px-3 py-1 responsive-default-div border  w-25" :class="{'bg-color1': product.color === 1, 'bg-color2': product.color === 2}">
                                {{ product.concentration }}
                            </div>
                            <div class="px-3 py-1 responsive-default-div border w-25" :class="{'bg-color1': product.color === 1, 'bg-color2': product.color === 2}">
                                {{ product.format }}
                            </div>
                            <div class="px-3 py-1 responsive-default-div rounded-end border w-25" :class="{'bg-color1': product.color === 1, 'bg-color2': product.color === 2}">
                                {{ product.utilidad }}
                            </div>

                        </div>
                    </div>
                </div>
                <div v-else class=" text-center " style="font-size: 12px;">
                    <div>NO SE ENCONTRARON PRODUCTOS RECOMENDADOS </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 table-responsive mt-3" id="div-tabla-anular">
        <h6 class="mt-2 me-2 "><i class="fas fa-pills"></i> MEDICAMENTOS</h6>
        <table id="tb-anular" class="table table-bordered">
            <caption></caption>
            <thead class="bg-main" style="font-size: 11px;">
                <tr class="text-white text-center">
                    <th scope="col" class="align-middle">
                        PROVEEDOR
                    </th>
                    <th scope="col" class="align-middle">
                        MARCA
                    </th>
                    <th scope="col" class="align-middle">
                        MOLECULA
                    </th>
                    <th scope="col" class="align-middle">
                        PRESENTACIÓN
                    </th>
                    <th scope="col" class="align-middle">
                        COMERCIAL
                    </th>
                    <th scope="col" colspan="1" class="align-middle">
                        DOSIS
                    </th>
                    <th scope="col" colspan="1" class="align-middle">
                        FORMA
                    </th>
                    <th scope="col" colspan="1" class="align-middle">
                        S/PRECIO DE COMPRA (SIN IGV)
                    </th>
                    <th scope="col" colspan="1" class="align-middle">
                        S/KAIROS UNITARIO
                    </th>
                    <th scope="col" colspan="1" class="align-middle">
                        UTILIDAD UNITARIO
                    </th>
                </tr>
            </thead>

            <tbody class="border-main" style="font-size: 11px;">
                <tr class="tbody-table" v-for="(producto, index) in cpData" :key="index">
                    <td class="clickCarrito" @click="mtdAddItemCarrito(producto)">
                        {{ producto.proveedor.name }}
                    </td>
                    <td>
                        {{ producto.brand }}
                    </td>
                    <td>
                        {{ producto.molecule }}
                    </td>
                    <td>
                        {{ producto.presentation }}
                    </td>
                    <td>
                        {{ producto.commercial }}
                    </td>
                    <td>
                        {{ producto.dosis }}
                    </td>
                    <td>
                        {{ producto.format }}
                    </td>
                    <td>
                        <div>{{ producto.precio_purchase }}</div>
                    </td>
                    <td>
                        <div>{{ producto.precio_farmacia_kairos }}</div>
                    </td>
                    <td>
                        {{ producto.utilidad }}
                    </td>
                </tr>
                <tr v-if="!cpData.length > 0" class="text-center">
                    <th scope="col" colspan="13">NO SE ENCONTRARON RESULTADOS</th>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="d-flex justify-content-center mt-3" style="align-items: center">
        <nav v-if="cpData.length > 0" aria-label="Page navigation example">
            <ul class="pagination">
                <li class="page-item">
                    <a class="anterior-siguiente page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                </li>
                <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                    <a class="page-link page" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                </li>
                <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                    <span class="page-link">...</span>
                </li>
                <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                    <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}</a>
                </li>
                <li v-if="
              limitedStepPagination[limitedStepPagination.length - 1] <
              stepPagination.length - 1
            " class="page-item disabled">
                    <span class="page-link">...</span>
                </li>
                <li v-if="
              limitedStepPagination[limitedStepPagination.length - 1] !==
              stepPagination.length
            " class="page-item">
                    <a class="page-link page" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}</a>
                </li>
                <li class="page-item">
                    <a class="anterior-siguiente page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                </li>
            </ul>
        </nav>
    </div>

    <!--Modal Carrito de  compra ya no -->

    <!--Modal Carrito de  compra -->
</div>
</template>

<script>
import {
    mapActions
} from "vuex";

export default {
    name: "Medicamento",
    components: {},
    data() {
        return {
            // filter: {},
            dataShow: [],
            search: "",
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 7,
            PurchaseDetails: [],
            dataRecommendedProducts: []
        };
    },
    computed: {
        cpData() {
            let filteredData = this.medicaments;
            let totalPages = Math.ceil(filteredData.length / 20);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * 20;
            let endIndex = startIndex + 19;
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;
            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

        cpRecommendedProducts() {
            let productosOrdenados = [...this.medicaments].sort((a, b) => b.utilidad - a.utilidad);
            let productosAgrupados = {};
            productosOrdenados.forEach(producto => {
                let clave = producto.presentation + '_' + producto.concentration + '_' + producto.molecule;
                if (!productosAgrupados[clave]) {
                    productosAgrupados[clave] = [];
                }
                productosAgrupados[clave].push(producto);
            });
            let recommendedProducts = [];
            let colorCounter = 1;
            for (let clave in productosAgrupados) {
                let productosDeGrupo = productosAgrupados[clave];
                let productosRecomendados = productosDeGrupo.slice(0, 2);
                productosRecomendados.forEach(producto => {
                    producto.color = colorCounter;
                });
                recommendedProducts.push(...productosRecomendados);
                colorCounter = colorCounter === 1 ? 2 : 1;
            }
            return recommendedProducts;
        }

    },

    props: {
        medicaments: {
            type: Array,
            default: () => [],
        },
        carrito: {
            type: Array,
            default: () => [],
        },
    },

    created() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),
        //metodo retornar a Home
        returnHome: function () {
            this.$emit("returnHome");
        },
        changePage(page) {
            this.$emit("changePage", page);
        },
        reco8mmendedProducts() {
            let productosordenados = [...this.medicaments].sort((a, b) => b.utilidad - a.utilidad);
            this.dataRecommendedProducts = productosordenados.slice(0, 2);
        },
        mtdGetData: function () {
            /** paginación */
            let cantidad = parseInt(this.medicaments.length / 20);
            let residuo = parseInt(this.medicaments.length % 20);
            cantidad = residuo > 0 ? cantidad + 1 : cantidad;
            for (let index = 0; index < cantidad; index++) {
                this.stepPagination.push(index + 1);
            }
        },

        /** metodos carrito */
        mtdAddItemCarrito: function (payload) {
            this.$emit("mtdAddItemCarrito", payload);
        },
        mtdviewcarrito: function () {
            this.$emit("mtdviewcarrito");
        },

        /** pagination */
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
    },
};
</script>

<style scoped>
#input-cantidad:focus-within input {
    border: none;
    outline: none;
    box-shadow: none;
}

#input-cantidad:hover {
    border: solid 1px #900052;
}

.bg-color1 {
    background-color: #D5DBDB;
}

.bg-color2 {
    background-color: #BFC9CA;
}

/* 
.table-container {
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: thin;
    scrollbar-color: darkgray lightgray;
}

.table-container::-webkit-scrollbar {
    width: 1px;
}

.table-container::-webkit-scrollbar-thumb {
    background-color: darkgray;
}

.table-container::-webkit-scrollbar-track {
    background-color: lightgray;
} */

@media (max-width: 1200px) {
    .responsive-proveedor {
        flex-direction: column;
    }

    .responsive-contador {
        flex-direction: column;
    }

    .responsive-contador {
        margin-top: 10px;
        width: 100% !important;
    }
}

@media (max-width: 536px) {
    .responsive-default {
        flex-direction: column;
    }

    .responsive-default>.py-1 {
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 0px;
    }

    .responsive-default-div {
        border-radius: 10px !important;
        text-align: center !important;
        font-size: 11px;
    }
}

.anterior-siguiente:focus {
    background: #900052;
}

.anterior-siguiente:hover {
    background: #bb026c;
}

.btn-ver:focus {
    background: #900052;
}

.btn-ver:hover {
    background: #bb026c;
}

.clickCarrito {
    cursor: pointer;
}

.clickCarrito:hover {
    background-color: #D5DBDB;

}
</style>
