<template>
<div class="c-app">
    <div class="container">
        <div class="d-flex title-option">
            <div class="btn-home mt-2 ms-auto" v-if="subpage == 'buttons'">
                <button type="button" class="btn btn-outline-main" @click="returnHome()">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>

            <!-- <div class="mt-3" v-if="subpage !== 'buttons'">
                    <span class="btn border border-main text-main" @click="mtdShowBook('buttons')" style="align-self: flex-start;">
                        <i class="fas fa-arrow-left"></i> Volver
                  </span>
                </div> -->

        </div>
        <div class="h-100 d-flex align-items-center">

            <div class="w-100" v-if="subpage == 'buttons'">
                <div class="d-lg-flex justify-content-center gap-2 mt-2">
                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-4">
                        <div class="card card-home-cirujano shadow" @click="mtdShowBook('cola')">
                            <h4>COLA DE PACIENTES</h4>
                            <div class="icon-book">
                                <img class="img-fluid-bed-sop rounded-start" src="../../../assets/consulta.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-4">
                        <div class="card card-home-cirujano shadow" @click="mtdShowBook('plan')">
                            <h4>PLANES</h4>
                            <div class="icon-book">
                                <img class="img-fluid-bed rounded-start" src="../../../assets/inventario.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 mb-4">
                        <div class="card card-home-cirujano shadow" @click="mtdShowBook('historia')">
                            <h4>HISTORIAS CLINICAS</h4>
                            <div class="icon-book">
                                <img class="img-fluid-bed-sop rounded-start" src="../../../assets/HC.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

    
<script>
import {
    mapActions
} from 'vuex';

export default {
    name: 'c-asistencial-controlDiario',
    data() {
        return {
            subpage: "buttons",

        }
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdShowBook(subpage) {
            //this.subpage = subpage;
            this.$emit('mtdChangePage', subpage);
        },
        returnHome() {
            //window.location.reload();
            this.$emit('returnHome');
        },

    }

}
</script>
