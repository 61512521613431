<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'xl'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
    :fullScreen="true"
  >
      <div class="row d-flex">
        <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12">
            <div class="text-end mb-3"> 
                <button type="button" class="btn btn-custom text-white" @click="registrarDevoluciones">REGISTRAR DEV.</button>
            </div>

            <div v-for="(productGroup, index) in Object.values(item)" :key="index" class="card mb-4">
                <div class="card-header bg-main text-white d-flex align-items-center justify-content-between">
                    <h5 class="card-title mb-0" style="font-size: 14px">
                        {{ productGroup[0].detail }}
                    </h5>
                    <div class="d-flex justify-content-end align-items-center">
                        <button type="button" class="btn btn-light btn-sm me-1 totals" style="color: blue;">
                            <strong style="white-space: nowrap;">TOTAL : &nbsp;<span class="ms-1">{{ productGroup[0].total }}</span></strong>
                        </button>
                        <button type="button" class="btn btn-light btn-sm totals" style="color: red;" v-if="productGroup[0].valorFalt !== 0">
                            <strong style="white-space: nowrap;">TOTAL FALTANTE : &nbsp;<span class="ms-1">{{ productGroup[0].total_faltante }}</span></strong>
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered mb-0 text-center" style="font-size: 12px">
                            <thead>
                                <tr>
                                    <th class="align-middle">Marca</th>
                                    <th class="align-middle">Cant. Despachada</th>
                                    <th class="align-middle">Cant. Devolver</th>
                                    <th class="align-middle" v-if="productGroup[0].valorFalt !== 0">Cant. Faltante</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(product, productIndex) in productGroup" :key="productIndex">
                                    <td class="align-middle">{{ product.brand_name }}</td>
                                    <td class="align-middle">{{ product.total_quantity}}</td>
                                    <td class="align-middle">
                                        <input
                                            type="text"
                                            class="input-p form-control form-control-sm text-center"
                                            v-model="product.quantity_dev"
                                            @keydown="filterKey"
                                            placeholder="Ingrese Cant."
                                            style="font-size: 10px; padding: 0.25rem 0.5rem"

                                            @input="validateInput(product.product_id, product.quantity_dev, product.total_quantity, productGroup[0].total, productGroup[0].detail, product.brand_name)"
                                        />
                                    </td>
                                    <td class="align-middle" v-if="productGroup[0].valorFalt !== 0">
                                      <input
                                            type="text"
                                            class="input-p form-control form-control-sm text-center"
                                            v-model="product.quantity_missing"
                                            @keydown="filterKey"
                                            placeholder="Ingrese Cant. Falt."
                                            style="font-size: 10px; padding: 0.25rem 0.5rem"
                                            :disabled="productGroup.length === 1"
                                            @input="validateInputFal(product.product_id, product.quantity_missing, productGroup[0].total_faltante, productGroup[0].detail, product.brand_name)"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 vertical-line">
            <div class="card text-center mb-4" v-if="dataDev.length == 0">
                <div class="card-header ">
                    <h5 class="card-title mb-0">Registre Devolucion</h5>
                </div>
            </div>
            <div id="table-detalle" class="" v-else>
                <div class="row d-flex">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sm-12">
                    <span class="badge custom">TIPO :</span>
                        <div class="input-group input-group">
<!--                             <v-select
                            class="text-dark form-control"
                            label="name"
                            :options="servicesType"
                            placeholder="- Seleccionar Tipo de Dev. -"
                            @search="searchType"
                            v-model="type"
                            :reduce="(name) => name.id"
                            required
                            :clearable="false"
                            >
                            <template slot="no-options">
                                No hay Tipos de Devolución
                            </template>
                            </v-select> -->
                            <select 
                                class="text-dark form-control" 
                                v-model="type" 
                                required
                                >
                                <option selected value="" disabled>Seleccione Tipo de Dev.</option>
                                <option 
                                    v-for="(item, index) in servicesType" 
                                    :key="index" 
                                    :value="item.id"
                                >
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row d-flex mt-2">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sm-12">
                    <span class="badge custom">MOTIVO :</span>
                        <div class="input-group input-group">
<!--                             <v-select
                            class="text-dark form-control"
                            label="name"
                            :options="servicesMotive"
                            placeholder="- Seleccionar Motivo de Dev. -"
                            @search="searchMotive"
                            v-model="motivo"
                            :reduce="(name) => name.id"
                            required
                            :clearable="false"
                            >
                            <template slot="no-options">
                                No hay Motivos de Devolución
                            </template>
                            </v-select> -->
                            <select 
                                class="text-dark form-control" 
                                v-model="motivo" 
                                required
                                >
                                <option selected value="" disabled>Seleccione Motivo de Dev.</option>
                                <option 
                                    v-for="(item, index) in servicesMotive" 
                                    :key="index" 
                                    :value="item.id"
                                >
                                    {{ item.name }}
                                </option>
                            </select>

                        </div>
                    </div>
                </div>

                <div v-for="(product, index) in Object.values(dataDev)" :key="index" class="mt-3">
                    <div class="card mb-3">
                        <div class="card-header bg-main text-white">
                        <h5 class="card-title" style="font-size: 11px">{{ product[0].product_name }}</h5>
                        </div>
                        <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mb-0" style="font-size: 12px">
                            <thead>
                              <tr>
                                  <th scope="col" class="align-middle">Documento</th>
                                  <th scope="col" class="align-middle">Cantidad</th>
                                  <th scope="col" class="align-middle">Lote</th>
                              </tr>
                          </thead>
                            <tbody>
                                <tr  class="text-xs" v-for="(dispatch, dispatchIndex) in product" :key="dispatchIndex">
                                  <th scope="col" class="align-middle">{{ dispatch.dispatch_name }}</th>
                                  <td class="align-middle">{{ dispatch.quantity }}</td>
                                  <td class="align-middle">
                                      {{ dispatch.lote }}
                                  </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
   
    <template #footer>
      <button class="btn btn-success guardar-btn btn-sm" @click="registrarDev" :disabled="!isValidDevolucion">
        <i class="fas fa-save" />&nbsp; GUARDAR
      </button>
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import { mapActions } from "vuex";

export default {
  name: "c-devoluciones",
  components: {
    SweetAlert,
    CModal,
  },
  data() {
    return {
      swal: null,
      motivo: "",
      type: "",
      dataDev: [],
      dataPdf: [],
      servicesType: [],
      servicesMotive: [],
      //variables
      productGroup: [],  
      product: null,       
      faltantes: []   
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: {
      type: Object,
      default: () => ({}) 
    },
    patient_id: Number,
    id_medical: Number,
    selectedItems: Array,
    typeAc: Number,
  },
  created() {
  },
  computed: {
    isValidDevolucion() {
        return this.motivo && this.type;
    }
  },
  watch: {
    item: {
      immediate: true,
      handler(newVal) {
        Object.values(newVal).forEach(productGroup => {
          if (productGroup.length === 1) {
            //productGroup[0].quantity_dev = productGroup[0].total;
            const product = productGroup[0];
            // Solo asigna `quantity_dev` si `total` no excede `total_quantity`
            if (product.total <= product.total_quantity) {
              product.quantity_dev = product.total;
            }
          }
        });
      }
    }
  },
  methods: {
    ...mapActions(["get", "post"]),

    mtdClose() {
      this.motivo = "",
      this.type = "",
      this.dataDev = [],
      this.dataPdf = [],
      this.servicesType = [],
      this.servicesMotive = [],
      //variables
      this.productGroup = [];  
      this.product = null;      
      this.faltantes = []; 
      this.$emit("mtdClose");
    },

    filterKey: function (e) {
      let b = false;
      b = /^[0-9]+$/.test(e.key);
      if (b == false && e.key != "Backspace") {
        e.preventDefault();
      }
    },

    //----------------------------------------------------

    validateInput(product_id, quantity_dev, total_quantity, total, detail, marca) {
        this.clearDataPdf();
        this.clearQuantityMissing(product_id);
        this.productGroup = Object.values(this.item).find(group =>
            group.some(product => product.product_id === product_id)
        );
        if (!this.productGroup) return;

        this.product = this.productGroup.find(p => p.product_id === product_id);
        if (!this.product) return;

        // Validar si quantity_dev es 0
        if (parseInt(quantity_dev) === 0) {
            this.product.quantity_dev = '';
            this.$refs.SweetAlert.showWarning("La Cant. a Devolver no puede ser 0");
            this.recalculateTotals(product_id, total_quantity, total);
            return;
        }

        const totalQuantity = parseInt(total_quantity);

        // Calcular la suma actual de quantity_dev en el grupo
        const totalDevSum = this.productGroup.reduce((sum, p) =>
            sum + (p.product_id === product_id ? parseInt(quantity_dev || 0) : parseInt(p.quantity_dev || 0))
        , 0);

        // Calcular totalFaltante
        const totalFaltante = total - totalDevSum;
        const valorFalt = totalFaltante > 0 ? 1 : 0;

        // Asignar totalFaltante y valorFalt a cada producto del grupo
        this.productGroup.forEach(p => {
            p.total_faltante = totalFaltante > 0 ? totalFaltante : 0;
            p.valorFalt = valorFalt;
        });

        // Validar cantidad máxima permitida
        const minQuantity = Math.min(totalQuantity, total);
        if (parseInt(quantity_dev) > minQuantity) {
            this.product.quantity_dev = '';
                const message = (minQuantity === totalQuantity)
                    ? `La <strong> Cant. Devolver (${quantity_dev})</strong>, supera la <strong>Cant. Despachada (${totalQuantity})</strong> en la Marca <strong>${marca}</strong>`
                    : `La <strong> Cant. Devolver (${quantity_dev})</strong>, supera la <strong style="color: blue;">Cant. Total (${total})</strong> en la Marca <strong>${marca}</strong>`;

                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    html: message
                }); 
            this.recalculateTotals(product_id, total_quantity, total);
            return;
        }

        // Validar si la suma de quantity_dev supera el total permitido
        if (totalDevSum > total) {
            this.product.quantity_dev = '';
            const message = `La <strong>suma</strong> de <strong> Cant. Devolver (${totalDevSum})</strong>, supera la <strong style="color: blue;">Cant. Total (${total})</strong> en <strong>${detail}</strong>`
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                html: message
            }); 
            this.recalculateTotals(product_id, total_quantity, total);
        }

        // Actualizar quantity_missing si solo hay un producto en el grupo
        if (this.productGroup.length === 1) {
            this.product.quantity_missing = totalFaltante > 0 ? totalFaltante : 0;
        }
    },

    recalculateTotals(product_id, total_quantity, total) {
        const totalDevSum = this.productGroup.reduce((sum, p) =>
            sum + (p.product_id === product_id ? 0 : parseInt(p.quantity_dev || 0))
        , 0);
        const totalFaltante = total - totalDevSum;
        const valorFalt = totalFaltante > 0 ? 1 : 0;

        this.productGroup.forEach(p => {
            p.total_faltante = totalFaltante > 0 ? totalFaltante : 0;
            p.valorFalt = valorFalt;
        });

        // Actualizar quantity_missing si solo hay un producto en el grupo
        if (this.productGroup.length === 1) {
            this.product.quantity_missing = totalFaltante > 0 ? totalFaltante : 0;
        }
    },


    validateInputFal(product_id, quantity_missing,  total, detail, marca) {
        this.clearDataPdf();

        this.productGroup = Object.values(this.item).find(group =>
            group.some(product => product.product_id === product_id)
        );
        if (!this.productGroup) return;

        this.product = this.productGroup.find(p => p.product_id === product_id);
        if (!this.product) return;

        // Validar si quantity_missing es 0
        if (parseInt(quantity_missing) === 0) {
            this.product.quantity_missing = '';
            this.$refs.SweetAlert.showWarning("La Cant. Faltante no puede ser 0");
            return;
        }

        const totalQuantity = parseInt(this.product.total_quantity || 0) - parseInt(this.product.quantity_dev || 0);
        const totalDevSum = this.productGroup.reduce((sum, p) =>
            sum + (p.product_id === product_id ? parseInt(quantity_missing || 0) : parseInt(p.quantity_missing || 0))
        , 0);

        
        // Validar cantidad máxima permitida
        const minQuantity = Math.min(totalQuantity, total);
        if (parseInt(quantity_missing) > minQuantity) {
            this.product.quantity_missing = '';
            const message = (minQuantity === totalQuantity)
                    ? `La <strong> Cant. Faltante (${quantity_missing})</strong>, supera la <strong>Cant. Restante (${totalQuantity})</strong> en la Marca <strong>${marca}</strong>`
                    : `La <strong> Cant. Faltante (${quantity_missing})</strong>, supera el <strong style="color: red;">Total Faltante (${total})</strong> en la Marca <strong>${marca}</strong>`;

                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    html: message
                }); 
            return;
        }

        // Validar si la suma de quantity_missing supera el total permitido
        if (totalDevSum > total) {
            this.product.quantity_missing = '';
            const message = `La <strong>suma</strong> de <strong> Cant. Faltante (${totalDevSum})</strong>, supera <strong style="color: red;">Total Faltante (${total})</strong> en <strong>${detail}</strong>`
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                html: message
            }); 
        }
    },

    clearQuantityMissing(product_id) {
        const productGroup = Object.values(this.item).find(group =>
            group.some(product => product.product_id === product_id)
        );
        
        if (productGroup) {
            productGroup.forEach(p => {
                if (p.product_id === product_id) {
                    p.quantity_missing = '';
                }
            });
        }
    },

    //----------------------------------------------------


    clearDataPdf(){
        this.motivo = "";
        this.type = "";
        this.dataDev = [];
        this.dataPdf = [];
        this.servicesType = [];
        this.servicesMotive = [];
        //variables
        this.productGroup = [];  
        this.product = null;      
        this.faltantes = []; 
    },

    registrarDevoluciones() {
        if (this.checkInvalidSum()) {
            return; 
        } 
        const devoluciones = [];
        Object.values(this.item).forEach(productGroup => {
            productGroup.forEach(product => {
                if (product.quantity_dev && product.quantity_dev !== '0' && product.quantity_dev !== '' && product.quantity_dev !== null && product.quantity_dev !== undefined) {
                devoluciones.push({
                    id: product.product_id,
                    quantity_dev: product.quantity_dev,
                    return_id: product.return_id
                });
                }
            });
        }); 
        if (devoluciones.length === 0) {
            this.$refs.SweetAlert.showWarning("Sin Cantidades a Devolver Agregadas");
            return; 
        }
        this.post({
            url: this.$store.getters.get__url + "/Pharmacy/returns/devolver",
            token: this.$store.getters.get__token,
            params: {
                data: devoluciones,
                idPa: this.patient_id,
                idActo: this.id_medical
            },
        })
        .then((response) => {
            if(response.statusCode ==  200){
                this.dataDev = response.data.data;
                this.dataPdf = response.data.dataPdf;
                this.searchTypePhar();
                this.searchMotive();
            }
        })
        .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
        });
    },

    handleFaltantes() {
        this.faltantes = [];
        Object.values(this.item).forEach(productGroup => {
            productGroup.forEach(product => {
                if (
                    product.quantity_missing &&
                    product.quantity_missing !== '0' &&
                    product.quantity_missing !== '' &&
                    product.quantity_missing !== null &&
                    product.quantity_missing !== undefined
                ) {
                    this.faltantes.push({
                        idProduct: product.product_id,
                        quantity_missing: product.quantity_missing,
                        recipeDetail_id: product.recipeDetail_id,
                        return_id: product.return_id
                    });
                }
            });
        });
        //console.log(this.faltantes);
        this.post({
            url: this.$store.getters.get__url + "/Pharmacy/returns/faltantesCreate",
            token: this.$store.getters.get__token,
            params: {
                data: this.faltantes,
                idPa: this.patient_id,
                idActo: this.id_medical
            },
        })
        .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
        });
    },



/*     checkInvalidSum() {
        //Acepte todo el total a devolver
        return Object.values(this.item).some(productGroup => {
            const quantityDevSum = productGroup.reduce((sum, product) => sum + (parseInt(product.quantity_dev) || 0), 0);
            if (quantityDevSum !== productGroup[0].total) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    html:   `La suma de Cant. a Devolver es <strong>${quantityDevSum}</strong> <br>
                            en <strong>${productGroup[0].detail}</strong> <br>
                            no coincide con el total: <strong>${productGroup[0].total}</strong>`
                });
                return true;
            }
            return false;
        });
    }, */

    checkInvalidSum() {
        return Object.values(this.item).some(productGroup => {
          const hasQuantityDev = productGroup.some(product => product.quantity_dev && product.quantity_dev !== "");
          if (!hasQuantityDev) {
              Swal.fire({
                  icon: 'warning',
                  title: 'Advertencia',
                  html: `En el grupo de Productos <strong>${productGroup[0].detail}</strong>, ningúna Marca tiene <strong>Cant. Devolver</strong>`
              });
              return true;
          }


          const quantityDevSum = productGroup.reduce((sum, product) => sum + (parseInt(product.quantity_missing) || 0), 0);
          if (quantityDevSum !== productGroup[0].total_faltante) {
              Swal.fire({
                 icon: 'warning',
                  title: 'Advertencia',
                  html:   `La suma de <strong>Cant. Faltantes (${quantityDevSum})</strong> <br>
                          no coincide con <strong style="color: red;">Cant. Total Faltante (${productGroup[0].total_faltante})</strong><br>
                          en <strong>${productGroup[0].detail}</strong>`
              });
              return true;
          }
          return false;
        });
    },
    
    searchTypePhar: function () {
        this.servicesType = [];
        this.get({
          url: this.$store.getters.get__url + "/Pharmacy/returns/searchTypePhar",
          token: this.$store.getters.get__token,
        })
        .then((response) => {
            this.servicesType = response.data;
        })
        .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
        });
    },


    searchMotive: function () {
        this.servicesMotive = [];
        this.get({
          url: this.$store.getters.get__url + "/Pharmacy/returns/searchMotivePhar",
          token: this.$store.getters.get__token,
        })
        .then((response) => {
            this.servicesMotive = response.data;
        })
        .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
        });
      
    },

    registrarDev() {
        Swal.fire({
            icon: "warning",
            title: "¿Está seguro de realizar la Devolución?",
            confirmButtonText: "Permitir",
            confirmButtonColor: "#3085d6",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            cancelButtonText: "Cerrar",
        }).then((result) => {
            if (result.isConfirmed) {
            this.post({
                  url: this.$store.getters.get__url + "/Pharmacy/returns/registerDev",
                  token: this.$store.getters.get__token,
                  params: {
                  data: this.dataPdf,
                  patient_id: this.patient_id,
                  motivo: this.motivo,
                  type: this.type,
                  selectedItems: this.selectedItems
                },
            })
            .then((response) => {
                if(response.data.state == 0){
                  this.motivo = "",
                  this.type = "",
                  this.idNote = response.data.allNoteCreditIds;
                  this.idNote.forEach(id => {
                      window.open(
                          this.$store.getters.get__url + "/viewpdfNoteCreditInt/" + id,
                          "_blank"
                      );
                  });
                  this.$refs.SweetAlert.showSuccess("Devolucion Registrada Correctamente");
                  //Faltantes
                  this.handleFaltantes();
                  //Fin Faltantes
                  this.$emit("updateDev", this.id_medical, this.typeAc);
                  this.mtdClose();
                }else{
                  this.$refs.SweetAlert.showError("Error");
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
                this.clearDataPdf();
            });
            }
        });
    },


  },

};
</script>
<style scoped>
#table-detalle {
  margin-top: 20px;
}

.table-responsive {
  overflow-x: auto;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table th,
.table td {
  border: 1px solid #dee2e6;
  padding: 10px;
}

.bg-main {
  background-color: #900052 !important;
}

.text-white {
  color: #fff !important;
}

.text-muted {
  color: #6c757d !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.custom{
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  background-color: #900052;
  margin-bottom: 5px;
}

.btn-custom {
    background-color: #900052;
    font-weight: bold;
    font-size: 16px;
}

/*Linea de Separacion*/
.vertical-line {
    border-left: 1px solid #900052; 
}


@media (max-width: 991px) {
    .vertical-line {
        border-left: none;
        border-top: 1px solid #900052;
    }
}

.guardar-btn {
  background-color: #ee7d8b;
  border-color: #ee7d8b;
  font-weight: bold; 
  color: #fff;
}

.totals {
  border-radius: 2px; 
  font-size: 0.75rem; 
  padding: 0.25rem 0.5rem; 
  height: auto; 
}
</style>

