import { render, staticRenderFns } from "./Uci.vue?vue&type=template&id=3ac36146&scoped=true"
import script from "./Uci.vue?vue&type=script&lang=js"
export * from "./Uci.vue?vue&type=script&lang=js"
import style0 from "./Uci.vue?vue&type=style&index=0&id=3ac36146&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ac36146",
  null
  
)

export default component.exports