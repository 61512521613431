<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true" :fullScreen="true">
    <div class="row mx-0 px-0">
        <div>
            <ul class="nav nav-tabs " id="myTab" role="tablist">
                <li v-for="(item, index) in ordendeservicio" :key="index" class="nav-item px-1 py-0 w-auto text-xs" role="presentation">
                    <button @click="selectOc(index)" :class="['item-button', 'nav-link', { active: index === posOc }]" :id="'tab-' + index" type="button">
                        {{ item.cabecera.proveedor_name}}
                    </button>
                </li>
            </ul>
            <div class="tab-content" id="" v-if="tablaDetail.length > 0">
                <div v-for="(item, index) in tablaDetail" :key="index">
                    <div class=" row mx-0 px-0 modal-title mt-4 pt-2" id="exampleModalLabel">
                        <div class=" row mx-0 px-0 col-xl-12">
                            <div class="col-1 d-none  d-xl-inline ">
                                <div class=" text-center bg-main rounded-circle w-50 py-3 d-flex align-items-center justify-content-center">
                                    <i class="fas fa-shopping-cart text-white"></i>
                                </div>
                            </div>
                            <div class="bg-white col-xl-5 col-md-12 px-1 mx-xl-0 mx-md-2 align-items-center ">
                                <div class="row d-flex">
                                    <div class="col-auto ">
                                        <label for="observaciones" class="col-form-label text-xs">OBSERVACIONES:</label>
                                    </div>
                                    <div class="col px-0 mb-2">
                                        <input v-model="item.cabecera.observation" id="observacion" class="form-control  form-control-sm" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-5 col-md-12 px-1">
                                <div class="row d-flex align-items-center mx-xl-2">
                                    <div class="col-lg-4 col-md-2">
                                        <label for="observaciones" class="col-form-label text-xs">CONDICIÓN DE CONTRATO:</label>
                                    </div>
                                    <div :class="item.cabecera.payment_condition == 1 ? 'col-md-6' : 'col-md-8'">
                                        <select v-model="item.cabecera.payment_condition" class="form-control form-control-sm" aria-label=".form-select-sm example">
                                            <option value="" selected disabled>Seleccione...</option>
                                            <option v-for="(item,index) in optionCondition" :key="index" :value="item.id">{{ item.name }}</option>
                                        </select>
                                    </div>
                                    <div :class="item.cabecera.payment_condition == 1 ? 'col-md-2' : ''" v-show="item.cabecera.payment_condition == 1">
                                        <div class="bg-white d-flex me-2 responsive-contador">
                                            <div>DIAS</div>
                                            <div class="ms-2">
                                                <input v-model="item.cabecera.expiration_days"  @keydown="filterKey" class="form-control form-control-sm costo-de-envio" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="px-3 mt-4">
                        <div class=" table-responsive" id="div-tabla-anular">
                            <table id="tb-anular" class="table table-bordered text-center" aria-describedby="mydesc">
                                <caption class="py-0 my-0"></caption>
                                <thead class="bg-main text-xs">
                                    <tr class="text-white text-center" style="vertical-align: middle">
                                        <th scope="col">N°</th>
                                        <th scope="col" colspan="1">SERVICIO ESPECIFICO</th>
                                        <th scope="col" colspan="1">CANTIDAD</th>
                                        <th scope="col" colspan="1">PRECIO SIN S/IGV</th>
                                        <th scope="col" colspan="1">PREC. UNIT. SIN S/IGV</th>
                                        <th scope="col" colspan="1">SUBTOTAL</th>
                                        <th scope="col" colspan="1">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main text-xs align-middle">
                                    <tr class="tbody-table1 text-center" v-for="(carro, ind) in item.detalle" :key="ind">
                                        <td>
                                            {{ ind + 1 }}
                                        </td>
                                        <td>
                                            {{ carro.servicio }}
                                        </td>
                                        <td v-if="posEditQuantity1 != ind" @dblclick="mtdEditQuantity(index, ind, 'cantidad')"
                                        :class="{'text-red-500': !carro.quantity || carro.quantity == 0 || carro.quantity == '0.00'}">
                                            {{ carro.quantity }}
                                        </td>
                                        <td v-else>
                                            <input id="input-cantidad" type="text" v-model="carro.editQuantity" @keyup.enter="
                                            mtdOutFocusEditPrice(index, ind, 'cantidad')" @blur="mtdOutFocusEditPrice(index, ind, 'cantidad')" class="form-control form-control-sm input-cantidad text-center " placeholder="CANTIDAD" />
                                        </td>
                                        <td v-if="posEditQuantity != ind" @dblclick="mtdEditQuantity(index, ind, 'Precio')"
                                        :class="{'text-red-500': !carro.precio_purchase || carro.precio_purchase == 0 || carro.precio_purchase == '0.00'}">
                                            {{ carro.precio_purchase }}
                                        </td>
                                        <td v-else>
                                            <input id="input-cantidad" type="text" class="form-control form-control-sm input-cantidad" style="text-align: center" placeholder="PRECIO UNITARIO" v-model="carro.editPrecio" @keyup.enter="
                                            mtdOutFocusEditPrice(index, ind, 'Precio')
                                            " @blur="mtdOutFocusEditPrice(index, ind, 'Precio')" />
                                        </td>
                                        <td>
                                            {{ carro.precio_purchase}}
                                        </td>
                                        <td>
                                            {{ calculateSubtotal(index, ind)}}
                                        </td>
                                        <td class="div-pointer">
                                            <a id="btn-remove" @click="mtdBeforeDelete(index, ind)" style="font-size: 15px">
                                                <i class="fas fa-trash-alt"></i>
                                            </a>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="row d-flex mt-3 justify-content-end text-end px-2">
                            <div class="px-2 py-1 bg-white">
                                VALOR VENTA S/: {{ mtdValor() }}
                            </div>
                            <div class="px-2 py-1 bg-white">IGV S/: {{ mtdGetIgv() }}</div>
                            <div class="px-2 py-1 mx-1 bg-white">
                                TOTAL C/ IGV: {{ mtdGetTotal() }}
                            </div>
                        </div>

                    </div>
                    <div class="row d-flex mt-2 px-3">
                        <div class="col-sm-12 my-1">
                            <div id="div-story" class="form-group row align-left">
                                <label for="story" class="col-sm-12 col-md-12 color-main fw-bold">COMENTARIO</label>
                                <div class="col-xl-6 col-sm-12 col-md-8 mt-2">
                                    <textarea rows="3" v-model="item.cabecera.comment" placeholder="Comentario" class="form-control text-xs" aria-label="With textarea" id="story"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="mt-1">
                            <button @click="mtdPreview()" :disabled="cpDisabled" type="button" class="px-3 btn-oc border-0 text-white bg-main">
                                ENVIAR
                            </button>
                            <div class="text-xxs text-danger mt-2" v-show="cpDisabled">
                                <span>Hay precios de 0.00</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert"/>

</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
    name: "c-logistica-modals-cmodalviewcarritoordenservicio",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            posOc: 0,
            tablaDetail: [],
            swal: "",
            optionCondition: [{
                    "id": 1,
                    "name": 'Crédito'
                },
                {
                    "id": 2,
                    "name": 'Contado'
                },
            ],
            posEditQuantity1: null,
            posEditQuantity: null,
            posEditDiscount: null,
            selectOcExecuted: false
        };
    },
    watch: {
        numcarrito: {
            handler: function (newValue, oldValue) {
                if (newValue > 0) {
                    this.selectOc(newValue - 1);

                }
            },
            deep: true,
            immediate: true,
        }
    },
    props: {
        title: String,
        boo_modal: Boolean,
        ordendeservicio: {
            type: Array,
            default: () => [],
        },
        numcarrito: {
            type: Number,
            default: 0
        }
    },
    computed: {
        cpDisabled() {
            return this.ordendeservicio.some(element =>
                element.detalle.some(detail => detail.price_purchase === '0.00')
            );
        }
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdClose() {
            this.$emit("closeModalCarrito");
        },
        mtdPreview: function () {
            let isValid = this.ordendeservicio.every(element => {
                const { payment_condition, expiration_days } = element.cabecera;
                return payment_condition !== "" && 
                (payment_condition == 1 ? (expiration_days !== "" && expiration_days !== null) : payment_condition == 2);
            });

            if (isValid) {
                this.$emit("closeModalCarrito", 'preview', this.money);
            } else {
                this.$refs.SweetAlert.showWarning('Completar información sobre la condición de contrato');
            }

        },

        selectOc: function (pos) {
            if (pos >= 0 && pos < this.ordendeservicio.length) {
                this.tablaDetail = [];
                this.posOc = pos;
                this.tablaDetail.push(this.ordendeservicio[this.posOc]);

            }

        },
        mtdEditQuantity: function (index, pos, edit) {
            if (edit === "Precio") {
                this.posEditQuantity = pos;
                this.posEditQuantity1 = -1;
                // Inicializa editPrecio con el valor actual
                this.tablaDetail[index].detalle[pos].editPrecio =
                    this.tablaDetail[index].detalle[pos].precio_purchase;
            } else if (edit === "cantidad") {
                this.posEditQuantity1 = pos;
                this.posEditQuantity = -1;
                // Inicializa editQuantity con el valor actual
                this.tablaDetail[index].detalle[pos].editQuantity =
                    this.tablaDetail[index].detalle[pos].quantity;
            } else if (edit === 'Descuento') {
                this.posEditDiscount = pos;
                this.posEditQuantity1 = -1;
                this.tablaDetail[index].detalle[pos].editDiscount =
                    this.tablaDetail[index].detalle[pos].discount;
            }
        },

        mtdOutFocusEditPrice(ind, index, field) {
            if (index >= 0 && index < this.tablaDetail[ind].detalle.length) {
                if (field === "Precio") {
                    const newPrecio = parseFloat(
                        this.tablaDetail[ind].detalle[index].editPrecio
                    );
                    if (!isNaN(newPrecio)) {
                        this.tablaDetail[ind].detalle[index].precio_purchase =
                            newPrecio.toFixed(2);
                    }
                    // this.mtdSelectUnit(ind, index);
                } else if (field === "cantidad") {
                    const newQuantity = parseInt(
                        this.tablaDetail[ind].detalle[index].editQuantity
                    );
                    if (!isNaN(newQuantity)) {
                        this.tablaDetail[ind].detalle[index].quantity = newQuantity;
                    }
                    // this.mtdSelectUnit(ind, index);
                } else if (field === 'Descuento') {
                    const newDiscount = parseInt(this.tablaDetail[ind].detalle[index].editDiscount);
                    if (!isNaN(newDiscount)) {
                        this.tablaDetail[ind].detalle[index].discount = newDiscount.toFixed(2);
                    }
                }
                this.calculateSubtotal(ind, index);
                this.posEditDiscount = -1;
                this.posEditQuantity = -1;
                this.posEditQuantity1 = -1;
            }
        },
        calculateSubtotal(ind, index) {
            const precioUnitario =
                parseFloat(this.tablaDetail[ind].detalle[index].precio_purchase) || 0;
            const cantidad =
                parseInt(this.tablaDetail[ind].detalle[index].quantity) || 0;
            const subtotal = precioUnitario * cantidad;
            this.tablaDetail[ind].detalle[index].price_purchase = subtotal.toFixed(2);
            return subtotal.toFixed(2);
        },
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        // mtdGetTotal: function () {
        //     if (this.ordendecompra.length > 0) {
        //         let valor = 0;
        //         let valornogravada = 0;
        //         this.ordendecompra[this.posOc].detalle.forEach((element) => {
        //             if (element.cargado == 0) {
        //                 valornogravada += parseFloat(element.discount !== null ? element.price_discount : element.price_purchase);
        //             }
        //             if (element.cargado == 1) {
        //                 valor += parseFloat(element.discount !== null ? element.price_discount : element.price_purchase);
        //             }
        //         });
        //         let igv = valor * 0.18;
        //         let total =
        //             parseFloat(valor) + parseFloat(igv) + parseFloat(valornogravada);
        //         return parseFloat(total).toFixed(2);
        //     } else {
        //         return parseFloat(0.00).toFixed(2);
        //     }
        // },
        mtdGetTotal: function () {
            if (this.ordendeservicio.length > 0) {
                let valor = 0;
                this.ordendeservicio[this.posOc].detalle.forEach((element) => {
                    valor += parseFloat(element.price_purchase);
                });
                let igv = valor * 0.18;
                let total = parseFloat(valor) + parseFloat(igv);
                this.ordendeservicio[this.posOc].cabecera.total = parseFloat(total).toFixed(2)
                return parseFloat(total).toFixed(2);
            } else {
                return parseFloat(0.00).toFixed(2);
            }
        },
        mtdValor: function () {
            if (this.ordendeservicio.length > 0) {
                let valor = 0;
                this.ordendeservicio[this.posOc].detalle.forEach((element) => {

                    valor += parseFloat(element.price_purchase);

                });
                this.ordendeservicio[this.posOc].cabecera.total_sin_igv = parseFloat(valor).toFixed(2)
                return parseFloat(valor).toFixed(2);
            } else {
                return parseFloat(0.00).toFixed(2);
            }
        },

        // mtdGetIgv: function () {
        //     if (this.ordendecompra.length > 0) {
        //         let valor = 0;
        //         this.ordendecompra[this.posOc].detalle.forEach((element) => {
        //             if (element.cargado == 1) {
        //                 valor += parseFloat(element.discount !== null ? element.price_discount : element.price_purchase);
        //             }
        //         });
        //         let igv = parseFloat(valor * 0.18);
        //         return parseFloat(igv).toFixed(2);
        //     } else {
        //         return parseFloat(0.00).toFixed(2);
        //     }
        // },
        mtdGetIgv: function () {
            if (this.ordendeservicio.length > 0) {
                let valor = 0;
                this.ordendeservicio[this.posOc].detalle.forEach((element) => {
                    valor += parseFloat(element.price_purchase);
                });
                let igv = parseFloat(valor * 0.18);
                this.ordendeservicio[this.posOc].cabecera.igv = parseFloat(igv).toFixed(2)
                return parseFloat(igv).toFixed(2);
            } else {
                return parseFloat(0.00).toFixed(2);
            }
        },
        mtdBeforeDelete: function (index, ind) {

            if (this.tablaDetail[index].detalle.length === 1) {
                if (this.ordendeservicio.length === 1) {
                    this.ordendeservicio.splice(this.posOc, 1);
                    this.tablaDetail.splice(index, 1);
                    this.mtdClose();
                } else {
                    this.ordendeservicio.splice(this.posOc, 1);
                    this.tablaDetail.splice(index, 1);
                    if (this.posOc - 1 < 0) {
                        this.selectOc(this.posOc)
                    } else {
                        this.selectOc(this.posOc - 1)
                    }
                }
            } else {
                this.tablaDetail[index].detalle.splice(ind, 1);
            }
        },

    },

};
</script>

<style scoped>
#btn-remove {
    color: #ff0000;
    font-size: 25px;
    margin: 0px 2px;
}

#btn-remove:hover {
    color: #a30404;
}

.text-red-500 {
    background-color: #febbbb;
}
</style>
