<template>
<CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :full-screen="true" :booTitile="true">
    <div class="row mx-0 px-0">
        <div>
            <ul class="nav nav-tabs " id="myTab" role="tablist">
                <li v-for="(item, index) in orden" :key="index" class="nav-item px-1 py-0 w-auto text-xs" role="presentation">
                    <button @click="selectOc(index)" :class="['item-button', 'nav-link', { active: index === posOc }]" :id="'tab-' + index" type="button">
                        {{ item.cabecera.name}}
                    </button>
                </li>
            </ul>
            <div class="tab-content px-5" id="" v-if="tablaDetail">
                <div class="col-lg-12 mt-2 px-2">
                    <div class="row d-flex text-xs">
                        <div class="col-lg-6 col-md-6 mb-1">
                            <div class="header-preview py-2">
                                PROVEEDOR
                            </div>
                            <div class="row d-flex py-0 px-4 div-contacto" v-for="(item, index) in proveedor" :key="index">
                                <div class="col-4">
                                   <b>{{ item.label }}:</b> 
                                </div>
                                <div class="col-8">
                                    {{ item.value }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="header-preview py-2">
                                DIRECCIÓN DEL RECEPTOR
                            </div>
                            <div class="row d-flex py-2 px-4 div-contacto">
                                <div class="col-md-4 text-left">
                                    <b> CONTACTO: </b>
                                </div>
                                <div class="col-md-8 text-left">
                                    {{user}}
                                </div>
                                <div class="col-md-4 text-left">
                                    <b> DIRECCIÓN: </b>
                                </div>
                                <div class="col-md-8 text-left">
                                    Av. Santa Victoria N° 416,Chiclayo - Lambayeque
                                </div>
                                <!-- <div class="col-md-4 text-left">
                                    <b>EMAIL:</b>
                                </div>
                                <div class="col-md-8 text-left">
                                    cmerino@bmclinica.com
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <table class="w-100 table table-bordered text-center text-xs my-table-oc">
                        <thead>
                            <tr class="">
                                <th>SOLICITADO POR</th>
                                <th>CONDICION DE PAGO</th>
                                <th>OBSERVACIONES</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ user }}</td>
                                <td>{{ tablaDetail.cabecera ? tablaDetail.cabecera.payment_condition == 2 ? 'CONTADO' :'CREDITO '+tablaDetail.cabecera.expiration_days+' DIAS' : "-"  }}</td>
                                <td>{{ tablaDetail.cabecera ? tablaDetail.cabecera.observation : "-"  }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-lg-12 mt-4 text-xs">
                    <table class="w-100 table table-bordered text-center my-table-oc">
                        <thead>
                            <tr>
                                <th>NOMBRE PRODUCTO</th>
                                <th>MARCA</th>
                                <th>GRAVADO</th>
                                <th>CANTIDAD</th>
                                <th>S/ P. UNIT</th>
                                <th>S/ TOTAL</th>
                                <th>TOTAL CON DESC.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(serv, index) in tablaDetail.detalle" :key="index+'1'">
                                <td>{{ serv.commercial }}</td>
                                <td>{{ serv.brand }}</td>
                                <td>{{ serv.grabado ? 'GRABADO' : 'NO GRABADO' }}</td>
                                <td>{{serv.quantity}}</td>
                                <td>{{ serv.price_purchase}}</td>
                                <td>{{ mtdSubTotal(serv) }}</td>
                                <td>{{ serv.price_discount }}</td>
                            </tr>
                            <tr>
                                <td colspan="6" class="os-total"><b>SUBTOTAL: </b>&nbsp;</td>
                                <td>S/. {{ mtdValor() }}</td>
                            </tr>
                            <tr>
                                <td colspan="6" class="os-total"><b>IGV (18%): </b>&nbsp;</td>
                                <td>S/.{{ mtdGetIgv() }}</td>
                            </tr>
                            <tr>
                                <td colspan="6" class="os-total"><b>TOTAL: </b>&nbsp;</td>
                                <td>S/. {{ mtdGetTotal() }}</td>
                            </tr>
                            <tr>
                                <td colspan="6" class="os-total" ><b>COSTO DE ENVIO: </b>&nbsp;</td>
                                <td>S/. {{ tablaDetail.cabecera ? parseFloat(tablaDetail.cabecera.send).toFixed(2) : "0.00"  }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row d-flex border border-main mx-0 mt-4">
                    <div class="col-md-4 col-lg-2">
                        <span>COMENTARIO:</span>
                    </div>
                    <div class="col-md-8 col-lg-10">
                        <p>
                            {{ tablaDetail.cabecera ?  tablaDetail.cabecera.comment : "-"}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <template #footer>
        <button @click="mtdback" class="btn btn-info btn-sm">
            <i class="fa fa-arrow-left" />&nbsp;Volver
        </button>
        &nbsp;
        <button class="btn btn-success btn-sm" @click="mtdstoredata">
            <i class="fas fa-save" />&nbsp; Registrar
        </button>
        &nbsp;
    </template>
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";

import {
    mapActions
} from "vuex";

export default {
    name: "c-logistica-preview",
    components: {
        CModal,
    },
    data() {
        return {
            tablaDetail: [],
            posOc: 0,
            user: "",
            proveedor: [{
                    label: "RAZON SOCIAL",
                    value: ""
                },
                {
                    label: "RUC",
                    value: ""
                },
                {
                    label: "DIRECCION",
                    value: ""
                },
                {
                    label: "TELEFONO",
                    value: ""
                },
                {
                    label: "EMAIL",
                    value: ""
                },
            ]

        };
    },
    watch: {
        orden: {
            handler: function (newValue, oldValue) {
                if (newValue.length > 0) {
                    this.selectOc(0);
                this.user = this.$store.getters.get__user.name + ' ' + this.$store.getters.get__user.last_name;

                }
            },
            deep: true,
            immediate: true,

         }
    },
    props: {
        title: String,
        boo_modal: Boolean,
        orden: Array,
    },
    computed: {

    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            this.$emit('mtdclosemodal');
        },
        selectOc: function (pos) {
            if (pos >= 0 && pos < this.orden.length) {
                this.tablaDetail = {};
                this.posOc = pos;
                this.tablaDetail = this.orden[this.posOc];

                //proveedor
                let data = this.tablaDetail.cabecera;
                if (data) {
                    this.proveedor = [{
                            label: "RAZON SOCIAL",
                            value: data.name
                        },
                        {
                            label: "RUC",
                            value: data.ruc
                        },
                        {
                            label: "DIRECCION",
                            value: data.address
                        },
                        {
                            label: "TELEFONO",
                            value: data.phone
                        },
                        {
                            label: "EMAIL",
                            value: data.email
                        },
                    ];

                }

            }

        },
        mtdGetTotal: function () {
            if (this.orden.length > 0) {
                let valor = 0;
                let valornogravada = 0;
                let valor2 = 0;
                let valornogravada2 = 0;
                this.orden[this.posOc].detalle.forEach((element) => {
                    if (element.cargado == 0) {
                        let precio_sin_igv = element.price_purchase/1.18;
                        let precioDes_sin_igv = element.price_discount/1.18;
                        //valor2 += parseFloat(element.discount !== null ? precioDes_sin_igv : precio_sin_igv);
                        valornogravada2 += parseFloat(element.discount !== null ? precioDes_sin_igv : precio_sin_igv);
                        valornogravada =  valornogravada2;
                    }
                    if (element.cargado == 1) {
                        valor2 += parseFloat(element.discount !== null ? element.price_discount : element.price_purchase);
                        valor = valor2;
                    }
                });
                let igv = valor * 0.18;
                let total =
                    parseFloat(valor) + parseFloat(igv) + parseFloat(valornogravada);
                    //parseFloat(valor) +  parseFloat(valornogravada);
                return parseFloat(total).toFixed(2);
            } else {
                return parseFloat(0.00).toFixed(2);
            }
        },
        mtdValor: function () {
            if (this.orden.length > 0) {
                let valor = 0;
                let valor2 = 0;
                this.orden[this.posOc].detalle.forEach((element) => {
                    if (element.cargado == 1) {
                        valor2 += parseFloat(element.discount !== null ? element.price_discount : element.price_purchase);
                    }
                });
                valor = valor2;
                return parseFloat(valor).toFixed(2);
            } else {
                return parseFloat(0.00).toFixed(2);
            }
        },
        mtdGetIgv: function () {
            if (this.orden.length > 0) {
                let valor = 0;
                let valor2 = 0;
                this.orden[this.posOc].detalle.forEach((element) => {
                    if (element.cargado == 1) {
                        valor2 += parseFloat(element.discount !== null ? element.price_discount : element.price_purchase);
                    }
                });
                //valor = valor2 * this.money.type;
                //valor = valor2;
                let igv = (valor2*0.18).toFixed(2);
                return parseFloat(igv).toFixed(2);
            } else {
                return parseFloat(0.00).toFixed(2);
            }
        },
        mtdSubTotal: function (item){
            let serv = item.quantity * (item.precio_purchase);
            return serv.toFixed(2);
        },
        mtdstoredata() {
            this.$emit('mtdstoredata');
        },
        mtdback(){
            this.$emit('mtdOpenModalCarrito');
        }
    },
};
</script>
