<template>
<div class="container">
    <div class="row">
        <div class="col-md-12 d-md-block d-lg-none">
            <button type="button" class="btn text-white btn-sm bg-main mt-2 text-xs w-100" @click="mtdstoredata()" v-if="rolname == 'DOCTOR(A)'">
                <i class="fas fa-save"></i> GUARDAR
            </button>
        </div>
    </div>
    <div class="row my-3 d-flex">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="d-md-flex gap-1" v-for="(item, index) in informeOperatorio.staff" :key="index">
                <div class="col-lg-8 col-md-8 col-sm-12 col-12 mt-1 align-left">
                    <div class="mb-1">
                        <label :for="'cirujano'+index" class="fw-bold text-xs color-main">{{item.label}}</label>
                        <input type="text" class="form-control input-text" :id="'cirujano'+index" v-model="item.value" :disabled="edit">
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-12 mt-1 align-left" v-if="index !== informeOperatorio.staff.length - 1">
                    <div class="mb-1">
                        <label :for="'cirujano_cmp'+index" class="fw-bold text-xs color-main">{{item.colegio}} N°</label>
                        <input type="text" class="form-control input-text" :id="'cirujano_cmp'+index" v-model="item.codigo" :disabled="edit">
                    </div>
                </div>
            </div>
            <!-- Escala ASA | Categoria -->
            <div class="row d-flex">
                <div class="col-md-6 mt-1 align-left">
                    <div class="mb-1">
                        <label for="escala_asa" class="fw-bold text-xs color-main">Estado físico pre operatorio (Escala
                            ASA)</label>
                        <input type="text" class="form-control input-text" id="escala_asa" v-model="informeOperatorio.asa" :disabled="edit">
                    </div>
                </div>
                <div class="col-md-6 mt-1 align-left">
                    <div class="mb-1">
                        <label for="categoria" class="fw-bold text-xs color-main">Riesgo anestésico quirúrgico (Categoría)</label>
                        <input type="text" class="form-control input-text" id="categoria" v-model="informeOperatorio.category" :disabled="edit">
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
            <!-- horas -->
            <div class="row d-flex">
                <div class="col-md-4 mb-2 align-left" v-for="(item2, index) in hours" :key="index">
                    <div class="">
                        <label :for="'inputh' + index" class="text-xs color-main fw-bold">{{ item2.label }}</label>
                        <input type="time" v-model="item2.value" class="form-control form-control-input form-control-sm" :id="'inputh' + index" @input="mtdCalculateTime" :disabled="edit"/>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="">
                        <label for="time_op" class="text-xs color-main fw-bold">Tiempo Operatorio (min)</label>
                        <input type="text" disabled v-model="informeOperatorio.operating_time" class="form-control form-control-input form-control-sm" id="time_op"/>
                    </div>
                </div>
            </div>
            <!-- diagnostico operatorio -->
            <div class="col-md-12 mt-1 align-left">
                <div class="mb-1">
                    <label for="diag_ope" class="fw-bold text-xs color-main">Diagnóstico Operatorio</label>
                    <textarea class="form-control input-textarea" id="diag_ope" rows="2" style="resize: none;" v-model="informeOperatorio.operative_diagnosis" :disabled="edit"/>
                    </div>
        </div>

        <!-- operacion realizada -->
        <div class="col-md-12 mt-1 align-left">
          <div class="mb-1">
            <label for="ope_realizada" class="fw-bold text-xs color-main">Operación realizada</label>
            <textarea class="form-control input-textarea" id="ope_realizada" rows="2" style="resize: none;"
              v-model="informeOperatorio.operation" :disabled="edit" />
          </div>
        </div>

        <!-- hallazgos-procedimientos -->
        <div class="col-md-12 mt-1 align-left">
          <div class="mb-1">
            <label for="hallazgos_procedimientos" class="fw-bold text-xs color-main">Hallazgos y
              procedimientos</label>
            <textarea class="form-control input-textarea" id="hallazgos_procedimientos" rows="3" style="resize: none;"
              v-model="informeOperatorio.findings" :disabled="edit"/>
          </div>
        </div>
        <!-- observaciones -->
        <div class="col-md-12 mt-1 align-left">
          <div class="mb-1">
            <label for="obs" class="fw-bold text-xs color-main">Observaciones(Complicaciones Quirúrgica)</label>
            <textarea class="form-control input-textarea" id="obs" rows="2" style="resize: none;"
              v-model="informeOperatorio.observations" :disabled="edit" />
          </div>
        </div>
        <!-- Anatomía patológica -->
        <div class="col-md-12 mt-1 align-left">
          <div class="mb-1">
            <label for="anatomia_pato" class="fw-bold text-xs color-main">Anatomía patológica</label>
            <input type="text" class="form-control input-text" id="anatomia_pato"
              v-model="informeOperatorio.pathological_anatomy" :disabled="edit">
          </div>
        </div>
        <!-- Diagnostico post operatorio -->
        <div class="col-md-12 mt-1 align-left">
          <div class="mb-1">
            <label for="diag_post_ope" class="fw-bold text-xs color-main">Diagnóstico post. operatorio</label>
            <textarea class="form-control input-textarea" id="diag_post_ope" rows="2" style="resize: none;"
            v-model="informeOperatorio.postoperative_diagnosis" :disabled="edit"/>
          </div>
        </div>
        <!-- <button @click="enviarDatos">enviar</button> -->
      </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />

  </div>
</template>

<script>
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from 'vuex'
export default {
    name: 'c-asistencial-sop-historia-informeoperatorio',
    components: {
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            edit: false,
            hours: [{
                    id: 1,
                    label: "Hora de Inicio",
                    value: "",
                },
                {
                    id: 2,
                    label: "Hora de Termino",
                    value: "",
                },
            ],
            informeOperatorio: {
                staff: [
                    //cada uno de los campos del staff
                    {
                        label: 'Cirujano',
                        value: '',
                        colegio: 'CMP',
                        codigo: "",
                    },
                    {
                        label: '1er Asistente',
                        value: '',
                        colegio: 'CMP',
                        codigo: "",
                    },
                    {
                        label: '2do Asistente',
                        value: '',
                        colegio: 'CMP',
                        codigo: "",
                    },
                    {
                        label: 'Anestesiólogo',
                        value: '',
                        colegio: 'CMP',
                        codigo: "",
                    },
                    {
                        label: 'Enf. Inst.',
                        value: '',
                        colegio: 'CEP',
                        codigo: "",
                    },
                    {
                        label: 'Enf o Téc Cir.',
                        value: '',
                        colegio: 'CEP',
                        codigo: "",
                    },
                    {
                        label: 'Tipo de Anestesia',
                        value: '',
                    }],
                asa: '',
                category: '',
                operating_time: '',
                operative_diagnosis: '',
                operation: '',
                findings: '',
                observations: '',
                pathological_anatomy: '',
                postoperative_diagnosis: "",
            },
        }
    },
    props: {
        medical_act_id: {
            type: Number,
            default: 0,
        },
        addReportSop: {
            type: Number,
            default: 0,
        },
        rolname: String,
    },
    watch: {
        medical_act_id: {
            handler: function (newValue) {
                if (newValue !== 0) {
                    this.mtdViewReport();
                }
            },
            deep: true,
            immediate: true,
        },
        addReportSop: {
            handler: function (newValue) {
                if (newValue == 1) {
                    this.mtdstoredata()
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {},
    methods: {
        ...mapActions(['get', 'post']),
        mtdCalculateTime() {
            let calcule = 0;
            if (!this.hours[0].value || !this.hours[1].value) calcule = 0; // Verifica que ambas fechas existan

            const inicio = new Date(`1970-01-01T${this.hours[0].value}`);
            const termino = new Date(`1970-01-01T${this.hours[1].value}`);

            const diferenciaMilisegundos = termino.getTime() - inicio.getTime();
            calcule = Math.round(diferenciaMilisegundos / (1000 * 60));

            if (calcule < 0) {
                this.$refs.SweetAlert.showWarning('Fecha no permitida');
                this.informeOperatorio.operating_time = "";
                this.hours[0].value = "";
                this.hours[1].value = "";
            } else {
                
                this.informeOperatorio.operating_time = isNaN(calcule) ? "" : calcule;
            }
        },
        mtdstoredata() {
            //registrar el order sop
            this.post({
                    url: this.$store.getters.get__url + "/Sop/storeOperativeReports",
                    token: this.$store.getters.get__token,
                    params: {
                        ...this.informeOperatorio,
                        start_time: this.hours[0].value,
                        end_time: this.hours[1].value,
                        medical_act_id: this.medical_act_id,
                    }
                })
                .then((response) => {
                    this.mtdViewReport();
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.$emit('finishAddOrder');
                })
                .catch((errors) => {
                    console.log(errors);
                });

        },
        mtdViewReport: function () {
            this.edit = this.rolname !== 'DOCTOR(A)' ? true:false;
            this.post({
                    url: this.$store.getters.get__url + "/Sop/preOperatorioView",
                    token: this.$store.getters.get__token,
                    params: {
                        medical_act_id: this.medical_act_id,
                        type: 'report'
                    }
                })
                .then((response) => {
                    let data = response.data.report;
                    if (data) {
                        this.informeOperatorio = data;
                        this.informeOperatorio.staff = JSON.parse(data.staff)
                        this.hours[0].value = response.data.start_time;
                        this.hours[1].value = response.data.end_time;
                    }
                    this.informeOperatorio.staff = response.data.new_name2 ? JSON.parse(response.data.new_name2) :this.informeOperatorio.staff
                })
                .catch((errors) => {
                    console.log(errors);
                });
        }
    },
}
</script>
