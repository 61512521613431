<template>
    <div class="c-app">
        <div class="p-4">
            <div class="row">
                <div id="btn-return" class="d-flex justify-content-end pt-1 mt-2">
                    <div class="btn-home h-100" role="group">
                        <button type="button" class="btn btn-outline-main bg-main text-white" @click="mtdHc">
                            <i class="fas fa-regular fa-notes-medical"></i><br />Agregar N° de HC 
                        </button>
                    </div>&nbsp;
                    <div class="btn-home h-100" role="group">
                        <button type="button" class="btn btn-outline-main bg-main text-white" @click="mtdBackCampus">
                            <i class="fas fa-recycle"></i><br />Cambiar de Sede
                        </button>
                    </div>&nbsp;
                    <div class="btn-home h-100" role="group">
                        <button type="button" class="btn btn-bm-noradius h-100" @click="mtdCloseBead">
                            <i class="fas fa-cash-register"></i><br />Cerrar
                        </button>
                    </div>
                </div>
            </div>
            <!-- Venta -->
            <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12">
                    <div class="text-center p-4">
                        <div class="d-grid gap-2 mx-auto col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <button class="btn btn-lg btn-bm mt-100" @click="generarVenta" type="button">
                                VENTA
                            </button>
                            <button class="btn btn-lg btn-bm" @click="generarEgreso" type="button">
                                EGRESO
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-12 col-sm-6">
                    <div class="row p-1">
                        <div class="col-md-12 col-lg-8 col-xl-6 px-sm-2 px-md-4">
                            <div class="row d-flex">
                                <div id="btn_refresh" class="col-md-12">
                                    <button type="button" @click="mtdCleanClient" class="btn">
                                        <i class="fa fa-repeat"></i>
                                    </button>
                                </div>
                                <div class="col-md-3 p-2 mt-200">
                                    <input class="form-control form-control-lg inpunt-rounded" type="text"
                                        @keypress="isNumber($event)" v-model="client.document"
                                        @keypress.enter="mtdSearchDocument" maxlength="12" placeholder="Documento"
                                        aria-label=".form-control-lg example" />
                                </div>
                                <div class="col-md-9 p-2">
                                    <input class="form-control form-control-lg inpunt-rounded" type="text"
                                        v-model="client.fullName" :disabled="disabledPatient"
                                        placeholder="Nombres y apellidos del paciente"
                                        aria-label=".form-control-lg example" />
                                </div>
                                <div class="col-md-3 p-2">
                                    <input class="form-control form-control-lg inpunt-rounded" type="text"
                                        v-on:keypress="isNumber($event)" v-model="client.celphone" maxlength="9"
                                        placeholder="Teléfono" aria-label=".form-control-lg example" />
                                </div>
                                <div class="col-md-6 p-2">
                                    <input class="form-control form-control-lg inpunt-rounded" type="text"
                                        v-model="client.email" placeholder="Correo del paciente"
                                        aria-label=".form-control-lg example" />
                                </div>
                                <div class="col-md-3 p-2">
                                    <input class="form-control form-control-lg inpunt-rounded" type="text"
                                        v-on:keypress="isNumber($event)" @keyup="mtdVerifEdad" v-model="client.yearOld"
                                        maxlength="3" placeholder="Edad" aria-label=".form-control-lg example" />
                                </div>
                                <div class="col-md-6 p-2">
                                    <button :class="client.type == 'particular'
                                            ? 'form-control form-control-lg inpunt-rounded bg-main text-white'
                                            : 'form-control form-control-lg inpunt-rounded'
                                        " @click="mtdSelectType('particular')" type="button" aria-label=".form-control-lg example">
                                        Particular
                                    </button>
                                </div>
                                <div class="col-md-6 p-2">
                                    <button :class="client.type == 'eps'
                                            ? 'form-control form-control-lg inpunt-rounded bg-main text-white'
                                            : 'form-control form-control-lg inpunt-rounded'
                                        " @click="mtdSelectType('eps')" type="button" aria-label=".form-control-lg example">
                                        Eps
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6"></div>
                        <div class="col-md-6 mt-2 px-sm-2 px-md-4" v-if="client.type == 'eps'">
                            <div id="list-eps" class="row d-flex justify-content-between">
                                <div class="col-auto">
                                    <span v-for="(item, index) in insurances" :key="index" class="badge mb-1 px-2"
                                        :class="client.insurance == item.id
                                                ? 'inpunt-rounded-inverse'
                                                : 'inpunt-rounded'
                                            " @click="mtdSelectEps(item.id)">{{ item.name }}</span>&nbsp;
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mx-2" v-if="client.type == 'eps'">
                            <div id="list-eps" class="d-flex justify-content-evenly">
                                <input type="text" class="form-control form-control-sm w-25 inpunt-rounded"
                                    placeholder="Costo fijo" v-model="client.fijo" v-on:keypress="isNumber($event)" />
                                <input type="text" class="form-control form-control-sm w-25 inpunt-rounded"
                                    placeholder="% CUBRE EPS" v-model="client.copago"
                                    v-on:keypress="isNumber($event)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SweetAlert :swal="swal" ref="SweetAlert" />
        </div>
    </div>
</template>

<script>
import {
    mapActions
} from "vuex";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue"

export default {
    name: 'c-Admision-home',
    props: {
        verifBead: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        SweetAlert
    },
    data() {
        return {
            page: null,
            disabledPatient: true,
            swal: null,
            client: {
                document: "",
                celphone: "",
                email: "",
                fullName: "",
                yearOld: "",
                type: "",
                insurance: null,
                copago: "",
                fijo: "",
            },
            insurances: [],

        }
    },
    created() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData: function () {
            this.get({
                url: this.$store.getters.get__url + "/Admision/listInsurances",
                token: this.$store.getters.get__token,
            })
                .then((response) => {

                    this.insurances = response.data;
                })
                .catch((errors) => { });
        },
        mtdSearchDocument: function () {
            if (this.client.document.length == 8) {
                this.get({
                    url: this.$store.getters.get__url + "/reniec/" + this.client.document,
                    token: this.$store.getters.get__token,
                })
                    .then((response) => {

                        if (response.data.boo == 3) {
                            // this.client.document = "";
                            this.$refs.SweetAlert.showWarning("DNI no encontrado");
                            this.disabledPatient = false;
                            this.client.fullName = "";
                        } else {
                            this.client.fullName = response.data.name + " " + response.data.last_name;
                            if (response.data.hasOwnProperty("patient")) {
                                this.client.celphone = response.data.patient.phone;
                                this.client.email = response.data.patient.email;
                                this.client.yearOld = response.data.patient.year;
                            } else {
                                this.client.celphone = "";
                                this.client.email = "";
                                this.client.yearOld = "";
                                this.disabledPatient = true;
                            }
                        }
                    })
                    .catch((errors) => {
                        console.log(errors);
                    });
            } else {
                this.disabledPatient = false;
                this.client.celphone = "";
                this.client.email = "";
                this.client.fullName = "";
                this.client.yearOld = "";
            }
        },
        mtdSelectType: function (type) {
            this.client.type = type;
        },
        mtdSelectEps: function (id) {
            this.client.insurance = id;
        },
        mtdCleanClient: function () {
            this.client = {
                document: "",
                celphone: "",
                email: "",
                fullName: "",
                yearOld: "",
                type: "",
                insurance: "",
                copago: "",
                fijo: ""
            };
        },
        mtdVerifClient: function () {
            if (
                this.client.document.trim().length > 0 &&
                // && this.client.celphone.trim().length >0
                // && this.client.email.trim().length > 0
                this.client.fullName.trim().length > 0 &&
                this.client.yearOld &&
                this.client.yearOld.trim().length > 0 &&
                this.client.type.trim().length > 0
            ) {
                // var email = this.client.email.trim();
                // var pos1 = email.indexOf("@"); // verificar que tenga el @
                // if(pos1 != -1){
                //   var pos2 = email.indexOf("gmail.com"); // verificar que tenga el gmail
                //   var pos3 = email.indexOf("hotmail.com"); // verificar que tenga el hotmail
                //   var pos4 = email.indexOf("yahoo.com"); // verificar que tenga el yahoo
                //   if(pos2 != -1 || pos3 != -1 || pos4 != -1){

                return {
                    msg: "Cliente válido.",
                    status: true
                };

                //   }else{
                //     var pos5 = email.indexOf(".edu.pe"); // en caso sea correo institucional estado
                //     var pos6 = email.indexOf(".gob.pe"); // en caso sea correo institucional trabajador del estado
                //     if(pos5 != -1 || pos6 != -1){
                //       return {msg: 'Cliente válido.', status: true};
                //     }else{
                //       return {msg: 'Ingrese un correo válido.', status: false};
                //     }
                //   }
                // }else{
                //   return {msg: 'Ingrese un correo válido.', status: false};
                // }
            } else {
                let msg = ' ';
                if (!this.client.document.trim().length > 0) {
                    msg = "Ingrese datos del cliente."
                } else
                    if (!this.client.yearOld || !this.client.yearOld.length > 0) {
                        msg = 'Ingrese edad';
                    } else if (!this.client.type.trim().length > 0) {
                        msg = 'Escoja un tipo';
                    }
                return {
                    msg: msg,
                    status: false
                };
            }
        },
        generarVenta() {
            if (this.mtdVerifClient().status == true) {
                this.$emit("mtdChangePage", "venta", this.client);
            } else {
                this.$refs.SweetAlert.showWarning(this.mtdVerifClient().msg);
            }
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        generarEgreso() {
            this.$emit("mtdChangePage", "egreso", this.client);
        },
        otrasViews() {
            this.$emit("mtdChangePage", "laboratorio"); // Cambiar por views: 'imagenes', 'topico', 'laboratorio'
        },
        mtdVerifEdad: function () {
            if (this.client.yearOld && this.client.yearOld.length > 0) {
                if (this.client.yearOld > 0 && this.client.yearOld <= 110) { } else {
                    this.client.yearOld = "";
                    Swal.fire({
                        text: "Edad Incorrecta",
                        icon: "warning",
                        confirmButtonColor: "#900052",
                    });
                }
            }
        },
        mtdCloseBead() {

            this.$emit("mtdChangePage", "close");
        },
        mtdHc() {
            this.$emit("mtdChangePage", "Hc");
        },
        mtdBackCampus: function () {
            this.$emit("mtdBackCampus");
        },
    },
}
</script>
