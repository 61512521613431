<template>
<div class="col-md-12" v-if="Object.keys(hoja).length > 0">
    <div class="row mt-2">
        <div class="panel-border my-1 px-0">
            <div class="d-md-flex text-xs">
                <div class="col-md-12 bg-main text-white text-center p-2">
                    <span class="fw-bold">HOJA DE ANESTECIA</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="panel-border my-1">
            <div class="d-md-flex gap-1 p-2">
                <div class="col-md-4 col-sm-12" v-for="(item, index) in hoja.staff" :key="index">
                    <div class="form-group d-md-flex">
                        <label class="color-main fw-semibold me-1">
                            {{ item.label }} :
                        </label>
                        <span>{{ item.value }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="panel-border my-1">
            <div class="d-md-flex gap-1 p-2">
                <!-- OTROS EXAMENES -->
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-12 mb-2 align-left" v-for="(item2, index) in hoja.values" :key="index">
                            <div class="form-group row">
                                <label :for="'inputev' + index" class="text-xs col-xl-4 col-md-12 color-main fw-semibold">{{
                                                item2.label }}</label>
                                <div class="col-xl-8 col-md-12 col-sm-12">
                                    <input type="text" v-model="item2.value" class="form-control form-control-input form-control-sm" :id="'inputev' + index" disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- INUBACION ORAL  -->
                <div class="col-md-6">
                    <div class="row p-2">
                        <div class="col-xl-6 col-md-12 mb-2">
                            <div class="form-group d-flex">
                                <label for="tet" class="col-md-4 col-sm-12 color-main fw-bold text-start">TET</label>
                                <div class="col-xl-8 col-md-7 col-sm-12">
                                    <input type="text" v-model="hoja.inubacion.tet" class="form-control form-control-input form-control-sm" id="tet" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-12">
                            <div class="form-group d-flex">
                                <label for="mm" class="col-md-4 col-sm-12 color-main fw-bold text-start">MM</label>
                                <div class="col-xl-8 col-md-7 col-sm-12">
                                    <input type="text" v-model="hoja.inubacion.mm" class="form-control form-control-input form-control-sm" id="mm" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-12 col-xl-5 mt-2">
                            <div class="form-inline mx-3">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioTipo" id="inlineRadio1" :value="'nasal'" v-model="hoja.inubacion.tipo">
                                    <label class="form-check-label text-md" for="inlineRadio1">Nasal</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioTipo" id="inlineRadio2" :value="'bronquial'" v-model="hoja.inubacion.tipo">
                                    <label class="form-check-label text-md" for="inlineRadio2">Bronquial</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-12 col-xl-7 mt-2">
                            <div class="row">
                                <div class="col-md-12 col-xl-3 text-start">
                                    <span class="color-main text-xs fw-bold ms-2">Dificultad</span>
                                </div>
                                <div class="col-xl-9 col-md-12">
                                    <div class="form-inline mx-1">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="dificultad" id="d1" :value="1" v-model="hoja.inubacion.dificultad">
                                            <label class="form-check-label text-md" for="d1">1</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="dificultad" id="d2" :value="2" v-model="hoja.inubacion.dificultad">
                                            <label class="form-check-label text-md" for="d2">2</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="dificultad" id="d3" :value="3" v-model="hoja.inubacion.dificultad">
                                            <label class="form-check-label text-md" for="d3">3</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="dificultad" id="d4" :value="4" v-model="hoja.inubacion.dificultad">
                                            <label class="form-check-label text-md" for="d4">4</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5 mt-2">
                            <div class="row">
                                <div class="col-sm-12 col-md-4 col-lg-6 text-start">
                                    <span class="color-main text-xs fw-bold">Posicion</span>
                                </div>
                                <div class="col-sm-12 col-md-8 col-lg-6">
                                    <div class="form-inline mx-2">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="dli" :value="'dli'" v-model="hoja.inubacion.posicion">
                                            <label class="form-check-label text-md" for="dli">DLI</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="dlo" :value="'dlo'" v-model="hoja.inubacion.posicion">
                                            <label class="form-check-label text-md" for="dlo">DLO</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-12 col-xl-7 mt-2">
                            <div class="row">
                                <div class="col-md-12 col-xl-4 text-start">
                                    <span class="color-main text-xs fw-bold ms-2">Cateter</span>
                                </div>
                                <div class="col-xl-8 col-md-12">
                                    <div class="form-inline mx-3">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="cateter" id="si" :value="true" v-model="hoja.inubacion.cateter">
                                            <label class="form-check-label text-md" for="si">Si</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="cateter" id="no" :value="false" v-model="hoja.inubacion.cateter">
                                            <label class="form-check-label text-md" for="no">No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
       <!--  {{ hoja }} -->
    </div>
</div>
<div class="px-0" v-else>
    <div class="alert alert-info mt-3" role="alert">
        No se ha encontrado información
    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
export default {
    name: "ReportHojaAnestecia",
    data() {
        return {
            hoja: {},
        };
    },
    watch: {
        medical_act_id: {
            immediate: true,
            handler: function (newValue, oldValue) {
                this.mtdGetData();
            }
        }
    },
    props: {
        medical_act_id: {
            type: Number,
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Sop/intraOperatorioView",
                    token: this.$store.getters.get__token,
                    params: {
                        medical_act_id: this.medical_act_id,
                        type: 'hojaanestecia'
                    }
                })
                .then((response) => {
                    let data = response.data;
                    if (data) {
                        this.hoja = data;
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
    }
};
</script>
