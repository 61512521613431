<template> 
    <div class="py-3">
        <div class="row mx-0">
            <div class="col-xl-2">
                <label for="hospitalReason" class=" text-xss w-100 text-start color-main fw-bold mb-2">OPERACIÓN PROGRAMADA</label>
            </div>
            <div class="col-xl-10">
                <input type="text" v-model="entrevista.operacion_programada" class="form-control form-control-sm" id="exampleFormControlInput1" placeholder="Ingrese operación programada">
            </div>
            <div class="col-xl-12 mt-2">
                <label for="hospitalReason" class=" text-xss w-100 text-start color-main fw-bold mb-2">¿Dónde se realiza la Entrevista en Enfermería?</label>
            </div>
            <div class="col-xl-3 ps-4 " >
                <div class="form-check justify-content-start">
                    <input value="Consultorio_de_Enfermería_Quirúrgica" v-model="entrevista.lugar_entrevista" class="form-check-input" type="radio" name="lugar" id="flexRadioDefault1">
                    <label class="form-check-label"  for="flexRadioDefault">
                        Consultorio de Enfermería Quirúrgica
                    </label>
                </div>
            </div>
            <div class="col-xl-3 ps-4 " >
                <div class="form-check justify-content-start">
                    <input value="Unidad_del_Paciente_Hospitalizado" v-model="entrevista.lugar_entrevista" class="form-check-input" type="radio" name="lugar" id="flexRadioDefault2">
                    <label class="form-check-label"  for="flexRadioDefault2">
                        Unidad del Paciente Hospitalizado
                    </label>
                </div>
            </div>
            <div class="col-xl-3 ps-4 " >
                <div class="form-check justify-content-start">
                    <input value="Emergencia" v-model="entrevista.lugar_entrevista" class="form-check-input" type="radio" name="lugar" id="flexRadioDefault3">
                    <label class="form-check-label"  for="flexRadioDefault3">
                        Emergencia
                    </label>
                </div>
            </div>
            <div class="col-xl-3 ps-4 " >
                <div class="form-check justify-content-start">
                    <input value="Otros" v-model="entrevista.lugar_entrevista" class="form-check-input" type="radio" name="lugar" id="flexRadioDefault4">
                    <label class="form-check-label"  for="flexRadioDefault4">
                        Otros
                    </label>
                </div>
            </div>
            <div class=" mt-3 p-content px-0">
                <div class="col text-center fw-bold py-2 div-title rounded">
                    <span class="text-uppercase">VALORACIÓN DE ENFERMERIA</span>
                </div>
            </div>
            <div class=" row  px-0 mx-0 mt-2">
                <div class=" row  mx-0 col-xl-6 px-0">
                    <div class="card py-2">
                        <div class=" col-xl-12 ">
                            <label for="hospitalReason" class=" text-xss w-100 text-start color-main fw-bold mb-2">1.1 El paciente viene al consultorio acompañado :</label>
                        </div>
                        <div class=" col-xl-12 d-flex px-4 ">
                            <div class="form-check me-4">
                                <input value="si" v-model="entrevista.pariente_entrevista.seleccion" class="form-check-input" type="radio" name="flexRadioDefault01" id="flexRadioDefault">
                                <label class="form-check-label"  for="flexRadioDefault">
                                    Si
                                </label>
                            </div>
                            <div class="form-check">
                                <input value="no" v-model="entrevista.pariente_entrevista.seleccion" class="form-check-input" type="radio" name="flexRadioDefault01" id="flexRadioDefault" checked>
                                <label class="form-check-label" for="flexRadioDefault">
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-xl-12 mt-2 d-flex ">
                            <div class="ps-2 ">
                                <label for="hospitalReason" class=" text-xss  text-start color-main fw-bold mb-2">Especificar :</label>
    
                            </div>
                            <div  class=" w-75 px-3">
                                <input type="text" v-model="entrevista.pariente_entrevista.value" class="form-control form-control-sm " id="exampleFormControlInput1" placeholder="Ingrese Nombre de la persona acompañante">
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-xl-6  px-0 ps-1   ">
                    <div class="card py-2 px-3">
                        <div class="col-xl-12 ">
                            <label for="hospitalReason" class=" text-xss w-100 text-start color-main fw-bold mb-2">1.2 ¿Cómo viene el paciente?</label>
                        </div>
                        <div class="col-xl-4 ps-5" >
                            <div class="form-check  justify-content-start">
                                <input value="Caminando" v-model="entrevista.estado" class="form-check-input" type="radio" name="estado" id="flexRadioDefault">
                                <label class="form-check-label"  for="flexRadioDefault">
                                    Caminando
                                </label> 
    
                            </div>
                        </div>
                        <div class="col-xl-4 ps-5" >
                            <div class="form-check  justify-content-start">
                                <input value=" Sillas_de_Ruedas" v-model="entrevista.estado" class="form-check-input" type="radio" name="estado" id="flexRadioDefault">
                                <label class="form-check-label"  for="flexRadioDefault">
                                    Sillas de Ruedas
                                </label> 
    
                            </div>
                        </div>
                        <div class="col-xl-4 ps-5" >
                            <div class="form-check  justify-content-start">
                                <input value="Muletas" v-model="entrevista.estado" class="form-check-input" type="radio" name="estado" id="flexRadioDefault">
                                <label class="form-check-label"  for="flexRadioDefault">
                                    Muletas
                                </label> 
    
                            </div>
                        </div>
                        
                    </div>
                </div>
               
            </div>
            <div class=" row  px-0 mx-0 mt-1">
                <div class="col-xl-6  px-0    ">
                    <div class="card py-2 px-3">
                        <div class="col-xl-12 ">
                            <label for="hospitalReason" class=" text-xss w-100 text-start color-main fw-bold mb-2">1.3 Nivel de Conciencia</label>
                        </div>
                        <div class="col-xl-4 ps-5" >
                            <div class="form-check  justify-content-start">
                                <input value="Alerta" v-model="entrevista.conciencia" class="form-check-input" type="radio" name="conciencia" id="flexRadioDefault">
                                <label class="form-check-label"  for="flexRadioDefault">
                                    Alerta
                                </label> 
    
                            </div>
                        </div>
                        <div class="col-xl-4 ps-5" >
                            <div class="form-check  justify-content-start">
                                <input value="Somnoliento" v-model="entrevista.conciencia" class="form-check-input" type="radio" name="conciencia" id="flexRadioDefault">
                                <label class="form-check-label"  for="flexRadioDefault">
                                    Somnoliento
                                </label> 
    
                            </div>
                        </div>
                        <div class="col-xl-4 ps-5" >
                            <div class="form-check  justify-content-start">
                                <input value="Confuso" v-model="entrevista.conciencia" class="form-check-input" type="radio" name="conciencia" id="flexRadioDefault">
                                <label class="form-check-label"  for="flexRadioDefault">
                                    Confuso
                                </label> 
    
                            </div>
                        </div>
                        <div class="col-xl-4 ps-5" >
                            <div class="form-check  justify-content-start">
                                <input value="Sopor" v-model="entrevista.conciencia" class="form-check-input" type="radio" name="conciencia" id="flexRadioDefault">
                                <label class="form-check-label"  for="flexRadioDefault">
                                    Sopor
                                </label> 
    
                            </div>
                        </div>
                        <div class="col-xl-4 ps-5" >
                            <div class="form-check  justify-content-start">
                                <input value="Coma" v-model="entrevista.conciencia" class="form-check-input" type="radio" name="conciencia" id="flexRadioDefault">
                                <label class="form-check-label"  for="flexRadioDefault">
                                    Coma
                                </label> 
    
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="col-xl-6  px-0 ps-1   ">
                    <div class="card py-2 px-3">
                        <div class="col-xl-12 ">
                            <label for="hospitalReason" class=" text-xss w-100 text-start color-main fw-bold mb-2">1.4 Presencia de vias invasivas:</label>
                        </div>
                        <div class="col-xl-4 ps-5" >
                            <div class="form-check  justify-content-start">
                                <input value="Sng" v-model="entrevista.invasivas" class="form-check-input" type="radio" name="invasiva" id="flexRadioDefault">
                                <label class="form-check-label"  for="flexRadioDefault">
                                    Sng
                                </label> 
    
                            </div>
                        </div>
                        <div class="col-xl-4 ps-5" >
                            <div class="form-check  justify-content-start">
                                <input value="Via_Central" v-model="entrevista.invasivas" class="form-check-input" type="radio" name="invasiva" id="flexRadioDefault">
                                <label class="form-check-label"  for="flexRadioDefault">
                                    Via Central
                                </label> 
    
                            </div>
                        </div>
                        <div class="col-xl-4 ps-5" >
                            <div class="form-check  justify-content-start">
                                <input value="Via_Periferica" v-model="entrevista.invasivas" class="form-check-input" type="radio" name="invasiva" id="flexRadioDefault">
                                <label class="form-check-label"  for="flexRadioDefault">
                                    Via Periferica
                                </label> 
    
                            </div>
                        </div>
                        <div class="col-xl-4 ps-5" >
                            <div class="form-check  justify-content-start">
                                <input value="Cateterismo_vertical" v-model="entrevista.invasivas" class="form-check-input" type="radio" name="invasiva" id="flexRadioDefault">
                                <label class="form-check-label"  for="flexRadioDefault">
                                    Cateterismo vertical
                                </label> 
    
                            </div>
                        </div>
                        <div class="col-xl-4 ps-5" >
                            <div class="form-check  justify-content-start">
                                <input value="Otros" v-model="entrevista.invasivas" class="form-check-input" type="radio" name="invasiva" id="flexRadioDefault">
                                <label class="form-check-label"  for="flexRadioDefault">
                                    Otros
                                </label> 
    
                            </div>
                        </div>
                        
                    </div>
                </div>
               
            </div>
            <div class=" row  px-0 mx-0 mt-1">
                <div class=" row  mx-0 col-xl-6 px-0">
                    <div class="card py-2">
                        <div class=" col-xl-12 ">
                            <label for="hospitalReason" class=" text-xss w-100 text-start color-main fw-bold mb-2">1.5 Información Previa:</label>
                        </div>
                        <div class=" col-xl-12 d-flex px-4 ">
                            <div class="form-check me-4">
                                <input v-model="entrevista.info_previa.seleccion" value="si" class="form-check-input" type="radio" name="flexRadioDefault02" id="flexRadioDefault1">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Si
                                </label>
                            </div>
                            <div class="form-check">
                                <input v-model="entrevista.info_previa.seleccion" value="no" class="form-check-input" type="radio" name="flexRadioDefault02" id="flexRadioDefault2" checked>
                                <label class="form-check-label" for="flexRadioDefault2">
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-xl-12 mt-2 d-flex ">
                            <div class="ps-2 ">
                                <label for="hospitalReason" class=" text-xss  text-start color-main fw-bold mb-2">¿Quien? :</label>
    
                            </div>
                            <div  class=" w-75 px-3">
                                <input v-model="entrevista.info_previa.value" type="text" class="form-control form-control-sm " id="exampleFormControlInput1" placeholder="">
                            </div>
                        </div>

                    </div>
                </div>
                <div class=" row  mx-0 col-xl-6 px-0 ps-1">
                    <div class="card py-2">
                        <div class=" col-xl-12 ">
                            <label for="hospitalReason" class=" text-xss w-100 text-start color-main fw-bold mb-2">1.6 Interveciones Quirurgicas Previas :</label>
                        </div>
                        <div class=" col-xl-12 d-flex px-4 ">
                            <div class="form-check me-4">
                                <input v-model="entrevista.interveciones_previa.seleccion" value="si" class="form-check-input" type="radio" name="flexRadioDefault03" id="flexRadioDefault1">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Si
                                </label>
                            </div>
                            <div class="form-check">
                                <input v-model="entrevista.interveciones_previa.seleccion" value="no"  class="form-check-input" type="radio" name="flexRadioDefault03" id="flexRadioDefault2" checked>
                                <label class="form-check-label" for="flexRadioDefault2">
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-xl-12 mt-2 d-flex ">
                            <div class="ps-2 ">
                                <label for="hospitalReason" class=" text-xss  text-start color-main fw-bold mb-2">¿Cual? :</label>
    
                            </div>
                            <div  class=" w-75 px-3">
                                <input v-model="entrevista.interveciones_previa.cual" type="text" class="form-control form-control-sm " id="exampleFormControlInput1" placeholder="">
                            </div>
                        </div>
                        <div class="col-xl-12 mt-2 d-flex ">
                            <div class="ps-2 ">
                                <label for="hospitalReason" class=" text-xss  text-start color-main fw-bold mb-2">¿Hace cuanto tiempo? :</label>
    
                            </div>
                            <div  class=" w-75 px-3">
                                <input v-model="entrevista.interveciones_previa.tiempo" type="text" class="form-control form-control-sm " id="exampleFormControlInput1" placeholder="">
                            </div>
                        </div>

                    </div>
                </div>
               
            </div>
            <div class=" row  px-0 mx-0 mt-1">
                <div class=" row  mx-0 col-xl-6 px-0">
                    <div class="card py-2">
                        <div class=" col-xl-12 ">
                            <label for="hospitalReason" class=" text-xss w-100 text-start color-main fw-bold mb-2">1.7 ¿Hubieron complicaciones durante o despues de la Operación?</label>
                        </div>
                        <div class=" col-xl-12 d-flex px-4 ">
                            <div class="form-check me-4">
                                <input v-model="entrevista.complicaciones.seleccion" value="si" class="form-check-input" type="radio" name="flexRadioDefault04" id="flexRadioDefault1">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Si
                                </label>
                            </div>
                            <div class="form-check">
                                <input v-model="entrevista.complicaciones.seleccion" value="no" class="form-check-input" type="radio" name="flexRadioDefault04" id="flexRadioDefault2" checked>
                                <label class="form-check-label" for="flexRadioDefault2">
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-xl-12 mt-2 d-flex ">
                            <div class="ps-2 ">
                                <label for="hospitalReason" class=" text-xss  text-start color-main fw-bold mb-2">¿Cual?</label>
    
                            </div>
                            <div  class=" w-75 px-3">
                                <input v-model="entrevista.complicaciones.value" type="text" class="form-control form-control-sm " id="exampleFormControlInput1" placeholder="">
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-xl-6  px-0 ps-1   ">
                    <div class="card py-2 px-3">
                        <div class="col-xl-12 ">
                            <label for="hospitalReason" class=" text-xss w-100 text-start color-main fw-bold mb-2">1.8 Estado Emocional:</label>
                        </div>
                        <div class="col-xl-4 ps-5" >
                            <div class="form-check  justify-content-start">
                                <input v-model="entrevista.emocional" value="Tranquilo" class="form-check-input" type="radio" name="estado" id="flexRadioDefault1">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Tranquilo
                                </label>
    
                            </div>
                        </div>
                        <div class="col-xl-4 ps-5" >
                            <div class="form-check  justify-content-start">
                                <input v-model="entrevista.emocional" value="Atemorizado" class="form-check-input" type="radio" name="estado" id="flexRadioDefault1">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Atemorizado
                                </label>
    
                            </div>
                        </div>
                        <div class="col-xl-4 ps-5" >
                            <div class="form-check  justify-content-start">
                                <input v-model="entrevista.emocional" value="Indiferente" class="form-check-input" type="radio" name="estado" id="flexRadioDefault1">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Indiferente
                                </label>
    
                            </div>
                        </div>
                        
                    </div>
                </div>
               
            </div>
            <div class=" row  px-0 mx-0 mt-1">
                <div class=" row  mx-0 col-xl-6 px-0">
                    <div class="card py-2 px-3">
                        <div class="col-xl-12 ">
                            <label for="hospitalReason" class=" text-xss w-100 text-start color-main fw-bold mb-2">1.9 Uso de Prótesis:</label>
                        </div>
                        <div class="col-xl-4 ps-5">
                            <div class="form-check  justify-content-start">
                                <input v-model="entrevista.protesis" value="Dental" class="form-check-input" type="radio" name="protesis" id="flexRadioDefault1">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Dental
                                </label>
    
                            </div>
                        </div>
                        <div class="col-xl-4 ps-5">
                            <div class="form-check  justify-content-start">
                                <input v-model="entrevista.protesis" value="Ocular" class="form-check-input" type="radio" name="protesis" id="flexRadioDefault1">
                                <label class="form-check-label" for="flexRadioDefault1">
                                  Ocular
                                </label>
    
                            </div>
                        </div>
                        <div class="col-xl-4 ps-5">
                            <div class="form-check  justify-content-start">
                                <input v-model="entrevista.protesis" value="Cardiaco" class="form-check-input" type="radio" name="protesis" id="flexRadioDefault1">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Cardiaco
                                </label>
    
                            </div>
                        </div>
                        <div class="col-xl-4 ps-5">
                            <div class="form-check  justify-content-start">
                                <input v-model="entrevista.protesis" value="Otro" class="form-check-input" type="radio" name="protesis" id="flexRadioDefault1">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Otro
                                </label>
    
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class=" row  mx-0 col-xl-6 px-0 ps-1">
                    <div class="card py-2">
                        <div class=" col-xl-12 ">
                            <label for="hospitalReason" class=" text-xss w-100 text-start color-main fw-bold mb-2">1.10 Presencia de Enfermedades crónicas:</label>
                        </div>
                        <div class=" col-xl-12 d-flex px-4 ">
                            <div class="form-check me-4">
                                <input v-model="entrevista.enfermedades_cronicas.seleccion" value="si" class="form-check-input" type="radio" name="flexRadioDefault05" id="flexRadioDefault1">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Si
                                </label>
                            </div>
                            <div class="form-check">
                                <input v-model="entrevista.enfermedades_cronicas.seleccion" value="no" class="form-check-input" type="radio" name="flexRadioDefault05" id="flexRadioDefault2" checked>
                                <label class="form-check-label" for="flexRadioDefault2">
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-xl-12 mt-2 d-flex ">
                            <div class="ps-2 ">
                                <label for="hospitalReason" class=" text-xss  text-start color-main fw-bold mb-2">Tiempo de Enfermedad :</label>
    
                            </div>
                            <div  class=" w-75 px-3">
                                <input v-model="entrevista.enfermedades_cronicas.tiempo" type="text" class="form-control form-control-sm " id="exampleFormControlInput1" placeholder="">
                            </div>
                        </div>
                        <div class="col-xl-12 mt-2 d-flex ">
                            <div class="ps-2 ">
                                <label for="hospitalReason" class=" text-xss  text-start color-main fw-bold mb-2">Toma medicina ¿Cuales? :</label>
    
                            </div>
                            <div  class=" w-75 px-3">
                                <input v-model="entrevista.enfermedades_cronicas.medicina_toma" type="text" class="form-control form-control-sm " id="exampleFormControlInput1" placeholder="">
                            </div>
                        </div>

                    </div>
                </div>
               
            </div>
            <div class=" row  px-0 mx-0 mt-1">
                
                <div class=" row  mx-0 col-xl-6 px-0 ">
                    <div class="card py-2">
                        <div class=" col-xl-12 ">
                            <label for="hospitalReason" class=" text-xss w-100 text-start color-main fw-bold mb-2">1.11 Presencia de alergias a Medicamentos:</label>
                        </div>
                        <div class=" col-xl-12 d-flex px-4 ">
                            <div class="form-check me-4">
                                <input v-model="entrevista.alergia_medicamentos.seleccion" value="si" class="form-check-input" type="radio" name="flexRadioDefault06" id="flexRadioDefault1">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Si
                                </label>
                            </div>
                            <div class="form-check">
                                <input v-model="entrevista.alergia_medicamentos.seleccion" value="no" class="form-check-input" type="radio" name="flexRadioDefault06" id="flexRadioDefault2" checked>
                                <label class="form-check-label" for="flexRadioDefault2">
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-xl-12 mt-2 d-flex ">
                            <div class="ps-2 ">
                                <label for="hospitalReason" class=" text-xss  text-start color-main fw-bold mb-2">¿Cual?:</label>
    
                            </div>
                            <div  class=" w-75 px-3">
                                <input type="text" v-model="entrevista.alergia_medicamentos.value" class="form-control form-control-sm " id="exampleFormControlInput1" placeholder="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" row  mx-0 col-xl-3 px-0 px-1 ">
                    <div class="card py-2">
                        <div class=" col-xl-12 ">
                            <label for="hospitalReason" class=" text-xss w-100 text-start color-main fw-bold mb-2">1.12 Pre-medicacion con Receta?</label>
                        </div>
                        <div class=" col-xl-12 d-flex px-4 ">
                            <div class="form-check me-4">
                                <input v-model="entrevista.pre_medicacion" value="si" class="form-check-input" type="radio" name="flexRadioDefault07" id="flexRadioDefault1">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Si
                                </label>
                            </div>
                            <div class="form-check">
                                <input v-model="entrevista.pre_medicacion" value="no" class="form-check-input" type="radio" name="flexRadioDefault07" id="flexRadioDefault2" checked>
                                <label class="form-check-label" for="flexRadioDefault2">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" row  mx-0 col-xl-3 px-0 ">
                    <div class="card py-2">
                        <div class=" col-xl-12 ">
                            <label for="hospitalReason" class=" text-xss w-100 text-start color-main fw-bold mb-2">1.13 ¿Cirugia programada con Guia?</label>
                        </div>
                        <div class=" col-xl-12 d-flex px-4 ">
                            <div class="form-check me-4">
                                <input v-model="entrevista.cirugia_programada" value="si" class="form-check-input" type="radio" name="flexRadioDefault08" id="flexRadioDefault1">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Si
                                </label>
                            </div>
                            <div class="form-check">
                                <input v-model="entrevista.cirugia_programada" value="no" class="form-check-input" type="radio" name="flexRadioDefault08" id="flexRadioDefault2" checked>
                                <label class="form-check-label" for="flexRadioDefault2">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
            <div class="col-xl-6 px-0">
                <div class=" mt-3 p-content px-0">
                    <div class="col text-center fw-bold py-2 div-title rounded">
                        <span class="text-uppercase">CUIDADOS DE ENFERMERIA AL PACIENTE PREQUIRURGICO</span>
                    </div>
                </div>
                <div class="mt-2 px-4" >
                    <div class=" col-xl-12 ">
                        <label for="hospitalReason" class=" text-xss w-100 text-start  fw-bold mb-2">1. Familiarizarlo con el ambiente quirúrgico, reconocimiento físico. </label>
                    </div>
                    <div class=" col-xl-12 ">
                        <label for="hospitalReason" class=" text-xss w-100 text-start  fw-bold mb-2">2. Explicar la rutina operatoria.</label>
                    </div>
                    <div class=" col-xl-12 ">
                        <label for="hospitalReason" class=" text-xss w-100 text-start  fw-bold mb-2">3. Explicar los tiempos aproximados durante el proceso.</label>
                    </div>
                    <div class=" col-xl-12 ">
                        <label for="hospitalReason" class=" text-xss w-100 text-start  fw-bold mb-2">4. Crear una relación de confianza para expresar dudas y temores.</label>
                    </div>
                    <div class=" col-xl-12 ">
                        <label for="hospitalReason" class=" text-xss w-100 text-start  fw-bold mb-2">5. Educar sobre la higiene corporal y preparación de la zona operatoria.</label>
                    </div>
                    <div class=" col-xl-12 ">
                        <label for="hospitalReason" class=" text-xss w-100 text-start  fw-bold mb-2">6. NPO x 6 horas/dieta líquida restringida.</label>
                    </div>
                    <div class=" col-xl-12 ">
                        <label for="hospitalReason" class=" text-xss w-100 text-start  fw-bold mb-2">7. Evacuación del intestino.</label>
                    </div>
                    <div class=" col-xl-12 ">
                        <label for="hospitalReason" class=" text-xss w-100 text-start  fw-bold mb-2">8. No traer accesorios ni objetos de valor.</label>
                    </div>
                    <div class=" col-xl-12 ">
                        <label for="hospitalReason" class=" text-xss w-100 text-start  fw-bold mb-2">9. Vestimenta adecuada, sin maquillaje, ni esmalte, protectores de cama y pañal.</label>
                    </div>
                </div>

            </div>
            <div class="col-xl-6 px-0">
                <div class=" mt-3 p-content px-0 ps-1">
                    <div class="col text-center fw-bold py-2 div-title rounded">
                        <span class="text-uppercase">OBSERVACIONES</span>
                    </div>
                </div>
                <div class="mt-2  ps-1" >
                    <div class="mb-3">
                        <textarea v-model="entrevista.observaciones" class="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                    </div>
                </div>

            </div>
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert"  />
    </div>
</template>

<script>
import {
    mapActions
} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
export default {
    name: "c-asistencial-sop-historia-entrevista",
    data() {
        return{
            swal:null,
            entrevista :{
                operacion_programada: '',
                pariente_entrevista:{
                    seleccion:'',
                    value:''
                },
                lugar_entrevista:'',
                estado:'',
                conciencia:'',
                invasivas:'',
                emocional:'',
                protesis:'',
                info_previa:{
                    seleccion:'',
                    value:''
                },
                interveciones_previa:{
                    seleccion:'',
                    cual:'',
                    tiempo:''
                }, 
                complicaciones:{
                    seleccion:'',
                    value:'',
                },
                enfermedades_cronicas:{
                    seleccion:'',
                    tiempo:'',
                    medicina_toma:''
                },
                alergia_medicamentos:{
                    seleccion:'',
                    value:''
                },
                pre_medicacion:'',
                cirugia_programada:'',
                observaciones:'',               
            }
        }
        
    },
    components:{
        SweetAlert
    },
    props: {
        medical_act_id: {
            type: Number,
            default: 0,
        },
        addEntrevista: {
            type: Number,
            default: 0
        },
        rolname: String,
    },
    watch: {
        medical_act_id: {
            handler: function (newValue) {
                if (newValue !== 0) {
                    this.mtdViewInterview();
                }
            },
            deep: true,
            immediate: true,
        },
        addEntrevista: {
            handler: function (newValue) {
                if (newValue == 1) {
                    this.mtdstoredata()
                }
            },
            deep: true,
            immediate: true,
        }

    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdstoredata: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Sop/storeInterview",
                    token: this.$store.getters.get__token,
                    params: {
                        ... this.entrevista,
                        medical_act_id: this.medical_act_id,
                    }
                })
                .then((response) => {
                    this.mtdViewInterview();
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.$emit('finishAddOrder');
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },

        mtdViewInterview: function () {
            this.edit = this.rolname !== 'DOCTOR(A)' ? true:false;
            this.post({
                url: this.$store.getters.get__url + "/Sop/preOperatorioView",
                token: this.$store.getters.get__token,
                params:{
                    medical_act_id: this.medical_act_id,
                    type: 'interview'
                }
            })
                .then((response) => {
                    let dataInterview = response.data;
                    if(dataInterview){
                        this.entrevista.operacion_programada = dataInterview.scheduled_operation,
                        this.entrevista.pariente_entrevista = JSON.parse(dataInterview.accompanied),
                        this.entrevista.lugar_entrevista = dataInterview.interview_location
                        this.entrevista.estado = dataInterview.patient_status
                        this.entrevista.conciencia = dataInterview.conscious_patient
                        this.entrevista.invasivas= dataInterview.invasive
                        this.entrevista.emocional = dataInterview.emotional_state
                        this.entrevista.protesis = dataInterview.prosthesis,
                        this.entrevista.info_previa = JSON.parse(dataInterview.previous_information),
                        this.entrevista.interveciones_previa = JSON.parse(dataInterview.previous_interventions),
                        this.entrevista.complicaciones = JSON.parse(dataInterview.complications)
                        this.entrevista.enfermedades_cronicas = JSON.parse(dataInterview.chronic_diseases)
                        this.entrevista.alergia_medicamentos = JSON.parse(dataInterview.	drug_allergy)
                        this.entrevista.pre_medicacion = dataInterview.premedication,
                        this.entrevista.cirugia_programada = dataInterview.scheduled_surgery,
                        this.entrevista.observaciones = dataInterview.observation
                    }
                
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
    }
}
</script>
<style>

</style> 