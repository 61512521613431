<template>
    <div>
      <div class="container">
        <div class="d-flex justify-content-between">
                <div class="mt-3 mb-4">
                    <button type="button" class="btn btn-secondary" @click="mtdChangeView('box')">
                        <i class="fas fa-arrow-left"></i> Volver
                    </button>
                </div>
                <div class=" btn-home mt-3 mb-4">
                    <button type="button" class="btn btn-outline-main" @click="mtdChangeView('home')">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
        <div id="div-modal-vueltos" class="d-flex justify-content-center">
          <div id="div-vueltos" class="card w-75 mb-2">
            <div
              class="card-header text-white h4 text-center bg-main"
            >
              <strong>VUELTO</strong>
              <!-- <span class="btn btn-sm float-end" style="background: white; color: #900052; font-weight: 1em">
                <strong> Caja inicial: S/ {{ process.initial }}</strong>
              </span> -->
            </div>
            <div class="card-body">
              <div class="row">
                <!-- LISTADO -->
                <div class="col-md-12" id="div-tabla-vueltos">
                  <table id="tb-vueltos" class="table table-bordered border-main">
                    <thead>
                      <tr
                        class="text-white border-white text-center bg-main"
                      >
                        <th scope="col">N°</th>
                        <th scope="col">RESPONSABLE</th>
                        <th scope="col">VUELTO PENDIENTE S/</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in dataTurned" :key="index">
                        <th
                          class="p-1"
                          scope="row"
                          v-if="item.state == 2"
                          style="background: #bbd7bd"
                        >
                          {{ index + 1 }}
                        </th>
                        <th class="p-1" scope="row" v-else-if="item.state == 1">
                          {{ index + 1 }}
                        </th>
  
                        <td
                          class="p-1"
                          align="left"
                          v-if="item.state == 2"
                          style="background: #bbd7bd"
                        >
                          {{ item.admision_passage.document }}-{{
                            item.admision_passage.name
                          }}
                        </td>
                        <td class="p-1" align="left" v-else-if="item.state == 1">
                          {{ item.admision_passage.document }}-{{
                            item.admision_passage.name
                          }}
                        </td>
  
                        <td
                          class="p-1"
                          align="right"
                          v-if="item.state == 2"
                          style="background: #bbd7bd"
                        >
                          <span
                            class="btn btn-sm w-100 input-group-text"
                            style="text-align: right"
                            >S/ {{ item.turned }}</span
                          >
                        </td>
                        <td class="p-1" align="right" v-if="item.state == 1">
                          <span
                            class="btn btn-sm w-100 input-group-text"
                            @click="mtdTurnedProccess(index, item.id, 2, item)"
                            style="text-align: right"
                            >S/ {{ item.turned }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td
                          align="left"
                          class="text-white bg-main"
                          colspan="2"
                        >
                          <strong>TOTAL</strong>
                        </td>
                        <td class="border-box-main text-right">
                          <strong>S/ {{ cpTurnedTotal }}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- <div class="col-md-1"> </div> -->
                <!-- FIN LISTADO -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  
  export default {
    name: "c-admision-vueltos",
    data() {
      return {
        process: {
          box: 0,
          initial: 0,
          observation: "",
          status: 0,
        },
        dataTurned: [],
        sumTurned: 0.0,
      };
    },
    computed: {
      cpTurnedTotal(data) {
        var vueltos = [];
        var sumVueltos = 0.0;
        vueltos = data._data.dataTurned;
        for (let i = 0; i < vueltos.length; i++) {
          if (vueltos[i].state == 1) {
            // vueltos faltantes de procesar
            sumVueltos = sumVueltos + parseFloat(vueltos[i].turned);
          }
        }
        return sumVueltos.toFixed(2);
      },
      recargarLista() {
        return this.mtdGetData();
      },
    },
    props: {
      boxes: [],
      bead: {},
    },
    created() {
      this.mtdGetData();
    },
    methods: {
      ...mapActions(["get", "post"]),
      mtdSelectBox: function (item) {
        this.process = item.process.length > 0 ? item.process : this.process;
        this.process.box = item.id;
      },
      /* mtdVueltosBead: function (status) {
        this.process.status = status;
        this.post({
          url: this.$store.getters.get__url + "/bead/vueltos",
          token: this.$store.getters.get__token,
          params: {
            data: this.process,
          },
        })
          .then((response) => {
            this.$emit("mtdVueltos", response);
          })
          .catch((errors) => {});
      }, */
      mtdSendOption: function (page) {
        if (this.verifBead == true) {
          this.page = page;
          this.$emit("mtdChangePage", this.page);
        }
      },
      mtdGetData: function () {
        this.post({
          url: this.$store.getters.get__url + "/Admision/turned",
          token: this.$store.getters.get__token,
          params: {
            Campus: this.$store.getters.get__campus,
            bead: this.bead.id,
          },
        })
          .then((response) => {
            this.dataTurned = response.data;
            //cpTurnedTotal(response.data);
          })
          .catch((errors) => {});
      },
      retroceder() {
        this.$emit("mtdChangePage", "home");
      },
      mtdTurnedProccess: function (index, id, state, item) {
        Swal.fire({
          title: "¿Vuelto entregado conforme?",
          showDenyButton: true,
          confirmButtonText: "SI",
          denyButtonText: "NO",
          confirmButtonColor: "#900052",
          denyButtonColor: "#900052",
        }).then((result) => {
          if (result.isConfirmed) {
            this.post({
              url: this.$store.getters.get__url + "/Admision/turned/proccess",
              token: this.$store.getters.get__token,
              params: {
                turned_id: id,
                state: state,
              },
            })
              .then((response) => {
                const numero = parseFloat(this.bead.turned) + parseFloat(item.turned);
                this.bead.turned = numero;
                this.$emit("mtdCommitBead", this.bead);
                // this.dataPassage[index].state = state;
                this.mtdGetData();
                this.$refs.SweetAlert.showSuccess("Vuelto confirmado");
              })
              .catch((errors) => {});
  
            this.recargarLista;
          } else if (result.isDenied) {
            this.$refs.SweetAlert.showInfo("El vuelto no es conforme");
          }
        });
      },
      /* retroceder o volver al inicio */
      mtdChangeView: function (option) {
            this.$emit("mtdChangePage", option);
        },
    },
  };
  </script>