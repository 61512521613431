<template>
<div>
    <div class="p-content" :class="{'checklist-frame-active': history === 1}">
        <div class="row" v-if="history !== 1">
            <div class="col-md-12 d-md-block d-lg-none my-2">
                <button type="button" class="btn btn-sm btn-outline-bm text-xs w-100" @click="mtdstoredata()">
                    <i class="fas fa-save"></i> GUARDAR
                </button>
            </div>
        </div>
        <div class="row my-2 d-none d-lg-flex">
            <div class="col-md-12 mt-3">
                <!-- OPCIONES -->
                <div class="btn-group w-100 px-1" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" @click="changeView(1)" checked>
                    <label class="btn btn-outline-bm align-middle" for="btnradio1">I. ANTES DE LA INDUCCIÓN DE LA ANESTESIA</label>

                    <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" @click="changeView(2)">
                    <label class="btn btn-outline-bm align-middle" for="btnradio2">II. ANTES DE LA INCISIÓN CUTÁNEA</label>

                    <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" @click="changeView(3)">
                    <label class="btn btn-outline-bm align-middle" for="btnradio3">III. ANTES QUE EL PACIENTE SALGA DEL QUIRÓFANO</label>
                </div>
            </div>
            <div class="col-md-12 mt-3 px-3" v-if="page == 1">
                <div class="row">
                    <div class="col-md-12 mt-1 align-center">
                        <div class="form-group row">
                            <span class="col-md-12 col-lg-8 fw-bold color-main mt-1 text-start">El cirujano, el Anestesiólogo y el personal de Enfermería en presencia del Paciente, ha confirmado</span>
                            <div class="col-md-12 col-lg-4 m-lg-auto d-md-none d-lg-block">
                                <span class="fw-bold color-main mt-1">Observaciones</span>
                            </div>
                        </div>
                    </div>
                    <!-- PREGUNTAS PARTE I -->
                    <div class="col-md-12 mt-1 align-left" v-for="(question, index) in checklist.parteI" :key="index">
                        <div class="form-group d-md-flex">
                            <label :for="'i'+question.id" class="col-xl-7 col-md-6 col-lg-6 fw-bold text-xs mt-1">{{question.id}}. {{ question.label }}</label>
                            <div class="col-xl-2 col-md-3 col-lg-2 m-auto d-flex px-0">
                                <div class="form-inline mx-1">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" :name="'p'+question.id" :id="'si'+index" value="si" v-model="question.value" :disabled="history === 1">
                                        <label class="form-check-label text-md" :for="'si'+index">SI</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" :name="'p'+question.id" :id="'no'+index" value="no" v-model="question.value" :disabled="history === 1">
                                        <label class="form-check-label text-md" :for="'no'+index">NO</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3 m-lg-auto">
                                <input :id="'i'+question.id" type="text" class="form-control form-control-input form-control-sm" v-model="question.comment" :readonly="history === 1">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-3" style="height: 100%" v-if="page == 2">
                <div class="row">
                    <div class="col-md-12 mt-1 align-center">
                        <div class="form-group row">
                            <span class="col-md-12 col-lg-8 fw-bold color-main text-start mt-1">Con el(la) enfermero(a) y anestesiólogo y cirujano</span>
                            <div class="col-md-8 col-lg-4 m-lg-auto d-md-none d-lg-block">
                                <span class="fw-bold color-main mt-1">Observaciones</span>
                            </div>
                        </div>
                    </div>
                    <!-- PREGUNTAS PARTE II -->
                    <div class="col-md-12 mt-1 align-left" v-for="(question, index) in checklist.parteII" :key="index">
                        <div class="form-group d-flex">
                            <label :for="'i2'+question.id" class="col-xl-7 col-md-6 col-lg-5 fw-bold text-xs mt-1">{{question.id}}. {{ question.label }}</label>
                            <div class="col-xl-2 col-lg-3 col-md-3  m-auto d-flex px-0">
                                <div class="form-inline mx-1">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" :name="'p2'+question.id" :id="'si2'+index" value="si" v-model="question.value" :disabled="history === 1">
                                        <label class="form-check-label text-md" :for="'si2'+index">SI</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" :name="'p2'+question.id" :id="'no2'+index" value="no" v-model="question.value" :disabled="history === 1">
                                        <label class="form-check-label text-md" :for="'no2'+index">NO</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3 m-lg-auto">
                                <input :id="'i2'+question.id" type="text" class="form-control form-control-input form-control-sm" v-model="question.comment" :readonly="history === 1">
                            </div>
                        </div>
                        <div v-if="question.id ==1" class="form-group row mt-3">
                            <span class="color-main fw-bold text-xss">El cirujano ha confimado de manera verbal con el Anestesiólogo y enfermera:</span>
                        </div>
                        <div v-if="question.id ==7" class="row mt-3">
                            <hr>
                        </div>
                        <div v-if="question.id ==9" class="form-group row mt-2">
                            <span class="color-main fw-bold text-xss">PREVENCION DE EVENTOS CRITICOS:</span>
                            <br>
                            <span class="color-main fw-semibold text-xss">Cirujano revisa:</span>
                        </div>
                        <div v-if="question.id ==12" class="form-group row mt-3">
                            <span class="color-main fw-semibold text-xss">Anesticista verifica:</span>
                        </div>
                        <div v-if="question.id ==13" class="form-group row mt-3">
                            <span class="color-main fw-semibold text-xss">Equipo de Enfermeria verifica:</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-3" v-if="page == 3">
                <div class="row">
                    <div class="col-md-12 mt-1 align-center">
                        <div class="form-group row">
                            <span class="col-md-12 col-lg-7 fw-bold color-main text-start mt-1">Con el(la) enfermero(a) y anestesiólogo y cirujano</span>
                            <div class="col-md-8 col-lg-5 m-lg-auto d-md-none d-lg-block">
                                <span class="fw-bold color-main mt-1">Observaciones</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-1  align-left">
                        <span class="color-main fw-semibold text-xss"><i>El(la) enfermero(a) confirma verbalmente:</i></span>
                    </div>
                    <!-- PREGUNTAS PARTE III -->
                    <div class="col-md-12 mt-1 align-left" v-for="(question, index) in checklist.parteIII" :key="index">
                        <div class="form-group d-flex">
                            <label :for="'q'+question.id" class="col-xl-7 col-md-6 col-lg-6 fw-bold text-xs mt-1">{{question.id}}. {{ question.label }}</label>
                            <div class="col-md-3 col-lg-2 m-auto d-flex px-0">
                                <div class="form-inline mx-1">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" :name="'p3'+question.id" :id="'si3'+index" value="si" v-model="question.value" :disabled="history === 1">
                                        <label class="form-check-label text-md" :for="'si3'+index">SI</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" :name="'p3'+question.id" :id="'no3'+index" value="no" v-model="question.value" :disabled="history === 1">
                                        <label class="form-check-label text-md" :for="'no3'+index">NO</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3 m-lg-auto">
                                <input :id="'q'+question.id" type="text" class="form-control form-control-input form-control-sm" v-model="question.comment" :readonly="history === 1">
                            </div>
                        </div>
                        <div v-if="question.id ==3" class="form-group row mt-4">
                            <span class="color-main fw-semibold text-xss"><i>El (La) cirujano, anestesiólogo(a) y enfermo(a) revisan:</i></span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row my-2 d-md-block d-lg-none pb-3">
            <div class="col-md-12">
                <div class="row my-3">
                    <div class="col text-center fw-bold py-2 div-title rounded">
                        <span class="text-uppercase">I. ANTES DE LA INDUCCIÓN DE LA ANESTESIA</span>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-3 px-3">
                <div class="row">
                    <div class="col-md-12 mt-1 align-center">
                        <div class="form-group row">
                            <span class="col-md-12 col-lg-8 fw-bold color-main mt-1 text-start">Con el(la) enfermero(a) y anestesiólogo, como mínimo</span>

                        </div>
                    </div>
                    <!-- PREGUNTAS PARTE I -->
                    <div class="col-md-12 mt-1 align-left" v-for="(question, index) in checklist.parteI" :key="index">
                        <div class="form-group d-md-flex">
                            <label :for="'questionp1'+question.id" class="col-xl-7 col-md-6 col-lg-6 fw-bold text-xs mt-1">{{question.id}}. {{ question.label }}</label>
                            <div class="col-xl-2 col-md-3 col-lg-2 m-auto d-flex px-0">
                                <div class="form-inline mx-1">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" :name="'p1'+question.id" :id="'si'+index" value="si" v-model="question.value" :disabled="history === 1">
                                        <label class="form-check-label text-md" :for="'si'+index">SI</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" :name="'p1'+question.id" :id="'no'+index" value="no" v-model="question.value" :disabled="history === 1">
                                        <label class="form-check-label text-md" :for="'no'+index">NO</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3 m-lg-auto">
                                <input :id="'questionp1'+question.id" type="text" class="form-control form-control-input form-control-sm" v-model="question.comment" :readonly="history === 1">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row my-3">
                    <div class="col text-center fw-bold py-2 div-title rounded">
                        <span class="text-uppercase">II. ANTES DE LA INCISIÓN CUTÁNEA</span>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-3">
                <div class="row">
                    <div class="col-md-12 mt-1 align-center">
                        <div class="form-group row">
                            <span class="col-md-12 fw-bold color-main text-start mt-1">Con el(la) enfermero(a) y anestesiólogo y cirujano</span>

                        </div>
                    </div>
                    <!-- PREGUNTAS PARTE II -->
                    <div class="col-md-12 mt-1 align-left" v-for="(question, index) in checklist.parteII" :key="index">
                        <div class="form-group d-flex">
                            <label :for="'qi2'+question.id" class="col-xl-7 col-md-6 col-lg-5 fw-bold text-xs mt-1">{{question.id}}. {{ question.label }}</label>
                            <div class="col-xl-2 col-lg-3 col-md-3  m-auto d-flex px-0">
                                <div class="form-inline mx-1">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" :name="'p22'+question.id" :id="'si22'+index" value="si" v-model="question.value" :disabled="history === 1">
                                        <label class="form-check-label text-md" :for="'si22'+index">SI</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" :name="'p22'+question.id" :id="'no22'+index" value="no" v-model="question.value" :disabled="history === 1">
                                        <label class="form-check-label text-md" :for="'no22'+index">NO</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3 m-lg-auto">
                                <input :id="'qi2'+question.id" type="text" class="form-control form-control-input form-control-sm" v-model="question.comment" :readonly="history === 1">
                            </div>
                        </div>
                        <div v-if="question.id ==1" class="form-group row mt-3">
                            <span class="color-main fw-bold text-xss">El cirujano ha confimado de manera verbal con el Anestesiólogo y enfermera:</span>
                        </div>
                        <div v-if="question.id ==7" class="row mt-3">
                            <hr>
                        </div>
                        <div v-if="question.id ==9" class="form-group row mt-2">
                            <span class="color-main fw-bold text-xss">PREVENCION DE EVENTOS CRITICOS:</span>
                            <br>
                            <span class="color-main fw-semibold text-xss">Cirujano revisa:</span>
                        </div>
                        <div v-if="question.id ==12" class="form-group row mt-3">
                            <span class="color-main fw-semibold text-xss">Anesticista verifica:</span>
                        </div>
                        <div v-if="question.id ==13" class="form-group row mt-3">
                            <span class="color-main fw-semibold text-xss">Equipo de Enfermeria verifica:</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row my-3">
                    <div class="col text-center fw-bold py-2 div-title rounded">
                        <span class="text-uppercase">III. ANTES QUE EL PACIENTE SALGA DEL QUIRÓFANO</span>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-3 pb-2">
                <div class="row">
                    <div class="col-md-12 mt-1 align-center">
                        <div class="form-group row">
                            <span class="col-md-12 col-lg-7 fw-bold color-main text-start mt-1">Con el(la) enfermero(a) y anestesiólogo y cirujano</span>
                            <div class="col-md-8 col-lg-5 m-lg-auto d-md-none d-lg-block">
                                <span class="fw-bold mt-1">Observaciones</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-1  align-left">
                        <span class="color-main fw-semibold text-xss"><i>El(la) enfermero(a) confirma verbalmente:</i></span>
                    </div>
                    <!-- PREGUNTAS PARTE III -->
                    <div class="col-md-12 mt-1 align-left" v-for="(question, index) in checklist.parteIII" :key="index">
                        <div class="form-group d-md-flex">
                            <label :for="'qp3'+question.id" class="col-xl-7 col-md-6 col-lg-6 fw-bold text-xs mt-1">{{question.id}}. {{ question.label }}</label>
                            <div class="col-md-3 col-lg-2 m-auto d-flex px-0">
                                <div class="form-inline mx-1">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" :name="'p-3'+question.id" :id="'sip3'+index" value="si" v-model="question.value" :disabled="history === 1">
                                        <label class="form-check-label text-md" :for="'sip3'+index">SI</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" :name="'p-3'+question.id" :id="'nop3'+index" value="no" v-model="question.value" :disabled="history === 1">
                                        <label class="form-check-label text-md" :for="'nop3'+index">NO</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3 m-lg-auto">
                                <input :id="'qp3'+question.id" type="text" class="form-control form-control-input form-control-sm" v-model="question.comment" :readonly="history === 1">
                            </div>
                        </div>
                        <div v-if="question.id ==3" class="form-group row mt-4">
                            <span class="color-main fw-semibold text-xss"><i>El (La) cirujano, anestesiólogo(a) y enfermo(a) revisan:</i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row my-3">
            <div class="col-xl-4 col-lg-4 col-md-12 mb-2 align-left" v-for="(item2, index) in checklist.staff" :key="index">
                <div class="form-group d-md-flex">
                    <label :for="'inputst' + index" class="col-xl-5 col-md-4 col-sm-12 color-main fw-bold">{{ item2.label }}</label>
                    <div class="col-xl-7 col-md-8 col-sm-12">
                        <input type="text" v-model="item2.value" class="form-control form-control-input form-control-sm" :id="'inputst' + index" :readonly="history === 1"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert"/>

</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
export default {
    name: 'c-asistencial-sop-historia-listaverificacion',
    components:{
        SweetAlert
    },
    props: {
        medical_act_id: {
            type: Number,
            default: 0,
        },
        addChecklist: {
            type: Number,
            default: 0,
        },
        history: {
            type: Number,
            default: 0,
        },
    },
    watch: {
        medical_act_id: {
            handler: function (newValue) {
                if (newValue !== 0) {
                    this.mtdView();
                }
            },
            deep: true,
            immediate: true,
        },
        addChecklist: {
            handler: function (newValue) {
                if (newValue == 1) {
                    this.mtdstoredata();
                }
            },
            deep: true,
            immediate: true,
        }
    },
    data() {
        return {
            page: 1,
            swal:null,
            checklist: {
                id: null,
                parteI: [{
                        id: 1,
                        label: '¿Ha confirmado el(la) paciente su identidad, el sitio quirúrgico, el procedimiento y consentimiento?',
                        value: null,
                        comment: "",
                    },
                    {
                        id: 2,
                        label: '¿El anestesiólogo ha confimado con el Cirujano que esté marcado el sitio quirurgico?',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 3,
                        label: '¿El Cirujano ha confirmado la realización de asepsia en el sitio quirúrgico?',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 4,
                        label: '¿El anestesiólogo ha completado el control de la seguridad de la anestesia al revisar: Medicamentos, equipo (funcionaliad y condiciones óptimas)?',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 5,
                        label: '¿El Anestesiólogo ha colocado y comprobado que funcione el oxíometro de pulso correctamento?',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 6,
                        label: '¿El Anestesiólogo han confirmado que el paciente tiene alergias conocidas?',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 7,
                        label: '¿Tiene el(la) paciente la vía aérea difícil / riesgo de aspiración?',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 8,
                        label: 'Si la respuesta es si, ¿hay instrumental de equipos o ayuda disponible?',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 9,
                        label: '¿Tiene el(la) paciente riesgo de hemorragia en adultos > 500ml (7ml/kg en niños)?',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 10,
                        label: 'Si la respuesta es si, ¿se ha previsto la disponibilidad de sangre, plasma u otros fluidos y 2 vías de acceso IV/Central?',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 11,
                        label: '¿El paciente tiene posibles necesidades de hemoderivados y soluciones disponibles?',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 12,
                        label: 'Si la respuesta es si, ¿se ha realizado el cruce de sangre previamente?',
                        value: "",
                        comment: "",
                    }
                ],
                parteII: [{
                        id: 1,
                        label: 'La Instrumentista ha identificado a cada de los miembros del equipo quirúrgico para que se presenten por su nombre y funcion, sin omisiones',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 2,
                        label: 'Paciente Correcto',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 3,
                        label: 'Procedimiento Correcto',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 4,
                        label: 'Sitio quirúrgico Correcto',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 5,
                        label: 'En caso de órgano bilateral, ha marcado derecho o izquierdo, según corresponda',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 6,
                        label: 'En caso de estructura mpultuple, ha especificado el nivel a operar ',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 7,
                        label: 'Posición correcta del paciente',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 8,
                        label: '¿El anestesiólogo ha verificado que se haya aplicado la profilaxis antibiótica conforme a las indicaciones médicas?',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 9,
                        label: '¿El cirujando ha verificado que cuenta con los estudios de las imágenes diagnosticadas esenciales?',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 10,
                        label: '¿Cuáles son los pasos críticos o inesperados?<',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 11,
                        label: '¿Cuánto durará la operación?',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 12,
                        label: '¿Cuánto es la perdida de sangre Prevista?',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 13,
                        label: '¿Presenta el paciente algún riesgo o enfermedad en el paciente que pueda complicar cirugía',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 14,
                        label: '¿Se ha confirmado la esterilidad de ropa, instrumental y equipos (con resultados de indicadores)?',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 15,
                        label: '¿Hay dudas o problemas relacionados con ellos?',
                        value: "",
                        comment: "",
                    }
                ],
                parteIII: [{
                        id: 1,
                        label: 'El nombre del procedimiento realizado',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 2,
                        label: 'El reencuentro COMPLETO de instrumentos, esponjas y agujas son correctos (lectura en voz alta, incluido el nombre del paciente).',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 3,
                        label: 'El etiquetado de las muestras (Nombre completo del paciente, fecha de nacimiento, fecha de la cirugía y descripcion general)',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 4,
                        label: '¿Hay algún problema que resolver relacionados con el instrumental y los equipos?',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 5,
                        label: 'Los principales aspectos de la recuperacion postoperatoria',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 6,
                        label: 'El plan de tratamiento',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 7,
                        label: 'Los riesgos del paciente',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 8,
                        label: 'Ocurrieron eventos adversos',
                        value: "",
                        comment: "",
                    },
                    {
                        id: 9,
                        label: 'Se registró el evento adverso? Si la respuesta es Si ¿En donde?',
                        value: "",
                        comment: "",
                    },
                ],
                staff: [{
                        id: 1,
                        label: "Anestesiologo",
                        value: "",
                    },
                    {
                        id: 2,
                        label: "Cirujano",
                        value: "",
                    },
                    {
                        id: 3,
                        label: "Asistente",
                        value: "",
                    },

                ],
            }
        };
    },
    methods: {
        ...mapActions(['get', 'post']),

        changeView: function (id) {
            this.page = id;
        },
        mtdstoredata: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Sop/storeCheckList",
                    token: this.$store.getters.get__token,
                    params: {
                        ...this.checklist,
                        medical_act_id: this.medical_act_id,
                    }
                })
                .then((response) => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    let data = response.data;
                    if(data){
                        this.checklist.parteI = JSON.parse(data.before_induction);
                        this.checklist.parteII = JSON.parse(data.before_skin_incision);
                        this.checklist.parteIII = JSON.parse(data.before_leaves);
                        this.checklist.staff = JSON.parse(data.staff);
                        this.checklist.id = data.id
                    }
                    this.$emit('mtdaddrpexit');
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdView: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Sop/intraOperatorioView",
                    token: this.$store.getters.get__token,
                    params: {
                        medical_act_id: this.medical_act_id,
                        type: 'checklist'
                    }
                })
                .then((response) => {
                    let data = response.data;
                    if(data){
                        this.checklist.parteI = JSON.parse(data.before_induction);
                        this.checklist.parteII = JSON.parse(data.before_skin_incision);
                        this.checklist.parteIII = JSON.parse(data.before_leaves);
                        this.checklist.staff = JSON.parse(data.staff);
                        this.checklist.id = data.id
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },

    },
}
</script>
<style scoped>
.checklist-frame-active {
  border: 2px solid #900052;
  border-radius: 5px;
  padding: 0 20px;
}
</style>
