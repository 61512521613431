<template>
<div class="col-12">
    <div class="row mt-2">
        <div class="panel-border my-1 px-0">
            <div class="d-md-flex text-xs">
                <div class="col-md-12 bg-main text-white text-center p-2">
                    <span class="fw-bold">ORDER DE SOP</span>
                </div>
            </div>
        </div>
    </div>
    <!-- {{ data }} -->
    <div class="row mt-2" v-if="data">
        <div class="panel-border my-1">
            <div class="d-lg-flex gap-2">
                <!-- DOS COLUMNAS -->
                <div class="col-xl-4 col-lg-4 col-md-12 my-2">
                    <label class="col-sm-12 color-main fw-bold">ANTECEDENTES</label>
                    <div class="row">
                        <div class="col-md-12 my-1" v-for="(item, index) in background" :key="index">
                            <span class="fw-semibold color-main text-xs">{{ item.label }}:</span>&nbsp;
                            <input type="text" class="form-control form-control-sm" v-model="item.value" disabled>
                        </div>
                        <div class="col-md-12" v-if="background.length == 0">
                            <span class="fw-semibold color-main">No hay registros</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-8 col-lg-8 col-md-12 my-2">
                    <label class="mt-2 color-main fw-bold">EXAMEN FÍSICO</label>
                    <div class="d-md-flex flex-wrap">
                        <!-- Si hay elementos en physicalExams -->
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-1 px-2" v-for="(itemE, indexE) in physicalExams" :key="indexE">
                            <div class="form-group d-flex flex-wrap align-items-center">
                                <label :for="itemE.label" class="col-12 col-md-6 text-xs color-main fw-bold">
                                    {{ itemE.label }}
                                </label>
                                <div class="col-12 col-md-6 mt-1 mt-md-0">
                                    <input type="text" class="form-control form-control-sm" v-model="itemE.value" :id="itemE.label" disabled />
                                </div>
                            </div>
                        </div>
                        <!-- Si NO hay elementos en physicalExams -->
                        <div class="col-12 text-center" v-if="physicalExams.length == 0">
                            <span class="fw-semibold color-main">No hay registros</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel-border my-2">
            <label class="color-main fw-bold">EXAMENES AUXILIARES</label>
            <div class="d-md-flex flex-wrap">
                <div class="col-sm-12 px-1 mt-1 align-left" :class="itemE.id > 4 ? 'col-md-6' : 'col-md-3'" v-for="(itemE, indexE) in auxExam" :key="indexE">
                    <div class="form-group d-md-flex"">
                                    <label :for="'aux' + indexE" class="col-lg-4 col-md-3 col-sm-12 color-main fw-bold text-xs">
                        {{ itemE.label }}</label>
                        <div class="col-lg-8 col-md-9 col-sm-12">
                            <input type="text" class="form-control form-control-input form-control-sm" v-model="itemE.value" :id="'aux' + indexE" disabled />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel-border my-2">
            <div class="row py-2">
                <div class="col-xl-6 col-md-12">
                    <label class="color-main fw-bold">DIAGNOSTICO</label>
                    <ul class="list-group text-xs">
                        <li class="list-group-item" v-for="(item, index) in dxs" :key="index">
                            <span><b>{{ item.codigo }} : </b> {{ item.name }}</span>
                        </li>
                        <li class="list-group-item" v-if="dxs.length == 0">
                            No hay registros </li>
                    </ul>
                </div>
                <div class="col-xl-6 col-md-12">
                    <div class="d-md-flex flex-wrap">
                        <div class="col-xl-6 col-md-6 mt-1 mb-3 align-left px-1">
                            <div class="form-group row">
                                <label for="rxqx" class="col-sm-12 color-main fw-bold text-xs">RIESGO
                                    QUIRÚRGICO</label>
                                <div class="col-sm-12 mt-1 mb-1">
                                    <textarea v-model="data.surgical_risk" class="form-control form-control-sm" id="rxqx" rows="3" style="resize: none" disabled></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-6 mt-1 mb-3 align-left px-1">
                            <div class="form-group row">
                                <label for="rxa" class="col-sm-12 color-main fw-bold text-xs">RIESGO
                                    ANESTESICO</label>
                                <div class="col-sm-12 mt-1 mb-1">
                                    <textarea v-model="data.anesthetic_risk" class="form-control form-control-sm" id="rxa" rows="3" style="resize: none" disabled></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-6 mt-1 mb-3 align-left px-1">
                            <div class="form-group row">
                                <label for="rxn" class="col-sm-12 color-main fw-bold text-xs">RIESGO
                                    NEUMOLOGICO</label>
                                <div class="col-sm-12 mt-1 mb-1">
                                    <textarea v-model="data.pneumological_risk" class="form-control form-control-sm" id="rxn" rows="3" style="resize: none" disabled></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-6 mt-1 mb-3 align-left px-1">
                            <div class="form-group row">
                                <label for="sugerencias" class="col-sm-12 color-main fw-bold text-xs">SUGERENCIAS</label>
                                <div class="col-sm-12 mt-1 mb-1">
                                    <textarea v-model="data.suggestions" class="form-control form-control-sm" id="sugerencias" rows="3" style="resize: none" disabled></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
    <div v-else>
        <div class="alert alert-warning text-center" role="alert">
            No hay registros
        </div>

    </div>
</div>
</template>

<script>
export default {
    name: 'ReportRxqx',
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            // staff: []
        }
    },
    computed: {
        background() {
            let background = [];
            let array = JSON.parse(this.data.background);
            array.forEach(element => {
                if (element.state == true) {
                    background.push(element);

                }
            });
            return background;
        },
        physicalExams() {
            let physicalExams = JSON.parse(this.data.physical_exams);
            return physicalExams;
        },
        auxExam() {
            let auxExam = JSON.parse(this.data.auxiliary_exams);
            return auxExam;
        },
        dxs() {
            let dxs = JSON.parse(this.data.diagnostics);
            return dxs;
        }
    },
}
</script>
