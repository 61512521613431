<template>
<div>
    <div id="app" class="px-2 mt-3">
        <div class="d-lg-flex mt-3">
            <div class="col-xl-1 mb-2">
                <div class="btn-success text-start ">
                    <button type="button" class="btn text-white btn-sm text-start" @click="mtdBackSop">
                        <i class="fas fa-arrow-left"></i> Atr&aacute;s
                    </button>
                </div>
            </div>
            <div class="col-xl-6 col-lg-7 col-md-12 rounded-3 px-0 bg-blue mb-2 text-white">
                <div class="row mx-0">
                    <div class="h-100 py-1 mx-0 col-sm-12 col-md-9 col-xl-9 px-0 text-x border rounded-start d-inline-flex">
                        <span class=" align-middle col-sm-4 h-100 px-2 text-start">Nombre y Apellido:</span>
                        <span class=" align-middle col-sm-8 h-100 px-0 text-start ">{{ datapatients.patient_name }}</span>
                    </div>
                    <div class="h-100 mx-0 text-x   px-0 py-1 col-sm-12 col-md-3 col-xl-3 border rounded-end d-inline-flex">
                        <span class=" align-middle col-sm-4 h-100 px-2 text-start">Edad:</span>
                        <span class=" align-middle col-sm-7 h-100 px-0 text-sm-start text-xl-center">{{datapatients.patient_year }}</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-2 mb-2 px-1 px-md-1 px-xl-3">
                <div class="card border border-main pt-1">
                    <span class="color-main fw-bold text-uppercase">Post-Operatorio</span>
                </div>
            </div>
            <div class="col-xl-2 col-lg-2 mb-1" v-if="rolname == 'DOCTOR(A)' && typeDoctor == 1">
                <button type="button" class="btn text-white btn-sm bg-main text-xs w-100" @click="mtdaddall">
                    <i class="fas fa-save"></i>  GUARDAR
                </button>
            </div>
        </div>
    </div>
    <!-- TABs -->
     <div>
        <ul class="nav pb-0 mt-2 d-none d-lg-flex" id="myTabHijoHistorial" role="tablist">
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('nota')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'nota' }" id="profile-tab" type="button">
                    <strong class="text-xxs">NOTA DE INGRESO</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('rp')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'rp' }" id="profile-tab" type="button">
                    <strong class="text-xxs">RP MEDICO</strong>
                </button>
            </li>
            <li class="nav-item-h" role="presentacion">
                <button @click="changeSubPage('kardex')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'kardex' }" id="profile-tab" type="button">
                    <strong class="text-xxs">KARDEX SOP</strong>
                </button>
            </li>
            <li class="nav-item-h" role="presentacion">
                <button @click="changeSubPage('notaenfermeria')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'notaenfermeria' }" id="profile-tab" type="button">
                    <strong class="text-xxs">NOTAS ENF.</strong>
                </button>
            </li>
            <li class="nav-item-h" role="presentacion">
                <button @click="changeSubPage('balance')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'balance' }" id="profile-tab" type="button">
                    <strong class="text-xxs">BALANCE</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('monitoreo')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'monitoreo' }" id="profile-tab" type="button">
                    <strong class="text-xxs">MONITOREO</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('hojag')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'hojag' }" id="profile-tab" type="button">
                    <strong class="text-xxs">HOJA GRAFICA</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('informeoperatorio')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'informeoperatorio' }" id="profile-tab" type="button">
                    <strong class="text-xxs">INFORME OPERATORIO</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('postanestecia')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'postanestecia' }" id="profile-tab" type="button">
                    <strong class="text-xxs">POST ANESTECIA</strong>
                </button>
            </li>
             
        </ul>

        
        <div class="tab-content mb-3 d-none d-lg-block" id="myTabContentHijo">
            <Informeoperatorio v-if="subpage=='informeoperatorio'" :medical_act_id="medical_act_id" :addReportSop="addReportSop" @finishAddOrder="finishAddOrder" :rolname="rolname" />
            <NotaIngreso v-if="subpage=='nota'" :addNota="addNota" :medical_act_id="medical_act_id" :dete_out="date_out" :rolname="rolname"  :dete_out_doctor="date_out_doctor" @mtdaddrpexit="mtdaddrpexit"></NotaIngreso>
            <Rp v-if="subpage=='rp'" :process="sop_process" :addRp="addRp" @mtdaddrpexit="mtdaddrpexit" :medical_act_id="datapatients.medical_act_id" :dete_out="date_out" :rolname="rolname"  :dete_out_doctor="date_out_doctor"/>
            <Kardex v-if="subpage == 'kardex'" :process="sop_process" :medical_act_id="medical_act_id" :datapatients="datapatients" :dete_out="date_out" :rolname="rolname" :dete_out_doctor="date_out_doctor"/>
            <Notaenfermeria v-if="subpage == 'notaenfermeria'" :process="sop_process" :medical_act_id="medical_act_id"  :dete_out="date_out" :dete_out_doctor="date_out_doctor" :rolname="rolname"/>
            <Balance v-if="subpage=='balance'" :process="sop_process" :medical_act_id="medical_act_id" :rolname="rolname"/>
            <Monitoreo v-if="subpage=='monitoreo'" :medical_act_id="medical_act_id" :rolname="rolname" :dete_out="date_out" :dete_out_doctor="date_out_doctor"></Monitoreo>
            <HojaGrafica v-if="subpage=='hojag'" :medical_act_id="medical_act_id" :rolname="rolname"></HojaGrafica>
            <Postanestecia v-if="subpage=='postanestecia'" :medical_act_id="medical_act_id" :addPostAnestecia="addPostAnestecia" @mtdaddrpexit="mtdaddrpexit"/>
        </div>

        <!-- Tablet -->
        <div id="app" class="root d-md-block d-lg-none">
            <div class="wrapper-list">
                <dropdown title="Nota de Ingreso Urpa" component="notaingreso" :addNota="addNota" :medical_act_id="medical_act_id" :dete_out="date_out" :rolname="rolname"  :dete_out_doctor="date_out_doctor" @mtdaddrpexit="mtdaddrpexit"></dropdown>
                <dropdown title="Rp" component="Rp" :addRp="addRp" @mtdaddrpexit="mtdaddrpexit" :process="sop_process" :medical_act_id="datapatients.medical_act_id" :date_out="date_out" :rolname="rolname"  :date_out_doctor="date_out_doctor"></dropdown>
                <dropdown title="Kardex" component="Kardex" :medical_act_id="medical_act_id" :process="sop_process" :datapatients="datapatients" :dete_out="date_out" :dete_out_doctor="date_out_doctor" :rolname="rolname"></dropdown>
                <dropdown title="Nota de Enfermeria" component="Notaenfermeria" :process="sop_process" :medical_act_id="medical_act_id"  :dete_out="date_out" :dete_out_doctor="date_out_doctor" :rolname="rolname"></dropdown>
                <dropdown title="Balance" component="Bhe" :medical_act_id="medical_act_id" :process="sop_process" :rolname="rolname"  :dete_out="date_out" :dete_out_doctor="date_out_doctor"></dropdown>                
                <dropdown title="Monitoreo" component="Mhemodinamico" :medical_act_id="medical_act_id" :process="sop_process" :rolname="rolname"  :dete_out="date_out" :dete_out_doctor="date_out_doctor"></dropdown>                
                <dropdown title="Hoja Gráfica" component="HojaG" :medical_act_id="medical_act_id" :process="sop_process" :rolname="rolname"  :dete_out="date_out" :dete_out_doctor="date_out_doctor"></dropdown>  
                <dropdown title="Post anestecia" component="Postanestecia" :medical_act_id="medical_act_id"></dropdown>        
                <dropdown title="Informe operatorio" component="Informeoperatorio" :medical_act_id="medical_act_id" :rolname="rolname" :addReportSop="addReportSop" @finishAddOrder="finishAddOrder" @changeSubPage="changeSubPage"></dropdown>       
            </div>
        </div>
     </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import NotaIngreso from '@/components/Asistencial/Sop/Historia/NotaIngresoUrpa.vue';
import Rp from '@/components/Asistencial/Sop/Historia/Rp.vue';
import Kardex from '@/components/Asistencial/Sop/Historia/Kardex.vue'
import Notaenfermeria from '@/components/Asistencial/Sop/Historia/Notaenfermeria.vue';
import Balance from '../Historia/Bhe.vue';
import Monitoreo  from '../Historia/Mhemodinamico.vue';
import HojaGrafica from '../Historia/Hojagrafica.vue';
import Dropdown from '@/components/Asistencial/Sop/Dropdown.vue';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import Postanestecia from '@/components/Asistencial/Sop/Historia/Postanestecia.vue';
import Informeoperatorio from '@/components/Asistencial/Sop/Historia/Informeoperatorio.vue';
export default {
    name: 'c-asistencial-sop-preoperatorio',
    props: {
        rolname: String,
        datapatients: {
            type: Object,
            default: () => ({})
        },
        typeDoctor: {
            type: Number,
            default: 1,
        }
    },
    components: {
        Rp,
        Notaenfermeria,
        Kardex,
        Balance,
        NotaIngreso,
        Monitoreo,
        SweetAlert,
        HojaGrafica,
        Dropdown,
        Postanestecia,
        Informeoperatorio
    },
    data() {
        return {
            sop_process: 2,
            subpage: 'nota',
            swal: null,
            medical_act_id: 0,
            date_out: false,
            date_out_doctor:false,
            addRp: null,
            addNota: null,
            addPostAnestecia: null,
            addReportSop: null,
        }
    },
    watch:{
        datapatients: {
            handler: function (newValue) {
                if (newValue) {
                    this.medical_act_id = newValue.medical_act_id;
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdBackSop: function () {
            this.$emit('mtdBackSop');
        },
        changeSubPage: function (subpage) {
            this.subpage = subpage;
        },
        mtdaddall: function () {
            const areEqual = JSON.stringify(this.$miVariableGlobalUrpa) === JSON.stringify(this.$miVariableGlobalUrpa_plantilla);
            const areEqualrp = JSON.stringify(this.$miVariableGlobalRpSop) === JSON.stringify(this.$miVariableGlobalRpSop_plantilla);
           /*  const areEqualsheet = JSON.stringify(this.$miVariableGlobalSheetEmergency) === JSON.stringify(this.$miVariableGlobalSheetEmergency_plantilla); */
            if (!areEqual) {
                this.post({
                        url: this.$store.getters.get__url + "/Sop/storeNotaUrpa",
                        token: this.$store.getters.get__token,
                        params: this.$miVariableGlobalUrpa,
                    })
                    .then((response) => {
                        this.addNota = 1
                        if (!areEqualrp) {
                            this.mtdaddrp()
                        } else {
                            this.$refs.SweetAlert.showSuccess('Registro exitoso de Nota de Ingreso Urpa');
                        }
                    })
                    .catch((errors) => {
                        console.log(errors);
                    });
            } else {
                if (!areEqualrp) {
                    this.mtdaddrp()
                } else {
                    if(this.subpage == 'postanestecia'){
                        this.addPostAnestecia = 1;
                    }else if(this.subpage == 'informeoperatorio'){
                        this.addReportSop = 1;
                    }else{
                        this.$refs.SweetAlert.showWarning("Registre Nota de ingreso Urpa o Rp");
                    }
                }
            }

        },
        mtdaddrp: function () {
            let count = 0
            this.$miVariableGlobalRpSop.recipe.forEach(element => {
                if(element.type == 1 && element.total != '' && element.group == 0){
                    count++
                }
             });
           
           if(count == 0){
                this.post({
                        url: this.$store.getters.get__url + "/Sop/storeRp",
                        token: this.$store.getters.get__token,
                        params: {
                            ... this.$miVariableGlobalRpSop,
                            process: 2,
                        },
                    })
                    .then((response) => { //   
                        this.addRp = 1
                        this.$refs.SweetAlert.showSuccess(response.message);
                    })
                    .catch((errors) => {
                        console.log(errors);
                    });
            }else{
                this.$refs.SweetAlert.showWarning("Medicamentos para realizar mezcla");
             }
        },
        mtdaddrpexit: function () {
            this.addRp = null;
            this.addNota = null;
            this.addPostAnestecia = null;
        },
        finishAddOrder: function(){
            // this.addOrder = 0;
            // this.addRxqx = 0;
            this.addReportSop = 0;
            // this.addPreAnestecia = 0;
        },
    }
}
</script>
