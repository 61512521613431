<template>
<div>
    <div class="px-3">
        <div id="btn-open-caja" class="d-flex justify-content-end pt-2 my-2">
            <div class="btn-group btn-home" role="group">
                <button type="button" class="btn btn-outline-main bg-main text-white" @click="returnHome">
                    <i class="fas fa-home"></i> Inicio
                </button>
            </div>
        </div>
        <div class="row row-home  " id="div_conte_2">
            <div id="div_conte-home" class="col-md-10 mx-auto mt-0 border border-2 rounded rounded-2 border-main">
                <div class="  bg-opacity-10">
                    <div class="text-center py-4 px-5" >
                        <div class=" row row-home">
                            <div class=" border-main card-book border border-2 card div-pointer col m-2 p-2 " @click="changePage('almacen')">
                                <div class="card-body">
                                    <h5 class="card-title color-main">ALMACENES</h5>
                                    <div class="icon-book">
                                        <img src="../../../../assets/almacen.png" alt="" class="img-fluid-bed-sop rounded-start">
                                    </div>
                                 
                                </div>
                       
                            </div>
                            <div class=" border-main card-book border border-2 card div-pointer col m-2 p-2  " @click="changePage('central')">
                                <div class="card-body">
                                    <h5 class="card-title color-main">CENTRAL DE COSTOS</h5>
                                    <div class="icon-book">
                                        <img src="../../../../assets/presupuesto.png" alt="" class="img-fluid-bed-sop rounded-start">
                                    </div>
                                </div>
                           
                            </div>
                            <!-- <div class=" border-main card-book  border border-2 card div-pointer col m-2 p-2  " @click="changePage('insumos')">
                                <div class="card-body">
                                    <h5 class="card-title color-main">INSUMOS</h5>
                                    <div class="icon-book">
                                        <img src="../../../../assets/jeringa.png" alt="" class="img-fluid-bed-sop rounded-start">
                                    </div>
                                </div>
                           
                            </div> -->
                            <div class=" border-main card-book border border-2 card div-pointer col m-2 p-2  " @click="changePage('inventario')">
                                <div class="card-body">
                                    <h5 class="card-title color-main"> INVENTARIO</h5>
                                    <div class="icon-book">
                                        <img src="../../../../assets/inventario.png" alt="" class="img-fluid-bed-sop rounded-start">
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div class=" row row-home">
                            <div class=" border-main  card-book border border-2 card div-pointer col m-2 p-2  " @click="changePage('marcas')">
                                <div class="card-body">
                                    <h5 class="card-title color-main"> MARCAS</h5>
                                    <div class="icon-book">
                                        <img src="../../../../assets/marca.png" alt="" class="img-fluid-bed-sop rounded-start">
                                    </div>
                                </div>
                              
                            </div>
                            <div class=" border-main card-book border border-2 card div-pointer col m-2 p-2  " @click="changePage('medic')">
                                <div class="card-body">
                                    <h5 class="card-title color-main"> PRODUCTOS </h5> 
                                    <div class="icon-book">
                                        <img src="../../../../assets/productosLogistica.png" alt="" class="img-fluid-bed-sop rounded-start">
                                    </div>
                                </div>
                              
                            </div>
                            <div class=" border-main card-book border border-2 card div-pointer col m-2 p-2  " @click="changePage('price')">
                                <div class="card-body">
                                    <h5 class="card-title color-main">PRECIOS DE COMPRAS</h5>
                                    <div class="icon-book">
                                        <img src="../../../../assets/precio.png" alt="" class="img-fluid-bed-sop rounded-start">
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                        <div class=" row row-home">
                            <div class=" border-main card-book border border-2 card div-pointer col m-2 p-2  " @click="changePage('provider')">
                                <div class="card-body">
                                    <h5 class="card-title color-main"> PROVEEDORES</h5>
                                    <div class="icon-book">
                                        <img src="../../../../assets/proveedor.png" alt="" class="img-fluid-bed-sop rounded-start">
                                    </div>
                                </div>
                             
                            </div>
                            <div class=" border-main card-book border border-2 card div-pointer col m-2 p-2  " @click="changePage('servicios')">
                                <div class="card-body">
                                    <h5 class="card-title color-main"> SERVICIOS</h5>
                                    <div class="icon-book">
                                        <img src="../../../../assets/servicios.png" alt="" class="img-fluid-bed-sop rounded-start">
                                    </div>
                                </div>
                              
                            </div>
                            <!-- <div class=" border-main card-book border border-2 card div-pointer col m-2 p-2  " @click="changePage('soluciones')">
                                <div class="card-body">
                                    <h5 class="card-title color-main"> SOLUCIONES</h5>
                                    <div class="icon-book">
                                        <img src="../../../../assets/soluciones.png" alt="" class="img-fluid-bed-sop rounded-start">
                                    </div>
                                </div>
                             
                            </div> -->
                            <div class=" border-main card-book border border-2 card div-pointer col m-2 p-2  " @click="changePage('unit')">
                                <div class="card-body">
                                    <h5 class="card-title color-main">    UNIDADES</h5>
                                    <div class="icon-book">
                                        <img src="../../../../assets/unidad.png" alt="" class="img-fluid-bed-sop rounded-start">
                                    </div>
                                </div>
                           
                            </div>
                            <div class=" border-main card-book border border-2 card div-pointer col m-2 p-2  " @click="changePage('subsub')">
                                <div class="card-body">
                                    <h5 class="card-title color-main nowrap">SUB CATEGORIA</h5>
                                    <div class="icon-book">
                                        <img src="../../../../assets/productosLogistica.png" alt="" class="img-fluid-bed-sop rounded-start">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

    
<script>
export default {
    name: 'homeLogistics',
    data() {
        return {
            page: null
        }
    },
    methods: {
        changePage(page) {
            this.$emit('changePage', page)
        },
        mtdBackCampus: function () {
            this.$emit('mtdBackCampus')
        },
        returnHome:function(){
            this.$emit('returnHome')
        }
    }
}
</script>
