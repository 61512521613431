<template>
<CModal @mtdclosemodal="mtdclosemodal" :show.sync="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-2 row mx-0 mb-2">
        <ul class="list-group" v-for="(item1, index1) in medicalStaff" :key="index1">
            <li class="list-group-item">
                <span class="color-main fw-semibold">{{item1.label}}</span>:  <span>{{item1.value ?? '-'}}</span>
            </li>
        </ul>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";
export default {
    name: "c-modal-showStaff",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            medicalStaff: [],
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        payload: {
            type: Object,
            default: () => ({})
        },
    },
    watch: {
        payload: {
            handler: function (newValue) {
                if (newValue) {

                    if (typeof newValue.medical_staff === 'string') {
                        this.medicalStaff = JSON.parse(newValue.medical_staff);
                    } else {
                        this.medicalStaff = newValue.medical_staff;
                    }

                }
            },
            deep: true,
            immediate: true,
        }
    },

    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal(opt) {
            this.file = '';
            this.$emit('mtdcloseModal', opt);
        },

    },
};
</script>
