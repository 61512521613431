<template>
<div class="col-md-12" v-if="data">
    <div class="row mt-2">
        <div class="panel-border my-1 px-0">
            <div class="d-md-flex text-xs">
                <div class="col-md-3 bg-main text-white p-2">
                    <span class="fw-bold">INFORME OPERATORIO</span>
                </div>
                <template v-if="data">
                    <div class="col-md-3 p-2">
                        <span class="fw-bold color-main">Hora de Inicio:</span>&nbsp;
                        <span class="fw-semibold">{{ data.start_time }}</span>
                    </div>
                    <div class="col-md-3 p-2">
                        <span class="fw-bold color-main">Hora Final:</span>&nbsp;
                        <span class="fw-semibold">{{ data.end_time }}</span>
                    </div>
                    <div class="col-md-3 p-2">
                        <span class="fw-bold color-main">Tiempo:</span>&nbsp;
                        <span class="fw-semibold">{{ data.operating_time }}</span>
                    </div>
                </template>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="panel-border my-1 p-md-3">
            <div class="d-md-flex gap-2 my-2">
                <div class="col-md-6 col-xl-6 col-sm-12 my-1">
                    <label for="story" class="color-main fw-bold text-xs">PERSONAL MEDICO</label>
                    <div id="div-signo" class="form-group row align-left">
                        <div class="col-xl-12 col-sm-12 mt-1">
                            <ul class="list-group" v-for="(item, index) in staff" :key="index">
                                <li class="list-group-item" v-if="index !== staff.length - 1">
                                    <b>{{ item.label }} : </b> {{ item.value }} ( {{ item.colegio }}: {{ item.codigo }} )
                                </li>
                                <li class="list-group-item" v-if="index == staff.length - 1">
                                    <b>{{ item.label }} : </b> {{ item.value }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row form-group align-left mt-2">
                        <label class="text-xs color-main fw-bold">Estado físico pre operatorio (Escala ASA)</label>
                        <div class=" mt-1">
                            <input type="text" name="" id="physical" class="form-control form-control-sm" v-model="data.asa" disabled>
                        </div>

                    </div>
                    <div class="row form-group align-left">
                        <label class="text-xs color-main fw-bold">Riesgo anestésico quirúrgico (Categoría)</label>
                        <div class=" mt-1">
                            <input type="text" name="" id="risk" class="form-control form-control-sm" v-model="data.category" disabled>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-6 col-sm-12 my-1">
                    <!-- diagnosticos -->
                    <div class="row form-group align-left">
                        <label class="text-xs color-main fw-bold">Diagnóstico Operatorio</label>
                        <div class=" mt-1">
                            <textarea v-model="data.operative_diagnosis" placeholder="-" class="form-control text-xs" aria-label="With textarea" id="operative" disabled></textarea>
                        </div>
                    </div>
                    <div class="row form-group align-left">
                        <label class="text-xs color-main fw-bold">Operacion realizada</label>
                        <div class=" mt-1">
                            <textarea v-model="data.operation" placeholder="-" class="form-control text-xs" aria-label="With textarea" id="operation" disabled></textarea>
                        </div>
                    </div>
                    <div class="row form-group align-left">
                        <label class="text-xs color-main fw-bold">Hallazgos y procedimientos</label>
                        <div class=" mt-1">
                            <textarea v-model="data.findings" placeholder="-" class="form-control text-xs" aria-label="With textarea" id="findings" disabled></textarea>
                        </div>
                    </div>
                    <div class="row form-group align-left">
                        <label class="text-xs color-main fw-bold">Observaciones(Complicaciones Quirúrgica)</label>
                        <div class=" mt-1">
                            <textarea v-model="data.observations" placeholder="-" class="form-control text-xs" aria-label="With textarea" id="observatio" disabled></textarea>
                        </div>
                    </div>
                    <div class="row form-group align-left">
                        <label class="text-xs color-main fw-bold">Anatomía patológica</label>
                        <div class=" mt-1">
                            <textarea v-model="data.pathological_anatomy" placeholder="-" class="form-control text-xs" aria-label="With textarea" id="anatomy" disabled></textarea>
                        </div>
                    </div>
                    <div class="row form-group align-left">
                        <label class="text-xs color-main fw-bold">Diagnóstico post. operatorio</label>
                        <div class=" mt-1">
                            <textarea v-model="data.postoperative_diagnosis" placeholder="-" class="form-control text-xs" aria-label="With textarea" id="diagnosis" disabled></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="px-0" v-else>
    <div class="alert alert-info mt-3" role="alert">
        No se ha encontrado información
    </div>
</div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        staff() {
            let staff = JSON.parse(this.data.staff);
            return staff;
        }
    }
}
</script>
