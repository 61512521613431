<template>
  <div>
    <div class="container mt-5" v-if="view == 'carrito'">
      <div
        id="div-modal-venta"
        style="margin-top: -15px"
        class="d-flex justify-content-center"
      >
        <div id="div-venta" class="card w-100">
          <div class="card-header text-white h5 text-center" style="background: #900052">
            <strong>AGREGAR MEDICAMENTOS</strong>
          </div>
          <div class="card-body" style="background-color: #e6e6e6">
            <div class="row">
              <div class="col-md-6" id="div-venta-body">
                <div class="col-md-12">
                  <div class="input-group input-group-sm mb-1">
                    <span
                      class="input-group-text"
                      style="background: #900052; color: #ffffff"
                      ><i class="fas fa-search"></i
                    ></span>
                    <input
                      type="search"
                      id="search_product"
                      class="form-control"
                      placeholder="Digitar Medicamento"
                      aria-describedby="search_product"
                      v-model="search_product"
                      @keyup="mtd_filter_product"
                    />
                  </div>
                </div>

                <div class="row py-1" id="list-product">
                  <div
                    v-for="(item, index) in data_product_filter"
                    :key="index"
                    class="col-md-12 py-1 d-flex align-items-center"
                    id="item-product"
                    style="background-color: #e6e6e6; height: 80px"
                  >
                    <div
                      class="p-1 d-flex flex-column w-100 justify-content-center"
                      style="background-color: #ffffff; height: 100%"
                      @click="mtdSelectSubsubcatergorie(item)"
                    >
                      <div class="align-items-center" id="name-product">
                        <p class="d-flex align-items-center mb-0 m-1">
                          <strong
                            class="mb-0 item-name custom-tooltip d-flex justify-content-between align-items-center"
                            data-toggle="tooltip"
                            :data-placement="'top'"
                            :title="item.name"
                            :class="{
                              'text-success': item.total_quantity > 5,
                              'text-danger': item.total_quantity === 0,
                              'text-custom-light-green':
                                item.total_quantity <= 5 && item.total_quantity > 0,
                            }"
                            style="
                              width: 88%;
                              margin-left: 10px;
                              font-size: 11.5px;
                              margin-top: 10px;
                            "
                          >
                            {{ item.name }} &nbsp;
                            <span
                              style="
                                font-weight: bold;
                                font-size: 14px;
                                text-align: right;
                              "
                            >
                              ({{ item.total_quantity }})
                            </span>
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- carrito -->
              <div class="col-md-6 pt-3">
                <div class="col-md-12">
                  <div class="input-group input-group-sm mb-3">
                    <span
                      class="input-group-text rounded-pill w-100"
                      style="background: #900052; color: #ffffff; padding-left: 25px"
                      ><strong>MEDICAMENTOS ESCOGIDOS</strong></span
                    >
                  </div>
                </div>
                <div class="col-md-12 list-monto">
                  <div class="container-list">
                    <div class="" v-if="carrito.length > 0">
                      <div
                        class="box item-monto"
                        v-for="(item, index) in carrito"
                        :key="index"
                      >
                        <p class="text-monto">{{ item.name }}</p>
                        <div class="input-group" style="">
                          <div
                            align="right"
                            class="form-control"
                            v-if="posEditPrice != index"
                          >
                            <button
                              type="button"
                              class="rounded-circle m-1"
                              @click="mtdDelQuantity(index)"
                            >
                              <i class="fas fa-minus text-white"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12"></div>
            </div>
          </div>
          <div
            class="card-footer text-white"
            style="background: #900052; font-size: 20px"
          >
            <div class="row">
              <div class="col" align="left">
                <span class="btn border border-white text-white" @click="retroceder"
                  ><strong>ATRAS</strong></span
                >
              </div>
              <div align="right" class="col">
                <span
                  class="btn border border-white text-white"
                  style="margin-left: 20px"
                  @click="mtdNextStep"
                  ><strong>OK</strong></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
    <div class="container">
      <cModalCarroRegRecipe
        :title="modalReci.title"
        :boo_modal="modalReci.boo_modal"
        :item="modalReci.item"
        @mtdCloseModalDeta="mtdCloseModalReci"
        @registrarReci="mtdSelectSubCar"
      />
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import cModalCarroRegRecipe from "./cModalCarroRegRecipe.vue";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
export default {
  name: "c-pharmacy-cCarritoCli",
  components: {
    SweetAlert,
    cModalCarroRegRecipe,
  },
  props: {
    recipe_id: {
      type: Number,
      default: 0,
    },
    patient_id: {
      type: Number,
      default: 0,
    },
    medical_act_id: {
      type: Number,
      default: 0,
    },
    hc_id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      swal: null,
      view: "carrito",
      dataSubSubCategories: [],
      data_product_filter: [],
      search_product: "",
      carrito: [],
      item_carrito: {},
      posEditPrice: null,
      stock: [],
      modalReci: {
        title: "Nueva Receta",
        item: [],
        boo_modal: false,
        pos: null,
      },
    };
  },
  computed: {},
  created() {},
  methods: {
    ...mapActions(["get", "post"]),

    retroceder() {
      this.$emit("mtdBack");
    },

    mtdGetDataSubSubCategories: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/Pharmacy/regRecipe/" +
          this.recipe_id +
          "/mtdGetCarrito?search_product=" +
          this.search_product,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.dataSubSubCategories = response.data;
          this.data_product_filter = response.data;
        })
        .catch((errors) => {
          this.$refs.SweetAlert.showError(errors);
        });
    },

    mtd_filter_product: function () {
      if (search_product.value.length >= 5 && search_product.value != "") {
        let q = search_product.value.toString().toUpperCase();
        this.mtdGetDataSubSubCategories(q);
        this.data_product_filter = [];
        let i = 0;
        this.dataSubSubCategories.forEach((element) => {
          let name = element.name.toString().toUpperCase();
          if (name.includes(q)) {
            this.data_product_filter.push(element);
          }
        });
      } else {
        this.data_product_filter = this.dataSubSubCategories;
      }
    },

    /** carrito */
    mtdSelectSubsubcatergoriee: function (item) {
      this.modalReci.boo_modal = true;
      this.modalReci.title = "Nueva Receta: " + item.name;
      let bo = false;
      if (this.carrito.length == 0) {
        this.item_carrito = {
          id: item.id,
          name: item.name,
          q: 1,
          dataRe: {
            via: "",
            frecuencia: 0,
            duracion: 0,
            total: 0,
            type: 0,
          },
        };

        this.modalReci.item = item;
        if (this.modalReci.boo_modal !== true) {
          this.carrito.push(this.item_carrito);
        }

        bo = true;
      } else {
        this.carrito.forEach((element) => {
          if (element.id == item.id) {
            this.modalReci.boo_modal = false;
            this.$refs.SweetAlert.showWarning(
              "Ya está agregado el Medicamento en el Carrito"
            );
            bo = true;
          }
        });
      }
      if (!bo) {
        this.item_carrito = {
          id: item.id,
          name: item.name,
          q: 1,
          dataRe: {
            via: "",
            frecuencia: 0,
            duracion: 0,
            total: 0,
            type: 0,
          },
        };
        this.modalReci.item = item;
        if (this.modalReci.boo_modal !== true) {
          this.carrito.push(this.item_carrito);
        }
        bo = false;
      }
      this.search_product = "";
    },

    mtdSelectSubsubcatergorie: function (item) {
      this.modalReci.boo_modal = true;
      this.modalReci.title =
        "Nueva Receta: " + item.name + " (" + item.total_quantity + ")";

      // Verifica si el producto ya está en el carrito
      const existingItem = this.carrito.find((element) => element.id === item.id);

      if (existingItem) {
        this.modalReci.boo_modal = false;
        this.$refs.SweetAlert.showWarning(
          "Ya está agregado el Medicamento en el Carrito"
        );
      } else {
        // Si no existe, agrega el producto al carrito
        this.item_carrito = {
          id: item.id,
          name: item.name,
          q: 1,
          dataRe: {
            via: "",
            frecuencia: 0,
            duracion: 0,
            total: 0,
            type: 0,
          },
        };

        this.modalReci.item = item;
      }
      this.search_product = "";
    },

    mtdSelectSubCar: function (item) {
      this.item_carrito.dataRe.via = item.via;
      this.item_carrito.dataRe.frecuencia = item.frecuencia;
      this.item_carrito.dataRe.duracion = item.duracion;
      this.item_carrito.dataRe.total = item.total;
      this.item_carrito.dataRe.type = item.type;
      this.carrito.push(this.item_carrito);
    },

    mtdDelQuantity: function (pos) {
      let cantidad = this.carrito[pos].quantity;
      let temp = [];
      this.carrito.forEach((element, index) => {
        if (index != pos) {
          temp.push(element);
        }
      });
      this.carrito = temp;
    },

    mtdNextStep: function () {
      if (this.carrito.length > 0) {
        
        this.$refs.SweetAlert.showConfirmSimple2("","¿Estás Seguro?","warning","Confirmar") 
          .then((result) => {
              if (result.value) {
                this.$emit("mtdSendCarrito", this.patient_id, this.medical_act_id, this.hc_id, this.recipe_id, this.carrito);
                this.carrito = [];
              }
          });

      } else {
        this.$refs.SweetAlert.showWarning("Seleccione un Medicamento");
      }
    },

    mtdCloseModalReci: function () {
      this.modalReci = {
        title: "Nueva Receta",
        item: [],
        boo_modal: false,
        pos: null,
      };
    },
  },
};
</script>

<style scoped>
.btn-home {
  z-index: 1;
}

.text-custom-light-green {
  color: #60d004;
}

#div-venta-body {
  height: 450px;
  overflow: auto;
  box-sizing: border-box;
  padding: 1rem 1rem;
}

#div-venta-body::-webkit-scrollbar {
  -webkit-appearance: none;
}

#div-venta-body::-webkit-scrollbar:vertical {
  width: 10px;
}

#div-venta-body::-webkit-scrollbar-button:increment,
#div-venta-body::-webkit-scrollbar-button {
  display: none;
}

#div-venta-body::-webkit-scrollbar:horizontal {
  height: 10px;
}

#div-venta-body::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

#div-venta-body::-webkit-scrollbar-track {
  border-radius: 10px;
}

#name-product {
  color: #8a8a97;
  font-size: 13px;
}

.list-doctor,
.list-monto {
  padding-right: 25px;
}

.text-doctor {
  color: #900052;
  font-size: 13px;
  font-weight: bold;
}

.text-monto {
  color: #900052;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 0px;
}

.container-list {
  margin: 30px;
  width: 100%;
}

.box {
  margin-bottom: 10px;
  position: relative;
}

.box::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  background: #808080;
  left: -15px;
  top: 30px;
}

.box::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #900052;
  left: -23px;
  top: 10px;
}

.item-doctor,
.item-monto {
  padding: 10px 10px;
  border: #900052 3px solid;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.item-doctor:hover {
  cursor: pointer;
  background-color: #900052;
}

.item-doctor:hover p {
  color: #ffffff !important;
}

#item-product {
  align-items: center;
}

#item-product:hover {
  cursor: pointer;
  align-items: center;
  transform: scale(1.05);
}

.item-monto .input-group-text,
.item-monto .form-control {
  background-color: #e6e6e6 !important;
  border: 0 !important;
  padding: 0px;
}

.item-monto .form-control button {
  background-color: #808080 !important;
  border: 0 !important;
}

.item-monto .form-control button:hover {
  background-color: #000000 !important;
  border: 0 !important;
}
.item-name {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item-name strong {
  padding: 0px 10px;
  width: 120px;
}

.circulo {
  vertical-align: middle;
  border-style: none;
}

@media (max-width: 800px) {
  #btn-open-caja {
    justify-content: center !important;
  }

  #btn-registro-venta {
    text-align: center;
  }

  #div-tabla-venta {
    overflow-x: auto;
  }
}

@media (min-width: 800px) {
  #btn-registro-venta {
    text-align: left;
  }
}
#table-stock tbody tr:hover {
  background-color: #f3e2ec;
}
</style>
