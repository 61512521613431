<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show.sync="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'lg'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
    :scrollable="true"
  >
    <div class="row d-flex">
      <div class="col-md-12 col-lg-12 col-xs-12 text-center">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-md-3" id="document_type"
            >Dr. encargado</span
          >
          <v-select
            :options="doctors"
            class="form-control form-control-sm"
            :reduce="(name) => name.id"
            label="name"
            placeholder="[Seleccione un doctor...]"
            required
            v-model="pdf.doctor"
          />
        </div>
      </div>
      <div class="col-md-12 col-lg-12 col-xs-12 text-center">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-md-3" id="document_type">Area</span>
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Area"
            v-model="pdf.area"
          />
        </div>
      </div>
      <div class="col-md-12 col-lg-12 col-xs-12 text-center">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-md-3" id="document_type"
            >Desde</span
          >
          <input
            type="date"
            class="form-control form-control-sm"
            v-model="pdf.ind"
          />
        </div>
      </div>
      <div class="col-md-12 col-lg-12 col-xs-12 text-center">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-md-3" id="document_type"
            >Hasta</span
          >
          <input
            type="date"
            class="form-control form-control-sm"
            v-model="pdf.end"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <button @click="mtdClose" color="danger" class="btn btn-danger btn-sm">
        <i class="fa fa-times" />&nbsp;Cerrar
      </button>
      &nbsp; &nbsp;
      <button class="btn btn-success btn-sm" @click="mtdSendData">
        <i class="fas fa-save" />&nbsp; Registrar
      </button>
      <button class="btn btn-primary btn-sm" @click="mtdViewTempLiqui">
        <i class="fas fa-eye" />&nbsp; Ver pre-liquidación
      </button>
    </template>
  </CModal>
</template>
  
<script>
import CModal from "../../shared/modals/CModal.vue";
import CButtonClose from "../../shared/modals/cButtonClose.vue";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "c-admision-modal-detalle",
  components: {
    CModal,
    CButtonClose,
  },
  data() {
    return {
      pdf: {
        doctor:null,
        area: null,
        ind: null,
        end: null,
      },
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    doctors: [],
  },
  computed: {},
  methods: {
    ...mapActions(["get", "post"]),

    mtdClose() {
      this.$emit("closeModalPdfLiquidacion");
    },
    mtdSendData: function () {
      if (!this.pdf.doctor) {
        Swal.fire({
          text: "Seleccione un Doctor",
          icon: "warning",
          confirmButtonColor: "#900052",
        });
        return;
      }
      if (!this.pdf.area) {
        Swal.fire({
          text: "Digite un Área",
          icon: "warning",
          confirmButtonColor: "#900052",
        });
        return;
      }
      if (!this.pdf.ind) {
        Swal.fire({
          text: "Seleccione Fecha de Inicio",
          icon: "warning",
          confirmButtonColor: "#900052",
        });
        return;
      }
      if (!this.pdf.end) {
        Swal.fire({
          text: "Seleccione Fecha de Fin",
          icon: "warning",
          confirmButtonColor: "#900052",
        });
        return;
      }
      this.$emit("mtdSendDataPdfLiquidacion", this.pdf);
      this.pdf = {
        doctor: null,
        area: null,
        ind: null,
        end: null,
      };
    },
    mtdViewTempLiqui: function(){
      this.$emit("mtdViewTempLiqui", this.pdf);
    }
  },
  filters: {
    formatDate(date) {
      return date != null ? moment(date).format("DD/MM/YYYY") : "-";
    },
    formatTime(time) {
      let arTime = time.split(":");
      if (arTime[0] <= 12) {
        return time + " am";
      } else {
        return arTime[0] - 12 + ":" + arTime[1] + ":" + arTime[2] + " pm";
      }
    },
  },
};
</script>
  
<style scoped>
.inpt_color {
  text-align: center !important;
  background-color: cadetblue !important;
  border-radius: 20px;
  color: white;
}

.inpt_color::placeholder {
  color: white;
}

#table-body {
  font-size: 13px !important;
}

@media (max-width: 767px) {
  #table-body {
    font-size: 10px !important;
  }
}
</style>
  