<template>
<div>
    <div class="px-4">
        <div class="row">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="changePage('home')">
                    <i class="fas fa-home"></i> Atras
                </button>
            </div>
        </div>
        <div id="div-modal-cxc" class="d-flex justify-content-center">
            <div id="div-cxc" class="card w-100">
                <div class="text-center card-header text-white h4 bg-main">
                    <strong>INVENTARIO</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row mt-2 px-3">
                        <div class="col-md-12">
                            <div class="row d-flex">
                                <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 px-1 mb-2">
                                    <div class="input-group input-group">
                                        <span class="input-group-text col-lg-3 col-md-4" id="basic-addon1">Sede</span>
                                        <v-select :options="dataCampus" class="form-control form-control-sm" :reduce="(name) => name.id" label="name" placeholder="-- Seleccionar Sede--" required v-model="filter.campus_id" @input="mtdChooseAlmacen" />
                                    </div>
                                </div>
                                <div class="col-xl-5 col-lg-5 col-md-8 col-sm-9 col-xs-12 px-1 mb-2">
                                    <div class="input-group input-group">
                                        <span class="input-group-text col-lg-3 col-md-4" id="basic-addon1">Almacen</span>
                                        <v-select :options="dataAlmacen" class="form-control form-control-sm" :reduce="(name) => name.id" label="name" placeholder="-- Seleccionar Almacen--" required v-model="filter.almacen_id" @input="mtdGetData" />
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-4 col-sm-3 col-xs-12 btn-home mb-2">
                                    <button type="button" class="btn btn-bm-noradius w-100" @click="mtdShowModal('store',null)">
                                        <i class="fas fa-plus"></i> Nuevo
                                    </button>
                                </div>
                                <div class="col-xl-10 col-lg-9 col-md-9 col-sm-12 col-xs-12 px-1 mb-2">
                                    <div class="input-group input-group">
                                        <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                        <input v-model="search" @keyup.enter="calculateData(10)" @input="calculateData(10)" type="text" class="form-control form-control" placeholder="Buscar" />
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-xs-6 btn-home">
                                    <button type="button" class="btn btn-bm-noradius w-100">
                                        Cargar
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2 px-0" id="content-timelinetable">
                            <table id="tb-cxc" class="table table-bordered border-main">
                                <caption class="py-0 my-0"></caption>
                                <thead class="bg-main">
                                    <tr class=" table-th text-white text-center text-xs">
                                        <th class="table-th-number">N°</th>
                                        <th>
                                            MOLECULA
                                        </th>
                                        <th>
                                            NOMBRE COMERCIAL
                                        </th>
                                        <th>
                                            MARCA
                                        </th>

                                        <th>
                                            AÑO
                                        </th>
                                        <th>
                                            FECHA DE INVENTARIO
                                        </th>
                                        <th>
                                            LOTE
                                        </th>
                                        <th>
                                            FECHA DE EXPIRACION
                                        </th>
                                        <th>
                                            REGISTRO SANITARIO
                                        </th>
                                        <th>
                                            CODIGO SE BARRAS
                                        </th>
                                        <th>
                                            CANTIDAD FISICO
                                        </th>
                                        <th>
                                            CANTIDAD SISTEMA
                                        </th>
                                        <th>
                                            DIFERENCIA DE STOCK
                                        </th>

                                        <th class="table-th-number">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main">
                                    <tr class="table-body text-center text-xs" v-if="filter.campus_id==null || filter.almacen_id==null">
                                        <td colspan="15" class="text-center">Seleccione un Campus y un Almacen para ver Registros</td>
                                    </tr>
                                    <tr class="table-body text-center text-xs" v-for="(item, index) in cpData" :key="index">
                                        <td>
                                            {{mtdNumber(index) }}
                                        </td>
                                        <td>{{ item.productdetail.molecule }}</td>
                                        <td>{{ item.productdetail.commercial }}</td>
                                        <td>{{ item.productdetail.brand}}</td>
                                        <td>{{ item.year}}</td>
                                        <td>{{formatExpirationDate(item.date_inventary) }}</td>
                                        <td>{{ item.lote }}</td>
                                        <td>{{formatExpirationDate(item.expiration) }}</td>
                                        <td>{{ item.register_sanitary}}</td>
                                        <td>{{ item.barcode}}</td>
                                        <td>{{ item.quantity}}</td>
                                        <td>{{ item.quantity_system}}</td>
                                        <td>{{ mtdCalculeQuantity(index)}}</td>
                                        <td class="text-center">
                                            <div class="p-0 d-flex px-2 justify-content-arround">
                                                <a @click="mtdShowModal('edit',item)" class="a-success px-1" title="Editar">
                                                    <i class="fas fa-edit"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 text-center">
                            <div v-if="cpData.length > 0" class="d-flex justify-content-center mb-2">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <cModalAddStockInitial @mtdInsertData="mtdInsertData" :action="modal.action" :dataCampus="dataCampus" @mtdCloseModalStockInitial="mtdCloseModalStockInitial" :title="modal.title" :boo_modal="modal.modal_form" :payload="modal.payload" />
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalAddStockInitial from '@/components/Logistica/modals/Mantenimiento/cModalAddStockInitial.vue';

export default {
    name: "logis-maintenance-units",
    components: {
        SweetAlert,
        cModalAddStockInitial
    },
    data() {
        return {
            swal: null,
            search: "",
            dataShow: [],
            dataAlmacen: [],
            dataCampus: [],
            datastockinitial: [],
            dataProductDetail: [],
            filter: {

                campus_id: null,
                almacen_id: null,

            },
            modal: {
                title: "",
                action: "",
                modal_form: false,
                payload: {},
            },
            //Pag
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
        };
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
    },
    created() {
        this.mtdGetDataCampu();
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetDataCampu: function () {
            this.get({
                    url: this.$store.getters.get__url + "/logistics/getAlmacens",
                    token: this.$store.getters.get__token,
                }).then((response) => {
                    this.dataCampus = response.data;
                    this.filter.campus_id = this.$store.getters.get__campus;
                    this.mtdChooseAlmacen();
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        mtdChooseAlmacen: function () {
            this.dataAlmacen = [];
            this.dataCampus.forEach(element => {
                if (element.id === this.filter.campus_id) {
                    this.dataAlmacen = element.almacenes;
                }
            });
        },

        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/logistics/maintenance/stockinitial/filter",
                    token: this.$store.getters.get__token,
                    params: this.filter
                }).then((response) => {
                    this.datastockinitial = response.data;
                    this.calculateData(10);
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        //insertar data
        mtdInsertData(payload) {
            if (this.modal.action == "store") {
                this.post({
                        url: this.$store.getters.get__url + "/logistics/maintenance/stockinitial/store",
                        token: this.$store.getters.get__token,
                        params: payload,
                    })
                    .then((response) => {
                        if (response.statusCode == 200) {
                            this.$refs.SweetAlert.showSuccess("Registro Exitoso");
                            this.filter.campus_id = response.data.campu_id;
                            this.filter.almacen_id = response.data.almacen_id;
                            this.mtdCloseModalStockInitial();
                            this.mtdGetData();

                        } else {}
                    })
                    .catch((errors) => {
                        console.log(errors);
                    });
            } else {
                this.mtdModifyData(payload);
            }
        },

        mtdModifyData: function (payload) {

            this.post({
                    url: this.$store.getters.get__url + "/logistics/maintenance/stockinitial/update",
                    token: this.$store.getters.get__token,
                    params: payload,
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        this.$refs.SweetAlert.showSuccess("Modificación Exitosa");
                        this.dataShow.forEach((element) => {
                            if (element.id == payload.id) {

                                // element.name = response.data[0].name;
                                // element.state = response.data[0].state;
                                // element.categorie_name = response.data[0].categorie_name;

                                this.filter.campus_id = response.data.campu_id;
                                this.filter.almacen_id = response.data.almacen_id;
                                this.mtdCloseModalStockInitial();
                                this.mtdGetData();
                            }
                        });
                    }
                })
                .catch((errors) => {});
        },

        //metodo previo a la eliminación
        mtdBeforeDelete: function (id) {
            this.$refs.SweetAlert.showConfirmSimple2("", "¿Estás seguro?", "warning", "Eliminar")
                .then((result) => {
                    if (result.value) {
                        this.mtdDeleteData(id);
                    }
                });
        },

        mtdDeleteData: function () {

            this.post({
                    url: this.$store.getters.get__url + "/logistics/maintenance/stockinitial/destroy",
                    token: this.$store.getters.get__token,
                    params: this.stockInitials,
                })
                .then((response) => {
                    this.$refs.SweetAlert.showSuccess('Eliminado Correctamente');
                    this.mtdGetData();

                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);

                });
        },
        calculateData(items) {
            if (!this.datastockinitial || !this.datastockinitial.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.datastockinitial;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.datastockinitial.filter((element) => {
                    let name = element.productdetail.molecule.toString().toUpperCase();
                    return name.includes(query);
                });
                indexInitial = 1;
            }

            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );

            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            this.count = this.count + 1;
        },
        formatExpirationDate(date) {

            if (date === null) {
                return null;
            }

            const parts = date.split('-');
            const year = parseInt(parts[0]);
            const month = parseInt(parts[1]);
            const day = parseInt(parts[2]);

            const formattedDate = `${year}/${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;

            return formattedDate;
        },

        mtdCalculeQuantity: function (index) {
            const diferencia = this.dataShow[index].quantity_system - this.dataShow[index].quantity;
            if (diferencia >= 0) {
                return parseFloat(diferencia).toFixed(2)
            }

            return parseFloat(-1 * diferencia).toFixed(2)
        },
        mtdShowModal: function (action, item) {

            this.modal = {
                title: action == "store" ? 'AGREGAR INVENTARIO' : 'EDITAR INVENTARIO',
                modal_form: true,
                action: action,
                payload: item
            };

        },

        mtdCloseModalStockInitial: function () {
            this.modal = {
                title: "",
                modal_form: false,
                action: "",
                payload: {},
            };

        },
        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (this.selectPage - 1) * 10 + (index + 1)
        },
        changePage(page) {
            this.$emit('changePage', page)
        },
    },
};
</script>
