<template>
<div>
    <div class="container">
        <div class="row">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div id="div-modal-cxc" class="d-flex justify-content-center">
            <div id="div-cxc" class="card w-100">
                <div class="text-center card-header text-white h4 bg-main">
                    <strong>ORDEN DE SOP</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row">
                        <div class="col-md-12 py-2">
                            <div class="d-md-flex flex-wrap">
                                <div class="col-xl-4 col-lg-3 col-md-6 col-sm-12 col-xs-12 px-1 mb-3 ">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-3">Desde</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="startdate" v-model="filter.date_start" @change="mtdGetData" />
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-3 col-md-6 col-sm-12 col-xs-12 px-1 mb-3">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-3">Hasta</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filter.date_end" @change="mtdGetData" />
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-6 col-md-12 px-1 mb-3">
                                    <div class="row px-2 py-0">
                                        <div class="col-12">
                                            <div class="row d-flex card-filter">
                                                <div class="col-lg-3 col-md-3 col-sm-12 bg-secondary-subtle">
                                                    <span>Estado</span>
                                                </div>
                                                <div class="col-lg-9 col-md-9 col-sm-12 d-flex align-items-center">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineCheckbox11" value="1" v-model="filter.type" @change="mtdGetData" />
                                                        <label class="form-check-label" for="inlineCheckbox11">Creado</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineCheckbox12" value="2" v-model="filter.type" @change="mtdGetData" />
                                                        <label class="form-check-label" for="inlineCheckbox12">Programado</label>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-10 col-lg-9 col-md-9 col-sm-12 col-xs-12 px-1 mb-3">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                        <input @keyup="calculateData(10)" v-model="search" type="text" class="form-control form-control" placeholder="Buscar por Nombre o DNI" />
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-xs-12 btn-home px-1">
                                    <button type="button" class="btn btn-sm btn-bm-noradius w-100" @click="mtdModdalShow(0,null)">
                                        <i class="fas fa-plus"></i> Nueva
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2" id="content-timelinetable">
                            <table id="tb-cxc" class="table table-bordered border-main text-xs">
                                <caption class="py-0 my-0"></caption>
                                <thead class="bg-main">
                                    <tr class=" table-th text-white text-center">
                                        <th class="table-th-number">N°</th>
                                        <th>DOCUMENTO</th>
                                        <th>PACIENTE</th>
                                        <th>FECHA DE PROGRAMACION</th>
                                        <th>TIPO DE SALA</th>
                                        <th>TIPO DE HAB</th>
                                        <th>DX</th>
                                        <th>PERSONAL MEDICO</th>
                                        <th>EQUIPO</th>
                                        <th class="table-th-number">ESTADO</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main">
                                    <tr class="table-body text-center" v-if="cpData.length === 0">
                                        <td colspan="10" class="text-center">No hay registros</td>
                                    </tr>
                                    <tr class="table-body text-center" v-for="(item, index) in cpData" :key="index">
                                        <td>
                                            {{mtdNumber(index)}}
                                        </td>
                                        <td class="">
                                            {{item.patient.document}}
                                        </td>
                                        <td class="">
                                            {{item.patient.name}}
                                        </td>
                                        <td>
                                            {{ item.date | formatDate }}
                                        </td>
                                        <td>
                                            {{ item.operationroom ? item.operationroom.name : '-' }}
                                        </td>
                                        <td>
                                            {{ item.type_room  == 1 ? 'INDIVIDUAL' : (item.type_room  == 1 ? 'DOBLE' : 'NO')}}
                                        </td>
                                        <td>
                                            {{ item.cie_text && item.cie_text.length > 0 ? item.cie_text :(item.cie ?item.cie.name : "" )  }}
                                        </td>
                                        <td>
                                            <button @click="ViewMedical(item)" type="button" class="btn btn-sm btn-info mt-1">
                                                <i class="fas fa-eye"></i></button>
                                            <!-- <div v-for="(item1, index1) in JSON.parse(item.medical_staff)" :key="index1">
                                                    {{ item1.label }}: {{ item1.value }} <br>
                                                </div> -->
                                        </td>
                                        <td>
                                            {{ item.equipment }}
                                        </td>
                                        <td>
                                            <div id="state_speciality" v-if="item.state == 1">
                                                <div class="bg-warning text-white px-1">Pendiente</div>
                                            </div>
                                            <div id="state_speciality2" v-else>
                                                <div class="bg-success text-white px-1">Programado</div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 text-center">
                            <!-- paginado -->
                            <div v-if="cpData.length > 0" class="d-flex justify-content-center mb-2">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <cModalInsert :payload="modal.payload" :title="modal.title" @mtdcloseModal="mtdCloseModal" :boo_modal="modal.modal_form"></cModalInsert>
    <cModalShowStaff :payload="modalStaff.payload" :title="modalStaff.title" @mtdcloseModal="mtdCloseModal" :boo_modal="modalStaff.modal_form"></cModalShowStaff>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalInsert from './cModalRegisterOrderSop.vue';
import cModalShowStaff from './cModalShowStaff.vue';
import moment from "moment";

export default {
    name: 'c-asistencia-homeCirujano-orderSop',
    components: {
        SweetAlert,
        cModalInsert,
        cModalShowStaff
    },
    data() {
        return {
            swal: null,
            search: "",
            dataShow: [],
            dataArea: [],
            filter: {
                date_start: "",
                date_end: "",
                type: ["1", "2"],
            },
            modal: {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            },
            modalStaff: {
                title: '',
                modal_form: false,
                payload: {},
            },
            //Pag
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
            action: "",
        }
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
    },
    computed: {
        classTh() {
            return 'align-middle border border-5 bg-main border-white';
        },
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    created() {
        this.getFirstDayOfMonth();
    },
    methods: {
        ...mapActions(["get", "post"]),
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            this.filter.date_start = firstDayOfMonth.toISOString().split('T')[0];
            this.filter.date_end = today.toISOString().split('T')[0];
            this.mtdGetData();
        },
        //Traer Data
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Sop/ListOrderSop",
                    token: this.$store.getters.get__token,
                    params: this.filter,
                })
                .then((response) => {
                    this.dataArea = response.data;
                    this.calculateData(10);
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },

        calculateData(items) {
            if (!this.dataArea || !this.dataArea.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.dataArea;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.dataArea.filter((element) => {
                    let name = element.patient.name.toString().toUpperCase();
                    let doc = element.patient.document.toString().toUpperCase();
                    return name.includes(query) || doc.includes(query);
                });
                indexInitial = 1;
            }
            //verificar si no hay data vacia cuando se borra
            if (this.action == 'd') {
                if (this.dataShow.length == 1) {
                    if (indexInitial > 1) {
                        indexInitial -= 1;
                    } else {
                        indexInitial = 1;
                    }
                }
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );

            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            this.count = this.count + 1;
        },
        mtdModdalShow: function () {
            this.modal = {
                title: 'REGISTRAR ORDEN DE SOP',
                modal_form: true,
            }
        },
        ViewMedical: function (item) {
            this.modalStaff = {
                title: 'STAFF MEDICO',
                modal_form: true,
                payload: item,
            }
        },
        mtdCloseModal: function (opt) {
            this.modal.modal_form = false;
            this.modalStaff.modal_form = false;
            if (opt == 0) {
                this.mtdGetData();
            }
        },
        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (this.selectPage - 1) * 10 + (index + 1)
        },
        returnHome: function () {
            this.$emit('mtdChangePage', 'homeCirujano')
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    },

}
</script>
