<template>
<CModal @mtdclosemodal="mtdClose" :show.sync="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true" :scrollable="true">
    <div class="row px-3">
        <div class="col-md-12 col-lg-12 col-xs-12 text-center">
            <table class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Nombre</th>
                        <th>Cantidad</th>
                        <th>Precio</th>
                        <th>Subtotal</th>
                    </tr>
                </thead>
                <tbody v-if="data.description">
                    <tr v-for="(det, index) in JSON.parse(this.data.description)" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ det.product }}</td>
                        <td>
                            {{ det.quantity }}
                        </td>
                        <td>S/. {{ det.price }}</td>
                        <td>S/. {{ det.subtotal }}</td>
                    </tr>
                    <tr>
                        <td colspan="4" class=" fw-bold">
                            <div class="d-flex justify-content-end">Total: </div></td>
                        <td class="fw-semibold">S/. {{ data.total }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <template #footer>
        <button @click="mtdClose" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <!-- &nbsp;
      <button class="btn btn-success btn-sm" @click="mtdEditValor">
        <i class="fas fa-save" />&nbsp; Editar
      </button> -->
        &nbsp;
    </template>
</CModal>
</template>

<script>
import CModal from "../../../shared/modals/CModal.vue";
import CButtonClose from "../../../shared/modals/cButtonClose.vue";
import {
    mapActions
} from 'vuex';

export default {
    name: "c-modalviewdetalle",
    components: {
        CModal,
        CButtonClose,
        SweetAlert,
    },
    data() {
        return {
            
            tabla: []
        };
    },
    computed: {
        cpData() {
            let show = [];
            if(this.data){
                let item = JSON.parse(this.data.description);
                item.forEach((element) => {
                    // if (element.subsub_categorie_id != 13061) {
                    show.push(element);
                    // }
                });
            }
            return show;
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        data: [],
    },
    methods: {
      ...mapActions(["get", "post"]),
        mtdClose() {
            this.$emit("mtdcloseModal");
        },
        
    },
};
</script>
