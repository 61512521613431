<template>
<div class="c-app">
    <div class="container">
        <div class="d-flex title-option">
            <div class="btn-home mt-2 ms-auto">
                <button type="button" class="btn btn-outline-main" @click="returnHome()">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div class="h-100 d-flex align-items-center">

            <div class="w-100">
                <div class="d-md-flex flex-wrap justify-content-around">
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 p-2" v-for="(card,index) in cards1" :key="index">
                        <div class="card card-book shadow" @click="mtdShowBook(card.namePage)">
                            <h5>{{card.title}}</h5>
                            <div class="icon-book">
                                <img class="img-fluid-footer rounded-start" :src="require(`@/assets/caja/${card.img}.png`)" :alt="card.namePage" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-md-flex flex-wrap justify-content-around mb-2">
                    <div class="col-12 col-md-6 col-lg p-2" v-for="(card,index) in cards2" :key="index">
                        <div class="card card-book shadow" @click="mtdShowBook(card.namePage)">
                            <h5>{{card.title}}</h5>
                            <div class="icon-book">
                                <img class="img-fluid-footer rounded-start" :src="require(`@/assets/caja/${card.img}.png`)" :alt="card.namePage" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

    
<script>
import {
    mapActions
} from 'vuex';

export default {
    name: 'c-admision-footerCaja',
    data() {
        return {
            cards1:[
                {
                    namePage: "anular",
                    img: "rechazar",
                    title: "ANULAR",
                },
                {
                    namePage: "rendirEgresos",
                    img: "rendir",
                    title: "RENDIR EGRESOS",
                },
                {
                    namePage: "vueltos",
                    img: "vuelto",
                    title: "VUELTOS",
                },
                {
                    namePage: "cxc",
                    img: "cuenta-por-cobrar",
                    title: "CXC",
                }
                
                
            ],
            cards2:[
            {
                    namePage: "planillas",
                    img: "archivo",
                    title: "PLANILLAS",
                },
            {
                    namePage: "consultar",
                    img: "consultar-documento",
                    title: "CONSULTAR DOCUMENTO",
                },
                {
                    namePage: "cierre_caja",
                    img: "caja-registradora",
                    title: "CIERRE DE CAJA",
                },
                {
                    namePage: "pasajes",
                    img: "pasaje",
                    title: "PASAJE",
                },
                {
                    namePage: "cxp",
                    img: "egreso",
                    title: "CXP",
                }
            ]

        }
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdShowBook(subpage) {
            //this.subpage = subpage;
            this.$emit('mtdChangePage', subpage);
        },
        returnHome() {
            //window.location.reload();
            this.$emit('mtdChangePage', 'home');
        },

    }

}
</script>
