<template>
<div>
    <div class="container">
        <div class="row">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <div class="card w-100 mb-xl-5">
                <div class="text-center card-header text-white h4 bg-main">
                    <strong>ANULAR DOCUMENTOS</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row">
                        <div class="col-md-12 py-2">
                            <div class="row d-flex mx-0">
                                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 mt-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-4">Desde</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.begindate" @change="mtdGetData" />
                                    </div>
                                </div>
                                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 mt-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-5">Hasta</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="end" v-model="filter.enddate" @change="mtdGetData" />
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 btn-home mt-2">
                                    <button type="button" class="btn btn-sm w-100" @click="mtdModalShow()">
                                        <i class="fas fa-plus"></i> Nueva
                                    </button>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3">
                                    <div class="input-group input-group">
                                        <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                        <input @keyup="calculateData(10)" v-model="search" type="text" class="form-control form-control-sm" placeholder="Buscar por motivo o por Serie - Numero" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center px-4">
                            <!-- paginado -->
                            <div v-if="cpData.length > 0" class="d-flex justify-content-end">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div class="col-md-12" id="content-timelinetable">
                            <table id="tb-cxc" class="table table-bordered border-main">
                                <caption class="py-0 my-0"></caption>
                                <thead class="bg-main">
                                    <tr class=" table-th text-white text-center">
                                        <th class="table-th-number">N°</th>
                                        <th>FECHA</th>
                                        <th>HORA</th>
                                        <th>COMPROBANTE</th>
                                        <th>SERIE Y N°</th>
                                        <th>MONTO</th>
                                        <th>MOTIVO</th>
                                        <th class="table-th-number">ESTADO</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main">
                                    <tr class="table-body text-center font-size" v-if="cpData.length === 0">
                                        <td colspan="8" class="text-center">No hay registros</td>
                                    </tr>
                                    <tr class="table-body text-center font-size" v-for="(item, index) in cpData" :key="index">
                                        <td>
                                            {{mtdNumber(index)}}
                                        </td>
                                        <td>
                                            {{ item.date | formatDate }}
                                        </td>
                                        <td>
                                            {{ item.hour | formatTime }}
                                        </td>
                                        <td>
                                            {{ item.voucher.document }}
                                        </td>
                                        <td>
                                            {{ item.voucher.serie }}-{{ item.voucher.number }}
                                        </td>
                                        <td>
                                            S/ {{ parseFloat(item.voucher.amount).toFixed(2) }}
                                        </td>
                                        <td>
                                            {{ item.motive_cancel }}
                                        </td>
                                        <td>
                                            <div class="text-center d-flex justify-content-center text-xs">
                                                <button class="btn btn-sm a-success text-white" v-if="item.state == 2">
                                                    <i class="fas fa-check-circle"></i>
                                                </button>
                                                <button class="btn btn-sm a-danger text-white" v-if="item.state == 3">
                                                    <i class="fas fa-times-circle"></i>
                                                </button>
                                                <button class="btn btn-sm a-warning text-white" v-if="item.state == 1">
                                                    <i class="far fa-circle"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
    <cModalRegisterAnular
      :title="modal.title"
      :boo_modal="modal.modal_form"
      :serieVouchers="serieVouchers"
      @mtdclosemodal="mtdclosemodal"
    />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import cModalRegisterAnular from '@/components/Farmacia/Modals/cModalRegisterCancelVoucher.vue'
export default {
    name: "c-pharmacy-pages-anular",
    components: {
        cModalRegisterAnular
    },
    data() {
        return {
            filter: {
                begindate: null,
                enddate: null,
            },
            swal: null,
            search: "",
            data: [],
            serieVouchers: [],
            dataShow: [],
            modal: {
                title: "",
                modal_form: false,
            },
            //Pag
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
        };
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    created() {
        this.getFirstDayOfMonth();

    },
    methods: {
        ...mapActions(["get", "post"]),
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            this.filter.begindate = firstDayOfMonth.toISOString().split('T')[0];
            this.filter.enddate = today.toISOString().split('T')[0];
            this.mtdGetData();
        },
        //Traer Data
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Pharmacy/anular",
                    token: this.$store.getters.get__token,
                    params: this.filter,
                })
                .then((response) => {
                    this.dataShow = [];
                    this.selectPage = 1;
                    this.data = response.data.voucherCancel;
                    this.serieVouchers = response.data.serieVouchers;
                    this.calculateData(10);
                })
                .catch((errors) => {
                    //this.$refs.SweetAlert.showError(errors);
                });
        },

        /* calculateDataa(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.data;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    let name = element.motive_cancel.toString().toUpperCase();
                    let number = element.voucher.number.toString().toUpperCase();
                    let serie = element.voucher.serie.toString().toUpperCase();
                    return name.includes(query) || number.includes(query) || serie.includes(query);
                });
                indexInitial = 1;
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );


            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        }, */

        calculateData(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }

            let filteredData = this.data;
            let indexInitial = this.selectPage;

            if (this.search.length >= 2) {
                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    let name = element.motive_cancel.toString().toUpperCase();
                    let number = element.voucher.number.toString().toUpperCase();
                    let serie = element.voucher.serie.toString().toUpperCase();
                    return name.includes(query) || number.includes(query) || serie.includes(query);
                });
                if (!filteredData.length && this.search === "") {
                    filteredData = this.data;
                }

                indexInitial = 1;
            }

            let totalPages = Math.ceil(filteredData.length / items);

            this.stepPagination = Array.from(
                { length: totalPages },
                (_, index) => index + 1
            );

            if (this.selectPage > totalPages) {
                this.selectPage = totalPages || 1;
            }

            let startIndex = (this.selectPage - 1) * items;
            let endIndex = startIndex + items;
            this.dataShow = filteredData.slice(startIndex, endIndex);
            this.count = this.count + 1;
        },

        mtdModalShow: function () {
            this.modal.title = 'REGISTRAR ANULACION';
            this.modal.modal_form = true;
        },

        mtdclosemodal: function (op) {
            this.modal = {
                title: '',
                modal_form: false,
            }
            if(op == 0){
                this.mtdGetData();
            }
        },

        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (this.selectPage - 1) * 10 + (index + 1)
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatTime(time) {
            let arTime = time.split(":");
            if (arTime[0] <= 12) {
                return time + " am";
            } else {
                return arTime[0] - 12 + ":" + arTime[1] + ":" + arTime[2] + " pm";
            }
        },
    },
}
</script>
<style scoped>
.font-size{
  font-size: 12px;
  vertical-align: middle;
}
</style>