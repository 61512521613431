<template>
<div class="col-12" v-if="data">
    <div class="row mt-2">
        <div class="panel-border my-1 px-0">
            <div class="d-md-flex text-xs">
                <div class="col-md-3 bg-main text-white p-2">
                    <span class="fw-bold">ORDER DE SOP</span>
                </div>
                <div class="col-md-3 p-2">
                    <span class="fw-bold color-main">Hora de Ingreso:</span>&nbsp;
                    <span class="fw-semibold">{{ data.entry_time }}</span>
                </div>
                <div class="col-md-3 p-2">
                    <span class="fw-bold color-main">Hora de Inicio:</span>&nbsp;
                    <span class="fw-semibold">{{ data.start_time }}</span>
                </div>
                <div class="col-md-3 p-2">
                    <span class="fw-bold color-main">Hora Final:</span>&nbsp;
                    <span class="fw-semibold">{{ data.end_time }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="panel-border my-1">
            <div class="px-3 d-md-flex my-2">
                <div class="col">
                    <span class="fw-semibold color-main">TE:</span>&nbsp;
                    <span class="">{{ data.te }}</span>
                </div>
                <div class="col">
                    <span class="fw-semibold color-main">Motivo:</span>&nbsp;
                    <span class="">{{ data.motive }}</span>
                </div>
                <div class="col">
                    <span class="fw-semibold color-main">Diagnóstico:</span>&nbsp;
                    <span class="">{{ data.cie_id ? data.cie.name+' '+data.cie.code : ''}}</span>
                </div>
            </div>
            <div class="px-3 d-md-flex my-2">
                <div class="col">
                    <span class="fw-semibold color-main">Tipo de tratamiento:</span>&nbsp;
                    <span class="">{{ data.type_treatment }}</span>
                </div>
                <div class="col">
                    <span class="fw-semibold color-main">Intervension:</span>&nbsp;
                    <span class="">{{ data.intervention }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="panel-border my-1">
            <div class="px-3 py-1 d-md-flex gap-2 ">
                <div class="col-md-4 col-xl-4 my-1">
                    <div id="div-signo" class="form-group row align-left">
                        <label for="story" class="col-sm-12 color-main fw-bold">PERSONAL MEDICO</label>
                        <div class="col-xl-12 col-sm-12 mt-1">
                            <ul class="list-group text-xs">
                                <li class="list-group-item" v-for="(item, index) in staff" :key="index">
                                    <span><b>{{ item.label }}: </b> {{ item.value }}</span>
                                </li>
                                <li class="list-group-item mb-2" v-if="staff.length == 0">
                                    No hay registros
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-xl-4 my-1">
                    <div id="div-gasa" class="form-group row align-left">
                        <label for="story" class="col-sm-12 color-main fw-bold">GASAS</label>
                        <div class="col-xl-12 col-sm-12 mt-1">
                            <ul class="list-group text-xs">
                                <li class="list-group-item" v-for="(item, index) in gauze" :key="index">
                                    <span><b>{{ item.label }}: </b> {{ item.value }}</span>
                                </li>
                                <li class="list-group-item mb-2" v-if="staff.length == 0">
                                    No hay registros
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-xl-4 my-1">
                    <div id="div-soluciones" class="form-group row align-left">
                        <label for="story" class="col-sm-12 color-main fw-bold">SOLUCIONES</label>
                        <div class="col-xl-12 col-sm-12 mt-1">
                            <ul class="list-group text-xs">
                                <li class="list-group-item" v-for="(item, index) in solutions" :key="index">
                                    <span><b>{{ item.label }}: </b> {{ item.value }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="panel-border my-1">
            <div class="px-3 py-1 d-md-flex gap-2 ">
                <div class="col-md-6 col-xl-6 my-1">
                    <div class="form-group row align-left">
                        <label for="story" class=" color-main fw-bold">EQUIPOS</label>
                        <div class=" mt-1">
                            <textarea :rows="cpRow" v-model="data.equipment" placeholder="-" class="form-control text-xs" aria-label="With textarea" id="recomendations" disabled></textarea>
                        </div>
                    </div>
                    <div class="form-group row align-left">
                        <label for="story" class=" color-main fw-bold">IMAGENES</label>
                        <div class=" mt-1">
                            <textarea :rows="cpRow" v-model="data.images" placeholder="-" class="form-control text-xs" aria-label="With textarea" id="recomendations" disabled></textarea>
                        </div>
                    </div>
                    <div class="form-group row align-left">
                        <label for="story" class=" color-main fw-bold">IMPLANTES</label>
                        <div class=" mt-1">
                            <textarea :rows="cpRow" v-model="data.implants" placeholder="-" class="form-control text-xs" aria-label="With textarea" id="recomendations" disabled></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-6 my-1">
                    <div id="div-insumo" class="form-group row align-left">
                        <label for="story" class="col-sm-12 color-main fw-bold">INSUMOS</label>
                        <div class="col-xl-12 col-sm-12 mt-1">
                            <ul class="list-group text-xs">
                                <li class="list-group-item" v-for="(item, index) in insumos" :key="index">
                                    <span>{{ item.molecule }} - {{ item.total }} und(s)</span>
                                </li>
                                <li class="list-group-item mb-2" v-if="insumos.length == 0">
                                    No hay registros
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="px-0" v-else>
    <div class="alert alert-info mt-3" role="alert">
        No se ha encontrado información
    </div>
</div>
</template>

<script>
export default {
    name: 'reportsop-order',
    data() {
        return {
        }
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    watch: {

    },
    computed: {
        cpDoctor() {
            let name = "";
            let last_name = "";
            /* if (this.doctor) {
                name = this.doctor.name ?? "";
                last_name = this.doctor.last_name ?? "";
            } */
            return name + " " + last_name;
        },
        staff() {
            let staff = JSON.parse(this.data.medical_staff);
            return staff;
        },
        gauze() {
            let gauze = JSON.parse(this.data.gauze);
            return gauze;
        },
        solutions() {
            let gauze = JSON.parse(this.data.solutions);
            return gauze;
        },
        insumos() {
            let insumos = JSON.parse(this.data.inputs);
            return insumos;

        },
        cpRow() {
            let size = this.insumos.length;
            return size < 2 ? 2 : size;
        },
        validation(){
            console.log(this.data)
        }
    }
}
</script>
