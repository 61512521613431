<template>
<CModal @mtdclosemodal="mtdClose" :show.sync="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-xs-12 text-center d-sm-flex">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" checked name="flexRadioDefault" :value="1" v-model="transaction.type">
                <label class="form-check-label">
                    Depósito
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input :value="2" class="form-check-input" type="radio" name="flexRadioDefault" v-model="transaction.type" />
                <label class="form-check-label">
                    Retiro
                </label>
            </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xs-12 mt-2">
            <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">Monto a {{transaction.type==1?'Depositar':'Retirar'}} <span class="text-danger">*</span></label>
                <input class="form-control form-control-sm" placeholder="0.00" v-model="transaction.amount" @input="validateAmount">
                <span class="text-danger text-alert" v-if="!isValidInput">
                    {{ mtdMessage }}</span>
            </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xs-12 mb-3">
            <label for="name" class="mb-2">{{transaction.type == 2 ? 'Para depositar en' : 'Origen del dinero'}}</label>
            <div class="form-inline">
                <!-- Opcion unica para retiro -->
                <div class="form-check form-check-inline" v-if="transaction.type ==2">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" :value="2" v-model="transaction.destination">
                    <label class="form-check-label" for="inlineRadio1">Caja Fuerte</label>
                </div>
                <!-- opcion habilkitada para depositivo -->
                <div class="form-check form-check-inline" v-if="transaction.type ==1">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" :value="1" v-model="transaction.destination">
                    <label class="form-check-label" for="inlineRadio2">Otro</label>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdClose" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <button class="btn btn-success btn-sm" @click="mtdSendPermission" :disabled="mtdDisableButton || !isValidInput">
            <i class='fas fa-save' />&nbsp; Registrar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

    
<script>
import CModal from "../../shared/modals/CModal.vue";
import CButtonClose from "../../shared/modals/cButtonClose.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-modal-retiroDeposito",
    components: {
        CModal,
        CButtonClose,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            transaction: {
                type: 1,
                amount: "",
                destination: 1, //1->otro 2->caja fuerte
                description: "",
                observation: "",
            },
            user_id: null,
            beadCajaFuerte: {},
            isValidInput: true,
            users: [],
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        item: {},
        size: String,
        bead: {}
    },
    mounted() {
        //traer data de caja fuerte
        this.mtdGetstrongbox();
    },
    computed: {
        mtdMessage() {
            const msgRetiro = 'El monto tiene que ser mayor a S/. 0.00 y menor a S/.' + this.bead.final;
            const msgDeposito = 'El valor tiene que ser mayor que 0.00';
            return this.transaction.type == 2 ? msgRetiro : msgDeposito;
        },
        mtdDisableButton() {
            //const disabled = this.movimiento.destination == 1 ? this.movimiento.bank_id : this.movimiento.box_id;
            return this.transaction.amount === "" || this.transaction.destination === "" || (this.transaction.type == 2 && this.transaction.destination == 1 && this.transaction.observation === "");
            /* || this.movimiento.motive == "" || this.movimiento.destination == "" || disabled == null */
            ;
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetstrongbox: function () {
            let modulo = "tesoreria";
            this.post({
                    url: this.$store.getters.get__url + "/Tesoreria/beads",
                    token: this.$store.getters.get__token,
                    params: {
                        page: modulo,
                        campu_id: this.$store.getters.get__campus,
                    },
                })
                .then((response) => {
                    this.beadCajaFuerte = response.data.beads[0];
                })
                .catch((errors) => {});
        },
        
        validateAmount(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }
            // Asignar el valor sanitizado al input
            this.transaction.amount = sanitizedValue;
            //validar que no sea mayor que el total de caja
            const final = parseFloat(this.bead.final).toFixed(2);
            const amountInput = parseFloat(sanitizedValue);
            let amount = parseFloat(sanitizedValue).toFixed(2);
            let validation = (amount > 0.00) ? (this.transaction.type == 2 ? amountInput > final : false) : true;
            if (validation == false) {
                this.transaction.amount = sanitizedValue;
                this.isValidInput = true;

            } else {
                this.isValidInput = false;
            }
        },
        mtdClose() {
            this.transaction = {
                type: 1,
                amount: "",
                destination: 1,
                description: "",
                observation: "",
            };

            this.$emit("closeModalRetiros");
        },
        mtdSendPermission: function () {
            //1->otro 2->caja fuerte
            let mensaje = this.transaction.destination == 2 ? (this.transaction.type == 1 ? 'Dinero proveniente de caja fuerte' : "Para enviar dinero a caja fuerte") : "";
            this.transaction.description = mensaje.toUpperCase();
            this.post({
                    url: this.$store.getters.get__url + "/Bead/store/transaction",
                    token: this.$store.getters.get__token,
                    params: {
                        bead: this.bead,
                        data: this.transaction,
                        campu: this.$store.getters.get__campus,
                    },
                })
                .then((response) => {

                    if (response.statusCode == 200) {
                        this.mtdClose();
                        this.$refs.SweetAlert.showSuccess("Registro correcto");
                    } else {
                        this.$refs.SweetAlert.showWarning("No se pudo registrar");
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError("Ocurró un error");
                });
        }
    },
};
</script>

    
<style scoped>
.form-inline {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.text-alert {
    font-size: 9px;
}
</style>
