<template>
    <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title"
        :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="px-3">
            <div class="row mx-0 px-0 d-flex">
                <div class="col-lg-4 col-md-6">
                    <label for="employee_id" class="mb-1 mt-3">EMPLEADO</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-user"></i>
                            </span>
                        </div>
                        <!-- <select class="form-control" id="employee_id" v-model="data_detail.employee_id">
                              <option value="" disabled>Seleccione un empleado...</option>
                              <option v-for="(item, index) in employeeList" :key="index" :value="item.id">
                                  {{ item.name }} {{ item.last_name }} 
                              </option>
                          </select> -->
                        <v-select id="employee" class="text-dark flex-grow-1 form-control" label="name"
                            :options="employeeOptions" placeholder="- Buscar Empleado -"
                            :reduce="employee => employee.id" v-model="data_detail.employee_id" />
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <label for="type_contract_id" class="mb-1 mt-3">TIPO DE CONTRATO</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-address-book"></i>
                            </span>
                        </div>
                        <select class="form-control" id="type_contract_id" v-model="data_detail.type_contract_id">
                            <option value="" disabled>Seleccione tipo de contrato...</option>
                            <option v-for="(item, index) in contractTypes" :key="index" :value="item.id">
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <label for="position_id" class="mb-1 mt-3">CARGO</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-address-book"></i>
                            </span>
                        </div>
                        <select class="form-control" id="position_id" v-model="data_detail.position_id">
                            <option value="" disabled>Seleccione un cargo...</option>
                            <option v-for="(item, index) in positionList" :key="index" :value="item.id">
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <label for="duration" class="mb-1 mt-3">DURACIÓN (En meses)</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fas fa-calendar"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" id="duration" placeholder="Duración en meses"
                            v-model="data_detail.duration" @input="validateDuration" />
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <label for="date_start" class="mb-1 mt-3">FEC. INICIO</label>
                    <div class="input-group">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text"><i
                                    class="fas fa-calendar"></i></span>
                        </div>
                        <input v-model="data_detail.date_start" type="date" class="form-control" id="date_start" />
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <label for="date_end" class="mb-1 mt-3">FEC. FIN</label>
                    <div class="input-group">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text"><i
                                    class="fas fa-calendar"></i></span>
                        </div>
                        <input v-model="data_detail.date_end" type="date" class="form-control" id="date_end" />
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <label for="date_reg" class="mb-1 mt-3">FEC. DE CONTRATO</label>
                    <div class="input-group">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text"><i
                                    class="fas fa-calendar"></i></span>
                        </div>
                        <input v-model="data_detail.date_reg" type="date" class="form-control" id="date_reg" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <label for="schedule" class="mb-1 mt-3">HORAS TRABAJADAS</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-clock"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" id="schedule" v-model="data_detail.schedule"
                            placeholder="Ingrese cantidad de horas trabajadas" />
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <label for="salary" class="mb-1 mt-3">SUELDO</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-dollar-sign"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" placeholder="0.00" id="salary"
                            v-model="data_detail.salary" @input="validateSalary" />
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <label for="description" class="mb-1 mt-3">DESCRIPCIÓN</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fas fa-text"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" id="description"
                            placeholder="Breve descripción del contrato" v-model="data_detail.description" />
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <label class="mb-1 mt-3" for="gender">GÉNERO</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text" style="font-size: 20px; border: none">
                            <span><i class="fa fa-venus-mars"></i></span>
                        </div>
                        <select v-model="data_detail.gender" class="form-control" name="gender" id="gender">
                            <option value="" disabled>--Seleccione un género--</option>
                            <option value="0">Masculino</option>
                            <option value="1">Femenino</option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <label for="renewal" class="mb-1 mt-2">RENOVACIÓN</label>
                    <div class="form-check form-switch mt-2">
                        <input class="form-check-input" type="checkbox" id="renewal" v-model="data_detail.renewal"
                            @change="changeRenewal" />
                        <label class="form-check-label" for="renewal">
                            {{ data_detail.renewal ? "Sí" : "No" }}
                        </label>
                    </div>
                </div>

                <!-- Campo por si se habilita la renovación -->
                <div class="col-lg-4 col-md-6" v-if="data_detail.renewal">
                    <label for="renewal_message" class="mb-1 mt-3">MENSAJE DE RENOVACIÓN</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fas fa-comment"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" id="renewal_message"
                            v-model="data_detail.renewal_message" placeholder="Ingrese el mensaje de renovación">
                    </div>
                </div>
                <!-- Fin -->

                <!-- Documento -->
                <div class="col-lg-5 col-md-6" v-if="num === 1">
                    <label class="mb-3 mt-2 form-label" for="formFile">ARCHIVO</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fas fa-solid fa-file-import"></i>
                            </span>
                        </div>
                        <div class="input-group-text">
                            <input class="form-control form-control-sm" type="file" id="formFile" ref="fileInput"
                                lang="es" name="file" @change="getFile">
                        </div>
                    </div>
                </div>
                <!-- Fin -->

                <div class="col-lg-3 col-md-6 mt-5" v-if="num === 1">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                            v-model="data_detail.state" :checked="data_detail.state == 1 ? true : false"
                            aria-checked="false" />
                        <label class="form-check-label" for="flexSwitchCheckChecked">
                            {{ data_detail.state == 1 ? "Activo" : "Inactivo" }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpButton">
                {{ num == 0 ? "Registrar" : "Editar" }}
            </button>
            &nbsp;
        </template>
    </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import { mapActions } from "vuex";

export default {
    name: "c-maintenance-modal-contract",
    components: {
        CModal,
    },
    data() {
        return {
            data_detail: {
                employee_id: null,
                type_contract_id: "",
                position_id: "",
                duration: "",
                date_start: "",
                date_end: "",
                date_reg: "",
                schedule: "",
                renewal: false,
                renewal_message: "",
                salary: "",
                description: "",
                gender: "",
                file: ""
            },
            isDocumentTypeSelected: false,
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if (this.num == 1) {
                    if (newVal) {
                        this.data_detail = {
                            ...newVal,
                        };
                    }
                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null,
        },
        employeeList: {
            type: Array,
            default: null,
        },
        employeeOptions: {
            type: Array,
            default: null,
        },
        positionList: {
            type: Array,
            default: null,
        },
        contractTypes: {
            type: Array,
            default: null,
        },
    },
    computed: {
        cpButton() {
            if (this.num == 0) {
                return !(
                    this.data_detail.employee_id != "" &&
                    this.data_detail.type_contract_id != "" &&
                    this.data_detail.position_id != "" &&
                    this.data_detail.duration != "" &&
                    this.data_detail.date_start != "" &&
                    this.data_detail.date_end != "" &&
                    this.data_detail.date_reg != "" &&
                    this.data_detail.schedule != "" &&
                    this.data_detail.description != "" &&
                    this.data_detail.gender != ""
                );

            } else if (this.num == 1) {
                return !(
                    this.data_detail.employee_id != "" &&
                    this.data_detail.type_contract_id != "" &&
                    this.data_detail.position_id != "" &&
                    this.data_detail.duration != "" &&
                    this.data_detail.date_start != "" &&
                    this.data_detail.date_end != "" &&
                    this.data_detail.date_reg != "" &&
                    this.data_detail.schedule != "" &&
                    this.data_detail.description != ""
                );
            }

        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        getFile(e) {
            let fileInput = this.$refs.fileInput;
            let file = e.target.files[0];
            if (!/\.(jpeg)$/i.test(file.name) && !/\.(png)$/i.test(file.name) && !/\.(jpg)$/i.test(file.name) && !/\.(jpeg)$/i.test(file.name)) {
                this.$refs.SweetAlert.showInfo('Seleccione un archivo formato JPEG, PNG O JPG');
                fileInput.value = '';
            } else {
                this.data_detail.file = file;
            }
        },
        mtdClose() {
            this.data_detail = {
                employee_id: null,
                type_contract_id: "",
                position_id: "",
                duration: "",
                date_start: "",
                date_end: "",
                date_reg: "",
                schedule: "",
                renewal: false,
                renewal_message: "",
                salary: "",
                description: "",
                gender: "",
                file: ""
            };
            this.isDocumentTypeSelected = false;
            this.$emit("closeModal");
        },
        changeRenewal() {
            if (!this.data_detail.renewal) {
                this.data_detail.renewal_message = "";
            }
        },
        validateDuration() {
            this.data_detail.duration = this.data_detail.duration.replace(/[^0-9]/g, "");
        },
        validateSalary() {
            this.data_detail.salary = this.data_detail.salary.replace(/[^0-9.]/g, "");
            const decimalIndex = this.data_detail.salary.indexOf(".");
            if (decimalIndex !== -1) {
                this.data_detail.salary = this.data_detail.salary.slice(0, decimalIndex + 1) +
                    this.data_detail.salary.slice(decimalIndex + 1).replace(/\./g, "");
            }
        },
        onDocumentTypeChange() {
            this.isDocumentTypeSelected = true;
            this.data_detail.num_document = "";
        },
        DataDetail() {
            this.$emit("Datadetail", this.data_detail, this.num);
            this.mtdClose();
        },
    },
};
</script>