<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12 d-md-block d-lg-none">
                <button type="button" class="btn text-white btn-sm bg-main mt-2 text-xs w-100" @click="mtdstoredata()">
                    <i class="fas fa-save"></i> GUARDAR
                </button>
            </div>
        </div>
        <div class="row my-3">
            <div class="col-md-12">
                <div class="row mb-2">
                    <div class="py-1 div-title rounded fw-bold justify-content-center"
                        style="background-color: #17A2B8">
                        <span>DIAGNÓSTICO</span>
                    </div>
                    <div class="col-12 mt-1 align-left" v-for="(item, index) in preAnestesica.cies" :key="index">
                        <div id="div-cies" class="form-group">
                            <label :for="'cie' + index" class="color-main fw-bold text-xs">{{ item.label }}</label>
                            <v-select class="form-control-sm rounded" :input-id="'cie' + index" :options="dataCies"
                                v-model="item.value" :reduce="cie => cie" label="name" placeholder="- Buscar Cie -"
                                required :clearable="false" @search="mtdgetCies" @input="mtdSeleted(index,item.value)"
                                :disabled="index === 0" >
                                <template slot="no-options">
                                    No hay diagnósticos disponibles.
                                </template>
                            </v-select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group w-100">
                            <label for="cie"
                                class="text-content w-100 text-sm-start color-main fw-bold mb-2">Intervención quirúrgica
                                propuesta</label>
                            <v-select class="form-control-sm rounded" input-id="cie" :options="dataProcedimientos"
                                v-model="preAnestesica.cirugiaProgramada" :reduce="cie => cie" label="name"
                                placeholder="- Buscar Procedimiento -" required :clearable="false"
                                @input="mtdaddSubsub">
                                <template slot="no-options">
                                    No hay diagnósticos disponibles.
                                </template>
                            </v-select>
                        </div>
                    </div>
                </div>
            </div>
            <!--COLUMNA 1-->
            <div class="col-xl-12 col-md-12">
                <!--PARTE 2-->
                <div class="row">
                    <div class="col-md-12">
                        <div class="row div-title rounded fw-bold py-1 justify-content-center"
                            style="background-color: #17A2B8">
                            <span class="text-uppercase">Antecedentes Patológicos</span>
                        </div>
                        <!-- Hábitos Nocivos -->
                        <div class="row d-flex mb-3">
                            <label class="form-check-label text-center my-auto mb-0 fw-bold color-main">Hábitos
                                Nocivos</label>
                            <div v-for="item in preAnestesica.harmful_habits" :key="item.id"
                                class="col-md-4 col-sm-4 col-12 mb-0 mt-0">
                                <div class="form-check">
                                    <input class="form-check-input align-middle" type="checkbox"
                                        :id="'harmful' + item.id" v-model="item.value" />
                                    <div class="w-100 text-start">
                                        <label class="form-check-label text-content mb-0" :for="'harmful' + item.id">
                                            {{ item.label }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <!-- Otros -->
                        <div class="row d-flex">
                            <div v-for="item in preAnestesica.pathological_history" :key="item.id"
                                class="col-md-4 col-sm-6 col-12 mb-0 mt-0">
                                <div class="form-check">
                                    <input class="form-check-input align-middle" type="checkbox" :id="'otro' + item.id"
                                        v-model="item.value" />
                                    <div class="w-100 text-start">
                                        <label class="form-check-label text-content mb-0" :for="'otro' + item.id">
                                            {{ item.label }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-lg-flex mb-2 gap-2">
                            <div class="col-xl-6 col-lg-6 col-md-12 mt-1">
                                <div class="mt-2">
                                    <label for="treatment" class="fw-bold text-xs color-main">Tratamiento</label>
                                    <textarea class="form-control input-textarea" id="treatment" rows="2"
                                        style="resize: none;" v-model="preAnestesica.treatment" />
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 mt-1">
                                <div class="row d-flex">
                                    <label for="cirugia"
                                        class="col-md-5 col-5 fw-bold text-xs color-main my-auto">Cirugía
                                        Previa</label>
                                    <div class="col-md-7 col-7">
                                        <label class="fw-bold text-xs color-main me-2" for="respuesta">Sí</label>
                                        <input class="form-check-input" type="checkbox" id="respuesta"
                                            v-model="cirujiaPrevia_check" />
                                    </div>
                                    <div class="col-md-12">
                                        <textarea class="form-control input-textarea mt-2" id="cirugia" rows="2"
                                            style="resize: none;" v-model="preAnestesica.previous_surgery"
                                            :disabled="!cirujiaPrevia_check"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- COLUMNA 2 -->
            <div class="col-xl-12 col-md-12">
                <div class="d-lg-flex gap-1">
                    <div class="col-xl-6 col-lg-6 col-md-12 px-3">
                        <!--PARTE 3-->
                        <div class="row d-flex">
                            <div class="div-title-anestecia fw-bold py-1 justify-content-center mt-2">
                                <span>CABEZA - CUELLO - DETADURA</span>
                            </div>
                            <div v-for="(item, index) in cabezaCuello" :key="index"
                                class="col-md-4 col-sm-4 col-12 mb-1 mt-0">
                                <div class="form-check">
                                    <input class="form-check-input align-middle" type="radio" :id="'cab_cue' + item.id"
                                        :value="item.id" v-model="preAnestesica.teeth" />
                                    <div class="w-100 text-start">
                                        <label class="form-check-label text-content mb-0" :for="'cab_cue' + item.id">
                                            {{ item.label }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--PARTE 4-->
                        <div class="row mb-1">
                            <div class="div-title-anestecia fw-bold py-1 justify-content-center mt-2 mb-1">
                                <span class="text-uppercase">Clasificación Mallampati de la orofaringe</span>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <div v-for="(image, index) in mallampatiImages" :key="index"
                                    class="col-md-3 col-sm-6 col-12 mb-3 text-center d-flex flex-column"
                                    @click="selectMallampati(index)"
                                    :class="['image-container', { 'selected-image': selectedMallampati === index }]"
                                    style="cursor: pointer;">
                                    <img :src="image.src" :alt="image.alt" class="img-fluid rounded" />
                                    <p class="mt-2 text-capitalize">{{ image.clasificacion }}</p>
                                </div>
                            </div>
                        </div>
                        <!--PARTE 5-->
                        <div class="row d-flex mb-2">
                            <div class="py-1 div-title-anestecia fw-bold justify-content-center">
                                <span class="text-uppercase">Aparato Respiratorio</span>
                            </div>
                            <div v-for="(item, index) in preAnestesica.respiratory_system" :key="index"
                                class="col-md-4 col-sm-4 col-12 mb-0 mt-0">
                                <div class="form-check">
                                    <input class="form-check-input align-middle" type="checkbox"
                                        :id="'habito' + item.id" v-model="item.value" />
                                    <div class="w-100 text-start">
                                        <label class="form-check-label text-content mb-0" :for="'habito' + item.id">
                                            {{ item.label }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--PARTE 6-->
                        <div class="row mb-2">
                            <div class="py-1 div-title-anestecia fw-bold justify-content-center">
                                <span class="text-uppercase">Evaluación Neumológica</span>
                            </div>
                            <div class="col-md-12 mt-1 align-left">
                                <div class="mb-1">
                                    <label for="rxtorax" class="fw-bold text-xs color-main">Rx Tórax</label>
                                    <textarea class="form-control input-textarea" id="rxtorax" rows="2"
                                        style="resize: none;" v-model="preAnestesica.rx_torax" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 px-3">
                        <!--PARTE 7-->
                        <div class="row d-flex my-2">
                            <div class="py-1 div-title-anestecia fw-bold">
                                <span class="text-uppercase">Aparato Cardiovacular</span>
                            </div>
                            <div class="col-xl-3 col-md-6 col-sm-6 col-12 mt-1 align-left"
                                v-for="(item, index) in preAnestesica.cardiovascular_system" :key="index">
                                <div id="div-estado" class="form-group">
                                    <label :for="'aparCardio' + index" class="color-main fw-bold text-xs">{{ item.label
                                        }}</label>
                                    <div class="">
                                        <input type="text" v-model="item.value"
                                            class="form-control form-control-input form-control-sm"
                                            :id="'aparCardio' + index">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--PARTE 8-->
                        <div class="row d-flex mb-1">
                            <div class="div-title-anestecia fw-bold py-1 justify-content-center mt-2">
                                <span>DOLOR PRECORDIAL</span>
                            </div>
                            <div v-for="item in preAnestesica.precordial_pain" :key="item.id"
                                class="col-md-4 col-sm-4 col-12 mb-1 mt-0">
                                <div class="form-check">
                                    <input class="form-check-input align-middle" type="checkbox"
                                        :id="'cordial' + item.id" v-model="item.value" />
                                    <div class="w-100 text-start">
                                        <label class="form-check-label text-content mb-0" :for="'cordial' + item.id">
                                            {{ item.label }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--PARTE 9-->
                        <div class="row mb-1">
                            <div class="col-md-12 py-1 div-title-anestecia fw-bold mb-2">
                                <label for="cianosis" class="text-uppercase">Cianosis</label>
                            </div>
                            <div class="col-md-12 mb-2">
                                <textarea id="cianosis" type="text" class="form-control input-textarea" rows="1"
                                    style="resize: none;" v-model="preAnestesica.cianosis" />
                            </div>
                        </div>
                        <!--PARTE 10 -->
                        <div class="row mb-2">
                            <div class="py-1 div-title-anestecia fw-bold">
                                <span class="text-uppercase">Evalucación Cardiológica</span>
                            </div>
                            <div class="col-md-12 mt-1 align-left">
                                <div class="mb-1">
                                    <label for="rq" class="fw-bold text-xs color-main">RQ</label>
                                    <textarea class="form-control input-textarea" id="rq" rows="2" style="resize: none;"
                                        v-model="preAnestesica.rq" />
                                </div>
                            </div>
                        </div>
                        <!--PARTE 11-->
                        <div class="row d-flex mb-1">
                            <div class="div-title-anestecia fw-bold py-1">
                                <span class="text-uppercase">Columna</span>
                            </div>
                            <div class="col-md-6 col-12 align-left">
                                <label for="dorso" class="text-uppercase fw-bold text-xs color-main">Dorso
                                    Lumbar</label>
                                <input id="dorso" type="text"
                                    class="form-control form-control-input form-control-sm mb-1"
                                    v-model="preAnestesica.lumbar_back" />
                            </div>
                            <div v-for="item in preAnestesica.column" :key="item.id"
                                class="col-md-2 col-sm-4 col-12 align-content-center">
                                <div class="form-check">
                                    <input class="form-check-input align-middle" type="checkbox"
                                        :id="'dorso_lumbar' + item.id" v-model="item.value" />
                                    <div class="w-100 text-start">
                                        <label class="form-check-label text-content mb-0"
                                            :for="'dorso_lumbar' + item.id">
                                            {{ item.label }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="d-lg-flex gap-1 my-3">
            <div class="col-xl-6 col-lg-6 col-md-12">
                <!--PARTE 1-->
                <div class="row d-flex mb-2 mx-1">
                    <div class="py-1 div-title rounded fw-bold" style="background-color: #17A2B8">
                        <span>EX. LABORATORIO</span>
                    </div>
                    <div class="col-xl-2 col-md-2 col-12 mt-1 align-left"
                        v-for="(item2, index) in preAnestesica.laboratory_exams" :key="'examLab-' + index">
                        <div id="div-estado" class="form-group">
                            <label :for="'input2' + index" class="color-main fw-bold text-xs">{{ item2.label
                                }}</label>
                            <div class="">
                                <input type="text" v-model="item2.value"
                                    class="form-control form-control-input form-control-sm" :id="'input2' + index">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <button @click="enviandoDatos">Enviar</button> -->
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 ">
                <!--PARTE 2-->
                <div class="row d-flex mx-1">
                    <div class="py-1 div-title rounded fw-bold" style="background-color: #17A2B8">
                        <span>A.S.A</span>
                    </div>
                    <div v-for="(item, index) in asa_num_options" :key="'asaNum-' + index"
                        class="col-md-4 col-sm-4 col-12 mb-1 mt-0">
                        <div class="form-check">
                            <input class="form-check-input align-middle" type="radio" :id="'asa_num' + item.id"
                                :value="item.id" v-model="preAnestesica.asa_num" />
                            <div class="w-100 text-start">
                                <label class="form-check-label text-content mb-0" :for="'asa_num' + item.id">
                                    {{ item.label }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-1 align-left mb-2" v-for="(item, index) in preAnestesica.asa"
                        :key="'asa-' + index">
                        <div class="mb-1">
                            <label :for="'textarea2' + index" class="fw-bold text-xs color-main">
                                {{ item.label }}
                            </label>
                            <textarea class="form-control input-textarea" :id="'textarea2' + index" rows="2"
                                style="resize: none;" v-model="item.value" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" />

    </div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
    name: 'c-asistencial-sop-historia-preanestecia',
    components: {
        SweetAlert
    },
    data() {
        return {
            cabezaCuello: [{
                    id: 1,
                    label: 'Dentadura Normal'
                },
                {
                    id: 2,
                    label: 'Prótesis'
                },
                {
                    id: 3,
                    label: 'D. Leche'
                },
            ],
            preAnestesica: {
                cirugiaProgramada: "",
                sub_sub_category_id: null,
                cies: [{
                        id: 1,
                        label: 'Diagnósticos pre Quirúrgico',
                        value: '',
                        cie_id: ""
                    },
                    {
                        id: 2,
                        label: 'Diagnósticos Asociados',
                        value: '',
                        cie_id: ""
                    },
                ],
                harmful_habits: [{
                        id: 1,
                        label: 'Alcohol',
                        value: ''
                    },
                    {
                        id: 2,
                        label: 'Tabaco',
                        value: ''
                    },
                    {
                        id: 3,
                        label: 'Drogas',
                        value: ''
                    },
                ],
                pathological_history: [{
                        id: 1,
                        label: 'Asma',
                        value: ''
                    },
                    {
                        id: 2,
                        label: 'Hipertirodismo',
                        value: ''
                    },
                    {
                        id: 3,
                        label: 'Obesidad',
                        value: ''
                    },
                    {
                        id: 4,
                        label: 'Cefaleas',
                        value: ''
                    },
                    {
                        id: 5,
                        label: 'Convulsiones',
                        value: ''
                    },
                    {
                        id: 6,
                        label: 'Diabetes Mellitus',
                        value: ''
                    },
                    {
                        id: 7,
                        label: 'Alergias Alimentarias',
                        value: ''
                    },
                    {
                        id: 8,
                        label: 'Hepatopatías',
                        value: ''
                    },
                    {
                        id: 9,
                        label: 'A.C.V',
                        value: ''
                    },
                    {
                        id: 10,
                        label: 'T.B.C',
                        value: ''
                    },
                    {
                        id: 11,
                        label: 'Hipertensión',
                        value: ''
                    },
                    {
                        id: 12,
                        label: 'Alergias a Fármacos',
                        value: ''
                    },
                    {
                        id: 13,
                        label: 'Trastornos Psiquiátricos',
                        value: ''
                    },
                    {
                        id: 14,
                        label: 'E.T.S',
                        value: ''
                    },
                    {
                        id: 15,
                        label: 'Cardiopatías',
                        value: ''
                    }
                ],
                treatment: "",
                previous_surgery: "",
                teeth: null,
                respiratory_system: [{
                        id: 1,
                        label: 'Tos',
                        value: ''
                    },
                    {
                        id: 2,
                        label: 'Sibilancias',
                        value: ''
                    },
                    {
                        id: 3,
                        label: 'Crepitantes',
                        value: ''
                    },
                ],
                rx_torax: "",
                cardiovascular_system: [{
                        id: 1,
                        label: 'PAS',
                        value: ''
                    },
                    {
                        id: 2,
                        label: 'PAD',
                        value: ''
                    },
                    {
                        id: 3,
                        label: 'PULSO',
                        value: ''
                    },
                    {
                        id: 4,
                        label: 'SO2',
                        value: ''
                    },
                ],
                precordial_pain: [{
                        id: 1,
                        label: 'Disnea',
                        value: ''
                    },
                    {
                        id: 2,
                        label: 'Ortopnea',
                        value: ''
                    },
                    {
                        id: 3,
                        label: 'Edemas',
                        value: ''
                    },
                ],
                cianosis: "",
                rq: "",
                lumbar_back: "",
                column: [{
                        id: 1,
                        label: 'Cifosis',
                        value: ''
                    },
                    {
                        id: 2,
                        label: 'Escoliosis',
                        value: ''
                    },
                    {
                        id: 3,
                        label: 'Secuelas',
                        value: ''
                    },
                ],
                laboratory_exams: [{
                        id: 1,
                        label: 'G',
                        value: ''
                    },
                    {
                        id: 2,
                        label: 'BT',
                        value: ''
                    },
                    {
                        id: 3,
                        label: 'NA',
                        value: ''
                    },
                    {
                        id: 4,
                        label: 'T. COAGU',
                        value: ''
                    },
                    {
                        id: 5,
                        label: 'G. SANGRE',
                        value: ''
                    },
                    {
                        id: 6,
                        label: 'ECO',
                        value: ''
                    },
                    {
                        id: 7,
                        label: 'U',
                        value: ''
                    },
                    {
                        id: 8,
                        label: 'BD',
                        value: ''
                    },
                    {
                        id: 9,
                        label: 'K',
                        value: ''
                    },
                    {
                        id: 10,
                        label: 'T. SANG',
                        value: ''
                    },
                    {
                        id: 11,
                        label: 'HTO',
                        value: ''
                    },
                    {
                        id: 12,
                        label: 'VIH',
                        value: ''
                    },
                    {
                        id: 13,
                        label: 'C',
                        value: ''
                    },
                    {
                        id: 14,
                        label: 'BI',
                        value: ''
                    },
                    {
                        id: 15,
                        label: 'CL',
                        value: ''
                    },
                    {
                        id: 16,
                        label: 'R. PLAQ',
                        value: ''
                    },
                    {
                        id: 17,
                        label: 'Hb',
                        value: ''
                    },
                    {
                        id: 18,
                        label: 'FA',
                        value: ''
                    },
                ],
                asa: [{
                        id: 1,
                        label: 'Técnica anestésica sugerida',
                        value: ''
                    },
                    {
                        id: 2,
                        label: 'Depósito / transfusión de sangre',
                        value: ''
                    },
                    {
                        id: 3,
                        label: 'Observaciones',
                        value: ''
                    },
                ],

                asa_num: null,
                clasif_mallampati: null,
            },
            asa_num_options: [{
                    id: 1,
                    label: '1'
                },
                {
                    id: 2,
                    label: '2'
                },
                {
                    id: 3,
                    label: '3'
                },
                {
                    id: 4,
                    label: '4'
                },
                {
                    id: 5,
                    label: '5'
                },
            ],
            selectedMallampati: null, // Índice de la imagen seleccionada
            mallampatiImages: [{
                    src: require('@/assets/clasif_mallampati_I.png'),
                    alt: 'Mallampati I',
                    clasificacion: 'I'
                },
                {
                    src: require('@/assets/clasif_mallampati_II.png'),
                    alt: 'Mallampati II',
                    clasificacion: 'II'
                },
                {
                    src: require('@/assets/clasif_mallampati_III.png'),
                    alt: 'Mallampati III',
                    clasificacion: 'III'
                },
                {
                    src: require('@/assets/clasif_mallampati_IV.png'),
                    alt: 'Mallampati IV',
                    clasificacion: 'IV'
                }
            ],
            dataProcedimientos: [],
            dataCies: [],
            swal: null,
            cirujiaPrevia_check: false,
            modalCie: {
                title: "",
                modal_form: false,
            },
            pos: null,

        };
    },
    props: {
        medical_act_id: {
            type: Number,
            default: 0,
        },
        addPreAnestecia: {
            type: Number,
            default: 0
        },
        rolname: String,
    },
    watch: {
        medical_act_id: {
            handler: function (newValue) {
                if (newValue !== 0) {
                    this.mtdView();
                }
            },
            deep: true,
            immediate: true,
        },
        addPreAnestecia: {
            handler: function (newValue) {
                if (newValue == 1) {
                    this.mtdstoredata()
                }
            },
            deep: true,
            immediate: true,
        }
    },
    created() {
        this.mtdgetSubsubcategories();
    },
    methods: {
        ...mapActions(['get', 'post']),
        selectMallampati(index) {
            this.selectedMallampati = index; // Actualizar la selección
            this.preAnestesica.clasif_mallampati = this.mallampatiImages[index].clasificacion;
        },
        mtdgetSubsubcategories: function () {
            this.post({
                url: this.$store.getters.get__url + "/Consulting/subsubcategorie",
                token: this.$store.getters.get__token,
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.dataProcedimientos = response.data.dataProcedimiento;
                }
            });
        },
        mtdaddSubsub: function (item) {
            this.preAnestesica.sub_sub_category_id = item.id;
            this.preAnestesica.cirugiaProgramada = item.name;
        },
        mtdView: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Sop/preOperatorioView",
                    token: this.$store.getters.get__token,
                    params: {
                        medical_act_id: this.medical_act_id,
                        type: 'preanestecia'
                    }
                })
                .then((response) => {
                    let data = response.data.anestesia;
                    if (data) {
                        this.preAnestesica = data;
                        this.preAnestesica.cardiovascular_system = JSON.parse(data.cardiovascular_system);
                        this.preAnestesica.laboratory_exams = JSON.parse(data.laboratory_exams);
                        this.preAnestesica.cies = JSON.parse(data.cies);
                        this.preAnestesica.asa = JSON.parse(data.asa);
                        this.preAnestesica.respiratory_system = JSON.parse(data.respiratory_system);
                        this.preAnestesica.pathological_history = JSON.parse(data.pathological_history);
                        this.preAnestesica.harmful_habits = JSON.parse(data.harmful_habits);
                        this.preAnestesica.precordial_pain = JSON.parse(data.precordial_pain);
                        this.preAnestesica.column = JSON.parse(data.column);
                        this.cirujiaPrevia_check = data.previous_surgery ? true : false;
                        let clasif = null;
                        switch (data.clasif_mallampati) {
                            case 'I':
                                clasif = 0;
                                break;
                            case 'II':
                                clasif = 1;
                                break;
                            case 'III':
                                clasif = 2;
                                break;
                            case 'IV':
                                clasif = 3;
                                break;
                            default:
                                break;
                        }
                        this.selectedMallampati = clasif;
                    }
                    this.preAnestesica.cies[0].value =response.data.new_name;
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdstoredata() {
            console.log(this.preAnestesica);
            this.post({
                    url: this.$store.getters.get__url + "/Sop/storePreAnestecia",
                    token: this.$store.getters.get__token,
                    params: {
                        ...this.preAnestesica,
                        medical_act_id: this.medical_act_id,
                        cirujiaPrevia_check: this.cirujiaPrevia_check,
                    }
                })
                .then((response) => {
                    this.mtdView();
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.$emit('finishAddOrder');
                })
                .catch((errors) => {
                    console.log(errors);
                });

        },
        mtdgetCies: function (search, loading) {
            if (search.length > 4) {
                this.dataCies = [];
                loading(true);
                this.get({
                        url: this.$store.getters.get__url + "/Consultation/cies/" + search,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        if (response.statusCode === 200) {
                            this.dataCies = response.data;
                        }
                        loading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        mtdSeleted: function (index, diagnostico) {
            this.pos = index;
            this.preAnestesica.cies[this.pos].value = diagnostico.name;
        },
        mtdclosemodalCie: function () {
            this.modalCie = {
                title: "",
                modal_form: false,
            };
            this.dataCies = [];
        },
    },
}
</script>

<style scoped>
.image-container {
    background-color: transparent;
    padding: 10px;
    border-radius: 8px;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.image-container img {
    max-height: 80%;
    width: auto;
    border-radius: 8px;
}

.image-container.selected-image {
    background-color: #17A2B8;
}
</style>
