<template>
<CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row d-flex">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label for="clasificacion" class="mb-3 mt-2">Clasificación</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none">
                        <i class="fas fa-copyright"></i>
                    </span>
                </div>
                <v-select @input="mtdgroup" id="clasificacion" class="text-dark form-control" label="name" :options="clasificacion" placeholder="- Buscar Clasificacion (Escriba uno...) -" @search="searchmarca" v-model="Subsubcategory.clasificacion_id" :reduce="(name) => name.id" required :clearable="false">
                            <template slot=" no-options">
                    No hay Productos para la busqueda.
                    </template>
                </v-select>
                
            </div>

        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label for="grupo" class="mb-3 mt-2">Grupo Terapéutico</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none">
                        <i class="fas fa-copyright"></i>
                    </span>
                </div>
                <v-select id="grupo" class="text-dark form-control" label="name" :options="Grupo" placeholder="- Buscar Clasificacion (Escriba uno...) -" @search="searchmarca" v-model="Subsubcategory.grupo_id" :reduce="(name) => name.id" required :clearable="false">
                            <template slot=" no-options">
                    No hay Productos para la busqueda.
                    </template>
                </v-select>
                
            </div>

        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label for="medicine" class="mb-3 mt-2">Producto</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none" class="input-group-text">
                        <i class="fa fa-prescription-bottle"></i>
                    </span>
                </div>
                <input id="medicine" type="text" class="form-control" placeholder="Medicamento" v-model="Subsubcategory.name" />
            </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label for="contrato" class="mb-3 mt-2">Contrato EPS</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none" class="input-group-text">
                        <i class="fa fa-medkit"></i>
                    </span>
                </div>
                <select id="contrato" class="form-control form-control-sm" aria-label="Default select example" v-model="Subsubcategory.insurance_contrat_id">
                    <option disabled selected value="">seleccione...</option>
                    <option v-for="(item, index) in contracts" :key="index" :value="item.id">
                        {{ item.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label for="preK" class="mb-3 mt-2">Prec. Kairos</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none" class="input-group-text">
                        <i class="fa fa-barcode"></i>
                    </span>
                </div>
                <input id="preK" type="number" class="form-control" placeholder="Kairos " v-model="Subsubcategory.kairos" step="0.1" @input="validateInput('kairos')" />
            </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label for="unidad" class="mb-3 mt-2">Unidad</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none" class="input-group-text">
                        <i class="fa fa-barcode"></i>
                    </span>
                </div>
                <input id="unidad" type="number" class="form-control" placeholder="Unidad" v-model="Subsubcategory.unidad" @input="validateInput('unidad')" />
            </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label class="mb-3 mt-2">Prec.Particular</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none" class="input-group-text">
                        <i class="fa fa-barcode"></i>
                    </span>
                </div>
                <input type="number" class="form-control" placeholder="Particular" v-model="Subsubcategory.particular" step="0.1" @input="validateInput('particular')" />
            </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label class="mb-3 mt-2">Concentración</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none">
                        <i class="fa fa-percent"></i>
                    </span>
                </div>
                <input type="text" class="form-control" placeholder="Concentración" v-model="Subsubcategory.dosis" />
            </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12" v-if="Subsubcategory.clasificacion_id ==1">
            <label class="mb-3 mt-2">Molécula</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none">
                        <i class="fa fa-flask"></i>
                    </span>
                </div>
                <input type="text" class="form-control" placeholder="Molécula" v-model="Subsubcategory.molecule" />
            </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label class="mb-3 mt-2">Comercial</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none">
                        <i class="fa fa-tags"></i>
                    </span>
                </div>
                <input type="text" class="form-control" placeholder="Comercial" v-model="Subsubcategory.commercial" />
            </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label for="brand" class="mb-3 mt-2">Marca</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none">
                        <i class="fas fa-copyright"></i>
                    </span>
                </div>
                <v-select @input="mtdbrandadd" id="brand" class="text-dark form-control" label="name" :options="dataMarca" placeholder="- Buscar Marca (Escriba uno...) -" @search="searchmarca" v-model="Subsubcategory.brand" :reduce="(name) => name.id" required :clearable="false">
                            <template slot=" no-options">
                    No hay Productos para la busqueda.
                    </template>
                </v-select>

                <!-- <select class="form-control form-control-sm" aria-label="Default select example" v-model="Subsubcategory.brand_id">
                        <option disabled selected value="">seleccione...</option>
                        <option v-for="(item, index) in dataMarca" :key="index" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select> -->
                <div class=" ms-1 p-1 h-100">
                    <button class="btn btn-success btn-sm text-xxs me-1" title="Agregar Marca" @click="mtdAddBrand()">
                        <i class="fas fa-plus text-white"></i>
                    </button>
                </div>
            </div>

        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label class="mb-3 mt-2">Presentation</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none">
                        <i class="fa fa-file-alt"></i>
                    </span>
                </div>
                <input type="text" class="form-control" placeholder="Presentation" v-model="Subsubcategory.presentation" />
            </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label class="mb-3 mt-2">Forma</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none">
                        <i class="fa fa-file"></i>
                    </span>
                </div>
                <input type="text" class="form-control" placeholder="Forma" v-model="Subsubcategory.format" />
            </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label class="mb-3 mt-2">Stock-Mínimo</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none">
                        <i class="fa fa-box"></i>
                    </span>
                </div>
                <input type="number" class="form-control" placeholder="Stock-Minimo" v-model="Subsubcategory.minimum_stock" @input="validateInput('minimum_stock')" />
            </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label class="mb-3 mt-2">Sector</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none">
                        <i class="fa fa-building"></i>
                    </span>
                </div>
                <select class="form-control form-control-sm" aria-label="Default select example" v-model="Subsubcategory.sector_id" @change="filteredSubSector()">
                    <option selected disabled value="">Seleccione..</option>
                    <option v-for="(item, index) in dataSector" :key="index" :value="item.id">
                        {{ item.name }}
                    </option>
                </select>
                <div class=" ms-1 p-1 h-100">
                    <button class="btn btn-success btn-sm text-xxs me-1" title="Agregar Marca" @click="mtdAddSector()">
                        <i class="fas fa-plus text-white"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label class="mb-3 mt-2">Sub Sector</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none">
                        <i class="fa fa-building"></i>
                    </span>
                </div>
                <select class="form-control form-control-sm" aria-label="Default select example" v-model="Subsubcategory.sub_sectors_id">
                    <option disabled selected value="">seleccione...</option>
                    <option v-for="(item, index) in dataSubSector" :key="index" :value="item.id">
                        {{ item.name }}
                    </option>
                </select>
                <div class=" ms-1 p-1 h-100">
                    <button class="btn btn-success btn-sm text-xxs me-1" title="Agregar Marca" @click="mtdAddSubsector()">
                        <i class="fas fa-plus text-white"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <label class="mb-3 mt-2">Ubicacion</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none">
                        <i class="fas fa-map-marker"></i>
                    </span>
                </div>
                <input type="text" class="form-control" placeholder="Ubicacion Fisica" v-model="Subsubcategory.location" />
            </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center align-items-center">
            <div class='me-lg-3' style="margin-right: 20px">
                <label class="mt-2">&nbsp;</label>
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckEps" :checked="Subsubcategory.eps == 1 ? true : false" @change="mtdChangeStateEps" />
                    <label class="form-check-label" for="flexSwitchCheckEps">EPS</label>
                </div>
            </div>
            <div>
                <label class="mt-2">&nbsp;</label>
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckParti" :checked="Subsubcategory.parti == 1 ? true : false" @change="mtdChangeStateParti" />
                    <label class="form-check-label" for="flexSwitchCheckParti">PARTICULAR</label>
                </div>
            </div>
        </div>
    </div>

    <template #footer>
        <button class="btn btn-success btn-sm" @click="mtdInsertData" :disabled="cpButtonForm">
            <i class="fas fa-save" />&nbsp; {{ action == "store" ? "Registrar" : "Editar" }}
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-documento",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            dataProvider: [],
            filter_name: "",
            Subsubcategory: {
                name: "",
                Id: "",
                state: "",
                categorie_id: "",
                subcategorie_id: "",
                specialty_id: "",
                insurance_contrat_id: "",
                kairos: 0.00,
                unidad: 0,
                particular: 0.00,
                eps: false,
                parti: false,
                molecule: "",
                commercial: "",
                brand: "",
                brand_id: null,
                clasificacion_id:null,
                grupo_id:null,
                presentation: "",
                concentration: "",
                format: "",
                stock: 0,
                minimum_stock: 0,
                state: "",
                sub_sub_category_id: null,
                sector: "",
                sector_id: "",
                location: "",
                sub_sectors_id: ""
            },
            contracts: [],
            dataMarca: [],
            dataSector: [],
            dataSubSector: [],
            Grupo:[]
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        action: String,
        subsubcategoryObject: Object,
        brand: Object,
        sector: Object,
        subsector: Object,
        clasificacion:Array,
        accion:Number
    },
    watch: {
        action: {
            handler(newVal) {
                this.mtdGetData();
                this.mtdGetSector();
            },
            immediate: true
        },
        accion: {
            handler(newVal) {
                if(newVal == 1){
                    this.Subsubcategory.brand = this.brand.name;
                    this.Subsubcategory.brand_id = this.brand.id;
                    
                }
            }

        },
        sector: {
            handler(newVal) {
                if (newVal && newVal.name && newVal.id) {
                    this.mtdGetSector();
                    this.Subsubcategory.sector = newVal.name;
                    this.Subsubcategory.sector_id = newVal.id;

                }
            },
            immediate: true
        },
        subsector: {
            handler(newVal) {
                if (newVal && newVal.name && newVal.id) {
                    this.Subsubcategory.sector_id = newVal.sector_id;
                    this.mtdGetSector();
                    this.filteredSubSector();
                    this.dataSubSector.push(newVal);
                    this.Subsubcategory.sub_sectors_id = newVal.id;

                }
            },
            immediate: true
        },

        subsubcategoryObject: {
            handler(newVal) {
                if (newVal) {
                    if (this.action == 'edit') {

                        this.Subsubcategory = {
                            ...newVal
                        };

                        this.mtdgroupview(this.Subsubcategory.clasificacion_id)
                    }
                }
            },
            immediate: true,
        },
    },
    computed: {
        cpButtonForm: function () {
            return false;
        },

    },
    methods: {
        ...mapActions(["get", "post"]),

        filteredSubSector() {
            if (this.Subsubcategory.sector_id) {
                this.dataSector.forEach(element => {
                    if (element.id == this.Subsubcategory.sector_id) {
                        console.log(element);
                        this.dataSubSector = element.sub_sector;
                    }
                    
                });
            } else {
                this.dataSubSector = [];
            }
        },

        mtdAddBrand: function () {
            this.$emit('mtdAddBrand');
        },
        mtdAddSector: function () {
            this.$emit('mtdAddSector');
        },
        mtdAddSubsector: function () {
            this.$emit('mtdAddSubsector');
        },
        searchmarca: function (search, loading) {
            if (search.length > 3) {
                this.dataMarca = [];
                loading(true);
                this.get({
                        url: this.$store.getters.get__url + "/logistics/indexbrand/" + search,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        this.dataMarca = response.data;
                        loading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        mtdGetData: function () {

            this.get({
                    url: this.$store.getters.get__url + "/Medicament",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.contracts = response.data;
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        mtdGetSector: function () {
            this.get({
                    url: this.$store.getters.get__url + "/Sector",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.dataSector = response.data.filter(sector => sector.state === 1);
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        mtdChangeStateEps: function () {
            this.Subsubcategory.eps = this.Subsubcategory.eps == 1 ? 0 : 1;
        },
        mtdChangeStateParti: function () {
            this.Subsubcategory.parti = this.Subsubcategory.parti == 1 ? 0 : 1;
        },
        mtdclosemodal() {
            this.$emit('closeModal', this.filter_name, this.Subsubcategory.clasificacion_id);
            this.Subsubcategory = {
                name: "",
                Id: "",
                state: "",
                categorie_id: "",
                subcategorie_id: "",
                specialty_id: "",
                insurance_contrat_id: "",
                kairos: 0.00,
                unidad: 0,
                particular: 0.00,
                eps: 0,
                parti: 0,
                molecule: "",
                commercial: "",
                brand: "",
                brand_id: null,
                presentation: "",
                concentration: "",
                format: "",
                stock: 0,
                minimum_stock: 0,
                state: "",
                sub_sub_category_id: null,
                sector: "",
                sector_id: "",
                location: "",
                clasificacion_id:null,
                grupo_id:null,
            };
         
        },
        mtdInsertData: function () {
            if (this.isFormValid()) {
                if (this.Subsubcategory.sector_id === "" || this.Subsubcategory.sector_id === null) {
                    this.Subsubcategory.sector_id = "";
                }
                if (this.Subsubcategory.sector_id === "" || this.Subsubcategory.sector_id === null) {
                    this.Subsubcategory.sector_id = "";
                }
                this.Subsubcategory.molecule = this.Subsubcategory.clasificacion_id == 1 ? this.Subsubcategory.molecule : this.Subsubcategory.name
                if (this.action == "store") {
                   
                    this.post({
                            url: this.$store.getters.get__url + "/Medicament/store",
                            token: this.$store.getters.get__token,
                            params: this.Subsubcategory,
                        })
                        .then((response) => {
                            if (response.data.state == 0) {
                                this.$refs.SweetAlert.showSuccess(response.message);
                                this.filter_name = response.data.data.name;
                                this.mtdclosemodal();
                            }
                        })
                        .catch((errors) => {
                            this.$refs.SweetAlert.showError(errors);
                        });
                } else {
                    this.mtdModifyData();
                }
            }
        },
        //metodo modificar
        mtdModifyData: function () {
            
            if (this.isFormValid()) {
                if (this.Subsubcategory.sector_id === "" || this.Subsubcategory.sector_id === null) {
                    this.Subsubcategory.sector_id = "";
                }
                if (this.Subsubcategory.sub_sectors_id === "" || this.Subsubcategory.sub_sectors_id === null) {
                    this.Subsubcategory.sub_sectors_id = "";
                }
                this.post({
                        url: this.$store.getters.get__url + "/Medicament/update",
                        token: this.$store.getters.get__token,
                        params: this.Subsubcategory,
                    })
                    .then((response) => {
                        if (response.data.state == 0) {
                            this.$refs.SweetAlert.showSuccess("Modificación Exitosa");
                            this.filter_name = response.data.data.name;
                            this.mtdclosemodal();
                        }
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });
            }
        },

        validateInput(field) {
            if (this.Subsubcategory[field] < 0) {
                this.Subsubcategory[field] = 0;
            }
        },

        isFormValid() {
           
            
            if(this.Subsubcategory.clasificacion_id == 1){
                if (this.Subsubcategory.name.trim() === "" ||
                this.Subsubcategory.brand_id === "" || this.Subsubcategory.brand_id === null || this.Subsubcategory.molecule.trim() == ''
                || this.Subsubcategory.clasificacion_id ==='' || this.Subsubcategory.clasificacion_id ===null
                //|| this.Subsubcategory.sector_id === "" || this.Subsubcategory.sector_id === null
                ) {
                    this.$refs.SweetAlert.showWarning("Por favor completa el Producto, Molecula, Clasificación y Marca");
                    return false;
                }
                return true;
            }else{
                if (this.Subsubcategory.name.trim() === "" ||
                this.Subsubcategory.brand_id === "" || this.Subsubcategory.brand_id === null 
                || this.Subsubcategory.clasificacion_id ==='' || this.Subsubcategory.clasificacion_id ===null
                //|| this.Subsubcategory.sector_id === "" || this.Subsubcategory.sector_id === null
                ) {
                    this.$refs.SweetAlert.showWarning("Por favor completa el Producto, Clasificación y Marca");
                    return false;
                }
                return true;
            }
           
        },

        mtdgroupview(payload){
            const foundItem = this.clasificacion.find(item => item.id === payload);
            if (foundItem) {
                this.Grupo = foundItem.group;
            }else{
                this.Grupo = []
            }
        },

        mtdgroup(payload){
            this.Subsubcategory.molecule = ''
            this.Subsubcategory.grupo_id = null
            this.mtdgroupview(payload)
        },

        mtdbrandadd(){
            this.Subsubcategory.brand_id = this.Subsubcategory.brand
        }

    },

};
</script>
