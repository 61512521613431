<template lang="es">
<div>
    <div class="row d-flex">
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-lg-3 col-md-5" id="document_type">Tipo Documento</span>
                <v-select :options="documents_type" class="form-control" :reduce="(name) => name.id" label="name" placeholder="-- Seleccione uno --" required v-model="egreso.document_type_id" />
            </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-lg-3 col-md-4">Fecha Egreso</span>
                <input type="date" aria-label="date" class="form-control form-control-sm" required v-model="egreso.date" />
            </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-lg-3 col-md-5">Fecha Comprobante</span>
                <input type="date" aria-label="date_doc" class="form-control form-control-sm" required v-model="egreso.date_doc" />
            </div>
        </div>

        <div class="col-md-3 col-lg-2 col-sm-6 col-xs-6">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-lg-4 col-md-5" id="serie">Serie</span>
                <input style="text-align: right" type="text" class="form-control" placeholder="N° Serie" aria-describedby="serie" v-model="egreso.serie" />
            </div>
        </div>

        <div class="col-md-3 col-lg-4 col-sm-6 col-xs-6">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-lg-3 col-md-5" id="number">Número</span>
                <input style="text-align: right" type="text" class="form-control" placeholder="Número" aria-describedby="number" v-on:keypress="isNumber($event)" v-model="egreso.number" />
            </div>
        </div>

        <div class="col-md-6 col-lg-6">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-lg-3 col-md-5" id="number">Tipo de Egreso</span>
                <v-select :options="centrals" class="form-control" :reduce="(name) => name.id" label="name" placeholder="-- Seleccione Tipo --" required v-model="egreso.type" />
            </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <input type="radio" aria-label="Checkbox for selecting RUC" v-model="egreso.type_document" value="RUC" />
                        <label for="rucCheckbox">RUC</label>
                    </div>
                </div>
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <input type="radio" aria-label="Checkbox for selecting DNI" v-model="egreso.type_document" value="DNI" />
                        <label for="dniCheckbox">DNI</label>
                    </div>
                </div>
                <input type="text" class="form-control" placeholder="RUC/DNI" aria-describedby="document" required v-model="egreso.document" @keyup="mtdSearchDocument" />
            </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-lg-3 col-md-4" id="company">Razón Social / Nombre</span>
                <input disabled type="text" class="form-control" placeholder="Razón Social / Nombre" aria-describedby="company" required v-model="egreso.company" />
            </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-lg-3 col-md-5">Monto</span>
                <input style="text-align: right" type="text" aria-label="total" placeholder="0.00" class="form-control form-control-sm" required v-on:keypress="isNumber($event)" v-model="egreso.total" @input="mtdCalculate" />
            </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-lg-3 col-md-4">Monto Pagado</span>
                <input style="text-align: right" type="text" aria-label="amount" placeholder="0.00" class="form-control form-control-sm" v-on:keypress="isNumber($event)" required @input="mtdCalculate" v-model="egreso.amount" />
            </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-lg-3 col-md-5">Monto Pendiente</span>
                <input readonly style="text-align: right" type="text" aria-label="amount_pay" placeholder="0.00" class="form-control form-control-sm" v-on:keypress="isNumber($event)" v-model="egreso.pending" />
            </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-lg-3 col-md-4" id="method">Método Pago</span>
                <v-select :options="methods" class="form-control" :reduce="(name) => name.id" label="name" placeholder="-- Seleccione uno --" required v-model="egreso.method" />
            </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <input @change="getFile" type="file" class="form-control" id="file" lang="es" name="file" ref="fileInput" />
            </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-lg-3 col-md-4" id="description">Motivo</span>
                <textarea type="text" class="form-control" aria-label="Sizing example input" placeholder="Motivo, descripción o detalle" aria-describedby="description" v-model="egreso.description"></textarea>
            </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
            <div class="btn-group" role="group" aria-label="Basic mixed styles example">
                <button type="button" class="btn btn-sm btn-danger" @click="mtdCancelar">
                    Cancelar
                </button>
                &nbsp;
                <button type="button" class="btn btn-sm btn-success ml-1" @click="mtdVerifEgreso">
                    Guardar
                </button>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
import {
    mapActions
} from "vuex";
//import { bus } from "../../main";
import moment from 'moment';

export default {
    name: "c-admsion-storerecibo",
    props: {
        bead: {}
    },
    components: {
        SweetAlert
    },
    data() {
        return {
            view: "table",
            swal: null,
            egreso: {
                campu_id: null,
                bead_id: null,
                type_document: "",
                document_type_id: null,
                date: "",
                date_doc: "",
                serie: "",
                number: "",
                document: "",
                company: "",
                total: 0.00,
                amount: 0.00,
                pending: 0.00,
                method: "",
                description: "",
                state: "",
                file: "",
                type: "",
            },
            documents_type: [],
            centrals: [],
            methods: [{
                    id: 'efectivo',
                    name: 'EFECTIVO'
                },
                {
                    id: 'tarjeta',
                    name: 'TARJETA'
                },
                {
                    id: 'deposito',
                    name: 'DEPOSITO'
                },
                {
                    id: 'mixto',
                    name: 'MIXTO'
                }
            ]
        };
    },
    created() {
        moment.locale('es');
        this.egreso.date = moment().format('L').substr(6, 4) + '-' + moment().format('L').substr(3, 2) + '-' + moment().format('L').substr(0, 2);
        this.egreso.date_doc = moment().format('L').substr(6, 4) + '-' + moment().format('L').substr(3, 2) + '-' + moment().format('L').substr(0, 2);
        this.egreso.bead_id = this.bead.id;
        this.egreso.campu_id = this.bead.campu_id;
        this.mtdGetData();
        this.mtdGetDataCentral();
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdBackTable: function () {
            this.$emit("mtdBackTable");
        },
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Expense/egreso",
                    token: this.$store.getters.get__token,
                    params: {
                        Campus: this.$store.getters.get__campus,
                    }
                })
                .then((response) => {
                    this.documents_type = response.documents_type;
                })
                .catch((errors) => {});
        },
        mtdGetDataCentral: function () {
            this.get({
                    url: this.$store.getters.get__url + "/Central",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.centrals = response.data;
                })
                .catch((errors) => {});
        },
        getFile(e) {
            let fileInput = this.$refs.fileInput;
            let file = e.target.files[0];
            if (!/\.(pdf)$/i.test(file.name) && !/\.(png)$/i.test(file.name) && !/\.(jpg)$/i.test(file.name) && !/\.(jpeg)$/i.test(file.name)) {
                this.$refs.SweetAlert.showInfo('Seleccione un archivo formato PDF, PNG O JPG');
                fileInput.value = '';
            } else {
                this.egreso.file = file;
            }
        },
        mtdStore: function () {
            this.view = "store";
        },
        mtdCancelar: function () {
            this.mtdBackTable();
        },
        mtdVerifEgreso: function () {
            const {
                document_type_id,
                total,
                amount,
                document,
                company,
                method,
                type,
                file,
            } = this.egreso;

            if (!document_type_id) {
                this.$refs.SweetAlert.showWarning("Seleccione tipo documento.");
                return;
            }

            if (parseFloat(total).toFixed(2) <= 0) {
                this.$refs.SweetAlert.showWarning("Ingrese un monto.");
                return;
            }

            if (parseFloat(amount).toFixed(2) <= 0) {
                this.$refs.SweetAlert.showWarning("Ingrese monto a pagar.");
                return;
            }

            if (parseFloat(amount) > parseFloat(total)) {
                this.$refs.SweetAlert.showWarning("El monto a pagar no puede ser mayor que el monto total.");
                return;
            }

            if (!document) {
                this.$refs.SweetAlert.showWarning("Ingrese un RUC o DNI.");
                return;
            }

            if (!company) {
                this.$refs.SweetAlert.showWarning("Ingrese una persona o compañía.");
                return;
            }

            if (!method) {
                this.$refs.SweetAlert.showWarning("Seleccione un método de pago.");
                return;
            }

            if(document_type_id == 2 || document_type_id == 1){
                if(!file){
                    this.$refs.SweetAlert.showWarning("Seleccione un archivo.");
                    return;
                }
            }
            if (!type) {
                this.$refs.SweetAlert.showWarning("Seleccione un tipo de egreso");
                return;
            }


           // console.log(this.egreso.document_type_id);
           this.mtdCommitEgreso();
        },

        mtdCommitEgreso: function () {
            let formData = new FormData();
            formData.append("campu_id", this.$store.getters.get__campus);
            //formData.append("campu_id", this.egreso.campu_id);
            formData.append("bead_id", this.egreso.bead_id);
            formData.append("document_type_id", this.egreso.document_type_id);
            formData.append("date", this.egreso.date);
            formData.append("date_doc", this.egreso.date_doc);
            formData.append("serie", this.egreso.serie);
            formData.append("number", this.egreso.number);
            formData.append("document", this.egreso.document);
            formData.append("company", this.egreso.company);
            formData.append("total", this.egreso.total);
            formData.append("amount", this.egreso.amount);
            formData.append("pending", this.egreso.pending);
            formData.append("method", this.egreso.method);
            formData.append("type", this.egreso.type);
            formData.append("description", this.egreso.description);
            formData.append("state", 1);
            formData.append("file", this.egreso.file);
            this.post({
                    url: this.$store.getters.get__url + "/Expense/egreso/store",
                    token: this.$store.getters.get__token,
                    params: formData,
                })
                .then((response) => {

                    if (response.statusCode == 200) {

                        const whatsappPhone = '+51959422813';
                        const gerente = 'Benjamin Medoza';
                        const tipo = 'ha registrado  una Solicitud de Egreso';
                        const monto = this.egreso.amount;
                        const admin = this.$store.getters.get__user.name + ' ' + this.$store.getters.get__user.last_name;

                        window.open(
                            this.generateWhatsAppLinks(gerente, whatsappPhone, tipo, monto, admin), '_blank');
                        this.egreso = {
                            campu_id: null,
                            bead_id: null,
                            document_type_id: null,
                            date: "",
                            date_doc: "",
                            serie: "",
                            number: "",
                            document: "",
                            company: "",
                            total: 0.00,
                            amount: 0.00,
                            pending: 0.00,
                            method: "",
                            description: "",
                            state: "",
                            file: "",
                        };
                        this.$emit('mtdAddEgreso', response.data);
                    } else {
                        alert(response.message);
                    }
                })
                .catch((errors) => {});
        },
        generateWhatsAppLinks(gerente, whatsappPhone, tipo, monto, admin) {
            const message = `Hola ${gerente},el Admisionista ${admin} ${tipo} ,por un monto de  S/. ${monto}.`;
            const encodedMessage = encodeURIComponent(message);
            // const whatsappLink=`https://wa.me/${whatsappPhone}?text=${encodedMessage}`;
            const whatsappLink = `https://api.whatsapp.com/send?phone=${whatsappPhone}&text=${encodedMessage}`;
            return whatsappLink;
        },
        /** helpers */
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        mtdSearchDocument: function () {
            if (!this.egreso.type_document) {
                this.$refs.SweetAlert.showWarning("Seleccione un Ruc o Dni");

            } else {
                if (this.egreso.document.length === 8 && this.egreso.type_document == 'DNI') {
                    this.get({
                            url: this.$store.getters.get__url +
                                "/reniec/" +
                                this.egreso.document,
                            token: this.$store.getters.get__token,
                        })
                        .then((response) => {

                            if (response.data.boo == 3) {
                                this.egreso.document = "";
                                this.$refs.SweetAlert.showWarning("DNI no encontrado");
                            } else {
                                this.egreso.company = response.data.name;
                            }
                        })
                        .catch((errors) => {});
                } else {
                    if (this.egreso.document.length === 11 && this.egreso.type_document == 'RUC') {
                        this.get({
                                url: this.$store.getters.get__url +
                                    "/reniec/" +
                                    this.egreso.document,
                                token: this.$store.getters.get__token,
                            })
                            .then((response) => {

                                if (response.data.boo == 3) {
                                    this.client.document = "";
                                    this.$refs.SweetAlert.showWarning("RUC no encontrado");
                                } else {
                                    this.egreso.company = response.data.razon_social;
                                }
                            })
                            .catch((errors) => {});
                    }
                }
            }
        },
        mtdCalculate: function () {
            // Verificar que tanto el monto como el monto pagado sean números
            let total = this.egreso.total;
            let amount = this.egreso.amount;
            if (isNaN(this.egreso.total) || this.egreso.total == "") {
                total = 0.00;
            }
            if (isNaN(this.egreso.amount || this.egreso.amount == "")) {
                amount = 0.00;
            }
            let num = parseFloat(total) - parseFloat(amount);
            this.egreso.pending = num.toFixed(2);
        }
    },
};
</script>
