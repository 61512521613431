<template>
    <div>
        <div class="px-5 mt-2" v-if="page !== 'carrito'">      
            <div class="d-flex title-option">
                <div class="btn-home mt-2 ms-auto">
                    <button type="button" class="btn btn-outline-main me-2 mt-1" @click="returnHome()">
                        <i class="fas fa-home-lg-alt"></i> Inicio 
                    </button>
                    <button type="button" class="btn btn-outline-main" @click="openRecipe()">
                        <i class="fas fa-fa-solid fa-receipt"></i> Ver Recetas 
                    </button>
                </div>
            </div>
            <ul class="nav pb-0 mt-2" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button @click="changePage('hospi')" :class="{'item-button':true, 'nav-link': true, 'active': page === 'hospi' }" id="home-tab" type="button">
                        <strong>HOSPITALIZACION</strong>
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button @click="changePage('uci')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'uci' }" id="profile-tab" type="button">
                        <strong>UCI ADULTO</strong>
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button @click="changePage('emergencia')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'emergencia' }" id="profile-tab" type="button">
                        <strong>EMERGENCIA</strong>
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button @click="changePage('consultas')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'consultas' }" id="profile-tab" type="button">
                        <strong>CONSULTAS</strong>
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button @click="changePage('sop')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'sop' }" id="profile-tab" type="button">
                        <strong>SOP Y URPA</strong>
                    </button>
                </li>
            </ul>
  
            <div class="tab-content mb-3" id="myTabContent">
                <HospitalizacionVue v-if="page == 'hospi'" @openCarroRegRecipe="openCarroRegRecipe"/>
                <UciVue v-if="page == 'uci'" @openCarroRegRecipe="openCarroRegRecipe"/>
                <EmergenciaVue v-if="page == 'emergencia'" @openCarroRegRecipe="openCarroRegRecipe"/>
                <ConsultasVue v-if="page == 'consultas'" @openCarroRegRecipe="openCarroRegRecipe"/>
                <SopVue v-if="page == 'sop'" @openCarroRegRecipe="openCarroRegRecipe"/>
            </div>
        </div>
        <div  v-if="page == 'carrito'">
            <cCarritoReg
              :patient_id="carrito.patient_id"
              :medical_act_id="carrito.medical_act_id"
              :hc_id="carrito.hc_id"
              :recipe_id="carrito.recipe_id"
              @mtdBack="mtdBack"
              @mtdSendCarrito="mtdSendCarrito"
            />
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
import { mapActions } from "vuex";
import HospitalizacionVue from "@/components/Farmacia/pages/RegRecipe/Hospitalizacion.vue";
import UciVue from "@/components/Farmacia/pages/RegRecipe/Uci.vue";
import EmergenciaVue from "@/components/Farmacia/pages/RegRecipe/Emergencia.vue";
import ConsultasVue from "@/components/Farmacia/pages/RegRecipe/Consultas.vue";
import SopVue from "@/components/Farmacia/pages/RegRecipe/Sop.vue";
import cCarritoReg from "@/components/Farmacia/Modals/RegRecipe/cCarroRegRecipe.vue";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
export default {
  name: "regRecipe",
  components: {
    SweetAlert,
    HospitalizacionVue,
    UciVue,
    EmergenciaVue,
    ConsultasVue,
    SopVue,
    cCarritoReg
  },
  data() {
    return {
      swal: null,
      page: "hospi",
      type: null,
      carrito: {
        recipe_id: 0,
        patient_id: null,
        medical_act_id: null,
        hc_id: null
      },
    };
  },
  computed: {
  },
  created() {},
  methods: {
    ...mapActions(["get", "post"]),
    changePage: function (page) {
      this.page = page;
    },
    returnHome: function () {
      this.$emit("returnHome");
    },
    openRecipe: function () {
        this.$emit("mtdChangePage", "recetas");
    },
    mtdBack() {
        this.page = "hospi";
    },
    openCarroRegRecipe: function(patient_id, medical_act_id, hc_id, recipe_id) {
      this.carrito.patient_id = patient_id;
      this.carrito.medical_act_id = medical_act_id;
      this.carrito.hc_id = hc_id;
      this.carrito.recipe_id = recipe_id ?? 0;
      this.page = "carrito";
/*       console.log(this.carrito.patient_id);
      console.log(this.carrito.medical_act_id);
      console.log(this.carrito.hc_id);
      console.log(this.carrito.recipe_id); */
    },
    mtdSendCarrito: function (patient_id, medical_act_id, hc_id, recipe_id, carrito) {
      this.post({
        url: this.$store.getters.get__url + "/Pharmacy/regRecipe/mtdAddCarrito",
        token: this.$store.getters.get__token,
        params: {
          patient_id: patient_id,
          medical_act_id: medical_act_id,
          hc_id: hc_id,
          recipe_id: recipe_id,
          carrito: carrito
        },
      })
        .then((response) => {
          if(response.statusCode == 200){
            if(response.data.msj == 0) {
              this.$refs.SweetAlert.showSuccess("Receta Creada Exitosamente");
            }else{
              this.$refs.SweetAlert.showSuccess("Medicamentos Agregados a Receta Exitosamente");
            }
            this.$emit("mtdChangePage", "recetas");
          }else{
            this.$refs.SweetAlert.showError("Error");
          }
        })
        .catch((errors) => {
          this.$refs.SweetAlert.showError(errors);
        });
    },
  },
};
</script>

<style scoped>
.h4-borde {
  border-radius: 50px;
}
#myTabContent {
  border: #900052 1.9px solid;
}

.item-button {
  background: #a9a9a9 !important;
  border-radius: 10px 10px 0px 0px;
  border: none !important;
  color: white !important;
  width: 155px;
}
.item-button1 {
  background: #a9a9a9 !important;
  border-radius: 10px 10px 0px 0px;
  border: none !important;
  color: white !important;
  width: 160px !important;
}

.nav-link:hover {
  background: #900052 !important;
}
.active {
  background-color: #900052 !important ;
}
.nav-item {
  width: 159.39px;
}
</style>
