<template>
<div class="p-3">
    <!-- Botones iniciales -->
    <div class="d-flex flex-row-reverse">
        <div class="mt-1 mb-0 d-inline-block btn-home">
            <button @click="mtdCloseBead(0)" class="btn btn-outline-main mb-2">
                <i :class="bead.authorize == 1 ?'fas fa-spinner' : 'fas fa-window-close'"></i> {{bead.authorize==0?' Solicitar Aprobación':
          (bead.authorize==1?"Pendiente de Aprobacion":(bead.authorize==2?"Cerrar Caja":"Corregir Cierre"))}}
            </button>
            &nbsp;
            <button type="button" class="btn btn-outline-main mb-2" @click="mtdModalRetiros">
                <i class="fas fa-cash-register"></i> Retiros/Depósitos
            </button>
            &nbsp;
            <button type="button" class="btn btn-outline-main mb-2" id="btn-edit" @click="mtdReportBead('INGRESO')">
                <i class="fas fa-file-excel"></i> Reporte Ingresos
            </button>
            &nbsp;
            <template v-if="originPage == 'Admision'">
                <button type="button" class="btn btn-outline-main mb-2" id="btn-edit" @click="mtdReportBead('EGRESO')">
                    <i class="fas fa-file-excel"></i> Reporte Egresos
                </button>
                &nbsp;
            </template>
            <button type="button" class="btn btn-outline-main mb-2" @click="returnHome">
                <i class="fas fa-home-lg-alt"></i> Inicio
            </button>
        </div>
    </div>
    <!-- Arqueo de caja -->
    <div class="row d-flex">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3">
            <div id="div-modal-caja-filtro" class="d-flex">
                <div id="div-caja-filtro" class="card w-100" style="height: 535px">
                    <div class="card-header text-white h4 text-center bg-main">
                        <strong>REPORTE DE CIERRE</strong>
                    </div>
                    <div class="card-body" style="max-height: 475px;">
                        <div class="row d-flex">
                            <div class="col-md-12 col-lg-12 col-xs-12" v-if="close == 1">
                                <div class="mb-3">
                                    <label class="form-label">Categoría</label>
                                    <select class="form-control form-control-sm" v-model="filter.categorie">
                                        <option value="0" selected>[Todas las categorias..]</option>
                                        <option v-for="(item, index) in categories" :key="index" :value="item.id">
                                            {{ item.label }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-12 col-xs-12" v-if="close == 1">
                                <div class="mb-3">
                                    <label class="form-label">Sub categorias</label>
                                    <select class="form-control form-control-sm" v-model="filter.subcategorie">
                                        <option value="0">[Todas las subcategorias..]</option>
                                        <option v-for="(item, index) in cpSubcategories" :key="index" :value="item.id">
                                            {{ item.label }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" v-model="filter.eps" />
                                        <label class="form-check-label">EPS</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" v-model="filter.particular" />
                                        <label class="form-check-label">PARTICULAR</label>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <!-- TABLA DE REPORTE -->
                            <div class="col-md-12" id="content-timelinetable" style="height: 200px;
            overflow-y: auto;">
                                <table class="table table-striped table-bordered table-responsive" aria-describedby="mydesc">
                                    <thead>
                                        <tr class="align-middle">
                                            <th scope="col" rowspan="2">Categoría</th>
                                            <th scope="col" rowspan="2">Sub categoria</th>
                                            <th scope="col" rowspan="2">Servicio especifico</th>
                                            <th scope="col" class="text-center" colspan="3">Medios de pagos</th>
                                        </tr>
                                        <tr>
                                            <th scope="col">Efectivo</th>
                                            <th scope="col">Otros</th>
                                            <th scope="col">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-xs" v-html="cpTableBody"></tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div id="div-modal-caja" class="d-flex justify-content-center">
                <div id="div-caja" class="card w-100">
                    <div class="card-header text-white h4 text-center bg-main">
                        <strong>ARQUEO DE CAJA</strong>
                    </div>
                    <div class="card-body" style="max-height: 485px; overflow-y: auto">
                        <div id="div-dataMoney" class="container">
                            <div class="row d-flex">
                                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 mt-0 text-center">
                                    <label class="form-label text-center"><strong>Billetes</strong></label>
                                    <div v-for="(item, index) in process.billetes" :key="index" class="">
                                        <div class="input-group input-group-sm mb-2">
                                            <span class="input-group-text col-4">{{ item.label }}</span>
                                            <input :disabled="bead.authorize==2" type="text" class="form-control form-control-sm" @keyup="cpContarBilletes" @keydown="filterKey" aria-label="Amount (to the nearest dollar)" placeholder="0" v-model="item.cantidad" />
                                            <span class="input-group-text col-5">= S/. {{ item.total == 0 ? '0.00' : item.total }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 text-center">
                                    <label class="form-label text-center"><strong>Monedas</strong></label>
                                    <div v-for="(item, index) in process.monedas" :key="index" class="">
                                        <div class="input-group input-group-sm mb-2">
                                            <span class="input-group-text col-4">{{ item.label }}</span>
                                            <input :disabled="bead.authorize==2" type="text" class="form-control form-control-sm" @keyup="cpContarBilletes" @keydown="filterKey" aria-label="Amount (to the nearest dollar)" placeholder="0" v-model="item.cantidad" />
                                            <span class="input-group-text col-5">= S/. {{ item.total == 0 ? '0.00' : item.total }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <div class="col-md-12 col-lg-4 col col-sm-12 text-center">
                                    <strong> TOTAL EFECTIVO </strong>
                                    <button class="btn btn-sm w-100 mt-1 bg-main text-white">
                                        <strong>APERTURA: S/. {{ bead.initial }}</strong>
                                    </button>
                                    <button class="btn btn-sm w-100 mt-1 bg-main text-white" @click="mtdShowModal('Ingresos')">
                                        <strong>INGRESOS: S/. {{ cpTotalEfectivo }}</strong>
                                    </button>

                                    <button class="btn btn-sm w-100 mt-1 bg-main text-white" @click="mtdShowModal('Egresos')">
                                        <strong>EGRESOS: S/. {{ cpTotalExpense }}</strong>
                                    </button>

                                    <button class="btn btn-sm w-100 mt-1 bg-main text-white">
                                        <strong>ANULACION: S/. {{ cpTotalAnulacion }}</strong>
                                    </button>

                                    <button class="btn btn-sm w-100 mt-1 bg-main text-white">
                                        <strong>TOTAL:S/. {{ cpEfectitoTotal }}</strong>
                                    </button>
                                </div>
                                <div class="col-md-12 col-lg-4 col col-sm-12 text-center">
                                    <strong> OTROS INGRESOS </strong>
                                    <div v-for="(item, index) in process.otroIngresos" :key="index">
                                        <button class="btn btn-sm w-100 mt-1 bg-main text-white" @click="mtdShowModal(item.label)">
                                            <strong>{{ item.label }}: S/.
                                                {{
                            item.label == "Transferencia"
                              ? cpTotalTransferencia
                              : item.label == "Tarjeta"
                              ? cpTotalTarjeta
                              : item.label == "Deposito"
                              ? cpTotalDeposito
                              : item.label == "Planilla"
                              ? cpTotalPlanilla
                              : item.total
                          }}
                                            </strong>
                                        </button>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-4 col col-sm-12 text-center">
                                    <strong> SALDOS </strong>
                                    <button class="btn btn-sm w-100 mt-1 bg-main text-white">
                                        <strong>Saldo efectivo S/{{ cpEfectitoTotal }}</strong>
                                    </button>

                                    <button class="btn btn-sm w-100 mt-1 bg-main text-white">
                                        <strong>Saldo General S/{{ cpSaldoGeneral }}</strong>
                                    </button>
                                    <button class="btn btn-sm w-100 mt-1 bg-main text-white">
                                        <strong>Vueltos Pte. S/{{ cpTotalTurned }}</strong>
                                    </button>
                                    <button class="btn btn-sm w-100 mt-1 bg-main text-white">
                                        <strong v-if="cpSobranteFaltante < 0">
                                            Faltante S/{{ cpSobranteFaltante }}</strong>
                                        <strong v-else> Sobrante S/{{ cpSobranteFaltante }}</strong>
                                    </button>
                                </div>
                            </div>
                            <div class="row d-flex mt-2">
                                <div class="col-md-4 col-sm-12 h-100">
                                    <label class="btn btn-sm w-100 mt-1 bg-main text-white">
                                        <strong>Vueltos Rec: {{ parseFloat(process.turned_received).toFixed(2) }}</strong>
                                    </label>
                                </div>
                                <div class="col-md-4 col-sm-12 h-100">
                                    <label class="btn btn-sm w-100 mt-1 bg-main text-white">
                                        <strong>Depósitos: {{ parseFloat(process.deposit).toFixed(2) }}</strong>
                                    </label>
                                </div>
                                <div class="col-md-4 col-sm-12 h-100">
                                    <label class="btn btn-sm w-100 mt-1 bg-main text-white">
                                        <strong>Retiros: {{ parseFloat(process.retirement).toFixed(2) }}</strong>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" @mtdCloseFinal="mtdCloseFinal" @mtdSendPermissionCloseBead="mtdSendPermissionCloseBead" />
    <cModalRetiroDepositoVue :title="modalRetiros.title" :bead="beadActual" :boo_modal="modalRetiros.modal_form" :item="modalRetiros.item" @closeModalRetiros="closeModalRetiros" />
    <cModalEgresoIngresoVue :type="type" :close='close' @mtdclosemodal="mtdclosemodal" :boo_modal="modalIE.modal_form" :title="modalIE.title" :data="modalIE.data" />
    <cModalDetalleVue :title="modalDetalle.title" :boo_modal="modalDetalle.modal_form" :item="modalDetalle.item" @closeModalDetalle="closeModalDetalle" />
</div>
</template>

<script>
import cModalRetiroDepositoVue from "../modal/cModalRetiroDeposito.vue";
import cModalEgresoIngresoVue from "../modal/cModalEgresoIngreso.vue";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
import cModalDetalleVue from "../modal/cModalDetalle.vue";

import {
    mapActions
} from 'vuex'

export default {
    name: "c-admision-pages-close",
    components: {
        SweetAlert,
        cModalRetiroDepositoVue,
        cModalDetalleVue,
        cModalEgresoIngresoVue,
    },
    data() {
        return {
            swal: null,
            beadActual: {},
            process: {
                billetes: [{
                        label: "200.00",
                        total: 0,
                        key: "dosCientos",
                        value: 200,
                        cantidad: "",
                    },
                    {
                        label: "100.00",
                        total: 0,
                        key: "unCiento",
                        value: 100,
                        cantidad: "",
                    },
                    {
                        label: "50.00",
                        total: 0,
                        key: "cincuenta",
                        value: 50,
                        cantidad: "",
                    },
                    {
                        label: "20.00",
                        total: 0,
                        key: "veinte",
                        value: 20,
                        cantidad: "",
                    },
                    {
                        label: "10.00",
                        total: 0,
                        key: "diez",
                        value: 10,
                        cantidad: "",
                    },
                ],
                monedas: [{
                        label: "5.00",
                        total: 0,
                        key: "cinco",
                        value: 5,
                        cantidad: "",
                    },
                    {
                        label: "2.00",
                        total: 0,
                        key: "dos",
                        value: 2,
                        cantidad: ""
                    },
                    {
                        label: "1.00",
                        total: 0,
                        key: "uno",
                        value: 1,
                        cantidad: ""
                    },
                    {
                        label: "0.50",
                        total: 0,
                        key: "cincuentaCentimos",
                        value: 0.5,
                        cantidad: "",
                    },
                    {
                        label: "0.20",
                        total: 0,
                        key: "veinteCentimos",
                        value: 0.2,
                        cantidad: "",
                    },
                    {
                        label: "0.10",
                        total: 0,
                        key: "diezCentimos",
                        value: 0.1,
                        cantidad: "",
                    },
                ],
                otroIngresos: [{
                        label: "Transferencia",
                        total: 0,
                        value: null,
                    },
                    {
                        label: "Tarjeta",
                        total: 0,
                        value: null,
                    },
                    {
                        label: "Deposito",
                        total: 0,
                        value: null,
                    },
                    {
                        label: "Planilla",
                        total: 0,
                        value: null,
                    },
                ],
                observation: "",
                incomes: [],
                expenses: [],
                anulaciones: [],
                pasajes: [],
                totEfectivo: 0,
                totTurned: 0,
                totIncome: 0,
                totExpense: 0,
                totAnulacion: 0,
                totTransferencia: 0,
                totDeposito: 0,
                totTarjeta: 0,
                totPlanilla: 0,
                totalArqueo: 0,
                retirement: 0,
                deposit: 0,
                initial: 0,
                turned_received:0,
            },
            data: [],
            finalCash: 0,
            filter: {
                categorie: 0,
                subcategorie: 0,
                eps: true,
                particular: true,
            },
            categories: [],
            categoriesModal: [],
            subcategories: [],
            subcategoriesModal: [],
            /* fin */
            dataCampus: [],
            dataBeadsList: [],
            type: "",
            dataReport: [],
            dataReportView: [],
            filterModal: {
                campu_id: 0,
                bead_id: 0,
                begindate: null,
                enddate: null,
                eps: true,
                particular: true,
                categorie: 0,
                subcategorie: 0,
            },
            excelIncome: [],
            excelExpense: [],
            /** retiros */
            modalRetiros: {
                title: "",
                modal_form: false,
                item: [],
            },
            modalIE: {
                title: "",
                modal_form: false,
                data: {},
            },
            modalDetalle: {
                title: "",
                modal_form: false,
                item: [],
            },
        };
    },
    created() {
        this.mtdDataBeads();
    },
    computed: {
        cpTotalEfectivo() {
            let tot = 0;
            this.process.incomes.forEach((element) => {
                element.charge.forEach((ele) => {
                    if (ele.method == "efectivo") {
                        tot += parseFloat(ele.amount);
                    }
                });
            });
            this.process.totIncome = parseFloat(tot).toFixed(2);
            return parseFloat(tot).toFixed(2);
        },
        cpTotalExpense() {
            // let tot = 0;
            // this.process.expenses.forEach((element) => {
            //   if (element.method == "efectivo") {
            //     tot += parseFloat(element.amount);
            //   }
            // });
            // this.process.pasajes.forEach(element => {
            //   tot += parseFloat(element.payment);
            // });
            this.process.totExpense = parseFloat(this.beadActual.expense).toFixed(2);
            return parseFloat(this.process.totExpense).toFixed(2);
        },
        cpTotalAnulacion() {
            /*
            this.process.totAnulacion = parseFloat(this.bead.cancellations;).toFixed(2);
            return parseFloat(this.process.totAnulacion).toFixed(2);
            */
            this.process.totAnulacion = this.beadActual.cancellations !== undefined ? parseFloat(this.beadActual.cancellations).toFixed(2) : '0.00';
            return this.process.totAnulacion;
        },
        cpTotalTurned() {
            this.process.totTurned = parseFloat(this.bead.turned).toFixed(2);
            return parseFloat(this.process.totTurned).toFixed(2);
        },
        cpEfectitoTotal() {
            let tot =
                parseFloat(this.bead.initial) +
                parseFloat(this.process.totIncome) -
                parseFloat(this.process.totExpense) +
                parseFloat(this.process.deposit) -
                parseFloat(this.process.retirement) -
                parseFloat(this.process.totAnulacion)+
                parseFloat(this.process.turned_received);
            // +
            // parseFloat(this.process.totTurned);
            this.process.totEfectivo = parseFloat(tot);
            parseFloat(this.process.retirement);
            // +
            // parseFloat(this.process.totTurned);
            this.process.totEfectivo = parseFloat(tot).toFixed(2);
            return parseFloat(tot).toFixed(2);
        },
        cpTotalTransferencia() {
            let tot = 0;
            this.process.incomes.forEach((element) => {
                element.charge.forEach((ele) => {
                    if (ele.method == "transferencia") {
                        tot += parseFloat(ele.amount);
                    }
                });
            });
            this.process.totTransferencia = parseFloat(tot).toFixed(2);
            return parseFloat(tot).toFixed(2);
        },
        cpTotalTarjeta() {
            let tot = 0;
            this.process.incomes.forEach((element) => {
                element.charge.forEach((ele) => {
                    if (ele.method == "tarjeta") {
                        tot += parseFloat(ele.amount);
                    }
                });
            });
            this.process.totTarjeta = parseFloat(tot).toFixed(2);
            return parseFloat(tot).toFixed(2);
        },
        cpTotalDeposito() {
            let tot = 0;
            this.process.incomes.forEach((element) => {
                element.charge.forEach((ele) => {
                    if (ele.method == "deposito") {
                        tot += parseFloat(ele.amount);
                    }
                });
            });
            this.process.totDeposito = parseFloat(tot).toFixed(2);
            return parseFloat(tot).toFixed(2);
        },
        cpTotalPlanilla() {
            let tot = 0;
            this.process.incomes.forEach((element) => {
                element.charge.forEach((ele) => {
                    if (ele.method == "planilla") {
                        tot += parseFloat(ele.amount);
                    }
                });
            });
            this.process.totPlanilla = parseFloat(tot).toFixed(2);
            return parseFloat(tot).toFixed(2);
        },
        cpTotalT() {
            let tot = 0;
            this.process.incomes.forEach((element) => {
                if (element.method == "tarjeta") {
                    tot += parseFloat(element.amount);
                }
            });
            this.process.totTarjeta = parseFloat(tot).toFixed(2);
            return parseFloat(tot).toFixed(2);
        },
        cpSaldoGeneral() {
            let tot =
                parseFloat(this.process.totTarjeta) +
                parseFloat(this.process.totDeposito) +
                parseFloat(this.process.totTransferencia) +
                parseFloat(this.process.totPlanilla);
            return parseFloat(tot).toFixed(2);
        },
        cpSobranteFaltante() {
            let dif =
                parseFloat(this.finalCash) -
                parseFloat(this.process.totEfectivo) -
                parseFloat(this.process.totTurned);
            return parseFloat(dif).toFixed(2);
        },
        cpSubcategories() {
            let view = [];
            this.subcategories.forEach((element) => {
                if (this.filter.categorie == element.categorie) {
                    view.push(element);
                }
            });
            this.filter.subcategorie = 0;
            return view;
        },
        
        cpTableBody() {
            let html = "";
            let boo = true; // true => si hay filtro
            if (this.filter.categorie == 0 && this.filter.subcategorie == 0) {
                boo = false;
            }
            let temp = [];
            if (this.filter.eps == true && this.filter.particular == true) {
                temp = this.data;
            } else if (this.filter.eps == true && this.filter.particular == false) {
                temp = this.data.filter(function (el) {
                    return el.type != "particular";
                });
            } else if (this.filter.eps == false && this.filter.particular == true) {
                temp = this.data.filter(function (el) {
                    return el.type == "particular";
                });
            } else {
                temp = [];
            }

            temp.forEach((element, index) => {
                let itemHtml =
                    "<tr>" +
                    "<td>" +
                    element.categorie +
                    "</td>" +
                    "<td>" +
                    element.subcategorie +
                    "</td>" +
                    "<td>" +
                    element.servicio +
                    "</td>" +
                    "<td>" +
                    parseFloat(element.efectivo).toFixed(2) +
                    "</td>" +
                    "<td>" +
                    parseFloat(element.otros).toFixed(2) +
                    "</td>" +
                    "<td>" +
                    parseFloat(element.total).toFixed(2) +
                    "</td>" +
                    "</td>" +
                    "</td>";
                if (!boo) {
                    html += itemHtml;
                } else {
                    if (this.filter.subcategorie != 0) {
                        if (
                            element.subcategorie_id != 0 &&
                            element.subcategorie_id == this.filter.subcategorie
                        ) {
                            html += itemHtml;
                        }
                    } else {
                        if (
                            element.categorie_id != 0 &&
                            element.categorie_id == this.filter.categorie
                        ) {
                            html += itemHtml;
                        }
                    }
                }
            });
            return html;
        },
    },
    props: {
        bead: {},
        originPage: String,
        close: Number
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdDataBeads: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Bead/reportClose",
                    token: this.$store.getters.get__token,
                    params: {
                        id: this.bead.id,
                        pageOrigin: this.originPage,
                    },
                })
                .then((response) => {
                    this.bead.authorize = response.data.bead.authorize;
                    this.beadActual = response.data.bead;
                    this.process.bead = this.bead.id;
                    this.process.retirement = response.data.bead.retirement;
                    this.process.deposit = response.data.bead.deposit;
                    this.process.incomes = response.data.income;
                    this.process.expenses = response.data.expense;
                    this.process.anulaciones = response.data.cancel;
                    this.process.pasajes = response.data.pasajes;
                    this.process.initial = response.data.bead.initial;
                    this.process.turned_received = response.data.bead.turned_received;
                    this.dataCampus = response.data.campus;
                    if (response.data.bead.authorize != 0) {
                        let cierre = JSON.parse(response.data.transaction.json_cierre);
                        this.process.billetes = cierre.billetes;
                        this.process.monedas = cierre.monedas;
                        this.cpContarBilletes();
                    }
                    this.mtdSetCategories();
                    this.mtdSetSubcategories();
                })
                .catch((errors) => {});
        },
        mtdSetCategories: function () {
            let str = "";
            this.data = [];
            this.process.incomes.forEach((element) => {
                let efec = 0;
                let efect = [];
                let otro = 0;
                let otros = [];

                element.charge.forEach((ele) => {
                    if (ele.method == "efectivo") {
                        element.sale.detail.forEach((d) => {
                            let price = d.validacion == 0 ? d.price : parseFloat(d.price - parseFloat(d.monto / d.quantity)) ;
                            efect.push(parseFloat(price).toFixed(2));
                        });
                        //efec += parseFloat(ele.amount);
                    } else {
                        element.sale.detail.forEach((d) => {
                            let price = d.validacion == 0 ? d.price : d.price - parseFloat(d.monto / d.quantity) ;
                            //otro = parseFloat(d.price);
                            otros.push(parseFloat(price));
                        });
                    }
                });

                /*
        if (element.sale.method == "efectivo") {
          efec = parseFloat(element.sale.amount);
        } else {
          element.sale.detail.forEach((d) => {
            //otro = parseFloat(d.price);
            otros.push(parseFloat(d.price));
          });
        }
*/
                element.sale.detail.forEach((ele, index) => {
                    if (!str.includes("," + ele.subsub_categorie.subategorie.categorie.id + ",")) {
                        this.categories.push({
                            label: ele.subsub_categorie.subategorie.categorie.name,
                            id: ele.subsub_categorie.subategorie.categorie.id,
                        });
                        str += "," + ele.subsub_categorie.subategorie.categorie.id + ",";
                    }
                    let totalDetail = ele.validacion == 1 ? parseFloat(ele.quantity * ele.price) - ele.monto : parseFloat(ele.quantity * ele.price);
                    this.data.push({
                        categorie_id: ele.subsub_categorie.subategorie.categorie.id,
                        categorie: ele.subsub_categorie.subategorie.categorie.name,
                        subcategorie_id: ele.subsub_categorie.subategorie.id,
                        subcategorie: ele.subsub_categorie.subategorie.name,
                        servicio: ele.subsub_categorie.name + " (" + ele.quantity + ")",
                        efectivo: efect[index] !== undefined ? efect[index] : 0.0,
                        otros: otros[index] !== undefined ? otros[index] : 0.0,
                        total: element.sale.detail.length > 1 ?
                            parseFloat(totalDetail) : parseFloat(element.amount).toFixed(2),
                        type: element.sale.type_patient,
                    });
                });
            });
        },
        mtdSetSubcategories: function () {
            let str = "";
            this.process.incomes.forEach((element) => {
                element.sale.detail.forEach((ele) => {
                    if (!str.includes("," + ele.subsub_categorie.subategorie.id + ",")) {
                        this.subcategories.push({
                            label: ele.subsub_categorie.subategorie.name,
                            id: ele.subsub_categorie.subategorie.id,
                            categorie: ele.subsub_categorie.subategorie.categorie.id,
                        });
                        str += "," + ele.subsub_categorie.subategorie.id + ",";
                    }
                });
            });
        },
        /**abrir modal retiros/depositos */
        cpContarBilletes: function () {
            let total = 0;
            this.process.billetes.forEach((element) => {
                let can = 0;
                if (!element.cantidad) {
                    can = 0;
                }
                can = element.cantidad != "" && element.cantidad != null ?
                    parseFloat(element.cantidad).toFixed(2) : 0;
                let tot = parseFloat(element.value).toFixed(2);
                element.total = tot == NaN ? 0 : parseFloat(can * tot).toFixed(2);
                total += parseFloat(element.total);
            });

            this.process.monedas.forEach((element) => {
                let can = 0;
                if (!element.cantidad) {
                    can = 0;
                }
                can = element.cantidad != "" && element.cantidad != null ?
                    parseFloat(element.cantidad).toFixed(2) : 0;
                let tot = parseFloat(element.value).toFixed(2);
                element.total = tot == NaN ? 0 : parseFloat(can * tot).toFixed(2);
                total += parseFloat(element.total);
            });
            this.finalCash = total;
        },
        /* Cerrar caja o pedir el permiso para el cierre de caja */
        mtdCloseBead: function (status) {
            this.process.otroIngresos.forEach((ingreso) => {
                switch (ingreso.label) {
                    case "Transferencia":
                        ingreso.total = parseFloat(this.cpTotalTransferencia).toFixed(2);
                        break;
                    case "Tarjeta":
                        ingreso.total = parseFloat(this.cpTotalTarjeta).toFixed(2);
                        break;
                    case "Deposito":
                        ingreso.total = parseFloat(this.cpTotalDeposito).toFixed(2);
                        break;
                    case "Planilla":
                        ingreso.total = parseFloat(this.cpTotalPlanilla).toFixed(2);
                        break;
                }
            });

            if (this.finalCash > 0) {
                this.process.status = status;
                this.process.box = this.bead.box_id;
                this.process.vueltos = []; // falta
                this.process.initial = this.bead.initial;
                this.process.observation = "";
                this.process.bead = this.bead.id;
                if (this.bead.authorize == 0) {
                    this.mtdSendPermissionCloseBead();
                } else if (this.bead.authorize == 1) {
                    let title = "Va reenviar la Solictud de Aprobación";
                    let buttonText = "Reenviar";
                    this.$refs.SweetAlert.showActionBeads(title, buttonText, 1);
                } else if (this.bead.authorize == 2) {
                    let title = "Cerrar Caja";
                    let buttonText = "Confirmar";
                    this.$refs.SweetAlert.showActionBeads(title, buttonText, 2);
                } else {
                    let title = "Enviar Corrección para la Autorización";
                    let buttonText = "Enviar";
                    this.$refs.SweetAlert.showActionBeads(title, buttonText, 1);
                }
            } else {
                this.$refs.SweetAlert.showWarning("Digite cantidades de arqueo");
            }
        },
        mtdSendPermissionCloseBead() {
            //enviar sin guardar los incomes y expenses
            let processCopy = JSON.parse(JSON.stringify(this.process));
            processCopy.incomes = [];
            processCopy.expenses = [];

            this.post({
                    url: this.$store.getters.get__url + "/Bead/storeClosePermission",
                    token: this.$store.getters.get__token,
                    params: {
                        process: processCopy
                    },
                })
                .then((response) => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.$emit("mtdChangePage", "home");
                })
                .catch((errors) => {
                    console.log(errors)
                });
        },
        /** REPORTES DE EGRESOS E INGRESOS*/
        mtdReportBead: function (type) {
            this.type = type;
            let typename = type == 'INGRESO' ? 'INGRESOS' : 'EGRESOS';
            let datasend = {
                dataCampus: this.dataCampus,
                dataReport: this.dataReport,

            }
            this.modalIE= {
                title: "REPORTE DE CAJA - " + typename,
                modal_form: true,
                data: datasend,
            };
        },
        
        mtdclosemodal(){
            this.modalIE= {
                title: "",
                modal_form: false,
                data: {},
            };
            this.type = "";
        },
        /* Modal de detalle de ingresos, planilla, etc */
        closeModalDetalle: function () {
            this.modalDetalle = {
                title: "",
                modal_form: false,
                item: [],
            };
        },
        mtdShowModal: function (title) {
            this.modalDetalle.title = title;
            if (title == "Ingresos") {
                if (title !== "Planilla") {
                    this.modalDetalle.modal_form = true;
                }
                this.process.incomes.forEach((income) => {
                    const efectivoCharge = income.charge.find(
                        (charge) => charge.method === "efectivo"
                    );
                    // Si encontramos el charge efectivo, agregamos solo ese ingreso
                    if (efectivoCharge) {
                        this.modalDetalle.item.push({
                            date: income.date,
                            vaucher_fullname: title !== "Egresos" ? income.vaucher_fullname : null,
                            serie: income.serie,
                            number: income.number,
                            chargeAmount: efectivoCharge.amount,
                            state: income.state,
                        });
                    }
                });
            } else if (title == "Egresos") {
                this.modalDetalle.modal_form = true;
                this.process.expenses.forEach((element) => {
                    this.modalDetalle.item.push(element);
                });
            } else {
                if (title == "Tarjeta") {
                    this.modalDetalle.modal_form = true;
                    this.process.incomes.forEach((income) => {
                        const efectivoCharge = income.charge.find(
                            (charge) => charge.method === "tarjeta"
                        );
                        // Si encontramos el charge efectivo, agregamos solo ese ingreso
                        if (efectivoCharge) {
                            this.modalDetalle.item.push({
                                date: income.date,
                                vaucher_fullname: income.vaucher_fullname,
                                serie: income.serie,
                                number: income.number,
                                chargeAmount: efectivoCharge.amount,
                                state: income.state,
                            });
                        }
                    });
                }
                if (title == "Transferencia") {
                    this.modalDetalle.modal_form = true;
                    this.process.incomes.forEach((income) => {
                        const efectivoCharge = income.charge.find(
                            (charge) => charge.method === "transferencia"
                        );
                        // Si encontramos el charge efectivo, agregamos solo ese ingreso
                        if (efectivoCharge) {
                            this.modalDetalle.item.push({
                                date: income.date,
                                vaucher_fullname: income.vaucher_fullname,
                                serie: income.serie,
                                number: income.number,
                                chargeAmount: efectivoCharge.amount,
                                state: income.state,
                            });
                        }
                    });
                }
                if (title == "Deposito") {
                    this.modalDetalle.modal_form = true;
                    this.process.incomes.forEach((income) => {
                        const efectivoCharge = income.charge.find(
                            (charge) => charge.method === "deposito"
                        );
                        // Si encontramos el charge efectivo, agregamos solo ese ingreso
                        if (efectivoCharge) {
                            this.modalDetalle.item.push({
                                date: income.date,
                                vaucher_fullname: income.vaucher_fullname,
                                serie: income.serie,
                                number: income.number,
                                chargeAmount: efectivoCharge.amount,
                                state: income.state,
                            });
                        }
                    });
                }
                if (title == "Planilla") {
                    this.modalDetalle.modal_form = true;
                    this.process.incomes.forEach((income) => {
                        const efectivoCharge = income.charge.find(
                            (charge) => charge.method === "planilla"
                        );
                        // Si encontramos el charge efectivo, agregamos solo ese ingreso
                        if (efectivoCharge) {
                            this.modalDetalle.item.push({
                                date: income.date,
                                vaucher_fullname: income.vaucher_fullname,
                                serie: income.serie,
                                number: income.number,
                                chargeAmount: efectivoCharge.amount,
                                state: income.state,
                            });
                        }
                    });
                }
            }

        },
        mtdCloseFinal() {
            this.post({
                    url: this.$store.getters.get__url + "/Bead/closeFinal",
                    token: this.$store.getters.get__token,
                    params: {
                        process: this.process
                    },
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        this.$refs.SweetAlert.showSuccess("Arqueo de Caja Finalizado");
                        const beadId = this.bead.id;
                        window.open(
                            this.$store.getters.get__url + "/ReportesCajas/viewpdf/cierre/" + beadId,
                            "_blank"
                        );
                        this.$emit("mtdBackCampus");
                    }

                })
                .catch((errors) => {
                    console.log(errors)
                });
        },
        mtdModalRetiros: function () {
            this.modalRetiros.title = "REGISTRAR PERMISOS PARA DEPÓSITOS O RETIROS";
            this.modalRetiros.modal_form = true;
        },
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        closeModalRetiros: function () {
            this.modalRetiros = {
                action: "",
                title: "",
                modal_form: false,
                item: [],
            };
        },

        returnHome: function () {
            this.$emit('mtdChangePage', 'home');
        }
    }
}
</script>
