<template>
<div class="">
  <div class="container d-flex justify-content-end" v-if="type == 3">
    <div class="btn-home mt-3 mb-2 ms-auto">
        <button type="button" class="btn btn-outline-main" @click="mtdSendOption('homeCirujano')">
            <i class="fas fa-home-lg-alt"></i> Inicio
        </button>
    </div>
  </div>
    <div id="btn_home" class="c-app align-items-center">

        <div class="d-grid gap-2 mx-auto text-center col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <h2>HOME ASISTENCIAL</h2>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'HomeComponent',
    props: {
        type: Number,
    },
    components: {},
    data() {
        return {
            page: null,
            swal: 'TITULO POR  PROPS',
        }
    },
    created() {

    },
    methods: {
        mtdGetStyle: function (option) {
            if (option == this.page) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return 'color:white';
            return '';
        },
        mtdSendOption: function (page) {
            this.page = page;
            this.$emit('mtdChangePage', this.page)
        }
    },
}
</script>
