<template>
<CModal @mtdclosemodal="mtdclosemodal" :show.sync="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-2 row mx-0">
        <div class="col-md-12 col-lg-12 col-xs-12 mb-2">
            <div class="mb-3">
                <span class="fw-bold">Total Factura: </span>
                <span class="fw-semibold">{{data.moneda == 'SOLES' ? 'S/.' : '$.'}} {{ data.totalBill }}</span>
            </div>
            <div class="input-group mb-3" v-for="(item, index) in centralCost" :key="index">
                <div v-if="data.option == 1" class="input-group-text">
                    <input class="form-check-input mt-0" type="checkbox" placeholder="0.00" aria-label="Checkbox for following text input" v-model="item.checked" @change="mtdCompletar(index)">
                </div>
                <span class="input-group-text col-lg-5 col-md-8">{{item.name}}</span>
                <input type="text" class="form-control" aria-label="Text input with checkbox" placeholder="0.00" v-model="item.value" :disabled="item.checked !== true">
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdclosemodal" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <button v-if="data.option == 1" class="btn btn-success btn-sm" @click="mtdInsert" :disabled="mtdDisableButton">
            <i class="fas fa-save" />&nbsp; Guardar
        </button>
        &nbsp;
    </template>
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import CButtonClose from "@/components/shared/modals/cButtonClose.vue";
import {
    mapActions
} from "vuex";
export default {
    name: "c-logistica-oc-bill-centralcost",
    components: {
        CModal,
        CButtonClose,
    },
    data() {
        return {
            centralCost: [],
        };
    },
    watch: {
        data: {
            handler(newVal) {
                if (newVal.central) {
                    if (newVal.option == 1) {
                        this.centralCost = newVal.central.map(item => ({
                            ...item,
                            checked: false,
                            value: ''
                        }));
                    } else {
                        this.centralCost = newVal.central
                    }
                }
            },
            immediate: true, // Para ejecutar el watcher inmediatamente al inicio
        }
    },
    props: {
        title: String,
        boo_modal: Boolean,
        data: Object,
    },
    computed: {
        mtdDisableButton() {
            let total = 0;
            if (this.centralCost.length > 0) {
                this.centralCost.forEach(element => {
                    if (element.checked == true) {
                        total += parseFloat(element.value || 0);
                    }
                });
                return total.toFixed(2) !== parseFloat(this.data.totalBill).toFixed(2);
            }else{
                return false;
            }
        }
    },

    methods: {
        ...mapActions(["get", "post"]),
        mtdCompletar(index) {
            let count = 0;
            this.centralCost.forEach(element => {
                if (element.checked == true) {
                    count += 1;
                }
            });
            this.centralCost[index].value = count == 1 && this.centralCost[index].checked ? this.data.totalBill : '';
            if (count > 1) {
                this.centralCost.forEach(element => {
                    element.value = ""
                });
            }
        },
        mtdclosemodal() {
            this.amount = "";
            let resul = this.mtdDisableButton;
            if (this.data.option === 1 && resul === false) {
                this.$emit('mtdcloseModal');
            } else if (this.data.option === 1) {
                Swal.fire({
                    icon: "error",
                    title: "Tiene que completar el formulario",
                    showConfirmButton: false,
                    timer: 1500,
                });
            } else {
                this.$emit('mtdcloseModal');
            }
        },
        validateAmount(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }

            this.amount = sanitizedValue;
        },
        mtdInsert: function () {
            //registrar en la tabla central cost bill
            let dataSend = [];
            this.centralCost.forEach(element => {
                if (element.value > 0 && element.checked == true) {
                    dataSend.push(element);
                }
            });
            this.post({
                    url: this.$store.getters.get__url + "/ordendecompra/storeCentralCost",
                    token: this.$store.getters.get__token,
                    params: {
                        id: this.data.id,
                        centralCosts: dataSend
                    },
                })
                .then((response) => {
                    Swal.fire({
                        text: "Registro correcto",
                        icon: "success",
                        confirmButtonColor: "#900052",
                    });
                    this.$emit('mtdContinuenRegister', response.data);
                })

                .catch((errors) => {});
        }

    },
};
</script>

<style scoped>
.text-alert {
    font-size: 9px;
}
</style>
