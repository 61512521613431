<template>
<CModal class="" @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="modalSize" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="container">
        <!-- FORMULARIO DE REGISTRO -->
        <div class="d-md-flex flex-wrap">
            <div class="col-xl-4 col-lg-4 col-md-6 px-1 mt-1">
                <label for="documentType" class="my-2">TIPO DE DOCUMENTO <span class="text-danger">*</span></label>
                <div class="input-group input-group-sm mt-1">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-id-card"></i>
                        </span>
                    </div>
                    <select class="form-control" id="documentType" v-model="data_detail.document_type" @change="onDocumentTypeChange">
                        <option value="" disabled>Selecciona un documento...</option>
                        <option v-for="documento in documentos" :key="documento.value" :value="documento.value">
                            {{ documento.label }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 px-1 mt-1">
                <label for="document" class="my-2">NUM. DE DOCUMENTO <span class="text-danger">*</span></label>
                <div class="input-group input-group-sm mt-1">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-id-card"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" id="document" placeholder="DNI del Paciente" @keypress="isNumber($event)" @keypress.enter="searchDocument" :disabled="!isDocumentTypeSelected" v-model="data_detail.document" maxlength="11" />
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 px-1 mt-1">
                <label for="patient" class="my-2">PACIENTE <span class="text-danger">*</span></label>
                <div class="input-group input-group-sm mt-1">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-user"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" id="patient" placeholder="Paciente" :disabled="disabledPatient" v-model="data_detail.patient" />
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 px-1 mt-1">
                <label for="date" class="my-2">D&Iacute;A <span class="text-danger">*</span></label>
                <div class="input-group input-group-sm mt-1">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-calendar"></i>
                        </span>
                    </div>
                    <input type="date" class="form-control" id="date" v-model="data_detail.date" @input="onVerificRoom" />
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 px-1 mt-1">
                <label for="hour_entry" class="my-2">HORA INICIO <span class="text-danger">*</span></label>
                <div class="input-group input-group-sm mt-1">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-clock"></i>
                        </span>
                    </div>
                    <input type="time" class="form-control" id="hour_entry" v-model="data_detail.hour_entry" @input="mtdCalculate" />
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 px-1 mt-1">
                <label for="timer" class="my-2">TIEMPO <span class="text-danger">*</span></label>
                <div class="input-group input-group-sm mt-1">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-clock"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" id="timer" v-model="data_detail.time" placeholder="30 min" @keypress="isNumber($event)" @input="mtdCalculate" />
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 px-1 mt-1">
                <label for="hour_end" class="my-2">HORA FIN</label>
                <div class="input-group input-group-sm mt-1">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-clock"></i>
                        </span>
                    </div>
                    <input type="time" class="form-control" disabled id="hour_end" v-model="data_detail.hour_end" />
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 px-1 mt-1">
                <label for="sop" class="my-2">SALA <span class="text-danger">*</span></label>
                <div class="input-group input-group-sm mt-1">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-bed"></i>
                        </span>
                    </div>
                    <select class="form-control" id="sop" v-model="data_detail.type_sop" @change="onVerificRoom">
                        <option value="" disabled>Selecciona un tipo de Sala...</option>
                        <option v-for="sop in operationRooms" :key="sop.id" :value="sop.id">
                            {{ sop.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 px-1 mt-1">
                <label class="my-2">HABITACI&Oacute;N</label>
                <div class="d-flex content-justify-between">
                    <div class="col-3 form-check form-switch mt-1">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="data_detail.type_room_state" :checked="data_detail.type_room_state == 1 ? true : false" aria-checked="false" />
                        <label class="form-check-label" for="flexSwitchCheckChecked">
                            {{ data_detail.type_room_state == 1 ? 'Si' : 'No' }}
                        </label>
                    </div>
                    <!-- selecccion el tipo de habitacion -->
                    <div class="col-9" v-if="data_detail.type_room_state">
                        <select class="form-control form-control-sm" v-model="data_detail.type_room">
                            <option value="" disabled>Selecciona un tipo de habitacion...</option>
                            <option v-for="room in rooms" :key="room.label" :value="room.value">
                                {{ room.label }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 px-1 mt-1">
                <div class="form-group w-100">
                    <div class="d-flex justify-content-between">
                        <div>
                            <label class="w-100 text-sm-start my-2">DIAGNÓSTICO <span class="text-danger">*</span></label>
                        </div>
                        <div class="form-check form-control-sm">
                            <input class="form-check-input  " v-model="otro" type="checkbox" value="" id="flexCheckDefault">
                            <label class="form-check-label" for="flexCheckDefault">
                                Otros
                            </label>
                        </div>
                    </div>
                    <v-select v-if="!otro" class=" form-control form-control-sm rounded custom-select-sm" input-id="cie" :options="dataCies" v-model="name" :reduce="cie => cie" label="name" placeholder="- Buscar Cie -" required :clearable="false" @search="mtdgetCies" @input="mtdaddDiagnosticoUnit">
                        <template slot="no-options">
                            No hay diagnósticos disponibles.
                        </template>
                    </v-select>
                    <div v-else class="">
                        <input type="text" v-model="data_detail.cie_text" class="form-control form-control-sm" id="exampleFormControlInput1" placeholder="Ingrese Diagnostico">
                    </div>
                </div>
            </div>
            <!-- equipo -->
            <div class="col-xl-4 col-lg-4 col-md-12 px-1 mt-1">
                <label for="equipo" class="my-2">EQUIPO</label>
                <div class="input-group input-group-sm mt-1">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fas fa-scalpel"></i>
                        </span>
                    </div>
                    <textarea class="form-control form-control-sm px-2 d-none d-lg-flex" v-model="data_detail.equipment" placeholder="Equipo" rows="5"></textarea>
                    <textarea class="form-control form-control-sm px-2 d-md-block d-lg-none" v-model="data_detail.equipment" placeholder="Equipo" rows="2"></textarea>
                </div>
            </div>
            <!-- staff -->
            <div class="col-xl-8 col-lg-8 col-md-12 px-1">
                <label for="equipo" class="my-2">PERSONAL MEDICO</label>
                <div class="d-md-flex flex-wrap pt-2">
                    <div class="col-md-6 px-2 mb-2 align-left" v-for="(item2, index) in data_detail.medical_staff" :key="index">
                        <div class="form-group d-md-flex">
                            <label :for="'inputreq' + index" class="col-md-5 col-sm-12 color-main fw-semibold">{{ item2.label }}</label>
                            <div class="col-xl-7 col-md-7 col-sm-12">
                                <input type="text" v-model="item2.value" class="form-control form-control-input form-control-sm" :id="'inputreq' + index" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2 px-1">
            <span class="text-danger">* Campos Obligatorios</span>
        </div>
    </div>
    <template #footer>
        <button @click="mtdclosemodal" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <button class="btn btn-success btn-sm" @click="mtdInsertData" :disabled="btnDisable">
            <i class="fa fa-save" />&nbsp;Guardar
        </button>
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-medico-modal-diagnostico",

    data() {
        return {
            swal: null,
            data_detail: {
                time: "",
                document_type: "",
                document: "",
                patient: "",
                patient_id: null,
                date: null,
                hour_entry: "",
                hour_end: "",
                type_room_state: false,
                type_room: "",
                type_sop: "",
                cies: [],
                equipment: "",
                cie_text: "",
                cie: "",
                medical_staff: [{
                        id: 1,
                        label: "Cirujano",
                        value: "",
                    },
                    {
                        id: 2,
                        label: "Asistente 1",
                        value: "",
                    },
                    {
                        id: 3,
                        label: "Asistente 2",
                        value: "",
                    },
                    {
                        id: 4,
                        label: "Anestesiólogo",
                        value: "",
                    },
                    {
                        id: 5,
                        label: "Instrumentista",
                        value: "",
                    },

                    {
                        id: 6,
                        label: "Pediatra",
                        value: "",
                    }

                ],
            },
            documentos: [{
                    value: "DNI",
                    label: "DNI"
                },
                {
                    value: "CARNET DE EXTRANJERIA",
                    label: "CARNET EXT."
                },
            ],
            rooms: [{
                    value: 1,
                    label: "INDIVIDUAL"
                },
                {
                    value: 2,
                    label: "CUARTO DOBLE"
                },
            ],
            /* cies */
            name: "",
            cie_id: '',
            cie_code: '',
            otro: false,
            operationRooms: [],
            dataCies: [],
            disabledPatient: true,
            isDocumentTypeSelected: false,
            windowWidth: window.innerWidth
        }
    },
    components: {
        CModal,
        SweetAlert
    },
    computed: {
        modalSize() {
            return this.windowWidth >= 992 ? 'xl' : 'md';
        },
        btnDisable() {
            return !this.data_detail.document_type || !this.data_detail.document || !this.data_detail.patient || !this.data_detail.date || !this.data_detail.hour_entry || !this.data_detail.time || !this.data_detail.type_sop || (this.data_detail.type_room_state && !this.data_detail.type_room) || (!this.otro && !this.name) || (this.otro && !this.data_detail.cie_text);

        }
    },
    created() {
        this.mtdGetSop();
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    props: {
        title: String,
        boo_modal: Boolean,
    },
    watch: {

    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdCalculate() {
            let startTime = this.data_detail.hour_entry;
            let duration = this.data_detail.time;

            if (startTime && duration) {
                let [hours, minutes] = startTime.split(':').map(Number);
                let durationMinutes = parseInt(duration);

                minutes += durationMinutes;
                hours += Math.floor(minutes / 60);
                minutes = minutes % 60;

                this.data_detail.hour_end = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
                //verificar la disponibilidad de sala:
                this.onVerificRoom();
            }
        },
        onDocumentTypeChange() {
            this.isDocumentTypeSelected = true;
            this.data_detail.document = "";
        },
        mtdaddDiagnosticoUnit: function (diagnostico) {
            this.name = diagnostico.name;
            this.cie_id = diagnostico.id;
            this.cie_code = diagnostico.code;
        },
        mtdGetSop: function () {
            this.post({
                    url: this.$store.getters.get__url + "/OperationsRoom/filter",
                    token: this.$store.getters.get__token,
                    params: {
                        campus_id: 1 //santa victoria
                    },
                })
                .then((response) => {
                    this.operationRooms = response.data;
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        mtdgetCies: function (search, loading) {
            if (search.length > 4) {
                this.dataCies = [];
                loading(true);
                this.get({
                        url: this.$store.getters.get__url + "/Consultation/cies/" + search,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        if (response.statusCode === 200) {
                            this.dataCies = response.data;
                        }
                        loading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        searchDocument: function () {
            if (this.data_detail.document_type === "DNI" && this.data_detail.document.length == 8) {
                this.get({
                        url: this.$store.getters.get__url + "/reniec/" + this.data_detail.document,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        if (response.data.boo == 3) {
                            // this.client.document = "";
                            this.$refs.SweetAlert.showWarning("DNI no encontrado");
                            this.disabledPatient = false;
                            this.data_detail.patient = "";
                        } else {
                            this.data_detail.patient = response.data.name + " " + response.data.last_name;
                            if (response.data.hasOwnProperty("patient")) {
                                //this.data_detail.patient_id = response.patient.id;
                            } else {
                                this.disabledPatient = true;
                            }
                        }
                    })
                    .catch((errors) => {
                        console.log(errors);
                    });
            } else {
                this.disabledPatient = false;
                this.data_detail.patient = "";
            }
        },

        mtdclosemodal(opt) {
            this.data_detail = {
                    document_type: "",
                    document: "",
                    time: "",
                    patient: "",
                    date: null,
                    hour_entry: "",
                    hour_end: "",
                    type_room_state: false,
                    type_room: "",
                    type_sop: "",
                    cies: [],
                    equipment: "",
                    medical_staff: [{
                            id: 1,
                            label: "Cirujano",
                            value: "",
                        },
                        {
                            id: 2,
                            label: "Asistente 1",
                            value: "",
                        },
                        {
                            id: 3,
                            label: "Asistente 2",
                            value: "",
                        },
                        {
                            id: 4,
                            label: "Anestesiólogo",
                            value: "",
                        },
                        {
                            id: 5,
                            label: "Instrumentista",
                            value: "",
                        },

                        {
                            id: 6,
                            label: "Pediatra",
                            value: "",
                        }

                    ],
                },
                this.$emit('mtdcloseModal', opt);
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        mtdInsertData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Sop/RegisterOrderSop",
                    token: this.$store.getters.get__token,
                    params: this.data_detail,
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.mtdclosemodal(0);
                    }

                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        onVerificRoom: function () {
            const {
                date,
                hour_entry,
                time,
                type_sop
            } = this.data_detail;

            if (date && hour_entry && time && type_sop) {
              //  this.funcion();
                this.post({
                        url: this.$store.getters.get__url + "/Venta/isOperationRoomAvailable",
                        token: this.$store.getters.get__token,
                        params: this.data_detail,
                    })
                    .then((response) => {
                        if (response.data == false) {
                            let message = "SALA OCUPADA PARA ESA FECHA";
                            this.$refs.SweetAlert.showWarning(message);
                        }
                    })
                    .catch((errors) => {});
            }

        }
    },

}
</script>
