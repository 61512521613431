<template>
  <div>
    <nav
      id="nav-menu"
      class="fixed-bottom py-0 d-flex justify-content-around mt-2"
      style="background: rgb(162, 162, 162); overflow-x: auto; white-space: nowrap;"
    >
    <div
        :style="mtdGetStyle('return')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('return')"
      >
        <span :style="mtdGetStyleSpan('return')" class="btn w-100"
          ><strong>DEVOLUCIONES </strong></span
        >
      </div>
      <div
        :style="mtdGetStyle('inventario')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('inventario')"
      >
        <span :style="mtdGetStyleSpan('inventario')" class="btn w-100"
          ><strong>INVENTARIO</strong></span
        >
      </div>
      <div
        :style="mtdGetStyle('movement')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('movement')"
      >
        <span :style="mtdGetStyleSpan('movement')" class="btn w-100"
          ><strong>MOVIMIENTO</strong></span
        >
      </div>
      <div
        :style="mtdGetStyle('histoMov')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('histoMov')"
      >
        <span :style="mtdGetStyleSpan('histoMov')" class="btn w-100"
          ><strong>HISTORIAL DE MOV.</strong></span
        >
      </div>
<!--       <div
        :style="mtdGetStyle('sinStock')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('sinStock')"
      >
        <span :style="mtdGetStyleSpan('sinStock')" class="btn w-100"
          ><strong>MEDICAMENTOS FALTANTES</strong></span
        >
      </div> -->
      <div
        :style="mtdGetStyle('reFac')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('reFac')"
      >
        <span :style="mtdGetStyleSpan('reFac')" class="btn w-100"
          ><strong>REPORTE DE FACTURAS</strong></span
        >
      </div>
      <div
        :style="mtdGetStyle('anular')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('anular')"
      >
        <span :style="mtdGetStyleSpan('anular')" class="btn w-100"
          ><strong>ANULAR DOCUMENTOS</strong></span
        >
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "NavBarComponent",
  props: {
    pageView: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      page: null,
    };
  },
  watch: {   
        pageView:{
            handler: function (newValue) {
                if( newValue !=''){
                    this.page = newValue
                    this.mtdGetStyle(newValue)
                    this.mtdGetStyleSpan(newValue)
              
                }
            },
            deep: true,
            immediate: true,
        },
        
    },
  methods: {
    mtdGetStyle: function (option) {
      if (
        option == this.page &&
        this.pageView != "home" &&
        this.pageView != "close" 
      )
        return "background: #900052";
      return "";
    },
    mtdGetStyleSpan: function (option) {
      if (
        option == this.page &&
        this.pageView !== "home" &&
        this.pageView !== "close"
      )
        return "color:white";
      return "text-dark";
    },
    mtdSendOption: function (page) {
      this.page = page;
      this.$emit("changePage", this.page);
    },
  },
};
</script>

<style scoped>
#contenedor-menu {
  justify-content: center;
}

#lista_menu_home {
  font-family: Arial;
  margin-bottom: 0;
}

ul {
  list-style-type: none;
  padding-left: 0 !important;
}

li {
  background-color: #900052;
  float: left;
  text-align: center;
  text-decoration: none;
}

li a {
  color: #ffffff;
  font-weight: bold;
  margin: 0;
}

.nav-footer:hover {
  color: #000000;
  /* background-color:rgb(162, 162, 162); */
  background: #900052;
}

#nav {
  position: absolute;
  float: left;
  bottom: 0px;
}
#img_logo {
  position: absolute;
  float: left;
  left: -5px;
  top: -5px;
}

@media (max-width: 800px), 
       (max-width: 820px) and (min-height: 1180px), 
       (max-width: 1024px) and (min-height: 1366px) {
  #title-user,
  #title-cash,
  #title,
  #title-date {
    text-align: right !important;
  }
  #img_logo img {
    width: 175px !important;
  }

  li {
    float: none;
  }

  #nav-menu {
    position: inherit;
  }
  /**  */
  #nav-menu {
    flex-direction: column;
  }
}
@media (min-width: 800px) {
  #title-cash,
  #title,
  #title-date {
    text-align: center !important;
  }
  #title-user {
    text-align: right !important;
  }
}

strong {
  font-size: 14px;
}
</style>
